<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingPersonDetails && personDetails">
    <header-dossier [user]="personDetails" [hasTabs]="false" (onNewInterview)="goToInterview()"></header-dossier>
    <div class="main-content-wrapper goal-details">
      <div class="back-button-container">
        <europ-button type="primary link" size="medium" iconLeft="assets/img/icons/arrow-left.svg"
          [label]="'generic.GO_BACK' | translate" (onButtonClicked)="goToPersonDetails()"></europ-button>
      </div>
      <ng-container *ngIf="!isLoadingGoalData">
        <!-- Banner scadenza valutazione finale -->
        <ng-container *ngIf="hasFinalEvaluation() && checkFinalEvaluationDatesValid()">
          <div class="final-notice-container">
            <p class="title">{{ ('final.ACTIVE_UNTIL' | translate) + (runningPhase.phaseEndDateForUser |
              date:
              'dd/MM/YYYY')  }}</p>
            <p class="descr">{{ 'final.ACTIVE_UNTIL_DESCR' | translate  }}</p>
          </div>
        </ng-container>
        <!-- card dettaglio goal -->
        <goal-card-detail [isHistory]="!isInCurrentYear()" [goal]="goalData" [isCollaborator]="true"
          (onEdit)="goToEditGoal()" (onDelete)="deleteGoal()" (onApprove)="goToEditGoal()" [isManager]="false"
          [commentData]="commentData" [managerData]="managerData" (onReadComment)="showComment()"
          [runningPhase]="runningPhase">
        </goal-card-detail>
        <!-- Cosa osserverai -->
        <europ-accordion *ngIf="goalData.type != 'PEOPLE_EMPOWERMENT'"
          [title]="'accordion.OBJECTIVE_REACHED' | translate" [tooltipModalMessage]="objectiveCompleteTooltipModal">
          <div class="accordion-content text">
            <p class="text-default">{{ goalData.objectiveDescription || ('generic.TO_BE_COMPLETED' |
              translate)}}</p>
          </div>
        </europ-accordion>
        <!-- Piano di sviluppo -->
        <europ-accordion *ngIf="goalData.isChallenge" [title]="'accordion.IDP' | translate"
          [tooltipModalMessage]="idpTooltipModal">
          <ng-container *ngIf="developmentActionsList && developmentActionsList.length">
            <div class="accordion-content goal-details-section">
              <div class="gray-background">
                <card-action *ngFor="let action of developmentActionsList" [isCollaborator]="true"
                  (onUpdateStatusToDevelopAction)="openModalUpdateStatusDevelopAction($event)" [loggedUserId]="userId"
                  (onDeleteComment)="openDeleteCommentModalDevelopAction($event)"
                  (onDeleteAction)="openDeleteModalDevelopAction($event)" (onEditAction)="goToEditAction($event)"
                  (onEditComment)="openEditCommentToDevelopAction($event)" (onReadComment)="showActionComment($event)"
                  (onInsertCommentToDevelopAction)="openInsertCommentToDevelopAction($event)" [object]="action">
                </card-action>
              </div>
              <div class="action-button-container" *ngIf="isInCurrentYear()">
                <europ-button *ngIf="!isLoadingGoalData && !isLoadingCanCreateDevelopmentActions"
                  (onButtonClicked)="goToCreateActionToGoal()" type="primary default" size="medium"
                  [label]="'developmentPlan.ADD_ACTION_BUTTON' | translate"></europ-button>
                <europ-button [disabled]="isFetchingCanShareActions" (onButtonClicked)="openDevelopActionsShareModal()"
                  *ngIf="developmentActionsList && developmentActionsList.length && canShareDevActions"
                  type="secondary default" size="medium" [label]="'developmentPlan.SHARE_ACTIONS' | translate">
                </europ-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(!developmentActionsList || !developmentActionsList.length)">
            <div class="no-data-found">
              <div class="no-data">
                <svg-icon src="assets/img/icons/coffee.svg" class="big-size blue-primary" [applyClass]="true">
                </svg-icon>
                <p class="no-data-text" translate="developmentPlan.NO_ACTIONS_FOR_THIS_GOAL"></p>
              </div>
              <div class="button-add-action-container">
                <europ-button *ngIf="!isLoadingGoalData && !isLoadingCanCreateDevelopmentActions && isInCurrentYear()"
                  (onButtonClicked)="goToCreateActionToGoal()" type="primary default" size="medium"
                  [label]="'developmentPlan.ADD_ACTION_BUTTON' | translate"></europ-button>
              </div>
            </div>
          </ng-container>
        </europ-accordion>
        <!-- Evidenze -->
        <europ-accordion [title]="'accordion.EVIDENCES' | translate" [tooltipModalMessage]="evidencesTooltipModal">
          <ng-container *ngIf="evidencesList && evidencesList.length">
            <div class="accordion-content goal-details-section">
              <div class="gray-background">
                <ng-container *ngFor="let evidence of evidencesList">
                  <card-evidence [goalTitle]="goalData.title" [isInChallengeDetail]="true" [object]="evidence"
                    (onFileDownload)="downloadFileOnEvidences($event, evidence.feedbackId)" [isHistory]="!isInCurrentYear()">
                  </card-evidence>
                </ng-container>
              </div>
              <div fxLayout.xs="column" class="action-button-container" *ngIf="isInCurrentYear()">
                <europ-button ngClass.xs="margin-bottom10" ngClass.gt-xs="margin-right10" [disabled]="isLoadingGoalData"
                  (onButtonClicked)="goToManageEvidences()" type="primary default" size="medium"
                  [label]="'evidences.MANAGE_EVIDENCES' | translate"></europ-button>
                <ng-container *ngIf="canAddEvidence()">
                  <europ-button [disabled]="isLoadingGoalData" (onButtonClicked)="goToCreateEvidenceToGoal()"
                    type="primary default" size="medium" [label]="'evidences.ADD_EVIDENCE' | translate"></europ-button>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(!evidencesList || !evidencesList.length)">
            <div class="no-data-found">
              <div class="no-data">
                <svg-icon src="assets/img/icons/coffee.svg" class="big-size blue-primary" [applyClass]="true">
                </svg-icon>
                <p class="no-data-text" translate="evidences.NO_EVIDENCES_FOR_THIS_GOAL"></p>
              </div>
              <div class="button-add-action-container" *ngIf="isInCurrentYear()">
                <europ-button (onButtonClicked)="goToCreateEvidenceToGoal()" type="primary default" size="medium"
                  [label]="'evidences.ADD_EVIDENCE' | translate"></europ-button>
              </div>
            </div>
          </ng-container>
        </europ-accordion>
        <!-- Feedback -->
        <europ-accordion [tooltipModalMessage]="feedbackTooltipModal" [title]="'accordion.FEEDBACK' | translate">
          <div class="accordion-content goal-details-section">
            <div class="gray-background">
              <!-- Lista Peer feedback -->
              <ng-container *ngIf="peerFeedbackList && peerFeedbackList.length">
                <card-peer *ngFor="let feedback of peerFeedbackList" [object]="feedback" [loggedUser]="personDetails"
                  [isManager]="false" (onEdit)="createPeerFeedback($event)"
                  (onFeedbackSend)="goToPeerFeedbackAnswer($event)" (onShowRequest)="openShowFeedbackRequest($event)"
                  (onDelete)="openDeletePeerFeedbackModal($event)">
                </card-peer>
              </ng-container>

              <!-- Placeholder se non peer feedback -->
              <ng-container *ngIf="!peerFeedbackList || !peerFeedbackList.length">
                <no-data [title]="('peerFeedback.TITLE' | translate)"
                  src="/assets/img/section/private-notes-placeholder.svg" [description]="peerFeedbackPlaceholder">
                </no-data>
              </ng-container>

              <!-- Pulsanti -->
              <div class="button-container development" *ngIf="peerFeedbackAllowed && isInCurrentYear()">
                <europ-button type="primary" [label]="('peerFeedback.createUpdate.INSERT_FEEDBACK' | translate)"
                  (onButtonClicked)="createPeerFeedback()"></europ-button>
              </div>
            </div>
          </div>
        </europ-accordion>
        <!-- Step praticabili (milestones) -->
        <europ-accordion [isDisabled]="true" [tooltipModalMessage]="milestonesTooltipModal"
          [title]="'accordion.MILESTONES' | translate">
        </europ-accordion>

        <!-- Valutazione finale / auto osservazione -->
        <ng-container *ngIf="hasFinalEvaluation()">
          <europ-accordion [tooltipModalMessage]="evaluationTooltipModal" [title]="'accordion.EVALUTAIONS' | translate"
            [statusData]="evaluationStatusData" [isDisabled]="!finalEvaluationEnabled()">
            <div class="accordion-content final-evaluation">

              <div class="divisor-line"></div>

              <ng-container *ngIf="goalData.goalEvaluation && goalData.goalEvaluation.length">
                <ng-container *ngFor="let evaluation of goalData.goalEvaluation">

                  <!-- valutazione manager -->
                  <ng-container *ngIf="evaluation.user && evaluation.user.userId != personDetails.userId">
                    <div class="final-evaluation-row clickable white-background"
                      (click)="openFinalEvaluationModal(evaluation)">
                      <div class="left-side">
                        <avatar-img [user]="evaluation.user" size="medium"></avatar-img>
                        <p class="user-name">{{ evaluation.user.forename[0] + '. ' +
                          evaluation.user.surname }}</p>
                        <evaluation [evaluation]="evaluation.evaluationScore"></evaluation>
                      </div>
                      <p class="compiled"> {{ ('final.COMPILED_IN' | translate) +
                        (evaluation.creationDate | date:
                        'dd.MM.YYYY') }}</p>
                    </div>
                  </ng-container>

                  <!-- Valutazione utente -->
                  <ng-container *ngIf="evaluation.user && evaluation.user.userId == personDetails.userId">
                    <div class="title-container">
                      <p class="self-title" translate="final.SELF_OBS"></p>
                      <div class="divisor-line"></div>
                    </div>

                    <div class="final-evaluation-row clickable" (click)="openFinalEvaluationModal(evaluation)">
                      <div class="left-side">
                        <avatar-img [user]="evaluation.user" size="medium"></avatar-img>
                        <p class="user-name">{{ evaluation.user.forename[0] + '. ' +
                          evaluation.user.surname }}</p>
                        <evaluation [evaluation]="evaluation.evaluationScore"></evaluation>
                      </div>
                      <p class="compiled"> {{ ('final.COMPILED_IN' | translate) +
                        (evaluation.creationDate | date:
                        'dd.MM.YYYY') }}</p>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <div class="action-button-container"
                *ngIf="isInCurrentYear() && (!goalData.goalEvaluation || !goalData.goalEvaluation.length)">
                <europ-button (onButtonClicked)="goToFinalEvaluation()" type="primary default" size="medium"
                  [label]="'final.SELF_OBS' | translate"></europ-button>
              </div>

            </div>
          </europ-accordion>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoadingGoalData">
        <div class="loader spinner-medium"></div>
      </ng-container>

    </div>
  </ng-container>
  <!-- Loader caricamento dati utente -->
  <ng-container *ngIf="isLoadingPersonDetails">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="confirmDeleteForUser" (onCancel)="closeDeleteModal()" (onClose)="closeDeleteModal()"
  (onConfirm)="confirmDeleteGoal()" cancelLabel="{{ 'generic.CANCEL' | translate}}"
  confirmLabel="{{ 'generic.DELETE' | translate}}">
  <modal-text-content [title]="deleteModalContent.title" [subtitle]="''" [text]="deleteModalContent.text">
  </modal-text-content>
</modal>

<!-- Modale per aggiungere un commento all'azione di sviluppo -->
<modal id="insertCommentDevelopmentPlanModal"
  [isDisabled]="developAction && (!newActionComment || !newActionComment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeInserCommentModalDevelopAction(true)"
  (onClose)="closeInserCommentModalDevelopAction(true)" (onConfirm)="addCommentToDevelopAction()"
  confirmLabel="{{ 'developmentPlan.INSERT_COMMENT_ACTION' | translate}}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.INSERT_COMMENT_TITLE' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="developAction">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="newActionComment && newActionComment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.INSERT_COMMENT_TITLE' | translate}}">
      <europ-text-area [bindValue]="newActionComment" [maxlength]="600"
        (onModelChanged)="developActionCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per modificare un commento all'azione di sviluppo -->
<modal id="editCommentDevelopmentPlanModal"
  [isDisabled]="commentToEdit && (!commentToEdit.comment || !commentToEdit.comment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeEditCommentModalDevelopAction(true)"
  (onClose)="closeEditCommentModalDevelopAction(true)" (onConfirm)="updateCommentForDevelopAction()"
  confirmLabel="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate }}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="commentToEdit">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="commentToEdit.comment && commentToEdit.comment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}">
      <europ-text-area [bindValue]="commentToEdit.comment" [maxlength]="600"
        (onModelChanged)="developActionEditCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per cancellare il commento dell'azione di sviluppo -->
<modal id="deleteDevelopActionCommentModal" [cancelLabel]="'No'" (onCancel)="closeDeleteCommentModalDevelopAction()"
  (onClose)="closeDeleteCommentModalDevelopAction()" (onConfirm)="deleteCommentDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d032" title="{{ 'developmentPlan.DELETE_COMMENT' | translate}}"
    text="{{ 'developmentPlan.DELETE_COMMENT_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per cancellare l'azione di sviluppo -->
<modal id="deleteDevelopmentAction" [cancelLabel]="'No'" (onCancel)="closeDeleteModalDevelopAction()"
  (onClose)="closeDeleteModalDevelopAction()" (onConfirm)="deleteDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d150" title="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION' | translate}}"
    text="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per aggiornare lo stato di avanzamento dell'azione di sviluppo -->
<modal id="updateStatusDevelopmentPlanModal" [isDisabled]="!developAction || !currentActionStatus"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeModalUpdateStatusDevelopAction()"
  (onClose)="closeModalUpdateStatusDevelopAction()" (onConfirm)="updateStatusDevelopAction()"
  confirmLabel="{{ 'developmentPlan.UPDATE_STATUS_ACTION' | translate}}">
  <modal-text-content modalId="d032" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.UPDATE_STATUS_MODAL_TITLE' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <input-container class="margin-bottom24" [instructions]="'developmentPlan.IMPOST_STATE' | translate"
    [isWithoutInfoIcon]="true" [isValued]="currentActionStatus && currentActionStatus.id" [isObbligatory]="true"
    title="{{'developmentPlan.UPDATE_STATUS_OF_THIS_ACTION' | translate}}">
    <europ-select [placeholder]="'developmentPlan.CHOOSE_STATUS_PLACEHOLDER' | translate"
      (onSelectedItem)="changeDevelopActionStatus($event)" [selectedItem]="currentActionStatus"
      [items]="developActionStatusesOptions" [searchable]="true" bindLabel="title"></europ-select>
  </input-container>
</modal>

<!-- Modale per condividere le azioni di sviluppo -->
<modal id="developActionsShareModal" (onCancel)="closeDevelopActionsShareModal()"
  (onClose)="closeDevelopActionsShareModal()" (onConfirm)="shareDevelopActions()"
  confirmLabel="{{ 'generic.SHARE' | translate}}" [cancelLabel]="'generic.ANNULL' | translate">
  <modal-text-content modalId="031" *ngIf="managerData" [noBackground]="true"
    image="assets/img/section/development-plan.svg" [title]="'developmentPlan.SHARE_DEVELOPMENT_PLAN' | translate"
    [subtitle]="'developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN' | translate"
    [text]="('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_1' | translate) + managerData.forename + ' ' + managerData.surname + ('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_2' | translate)">
  </modal-text-content>
</modal>

<!-- Modale eliminazione peer feedback -->
<modal id="deletePeerFeedback" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeletePeerFeedbackModal()" (onClose)="closeDeletePeerFeedbackModal(false)"
  (onConfirm)="closeDeletePeerFeedbackModal(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pf013" [title]="'peerFeedback.modals.DELETE_FEEDBACK_TITLE' | translate"
    [text]="'peerFeedback.modals.DELETE_FEEDBACK_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale mostra richiesta peer feedback -->
<modal id="modalShowRequest" (onClose)="closeShowFeedbackRequest()" (onConfirm)="closeShowFeedbackRequest()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <div class="peer-feedback-modal-request">
    <div class="title-code-wrapper full-width">
      <div class="title-container" translate="peerFeedback.card.REQUEST_TITLE"></div>
    </div>
    <div class="confirm-request-content" *ngIf="feedbackForRequestModal">
      <div class="section users">
        <div class="title" translate="peerFeedback.modals.REQUEST_FROM"></div>
        <div class="content">
          <div class="mini-user-card">
            <avatar-img size="small" [user]="feedbackForRequestModal.recipientUser"></avatar-img>
            <p class="name">{{ feedbackForRequestModal.recipientUser.forename + ' ' +
              feedbackForRequestModal.recipientUser.surname}}</p>
          </div>
        </div>
      </div>
      <div class="section" *ngif="feedbackForRequestModal.goal">
        <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
        <div class="content">{{ feedbackForRequestModal.goal && feedbackForRequestModal.goal.title}}</div>
      </div>
      <div class="section">
        <div class="title" translate="peerFeedback.modals.WHY"></div>
        <div class="content">{{ feedbackForRequestModal.request }}</div>
      </div>
    </div>
  </div>
</modal>

<!-- Modale per valutazione finale -->
<modal id="finalEvaluationModal" (onClose)="closeFinalEvaluationModal()" (onConfirm)="closeFinalEvaluationModal()"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <div class="final-evaluation-modal-container" *ngIf="finalEvaluationForModal">
    <p class="modal-title"
      translate="{{personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS' : 'final.TITLE'}}">
    </p>
    <div class="divisor-line"></div>
    <evaluation [evaluation]="finalEvaluationForModal.evaluationScore" customClass="big"></evaluation>
    <div class="divisor-line"></div>
    <div class="observation-container">
      <p class="title">
        {{ ('final.SELF_OBS_BY' | translate) + finalEvaluationForModal.user.forename + ' ' +
        finalEvaluationForModal.user.surname
        }}
      </p>
      <p class="descr">{{ ('final.INSERT_DATE' | translate) + (finalEvaluationForModal.creationDate | date:
        'dd/MM/YYYY') }}</p>
      <p class="title" translate="final.OBSERVATION"></p>
      <p class="descr">{{finalEvaluationForModal.observation}}</p>
    </div>
  </div>
</modal>