import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { PerfCareUserPhaseGoal, GetPerformanceCareTagsResponse, SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, CreateUserGoalForUserResponse, PerfCareGoalRentStatuses, PerfCareMacroGoalTypes, CreateUserGoalForManagerResponse, UpdateUserGoalForManagerResponse, GetGoalByIdForManagerResponse, GetUserGoalWeightSumForManagerResponse, GetBehaviorCareTagsResponse, ApproveGoalProposedBySubordinateUserResponse, UpdateApproveGoalCommentProposedBySubordinateUserResponse, RentStatusTypes } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { TabSection } from 'src/app/utils/classes.utils';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Tag, PerfCareTag } from '../../../commonclasses';
import { Params, ActivatedRoute } from '@angular/router';
import { Subordinateservice } from '../services/subordinates.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as ManagerActions from "../../../app/manager/ngrx/manager.actions";

@Component({
  selector: 'app-manager-createUpdateGoal',
  templateUrl: './createUpdateGoal.component.html',
  styleUrls: ['./createUpdateGoal.component.scss']
})
export class CreateUpdateGoalComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: any;
  personDetails$: any;
  personDetails: any;
  tabSectionsList: TabSection[] = [];
  differenceWeightSum: number = 0;
  goal: PerfCareUserPhaseGoal = {
    goalId: '',
    creationUserId: '',
    phaseId: '',
    userId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL
  };
  isHowSection: boolean = false;
  isFetchingCareOptions: boolean = false;
  getCareOptions$: Subscription | null = null;
  careOptionsCounter: number = 0;
  careOptions: PerfCareTag[] = [];
  isFetchingBehaviourOptions: boolean = false;
  getBehaviourOptions$: Subscription | null = null;
  behaviourOptionsCounter: number = 0;
  behaviourOptions: PerfCareTag[] = [];
  goalCreated: CreateUserGoalForUserResponse = [];
  createGoal$: Subscription | null = null;
  isFetchingCreateGoal: boolean = false;
  isFetchingUpdateGoal: boolean = false;
  updateGoal$: Subscription | null = null;
  isFetchingGetGoal: boolean = false;
  getGoal$: Subscription | null = null;
  goalId: string = '';
  goalCopy: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL
  };
  modalUpdateGoalTitle: string = '';
  modalUpdateGoalText: string = '';
  confirmModalUpdateGoalButton: any;
  isBackButton: boolean = false;
  goBackNoSave: boolean = false;
  charsGoalDescription: number = 600;
  charsGoalObjectiveDescription: number = 600;
  charsGoalHowToUseDescription: number = 600;
  charsGoalManagerComment: number = 600;
  maxTextAreaChars: number = 600;
  goToGoalObjectiveList: boolean = false;
  modalUpdateGoalTitleConfirmTextButton: string = '';
  modalUpdateGoalTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  isEdit: boolean = false;
  isEditApprove: boolean = false;
  weightOptions: any[] = [];
  weightOptionSelected: any = null
  isFetchingWeightSums: boolean = false;
  weightSum: number = 0;
  getWeightSum$: Subscription = new Subscription();
  getTotalWeightSection$: Subscription = new Subscription();
  isLoadingTotalWeightSection: boolean = false;
  totalWeightSection: number = 0;
  isFetchingApproveGoal: boolean = false;
  today = new Date();
  isSharedGoal: boolean = false;

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objCompletedTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  weightTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  deadlineTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  careTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  behaviourTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  applyCareTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  managerTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  describeWhatTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalUpdateGoalId: string = '';
  isEmpowermentGoal: boolean = false;
  isManagerOperationsApprove: boolean = false;
  isManagerOperations: boolean = false;
  isAfterSharing: boolean = false;
  getObjectiveOnHold$: Subscription = new Subscription;
  hasDraft: boolean = false;

  isSti: boolean = false;
  endYear: Date = new Date();

  constructor(
    private subordinatesService: Subordinateservice,
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      this.endYear = new Date(this.runningYear, 11, 31, 11, 59);
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;

              if (window.location.href.indexOf('afterSharing') > -1) {
                this.isAfterSharing = true;
                if (window.location.href.indexOf('individual') > -1) {
                  this.goal.macroGoalType = 'TEMP_INDIVIDUAL'
                } else {
                  this.goal.macroGoalType = 'TEMP_OPERATIONS';
                  this.isManagerOperations = true;
                }
              } if (window.location.href.indexOf('afterSharing') < 0) {
                this.isAfterSharing = false;
                if (window.location.href.indexOf('individual') > -1) {
                  this.goal.macroGoalType = PerfCareMacroGoalTypes.INDIVIDUAL;
                } else {
                  this.goal.macroGoalType = PerfCareMacroGoalTypes.OPERATIONS;
                  this.isManagerOperations = true;
                }
              }
              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];

                if (this.chargeSelectedUser && this.personDetails) {
                  if (this.goalId) {
                    this.isEdit = true;
                    this.getGoalById();
                  } else {
                    this.getTotalWeightSection();
                  }
                }
                // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                if (!this.personDetails) {
                  this.chargeSelectedUser = false;
                  this.getPersonDetails();
                }
              });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.WHAT',
        'generic.HOW',
        'goalSetting.createGoal.WHAT_TAB',
        'goalSetting.createGoal.HOW_TAB',
        'goalSetting.createGoal.CARE_ATTRIBUTES_MANAGER',
        'goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION',
        'goalSetting.createGoal.MANAGER_COMMENT',
        'generic.TITLE',
        'goalSetting.createGoal.OBJECTIVE_TITLE',
        'goalSetting.createGoal.OBJECTIVE_TITLE_TEXT',
        'generic.DESCRIPTION',
        'goalSetting.createGoal.DESCRIPTION_SUBTITLE',
        'goalSetting.createGoal.DESCRIPTION_TEXT',
        'goalSetting.createGoal.OBJECTIVE_DESCRIPTION',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT_SUBTITLE',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT_TEXT',
        'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE',
        'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_TEXT',
        'goalSetting.createGoal.MANAGER_COMMENT_SUBTITLE',
        'goalSetting.createGoal.MANAGER_COMMENT_TEXT'
      ])
      .subscribe(translations => {
        this.confirmModalUpdateGoalButton = document.getElementById('modalUpdateGoalForManager');
        this.tabSectionsList = [
          {
            id: 'whatTab',
            attrAriaLabel: translations['goalSetting.createGoal.WHAT_TAB'],
            title: translations['generic.WHAT'],
            isActive: true,
            isCompleted: this.isWhatTabCompleted()
          },
          {
            id: 'howTab',
            attrAriaLabel: translations['goalSetting.createGoal.HOW_TAB'],
            title: translations['generic.HOW'],
            isCompleted: this.isHowTabCompleted()
          }
        ];

        this.titleTooltipModal = {
          modalId: "118",
          title: translations['generic.TITLE'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_TITLE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_TITLE_TEXT']
        };

        this.descriptionTooltipModal = {
          modalId: "119",
          title: translations['generic.DESCRIPTION'],
          subtitle: translations['goalSetting.createGoal.DESCRIPTION_SUBTITLE'],
          text: translations['goalSetting.createGoal.DESCRIPTION_TEXT']
        };
        this.objCompletedTooltipModal = {
          modalId: "120",
          title: translations['goalSetting.createGoal.OBJECTIVE_DESCRIPTION'],
          text: '-'
        };
        this.weightTooltipModal = {
          modalId: "121",
          title: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT_SUBTITLE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT_TEXT']
        };
        this.deadlineTooltipModal = {
          modalId: "122",
          title: translations['goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_TEXT']
        };
        this.careTooltipModal = {
          modalId: "123",
          title: translations['goalSetting.createGoal.CARE_ATTRIBUTES_MANAGER'],
          text: translations['goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION']
        };
        this.formatBehaviorTextModal();
        /* this.behaviourTooltipModal = {
          modalId: "124",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.applyCareTooltipModal = {
          modalId: "125",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        }; */
        this.managerTooltipModal = {
          modalId: "126",
          title: translations['goalSetting.createGoal.MANAGER_COMMENT'],
          subtitle: translations['goalSetting.createGoal.MANAGER_COMMENT_SUBTITLE'],
          text: translations['goalSetting.createGoal.MANAGER_COMMENT_TEXT']
        };
        this.describeWhatTooltipModal = {
          modalId: "701",
          title: 'Lorem ipsum',
          subtitle: 'Lorem ipsum',
          text: 'Lorem ipsum'
        };
        this.modalUpdateGoalId = this.getModalSaveId();
        this.modalUpdateGoalTitle = this.getModalSaveTitle();
        this.modalUpdateGoalText = this.getModalSaveDescription();
        this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
        this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();

        this.weightOptions = [
          {
            id: 10,
            title: '10',
            disabled: false
          },
          {
            id: 15,
            title: '15',
            disabled: false
          },
          {
            id: 20,
            title: '20',
            disabled: false
          },
          {
            id: 25,
            title: '25',
            disabled: false
          },
          {
            id: 30,
            title: '30',
            disabled: false
          },
          {
            id: 35,
            title: '35',
            disabled: false
          },
          {
            id: 40,
            title: '40',
            disabled: false
          },
          {
            id: 45,
            title: '45',
            disabled: false
          },
          {
            id: 50,
            title: '50',
            disabled: false
          },
          {
            id: 55,
            title: '55',
            disabled: false
          },
          {
            id: 60,
            title: '60',
            disabled: false
          },
          {
            id: 65,
            title: '65',
            disabled: false
          },
          {
            id: 70,
            title: '70',
            disabled: false
          },
          {
            id: 75,
            title: '75',
            disabled: false
          },
          {
            id: 80,
            title: '80',
            disabled: false
          },
          {
            id: 85,
            title: '85',
            disabled: false
          },
          {
            id: 90,
            title: '90',
            disabled: false
          },
          {
            id: 95,
            title: '95',
            disabled: false
          },
          {
            id: 100,
            title: '100',
            disabled: false
          }
        ]
      })
  }

  getObjectiveOnHold() {
    if (this.getObjectiveOnHold$) {
      this.getObjectiveOnHold$.unsubscribe();
    }
    this.getObjectiveOnHold$ = this.managerService.listUserGoalForManager(this.runningYear, this.personDetails.userId, "DRAFT_INDIVIDUAL_TAB")
      .subscribe((goalDrafts: SenecaResponse<any>) => {
        this.hasDraft = false;
        if (goalDrafts && goalDrafts.response && goalDrafts.response.length) {
          let current = goalDrafts.response.filter((x: any) => x.goalId === this.goalId)
          this.hasDraft = current && current.length > 0;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "af001",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        window.history.back();
        // this.isLoadingUserGoals = false;
      });
  }

  // Peso totale che deve avere la sezione
  getTotalWeightSection() {
    this.isLoadingTotalWeightSection = true;
    if (this.getTotalWeightSection$) {
      this.getTotalWeightSection$.unsubscribe();
    }
    this.getTotalWeightSection$ = this.managerService.getObjectiveWeightsByProfile(this.userId, this.runningYear)
      .subscribe((tabList: SenecaResponse<any>) => {
        if (tabList && (tabList.error || !tabList.response)) {
          const messageObj: ApplicationModalMessage = {
            modalId: "112",
            text: this.translate.instant("errors." + ((tabList && tabList.error) || tabList)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          for (let i = 0; i < tabList.response.length; i++) {
            for (let q = 0; q < tabList.response[i].goalTypes.length; q++) {
              let goalType = tabList.response[i].goalTypes[q];
              if (this.isAfterSharing) {
                let currentGoalType = this.goal.macroGoalType.split('TEMP_')[1];
                if (goalType.goalType == currentGoalType) {
                  this.totalWeightSection = goalType.weight;
                  break;
                }
              } else {
                if (goalType.goalType == this.goal.macroGoalType) {
                  this.totalWeightSection = goalType.weight;
                  break;
                }
              }
            }
          }
          this.getUserGoalWeightSumForManager();
        }
        this.isLoadingTotalWeightSection = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTotalWeightSection = false;
      });
  }

  findWithAttr(array: any, attr: string, value: any) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  // Formatta il testo della modale Behavior
  formatBehaviorTextModal(modalBehaviorText?: string) {
    this.behaviourTooltipModal = {
      modalId: "124",
      withScrolledBody: false,
      title: this.goal.careTag ? this.translate.instant('goalSetting.createGoal.BEHAVIORS') : this.translate.instant('goalSetting.createGoal.NO_CARE_ATTRIBUTE_SELECTED'),
      text: this.goal.careTag ? modalBehaviorText : this.translate.instant('goalSetting.createGoal.NO_CARE_ATTRIBUTE_SELECTED_DESCRIPTION'),
    }
    this.applyCareTooltipModal = {
      modalId: "125",
      title: this.goal.careTag ? this.translate.instant('goalSetting.createGoal.HOW_TO_USE_CARE_COLLABORATOR') : this.translate.instant('goalSetting.createGoal.NO_BEHAVIOR_ATTRIBUTE_SELECTED'),
      text: this.goal.careTag ? this.translate.instant('goalSetting.createGoal.HOW_TO_USE_CARE_COLLABORATOR_DESCRIPTION') : this.translate.instant('goalSetting.createGoal.NO_BEHAVIOR_ATTRIBUTE_SELECTED_DESCRIPTION'),
    };
  }

  // Recupera la somma pesi obiettivi
  getUserGoalWeightSumForManager() {
    this.isFetchingWeightSums = true;
    let goalType;
    if (this.isAfterSharing) {
      goalType = 'TEMP_INDIVIDUAL'; // PerfCareMacroGoalTypes.TEMP_INDIVIDUAL
    } else {
      goalType = PerfCareMacroGoalTypes.INDIVIDUAL;
    }
    this.getWeightSum$ = this.managerService.getUserGoalWeightSumForManager(this.runningYear.toString(), this.personDetails.userId, goalType)
      .subscribe((data: SenecaResponse<GetUserGoalWeightSumForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "073",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.weightSum = data.response || 0;
          this.differenceWeightSum = this.totalWeightSection - this.weightSum;
          let indexToHandleWeights = this.findWithAttr(this.weightOptions, 'id', (this.totalWeightSection - this.weightSum + (this.goal.weight || 0)));
          for (let i = indexToHandleWeights + 1; i < this.weightOptions.length; i++) {
            this.weightOptions[i].disabled = true;
          }
          this.weightOptions = this.weightOptions;
        }
        this.isFetchingWeightSums = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "074",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingWeightSums = false;
        })
  }

  getModalSaveId() {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
      if (this.isEditApprove) {
        return "167";
      } else {
        if (this.goalId && this.goalId.length) {
          return "166";
        } else {
          return "165";
        }
      }
    } else {
      if (this.goalId && this.goalId.length) {
        return "164";
      } else {
        return "163";
      }
    }
  }

  // Testo per modifica dati obbligatori mancanti
  checkObbligatoryData() {
    if (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT)) {
      if (this.isEditApprove) {
        if (!this.goal || !this.goal.managerComment) {
          return true;
        } else {
          return false;
        }
      } else {
        if (((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && this.goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && (!this.goal.title || !this.goal.title.trim().length))) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  // Testo per il modale Salva obiettivo
  getModalSaveTitle() {
    if (this.checkObbligatoryData()) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
        if (this.isEditApprove) {
          return this.translate.instant('goalSetting.createGoal.APPROVE_GOAL');
        } else {
          if (this.goalId && this.goalId.length) {
            return this.translate.instant('goalSetting.createGoal.UPDATE_GOAL');
          } else {
            return this.translate.instant('goalSetting.createGoal.SAVE_GOAL');
          }
        }
      } else {
        if (this.goalId && this.goalId.length) {
          return this.translate.instant('goalSetting.createGoal.UPDATE_GOAL_DRAFT');
        } else {
          return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_IN_DRAFT');
        }
      }
    }
  }

  // Descrizione per il modale Aggiorna obiettivo
  getModalSaveDescription() {
    if (this.checkObbligatoryData()) {
      if (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
        return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_IS_EMPOWERMENT');
      } else {
        return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_IS_SHARED');
      }
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
        if (this.isEditApprove) {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_APPROVE_GOAL');
        } else {
          if (this.goalId && this.goalId.length) {
            return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL');
          } else {
            return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL');
          }
        }
      } else {
        if (this.goalId && this.goalId.length) {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL_DRAFT');
        } else {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_IN_DRAFT');
        }
      }
    }
  }

  // Testo per il modale Salva/aggiorna obiettivo quando schiacci Torna indietro
  getModalSaveTitleBack() {
    if (this.checkObbligatoryData()) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
        return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_IN_DRAFT_BACK');
      }
    }
  }

  // Descrizione per il modale Salva/aggiorna obiettivo quando schiacci Torna indietro
  getModalSaveDescriptionBack() {
    if (this.checkObbligatoryData()) {
      if (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
        return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_IS_EMPOWERMENT');
      } else {
        return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_IS_SHARED');
      }
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
        if (this.isEditApprove) {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_APPROVE_GOAL_BACK');
        } else {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_BACK');
        }
      } else {
        return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_IN_DRAFT_BACK');
      }
    }
  }

  // Testo per il pulsante Salva all'atto della creazione di un obiettivo
  getButtonSaveText() {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
      return this.translate.instant('goalSetting.createGoal.CREATE_GOAL');
    } else {
      return this.translate.instant('goalSetting.createGoal.CREATE_GOAL_IN_DRAFT');
    }
  }

  // Testo per il pulsante Salva all'atto della modifica di un obiettivo
  getButtonUpdateText() {
    if ((this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || this.isSharedGoal)) {
      return this.translate.instant('generic.SAVE');
    } else {
      return this.translate.instant('goalSetting.createGoal.SAVE_IN_DRAFT');
    }
  }

  // Testo modale pulsante close
  getCancelLabelUpdateGoalModal(isBack?: boolean) {
    if (this.goalId && this.goalId.length) {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      }
    } else {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      }
    }
  }

  // Testo modale pulsante conferma
  getConfirmLabelUpdateGoalModal(isBack?: boolean) {
    if (this.goalId && this.goalId.length) {
      if (this.checkObbligatoryData()) {
        return this.translate.instant('goalSetting.createGoal.YES_REMAIN_IN_PAGE');
      } else {
        if (isBack) {
          if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
            if (this.isEditApprove) {
              return this.translate.instant('generic.APPROVE_AND_GO_BACK');
            } else {
              return this.translate.instant('generic.SAVE_AND_GO_BACK');
            }
          } else {
            return this.translate.instant('generic.SAVE_AND_GO_BACK_DRAFT');
          }
        } else {
          if (this.isEditApprove) {
            return this.translate.instant('generic.APPROVE');
          } else {
            return this.translate.instant('goalSetting.createGoal.YES_UPDATE_THE_GOAL');
          }
        }
      }
    } else {
      if (isBack) {
        if (this.isWhatTabCompleted() && this.isHowTabCompleted() || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.goal.title && this.goal.title.trim().length) || (this.isEditApprove && this.goal.managerComment && this.goal.managerComment.trim().length)) {
          return this.translate.instant('generic.SAVE_AND_GO_BACK');
        } else {
          return this.translate.instant('generic.SAVE_AND_GO_BACK_DRAFT');
        }
      } else {
        if (this.isEditApprove) {
          return this.translate.instant('generic.APPROVE');
        } else {
          return this.translate.instant('goalSetting.createGoal.YES_SAVE_THE_GOAL');
        }
      }
    }
  }

  // Se non hai inserito nessun dato
  noDataInserted() {
    if (!this.goal.title && !this.goal.description && !this.goal.objectiveDescription && !this.goal.weight && !this.goal.deadlineDate &&
      !this.goal.careTag && !this.goal.howToUseCareTag) {
      return true;
    }
    return false;
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateGoalModal() {
    this.modalUpdateGoalId = this.getModalSaveId();
    this.modalUpdateGoalTitle = this.getModalSaveTitle();
    this.modalUpdateGoalText = this.getModalSaveDescription();
    this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
    this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
    this.openModalForUpdateGoal();
  }

  // Quando switcho tab Cosa/Come
  onTabClicked(tab: TabSection) {
    switch (tab.id) {
      case 'whatTab':
        this.goToWhatSection()
        break;
      case 'howTab':
        this.goToHowSection()
        break;
    }
  }

  // Recupara l'utente dal suo userId
  getPersonDetails() {
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.runningYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "075",
            text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.personDetails = personDetailsData.response;
          if (!this.goalId && this.isSti) {
            this.goal.macroGoalType = PerfCareMacroGoalTypes.INDIVIDUAL;
          }
          if (this.goalId) {
            this.isEdit = true;
            this.getGoalById();
          } else {
            this.getTotalWeightSection();
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "076",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Controlla se mettere completa la tab Cosa
  checkWhatTabCompleted() {
    this.tabSectionsList[0].isCompleted = this.isWhatTabCompleted();
  }

  // Controlla se mettere completa la tab Come
  checkHowTabCompleted() {
    this.tabSectionsList[1].isCompleted = this.isHowTabCompleted();
  }

  // Quando si scrive sulla textare del commento manager obiettivo
  goalManagerCommentChanged(text: string) {
    this.goal.managerComment = text;
    this.charsGoalManagerComment = this.maxTextAreaChars - text.length;
  }

  // Quando si scrive sull'input del titolo obiettivo
  goalTitleChanged(text: string) {
    this.goal.title = text;
    this.checkWhatTabCompleted();
  }

  // Quando si scrive sulla textarea della descrizione obiettivo
  goalDescriptionChanged(text: string) {
    this.goal.description = text;
    this.charsGoalDescription = this.maxTextAreaChars - text.length;
    this.checkWhatTabCompleted();
  }

  // Quando si scrive sulla textarea della descrizione obiettivo(la seconda)
  goalObjectiveDescriptionChanged(text: string) {
    this.goal.objectiveDescription = text;
    this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
    this.checkWhatTabCompleted();
  }

  // Quando si scrive sull'input del peso obiettivo
  goalWeightChanged(weightOption: any) {
    let weightSelected = this.weightOptions.filter((x: any) => {
      return x.id == weightOption.id;
    })
    this.weightOptionSelected = weightSelected && weightSelected[0];
    this.goal.weight = this.weightOptionSelected.id;
    if (this.goalId && this.goalId.length) {
      this.differenceWeightSum = this.totalWeightSection - (this.weightSum - <number>(this.goalCopy.weight || 0) || 0) - (this.goal.weight || 0);
    } else {
      this.differenceWeightSum = this.totalWeightSection - (this.weightSum || 0) - (this.goal.weight || 0);
    }
    this.checkWhatTabCompleted();
  }

  // Quando si seleziona il giorno sul datepicker della deadline obiettivo
  goalDeadlineDateChanged(date: Date) {
    this.goal.deadlineDate = date;
    this.checkWhatTabCompleted();
  }

  // Vai alla sezione Come
  goToHowSection() {
    this.isHowSection = true;
    this.tabSectionsList[1].isActive = true;
    this.getCareOptions();
    if (this.goal && this.goal.careTag) {
      this.getBehaviourOptions();
    }
  }

  // Vai alla sezione Cosa
  goToWhatSection() {
    this.isHowSection = false;
    this.tabSectionsList[1].isActive = false;
  }

  // Quando selezioni un altro Care tag
  changeCareTag(tag: Tag) {
    this.goal.careTag = tag;
    this.goal.careTagId = tag.tagId;
    this.goal.behaviorTag = undefined;
    this.goal.behaviorTagId = '';
    //this.getBehaviourOptions();
    this.checkHowTabCompleted();
  }

  // Quando selezioni un altro Behaviour tag
  changeBehaviourTag(tag: Tag) {
    this.goal.behaviorTag = tag;
    this.goal.behaviorTagId = tag.tagId;
    this.checkHowTabCompleted();
  }

  // Quando selezioni un altro How to use Care tag
  goalHowToUseCareChanged(text: string) {
    this.goal.howToUseCareTag = text;
    this.charsGoalHowToUseDescription = this.maxTextAreaChars - this.goal.howToUseCareTag.length;
    this.checkHowTabCompleted();
  }

  // Quando la tab what è completata
  isWhatTabCompleted() {
    if (this.isManagerOperations) {
      if (this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.deadlineDate) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.weight && this.goal.deadlineDate) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Quando la tab how è completata
  isHowTabCompleted() {
    if (this.goal.careTag && this.goal.howToUseCareTag) {
      return true;
    }
    return false;
  }

  // Recupera i tag Care
  getCareOptions() {
    this.isFetchingCareOptions = true;
    this.getCareOptions$ = this.managerService.getCareOptions('', '', true)
      .subscribe((data: SenecaResponse<GetPerformanceCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "077",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.careOptionsCounter = data.response.totalRowsCount;
          this.careOptions = data.response.rows;
        }
        this.isFetchingCareOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "078",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCareOptions = false;
        })
  }

  // Recupera i tag Behaviour
  getBehaviourOptions() {
    this.isFetchingBehaviourOptions = true;
    this.getBehaviourOptions$ = this.managerService.getBehaviourOptions('', '', true, (this.goal.careTagId || ''))
      .subscribe((data: SenecaResponse<GetBehaviorCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "079",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.behaviourOptionsCounter = data.response.totalRowsCount;
          this.behaviourOptions = data.response.rows;
          let modalBehaviorText = this.translate.instant("goalSetting.createGoal.MODAL_BEHAVIOR_TEXT_1") + ((this.goal && this.goal.careTag && this.goal.careTag.title) || '') + this.translate.instant("goalSetting.createGoal.MODAL_BEHAVIOR_TEXT_2");
          for (let i = 0; i < this.behaviourOptions.length; i++) {
            modalBehaviorText += '<h3>' + this.behaviourOptions[i].title + '</h3>' + this.behaviourOptions[i].description;
          }
          this.formatBehaviorTextModal(modalBehaviorText);
        }
        this.isFetchingBehaviourOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "080",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingBehaviourOptions = false;
        })
  }

  // Recupera l'obiettivo dal goalId
  getGoalById() {
    this.isFetchingGetGoal = true;

    this.getGoal$ = this.managerService.getGoalByIdForManager(this.runningYear.toString(), this.goalId, this.personDetails.userId)
      .subscribe((data: SenecaResponse<GetGoalByIdForManagerResponse>) => {
        if (data && data.error) {
          this.goToPersonDetails();
          const messageObj: ApplicationModalMessage = {
            modalId: "081",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goal = data.response;
          if (this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT || this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
            this.isEditApprove = true;
          }
          if (this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
            this.isManagerOperationsApprove = true;
          }
          if (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
            this.isEmpowermentGoal = true;
          }
          if (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
            this.isSharedGoal = true;
          }
          let weightSelected = this.weightOptions.filter((x: any) => {
            return x.id == this.goal.weight;
          })
          this.weightOptionSelected = weightSelected && weightSelected[0];
          if (this.goal.description && this.goal.description.length) {
            this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
          }
          if (this.goal.objectiveDescription) {
            this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
          }
          if (this.goal.howToUseCareTag) {
            this.charsGoalHowToUseDescription = this.maxTextAreaChars - this.goal.howToUseCareTag.length;
          }
          if (this.goal.managerComment) {
            this.charsGoalManagerComment = this.maxTextAreaChars - this.goal.managerComment.length;
          }
          this.goalCopy = JSON.parse(JSON.stringify(this.goal));
          this.checkHowTabCompleted();
          this.checkWhatTabCompleted();
          this.getTotalWeightSection();
        }
        this.isFetchingGetGoal = false;
      },
        (err: string) => {
          this.goToPersonDetails();
          const messageObj: ApplicationModalMessage = {
            modalId: "082",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetGoal = false;
        })
  }

  // Gestisce quando crei un nuovo obiettivo
  goToCreateGoal(openModalAlways?: boolean) {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted() && !openModalAlways) {
      this.modalUpdateGoalId = this.getModalSaveId();
      this.modalUpdateGoalTitle = this.getModalSaveTitle();
      this.modalUpdateGoalText = this.getModalSaveDescription();
      this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
      this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
      this.openModalForUpdateGoal();
    } else {
      if (this.checkIfHowSectionIsChanged() || this.checkIfWhatSectionIsChanged()) {
        if (openModalAlways) {
          this.modalUpdateGoalId = this.getModalSaveId();
          this.modalUpdateGoalTitle = this.getModalSaveTitleBack();
          this.modalUpdateGoalText = this.getModalSaveDescriptionBack();
        } else {
          this.modalUpdateGoalId = this.getModalSaveId();
          this.modalUpdateGoalTitle = this.getModalSaveTitle();
          this.modalUpdateGoalText = this.getModalSaveDescription();
        }
        this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal(openModalAlways);
        this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal(openModalAlways);
        this.openModalForUpdateGoal();
      } else {
        this.goBackBrowser();
      }
    }
  }

  // Gestisce quando aggiorni un'obiettivo
  goToUpdateGoal(openModalAlways?: boolean) {
    if (!openModalAlways) {
      if (this.isEditApprove) {
        this.modalUpdateGoalId = this.getModalSaveId();
        this.modalUpdateGoalTitle = this.getModalSaveTitle();
        this.modalUpdateGoalText = this.getModalSaveDescription();
        this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
        this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
        this.openModalForUpdateGoal();
      } else {
        this.modalUpdateGoalId = this.getModalSaveId();
        this.modalUpdateGoalTitle = this.getModalSaveTitle();
        this.modalUpdateGoalText = this.getModalSaveDescription();
        this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
        this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
        this.openModalForUpdateGoal();
      }
    } else if (!this.noDataInserted() && (this.checkIfHowSectionIsChanged() || this.checkIfWhatSectionIsChanged() || (this.goal.managerComment != this.goalCopy.managerComment))) {
      this.modalUpdateGoalId = this.getModalSaveId();
      this.modalUpdateGoalTitle = this.getModalSaveTitleBack();
      this.modalUpdateGoalText = this.getModalSaveDescriptionBack();
      this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal(true);
      this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal(true);
      this.openModalForUpdateGoal();
    } else {
      this.goBackBrowser();
    }
  }

  // Gestione del approva/riapprova commento manager
  goToApproveGoal() {
    if (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
      this.riApproveGoal();
    } else {
      this.approveGoal();
    }
  }

  // Riapprova con un commento il goal
  riApproveGoal() {
    if (this.isEditApprove && (!this.goal || !this.goal.managerComment)) {
      this.closeModalGoalUpdate();
    } else {
      this.isFetchingApproveGoal = true;
      this.closeModalGoalUpdate();
      this.goal.userId = this.personDetails.userId;

      this.updateGoal$ = this.managerService.riApproveGoalProposedByUser({
        perfCareYear: this.runningYear,
        subordinateUserId: this.personDetails.userId,
        comment: <string>this.goal.managerComment
      })
        .subscribe((data: SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "300",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "301",
              title: this.translate.instant("goalSetting.createGoal.GOAL_APPROVED"),
              text: this.translate.instant("goalSetting.createGoal.GOAL_APPROVED_DESCRIPTION")
            }

            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isEditApprove = false;
            this.goBackBrowser()
          }
          this.isFetchingApproveGoal = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "302",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingApproveGoal = false;
          })
    }
  }

  // Approva con un commento il goal
  approveGoal() {
    if (this.isEditApprove && (!this.goal || !this.goal.managerComment)) {
      this.closeModalGoalUpdate();
    } else {
      this.isFetchingApproveGoal = true;
      this.closeModalGoalUpdate();
      this.goal.userId = this.personDetails.userId;

      this.updateGoal$ = this.managerService.approveGoalProposedByUser({
        perfCareYear: this.runningYear,
        subordinateUserId: this.personDetails.userId,
        comment: <string>this.goal.managerComment
      })
        .subscribe((data: SenecaResponse<ApproveGoalProposedBySubordinateUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "083",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "138",
              title: this.translate.instant("goalSetting.createGoal.GOAL_APPROVED"),
              text: this.translate.instant("goalSetting.createGoal.GOAL_APPROVED_DESCRIPTION")
            }

            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isEditApprove = false;
            this.goBackBrowser()
          }
          this.isFetchingApproveGoal = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "084",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingApproveGoal = false;
          })
    }
  }

  // Aggiorna obiettivo
  updateGoal(goNext?: boolean) {
    if ((((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && this.goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) || (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && (!this.goal.title || !this.goal.title.trim().length))) && (this.goal &&
      this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED && !this.isEditApprove)) {
      this.closeModalGoalUpdate();
    } else {
      this.isFetchingUpdateGoal = true;
      this.closeModalGoalUpdate();
      this.goal.userId = this.personDetails.userId;
      if (this.goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && this.isManagerOperations && this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.deadlineDate && this.goal.careTag && this.goal.howToUseCareTag) {
        this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE;
      } else if (this.goal.macroGoalType != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT && !this.isManagerOperations && this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.weight && this.goal.deadlineDate && this.goal.careTag && this.goal.howToUseCareTag) {
        this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE;
      } else if (this.isSharedGoal || this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
        this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED;
      } else {
        this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE_DRAFT;
      }

      this.updateGoal$ = this.managerService.updateGoal(this.runningYear, this.goal)
        .subscribe((data: SenecaResponse<UpdateUserGoalForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "085",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            this.goal = data.response;
            if (this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT || this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
              this.isEditApprove = true;
            }
            if (this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
              this.isManagerOperationsApprove = true;
            }
            if (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
              this.isEmpowermentGoal = true;
            }
            if (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
              this.isSharedGoal = true;
            }
            let weightSelected = this.weightOptions.filter((x: any) => {
              return x.id == this.goal.weight;
            })
            this.weightOptionSelected = weightSelected && weightSelected[0];
            if (this.goal.description && this.goal.description.length) {
              this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
            }
            if (this.goal.objectiveDescription) {
              this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
            }
            if (this.goal.howToUseCareTag) {
              this.charsGoalHowToUseDescription = this.maxTextAreaChars - this.goal.howToUseCareTag.length;
            }
            if (this.goal.managerComment) {
              this.charsGoalManagerComment = this.maxTextAreaChars - this.goal.managerComment.length;
            }
            this.goalCopy = JSON.parse(JSON.stringify(this.goal));

            const messageObj = {
              modalId: "086",
              title: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED"),
              text: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            if (!goNext && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE || this.isAfterSharing)) {
              this.redirectService.goToPersonDetails(this.userId, this.runningYear);
            } else if (goNext) {
              this.getGoalById();
            } else {
              this.goBackBrowser()
            }
          }
          this.isFetchingUpdateGoal = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "087",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingUpdateGoal = false;
          })
    }
  }

  // Crea obiettivo
  createGoal() {
    this.isFetchingCreateGoal = true;
    /* if(!this.weightOptionSelected) {
      this.goal.weight = 0;
    } */
    this.closeModalGoalUpdate();
    this.goal.userId = this.personDetails.userId;
    this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE_DRAFT;
    if (this.isManagerOperations && this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.deadlineDate && this.goal.careTag && this.goal.howToUseCareTag) {
      this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE;
    }
    if (!this.isManagerOperations && this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.weight && this.goal.deadlineDate && this.goal.careTag && this.goal.howToUseCareTag) {
      this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE;
    }
    if (this.isAfterSharing) {
      if (this.isManagerOperations) {
        this.goal.macroGoalType = PerfCareMacroGoalTypes.OPERATIONS;
      } else {
        this.goal.macroGoalType = PerfCareMacroGoalTypes.INDIVIDUAL;
      }
    }
    this.createGoal$ = this.managerService.createGoal(this.runningYear, this.goal)
      .subscribe((data: SenecaResponse<CreateUserGoalForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "088",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response && data.response.length) {
          this.goal = data.response[0];
          if (this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT || this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
            this.isEditApprove = true;
          }
          if (this.goal.macroGoalType == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
            this.isManagerOperationsApprove = true;
          }
          if (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
            this.isEmpowermentGoal = true;
          }
          if (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
            this.isSharedGoal = true;
          }
          let weightSelected = this.weightOptions.filter((x: any) => {
            return x.id == this.goal.weight;
          })
          this.weightOptionSelected = weightSelected && weightSelected[0];
          if (this.goal.description && this.goal.description.length) {
            this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
          }
          if (this.goal.objectiveDescription) {
            this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
          }
          if (this.goal.howToUseCareTag) {
            this.charsGoalHowToUseDescription = this.maxTextAreaChars - this.goal.howToUseCareTag.length;
          }
          if (this.goal.managerComment) {
            this.charsGoalManagerComment = this.maxTextAreaChars - this.goal.managerComment.length;
          }
          this.goalCopy = JSON.parse(JSON.stringify(this.goal));
          const messageObj: ApplicationModalMessage = {
            modalId: "089",
            title: this.translate.instant("goalSetting.createGoal.GOAL_CREATED"),
            text: this.translate.instant("goalSetting.createGoal.GOAL_CREATED_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.store.dispatch(ManagerActions.UpdateCollaboratorUserStatusType({
            payload: {
              userId: this.userId,
              userStatusType: RentStatusTypes.STARTED
            }
          }));
          if (this.isAfterSharing) {
            this.redirectService.goToPersonDetails(this.userId, this.runningYear);
          } else {
            this.goBackBrowser()
          }
        }
        this.isFetchingCreateGoal = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "090",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCreateGoal = false;
        })
  }

  // Controlla se è cambiato qualcosa nella sezione What
  checkIfWhatSectionIsChanged() {
    return this.goal.title != this.goalCopy.title || this.goal.description != this.goalCopy.description || this.goal.objectiveDescription != this.goalCopy.objectiveDescription ||
      this.goal.weight != this.goalCopy.weight || this.goal.deadlineDate != this.goalCopy.deadlineDate;
  }

  // Controlla se è cambiato qualcosa nella sezione How
  checkIfHowSectionIsChanged() {
    return this.goal.careTagId != this.goalCopy.careTagId || this.goal.behaviorTagId != this.goalCopy.behaviorTagId ||
      this.goal.howToUseCareTag != this.goalCopy.howToUseCareTag;
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdateGoal() {
    this.modalService.open('modalUpdateGoalForManager');
  }

  // Vai alla lista obiettivi dell'utente
  goToPersonDetails() {
    this.goToGoalObjectiveList = true;
    this.redirectService.goToPersonDetails(this.personDetails.userId, this.runningYear);
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.goToGoalObjectiveList = true;
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per il salvataggio dell'obiettivo
  closeModalGoalUpdate() {
    this.modalService.close('modalUpdateGoalForManager');
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getCareOptions$) {
      this.getCareOptions$.unsubscribe();
    }
    if (this.getBehaviourOptions$) {
      this.getBehaviourOptions$.unsubscribe();
    }
    if (this.createGoal$) {
      this.createGoal$.unsubscribe();
    }
    if (this.updateGoal$) {
      this.updateGoal$.unsubscribe();
    }
    if (this.getGoal$) {
      this.getGoal$.unsubscribe();
    }
    if (this.getWeightSum$) {
      this.getWeightSum$.unsubscribe();
    }
  }
}