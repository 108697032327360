<div class="component-container">
  <ng-container *ngIf="!isAdminHeader">
    <div class="info-container">
      <div class="data-container">
        <div class="user-container">
          <div class="user" *ngIf="user">
            <avatar-img size="big" [src]="user && user.avatar" [user]="user" [initials]="user | userInitials">
            </avatar-img>
            <div class="info">
              <div class="user-name">
                <p class="text-title" translate="goalSetting.DIARY_OF"></p>
                <p class="text-description">{{user.surname}} {{user.forename}}</p>
              </div>
              <ng-container *ngIf="selectedYear">
                <ng-select class="header-dossier-select" [clearable]="false" [multiple]="false" [searchable]="false"
                  [(ngModel)]="selectedYear" [items]="yearItems"
                  placeholder="{{ ('goalSetting.SELECT_YEAR' | translate) }}" (change)="emitOnSelectedYearItem($event)">

                  <!-- Elementi selezionati -->
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <p>{{item.name}}</p>
                  </ng-template>

                  <!-- Template per l'inizio della ricerca -->
                  <ng-template ng-typetosearch-tmp>
                    <div class="ng-option disabled">{{ ('generic.START_SEARCH' | translate) }}</div>
                  </ng-template>
                  <!-- Template per i dati non trovati -->
                  <ng-template ng-notfound-tmp let-searchTerm="filter">
                    <div class="ng-option disabled">
                      <europ-dropdown-item class="full-width" [item]='noDataObject'>
                      </europ-dropdown-item>
                    </div>
                  </ng-template>
                  <!-- Template per i singoli item nelle options -->
                  <ng-template ng-option-tmp let-item="item" let-search="filter">
                    <europ-dropdown-item class="full-width" [item]="item"></europ-dropdown-item>
                  </ng-template>
                </ng-select>
              </ng-container>
            </div>
          </div>
          <div class="logo">
            <div class="logo-container">
              <svg-icon class="small-logo" [applyClass]="true" src="./assets/img/icons/europ-assistance-logo.svg">
              </svg-icon>
            </div>
          </div>
        </div>
        <div class="button-container">
          <div class="button-create-interview" *ngIf="!isChief">
            <europ-button type="primary bordless" size="medium" label="{{'goalSetting.CREATE_INTERVIEW' | translate}}"
              (click)="emitOnOrganizeinterview()">
            </europ-button>
          </div>
        </div>
      </div>
      <div class="notify-wrapper" *ngIf="interview">
        <div class="notify-container">
          <div class="interview-date">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="3" cy="3" r="3" fill="#FC5A60" />
            </svg>
            <p *ngIf="interview.isEditabled">{{('goalSetting.YOU_ORGANIZED_INTERVIEW_FOR' | translate) +
              (interview.startDate | date: 'dd/MM/YYYY')}}
            </p>
            <p *ngIf="!interview.isEditabled">{{('goalSetting.IT_ORGANIZED_INTERVIEW_FOR' | translate) +
              (interview.startDate | date: 'dd/MM/YYYY')}}
            </p>
          </div>
          <a *ngIf="interview.isEditabled" translate="generic.ANNULL_INTERVIEW"
            (click)="emitOnAnnullInterview(interview.feedbackDialogueId)"></a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="hasTabs && !isChief">
      <div class="buttons-tab-container">
        <div class="tab-container">
          <div class="button" (click)="menuButtonClicked(item)" *ngFor="let item of menuButtons"
            [ngClass]="{'active': item.id == selectedMenuButton.id, 'disabled': item.isDisabled}">
            <p class="text-button">{{item.name}}</p>
            <badge-notifications *ngIf="item.badgeNumber" [badgeNumber]="item.badgeNumber" type="active">
            </badge-notifications>
            <svg-icon *ngIf="item.id == selectedMenuButton.id" fxShow.xs fxHide class="small-size arrow-down-mobile"
              [applyClass]="true" src="./assets/img/icons/chevron-down.svg"></svg-icon>
          </div>
          <div *ngIf="mobileMenuOpened" class="menu-container-mobile">
            <europ-dropdown-item *ngFor="let item of mobileMenuItems" [item]="item" (click)="emitOnOptionClicked(item)">
            </europ-dropdown-item>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- admin -->
  <ng-container *ngIf="isAdminHeader">
    <div class="admin-container">
      <div class="left-section" *ngIf="adminBack">
        <div>
          <europ-button iconLeft="assets/img/icons/arrow-left.svg" [label]="('generic.GO_BACK' | translate)"
            (onButtonClicked)="redirectService.goBackBrowser()" type="primary link" size="medium">
          </europ-button>
        </div>
      </div>
      <div class="center-section">
        <p class="admin-area" translate="generic.ADMIN_AREA"></p>
        <p class="title">{{adminPageTitle }}</p>
        <p class="subtitle">{{adminPageSubtitle}}</p>
      </div>
      <div class="right-section">
        <ng-container *ngIf="adminActionLabel && adminActionLabel.length">
          <europ-button [disabled]="adminActionDisabled" [iconLeft]="adminActionIcon" [label]="adminActionLabel"
            (onButtonClicked)="emitOnAdminAction()" type="primary" size="medium">
          </europ-button>
        </ng-container>
        <ng-container *ngIf="counter">
          <div class="counter-container">
            <p class="count">{{counter}}</p>
            <p class="label">{{counterLabel}}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="hasTabs">
      <div class="buttons-tab-container process-setup">
        <div class="tab-container">
          <div class="button" (click)="menuButtonClicked(item)" *ngFor="let item of menuButtons"
            [ngClass]="{'active': item.id == selectedMenuButton.id, 'disabled': item.isDisabled}">
            <p class="text-button">{{item.name}}</p>
            <badge-notifications *ngIf="item.badgeNumber" [badgeNumber]="item.badgeNumber" type="active">
            </badge-notifications>
            <svg-icon *ngIf="item.id == selectedMenuButton.id" fxShow.xs fxHide class="small-size arrow-down-mobile"
              [applyClass]="true" src="./assets/img/icons/chevron-down.svg"></svg-icon>
          </div>
          <div *ngIf="mobileMenuOpened" class="menu-container-mobile">
            <europ-dropdown-item *ngFor="let item of mobileMenuItems" [item]="item" (click)="emitOnOptionClicked(item)">
            </europ-dropdown-item>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>