import * as CommonClasses from "../../../commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { GetPerformanceCareTagsResponse, SenecaResponse } from "../../../commonclasses";

@Injectable()
export class Subordinateservice {
  applicationContext: string;
  constructor(private urlService: UrlService,
    private http: HttpClient) {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  // Recupera la lista di tutti i sottoposti di un  manager con lo stato di ogni
  getSubordinatesWithPhaseStatuses(perfCareYear: number, userIdToRetrieve?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (userIdToRetrieve) {
      if (userIdToRetrieve && userIdToRetrieve.length && Array.isArray(userIdToRetrieve)) {
        userIdToRetrieve && userIdToRetrieve.forEach(id => {
          httpParams = httpParams.append('userIdToRetrieve', id);
        });
      } else if (userIdToRetrieve && userIdToRetrieve.length && !Array.isArray(userIdToRetrieve)) {
        httpParams = httpParams.append('userIdToRetrieve', userIdToRetrieve);
      }
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/performancecare-mediator/get-subordinates-with-phase-statuses-for-manager', {
      params: httpParams
    });
  }

  // Recupera il dettaglio di un subordinato
  getSubordinateByUserId(userId: string, perfCareYear?: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    if (perfCareYear) {
      httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    }
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/performancecare-mediator/get-my-subordinate-user-data', {
      params: httpParams
    });
  }

  // Recupera i dati per il profilo utente
  listMyBadges(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationContext + 'rest-api/performancecare-mediator/list-my-obtained-badges/', {
      params: httpParams
    });
  }

  uploadAvatar(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationContext + 'rest-api/performancecare-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  processFirstAccessBadge() {
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationContext + 'rest-api/performancecare-mediator/process-first-access-badge/', {});
  }

}