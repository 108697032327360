import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareMidTermReviewGoalAdvancementStatus, GetMyManagerUserDataResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { GoalUtils } from 'src/app/utils/goal.utils';
import * as moment from 'moment';

@Component({
  selector: 'app-collaborator-addMidTermReview',
  templateUrl: './addMidTermReview.component.html',
  styleUrls: ['./addMidTermReview.component.scss']
})
export class AddMidTermReviewCollaboratorComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserGoalList$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;
  objectiveFeedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveOsservationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isLoadingUserGoals: boolean = false;
  goalList: any[] = [];

  careCards: any[] = [];
  cardSelected: any;

  osservation: string = '';

  modalCreateRevisionId: string = '';
  modalCreateRevisionTitle: string = '';
  modalCreateRevisionSubtitle: string = '';
  modalCreateRevisionText: string = '';
  modalCreateRevisionTitleConfirmTextButton: string = '';
  modalCreateRevisionTextCloseTextButton: string = '';

  isFromBack: boolean = false;
  sendRevision$: Subscription = new Subscription;
  alreadySent: boolean = false;
  statusForRecap: string = '';
  userRevisionDate: string = '';
  isLoadingManagerInfo: boolean = false;
  getManagerData$: Subscription = new Subscription();
  managerData: any;
  managerAnswer: boolean = false;
  managerRevisionDate: string = '';
  managerOsservation: string = '';
  isOperations: boolean = false;
  backWithData: boolean = false;

  constructor(
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.isOperations = window.location.href.indexOf('collaboratorOperations') > -1;
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.loggedUser = loggedUser.user;
                    if (this.loggedUser) {
                      this.getManagerData();
                      this.getMidTermGoals();
                    }
                  })
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT',
        'midTerm.addMidTermReview.IN_RETARD',
        'midTerm.addMidTermReview.IN_LINE',
        'midTerm.addMidTermReview.GREAT_POINT'
      ])
      .subscribe(translations => {
        this.objectiveFeedbackTooltipModal = {
          modalId: "mt002",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT'],
        };
        this.objectiveOsservationTooltipModal = {
          modalId: "mt003",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT'],
        }

        this.careCards = [{
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.LATE,
          title: translations['midTerm.addMidTermReview.IN_RETARD'],
          isChecked: false,
          icon: "assets/img/icons/red-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.ON_LINE,
          title: translations['midTerm.addMidTermReview.IN_LINE'],
          isChecked: false,
          icon: "assets/img/icons/yellow-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.WELL_ADVANCED,
          title: translations['midTerm.addMidTermReview.GREAT_POINT'],
          isChecked: false,
          icon: "assets/img/icons/green-face.svg"
        }]
      })
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.osservation && this.osservation.trim().length && this.cardSelected;
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return !this.alreadySent && this.cardSelected || (this.osservation && this.osservation.trim().length);
  }

  sendRevision() {
    // this.goBackBrowser();
    this.isLoadingUserGoals = true;
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    this.sendRevision$ = this.collaboratorService.setMidTermReviewRevision(this.runningYear, this.osservation, this.cardSelected.id)
      .subscribe((data: any) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj = {
            modalId: "mt005",
            title: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS"),
            text: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
        }
        this.goBackBrowser();
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt006",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  sendRevisionAction(isBack?: boolean) {
    if (this.alreadySent) {
      this.goBackBrowser();
    } else if (isBack && !this.isObbligatoryDataInserted()) {
      if (this.checkIfDataIsChanged()) {
        this.backWithData = true;
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      } else {
        this.goBackBrowser();
      }
    } else if (this.isObbligatoryDataInserted()) {
      if (isBack) {
        this.isFromBack = true;
      } else {
        this.isFromBack = false;
      }
      this.modalService.open("modalCreateRevisionForCollaborator");
    } else {
      if (isBack) {
        if (this.checkIfDataIsChanged()) {
          this.modalService.open("modalCreateRevisionNoDataForCollaborator");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      }
    }
  }

  closeModalCreateRevisionNoDataForCollaborator() {
    this.modalService.close("modalCreateRevisionNoDataForCollaborator");
    this.backWithData = false
  }

  closeModalCreateRevisionForCollaborator() {
    this.modalService.close("modalCreateRevisionForCollaborator");
  }

  osservationChanged(text: string) {
    this.osservation = text;
  }

  closeObjectiveFeedbackTooltip() {
    this.modalService.close("objectiveFeedbackModalTooltip");
  }

  openObjectiveFeedbackTooltip() {
    this.modalService.open("objectiveFeedbackModalTooltip");
  }

  onSelectCareCard(cards: any) {
    this.cardSelected = cards.filter((x: any) => {
      return x.isChecked == true;
    })[0];
  }

  getMidTermGoals() {
    this.isLoadingUserGoals = true;
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    this.getUserGoalList$ = this.collaboratorService.getMidTermGoals(this.runningYear)
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (goalList.response && goalList.response.goals && goalList.response.goals.length) {
            this.goalList = GoalUtils.formatGoalCardList(goalList.response.goals, false, this.loggedUser.userId);
            // Dati osservazione collaboratore
            if (goalList.response.midTermReviewUserGoalAdvancement && goalList.response.midTermReviewUserGoalAdvancement.length) {
              this.alreadySent = true;
              this.osservation = goalList.response.midTermReviewUserObservation;
              this.statusForRecap = goalList.response.midTermReviewUserGoalAdvancement;
              this.userRevisionDate = moment(goalList.response.midTermReviewUserObservationDate).format('DD.MM.YYYY');
            }
            // Dati risposta manager
            if (goalList.response.midTermReviewManagerObservationDate && goalList.response.midTermReviewManagerObservationDate.length) {
              this.managerAnswer = true;
              this.managerRevisionDate = moment(goalList.response.midTermReviewManagerObservationDate).format('DD.MM.YYYY');
              this.managerOsservation = goalList.response.midTermReviewManagerObservation;
            }
          } else {
            this.goalList = [];
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Recupera i dati del manager
  getManagerData() {
    this.isLoadingManagerInfo = true;
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((managerData: SenecaResponse<GetMyManagerUserDataResponse>) => {
        if (managerData && managerData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + managerData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.managerData = managerData.response;
        }
        this.isLoadingManagerInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerInfo = false;
      });
  }


  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
  }
}