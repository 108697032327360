import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as fromManager from "../../manager/ngrx/manager.reducers";
import * as ManagerActions from "../../manager/ngrx/manager.actions";
import { GetPerformanceAssessmentBannerPhaseResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, JwtPayload, MapById, PerfCareAssessmentPhaseKeys, SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ManagerService } from 'src/app/shared/services/manager.service';
import * as CoreActions from "../../core/ngrx/core.actions";
import { UserTableObject } from 'src/app/shared/components/item-list-team-for-table/item-list-team-for-table.component';

@Component({
  selector: 'app-manager-team-list',
  templateUrl: './teamList.component.html',
  styleUrls: ['./teamList.component.scss']
})
export class TeamListComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "";
  updateUserAck$: any;
  userAcknowledges: any;
  runningPhase: any;
  isLoadingCurrentPhase: boolean = true;
  currentYear = new Date().getFullYear();

  notesDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  }

  // funzioni da usersTable
  userObject: any;
  listTeamMembers$: Subscription = new Subscription;
  isLoadingTeamMembers: boolean = false;
  teamList: UserTableObject[] = [];
  userFeedbackModal: any;
  menuTable: any;
  isOperations: boolean = false;
  reloadingFilters: boolean = false;
  phaseIndex: number = 0;
  searchedText: string = '';
  searchResults: any[] = [];
  phaseIdForModal: string = '';
  phasesInfoData: any;
  isPhaseDisabled: boolean = false;

  // gestione responsive
  compactView: boolean = window.innerWidth <= 990;
  profileReducedView: boolean = window.innerWidth <= 520;


  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    public modalService: ModalService,
    public redirectService: RedirectService,
    private managerService: ManagerService,
    public cdr: ChangeDetectorRef
  ) {
    // se avevo un utente attivo lo rimuovo
    this.appStore.dispatch(ManagerActions.RemoveActiveUserId());
    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });

    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const isOperations$: Observable<boolean> = this.appStore.select(fromApp.getIsOperation);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.appStore.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$, isOperations$])
      .subscribe(
        ([loggedUser, runningPhase, isOperations]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.runningPhase = runningPhase;
            this.isOperations = isOperations;
          }
        });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth <= 990) {
      this.compactView = true;
    } else {
      this.compactView = false;
    }
    if (event.target.innerWidth <= 520) {
      this.profileReducedView = true;
    } else {
      this.profileReducedView = false;
    }
  }

  ngOnInit() {
    this.translate.get(
      [
        "teamList.ALL",
        "teamList.TO_START",
        "teamList.IN_PROGRESS",
        "teamList.COMPLETED",
        "phases.GOAL_SETTING",
        "phases.DEVELOPMENT_PLAN",
        "phases.MID_TERM_REVIEW",
        "phases.FINAL_EVALUATION",
        "phases.initials.GOAL_SETTING",
        "phases.initials.DEVELOPMENT_PLAN",
        "phases.initials.MID_TERM_REVIEW",
        "phases.initials.FINAL_EVALUATION",
        "teamList.info.TITLE",
        "teamList.info.goalSetting.toStart",
        "teamList.info.goalSetting.inProgress",
        "teamList.info.goalSetting.completed",
        "teamList.info.developmentPlan.toStart",
        "teamList.info.developmentPlan.inProgress",
        "teamList.info.developmentPlan.completed",
        "teamList.info.midTerm.toStart",
        "teamList.info.midTerm.inProgress",
        "teamList.info.midTerm.completed",
        "teamList.info.final.toStart",
        "teamList.info.final.inProgress",
        "teamList.info.final.completed",
      ])
      .subscribe(translations => {
        this.phasesInfoData = {
          goalSetting: {
            toStart: translations["teamList.info.goalSetting.toStart"],
            inProgress: translations["teamList.info.goalSetting.inProgress"],
            completed: translations["teamList.info.goalSetting.completed"],
          },
          developmentPlan: {
            toStart: translations["teamList.info.developmentPlan.toStart"],
            inProgress: translations["teamList.info.developmentPlan.inProgress"],
            completed: translations["teamList.info.developmentPlan.completed"],
          },
          midTerm: {
            toStart: translations["teamList.info.midTerm.toStart"],
            inProgress: translations["teamList.info.midTerm.inProgress"],
            completed: translations["teamList.info.midTerm.completed"],
          },
          final: {
            toStart: translations["teamList.info.final.toStart"],
            inProgress: translations["teamList.info.final.inProgress"],
            completed: translations["teamList.info.final.completed"],
          }
        }

        let baseMenuObject = {
          isOpened: false,
          filterAll: {
            id: 'all',
            name: translations['teamList.ALL'],
            isChecked: true
          },
          filterToStart: {
            id: 'toStart',
            name: translations['teamList.TO_START'],
            isChecked: true
          },
          filterInProgress: {
            id: 'inProgress',
            name: translations['teamList.IN_PROGRESS'],
            isChecked: true
          },
          filterCompleted: {
            id: 'completed',
            name: translations['teamList.COMPLETED'],
            isChecked: true
          }
        }
        this.menuTable = [
          {
            ...JSON.parse(JSON.stringify(baseMenuObject)),
            title: translations['phases.GOAL_SETTING'],
            titleCompact: translations["phases.initials.GOAL_SETTING"],
            phaseId: PerfCareAssessmentPhaseKeys.GOAL_SETTING,
            id: 'goalSetting'
          }, {
            ...JSON.parse(JSON.stringify(baseMenuObject)),
            title: translations['phases.DEVELOPMENT_PLAN'],
            titleCompact: translations["phases.initials.DEVELOPMENT_PLAN"],
            phaseId: PerfCareAssessmentPhaseKeys.DEVELOPMENT_PLAN,
            id: 'developmentPlan'
          }, {
            ...JSON.parse(JSON.stringify(baseMenuObject)),
            title: translations['phases.MID_TERM_REVIEW'],
            titleCompact: translations["phases.initials.MID_TERM_REVIEW"],
            phaseId: PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW,
            id: 'midTerm'
          }, {
            ...JSON.parse(JSON.stringify(baseMenuObject)),
            title: translations['phases.FINAL_EVALUATION'],
            titleCompact: translations["phases.initials.FINAL_EVALUATION"],
            phaseId: PerfCareAssessmentPhaseKeys.FINAL_EVALUATION,
            id: 'final'
          },
        ]
        this.listTeamMembers()
      })
  }

  // Apre menu tabella utenti
  openFilterModal(event: any, id: number) {
    this.phaseIndex = id;
    this.modalService.open('filterModal')
  }

  closeFilterModal() {
    this.modalService.close('filterModal')
    this.listTeamMembers();
  }

  checkFilters(filterChecked: any, phase: any) {
    this.reloadingFilters = true;
    if (filterChecked.id == 'all') {
      if (filterChecked.isChecked) {
        phase.filterToStart.isChecked = true;
        phase.filterInProgress.isChecked = true;
        phase.filterCompleted.isChecked = true;
      } else {
        phase.filterToStart.isChecked = false;
        phase.filterInProgress.isChecked = false;
        phase.filterCompleted.isChecked = false;
      }
    } else {
      if (filterChecked.isChecked) {
        if (phase.filterToStart.isChecked &&
          phase.filterInProgress.isChecked &&
          phase.filterCompleted.isChecked) {
          phase.filterAll.isChecked = true;
        }
      } else {
        phase.filterAll.isChecked = false;
      }
    }
    this.cdr.detectChanges();
    this.reloadingFilters = false;
  }

  filterList() {
    let filterForService: MapById<string | string[]> = {};
    let tmpFilter = [];
    for (let i = 0; i < this.menuTable.length; i++) {
      tmpFilter = [];
      tmpFilter = this.getPhaseStatusForFilter(this.menuTable[i]);
      if (tmpFilter && tmpFilter.length) {
        filterForService[this.menuTable[i].phaseId] = tmpFilter;
      }
    }
    return filterForService;
  }

  getPhaseStatusForFilter(phase: any) {
    let checked = [];
    if (!phase.filterAll.isChecked) {
      if (phase.filterToStart.isChecked) {
        checked.push('TO_START');
      }
      if (phase.filterInProgress.isChecked) {
        checked.push('STARTED');
      }
      if (phase.filterCompleted.isChecked) {
        checked.push('COMPLETED');
      }
    }
    return checked;
  }

  listTeamMembers() {
    this.isLoadingTeamMembers = true;

    if (this.listTeamMembers$) {
      this.listTeamMembers$.unsubscribe();
    }

    this.listTeamMembers$ = this.managerService.listTeamMembers(this.runningYear, this.filterList(), this.searchedText)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "tl002",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response && data.response.length) {
            this.translate.get([
              'phases.FEEDBACK',
              'teamList.modals.COUNTER_FEEDBACK_1',
              'teamList.modals.COUNTER_FEEDBACK_2',
              'teamList.modals.PEER_REC',
              'teamList.modals.PEER_REQ',
              'teamList.modals.PEER_REQ1',
              'teamList.modals.PEER_REQ2',
              'teamList.modals.CLAP_SENT',
              'teamList.modals.INTERVIEW',
              'teamList.modals.EVIDENCES',
              'teamList.modals.TOTAL_FEEDBACK',
            ]).subscribe((translations: any) => {
              for (let i = 0; i < data.response.length; i++) {
                data.response[i].goalSettingStatus = this.getStatus(data.response[i].goalSettingStatus);
                data.response[i].developmentPlanStatus = this.getStatus(data.response[i].developmentPlanStatus);
                data.response[i].midTermReviewStatus = this.getStatus(data.response[i].midTermReviewStatus);
                data.response[i].finalEvaluationStatus = this.getStatus(data.response[i].finalEvaluationStatus);
                data.response[i].modalData = {
                  modalId: 'tl001',
                  title: translations['phases.FEEDBACK'] + ' ' + data.response[i].user?.forename + ' ' + data.response[i].user?.surname,
                  subtitle: (data.response[i]?.feedback?.totalFeedbackCount || 0) + ' ' + translations['teamList.modals.TOTAL_FEEDBACK'],
                  description: this.getModalDescription(data.response[i].feedback, translations)
                }
              }
              this.teamList = data.response;
            })
          } else {
            this.teamList = [];
          }
        }
        this.isLoadingTeamMembers = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "tl003",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.appStore.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTeamMembers = false;
      });
  }

  getStatus(status: string) {
    switch (status) {
      case 'TO_START':
        return "toStart";
      case 'STARTED':
        return 'inProgress';
      case 'COMPLETED':
        return 'completed'
      default:
        return 'disabled'
    }
  }

  getModalDescription(feedback: any, translations: any) {
    let text = '';
    let peerFeedback = '';
    if (feedback && feedback.requestedPeerFeedbackCount > 0) {
      peerFeedback = '<b>' + feedback.requestedPeerFeedbackCount + '</b>' + translations['teamList.modals.PEER_REQ1'] + '<b>' + feedback.requestedFeedbackNotesWithResponseCount + ' </b> ' + translations['teamList.modals.PEER_REQ2'] + '</b><br>';
    }
    if (feedback) {
      if (peerFeedback && peerFeedback.length) {
        text += peerFeedback;
      }
      text += translations['teamList.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseCount || '-') + '</b><br>';
      text += translations['teamList.modals.INTERVIEW'] + ' <b>' + (feedback.createdFeedbackDialogueCount || '-') + '</b><br>';
      text += translations['teamList.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceCount || '-') + '</b><br>';
    }
    return text;
  }

  openFeedbackDetailsModal(userFeedback: any) {
    this.userFeedbackModal = userFeedback;
    this.modalService.open('feedbackDetails');
  }

  closeFeedbackDetailsModal() {
    this.modalService.close('feedbackDetails');
    this.userFeedbackModal = null;
  }


  goToPersonDetails(user: any) {
    this.redirectService.goToPersonDetails(user.userId, this.runningYear)
  }

  openPhaseInfoModal(phaseId: string, userPhaseStatus: any) {
    this.phaseIdForModal = phaseId;
    if (userPhaseStatus === 'disabled') {
      this.isPhaseDisabled = true;
    }
    this.modalService.open('phaseInfo');
  }

  closePhaseInfoModal() {
    this.modalService.close('phaseInfo');
    this.phaseIdForModal = '';
    this.isPhaseDisabled = false;
  }

  ngOnDestroy() {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
  }
}

export interface MenuItem {
  id: string;
  name: string;
}