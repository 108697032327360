<div class="admin-table-container column-spacing">
    <p class="section-title">{{ ('process.phases.TITLE' | translate) }}</p>
    <div class="divisor-line"></div>

    <ng-container *ngIf="!isLoadingPhases && phasesData && phasesData.length">
        <div class="table-content process-structure phases">
            <table>
                <thead>
                    <tr>
                        <th translate="{{ ('process.phases.PROCESS_PHASE' | translate)}}"></th>
                        <th translate="{{ ('process.phases.START_DATE' | translate)}}"></th>
                        <th translate="{{ ('process.phases.END_DATE' | translate)}}"></th>
                        <th translate="{{ ('process.phases.STATUS' | translate)}}"></th>
                        <th translate="{{ ('process.phases.ACTIONS' | translate)}}"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let phase of phasesData">
                        <td>{{ phase.name }}</td>
                        <td>{{ phase.startDate ? (phase.startDate | date: 'dd/MM/YYYY') : '--' }}</td>
                        <td>{{ phase.endDate ? (phase.endDate | date: 'dd/MM/YYYY') : '--' }}</td>
                        <td>
                            <item-list-team-status *ngIf="phase.status == 'ACTIVE'" type="completed"
                                [message]="('process.phases.ACTIVE' | translate)">
                            </item-list-team-status>
                            <item-list-team-status *ngIf="phase.status == 'DISABLED'" type="disabled"
                                [message]="('process.phases.DISABLED' | translate)">
                            </item-list-team-status>
                        </td>
                        <td class="action">
                            <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(phase)"
                                src="/assets/img/icons/more-horizontal.svg">
                            </svg-icon>
                            <ng-container *ngIf="phase.isMenuOpen">
                                <div class="dropdown-menu">
                                    <europ-dropdown-item (click)="onMenuOptionsClicked(option, phase)"
                                        [ngClass]="{'last': last}" class="full-width"
                                        *ngFor="let option of menuOptions; let last = last" [item]="option">
                                    </europ-dropdown-item>
                                </div>
                            </ng-container>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoadingPhases">
        <div class="loader spinner-big"></div>
    </ng-container>
    <ng-container *ngIf="!isLoadingPhases && (!phasesData || !phasesData.length)">
        <div class="admin-no-data-wrapper">
            <p translate="process.details.NO_DATA"></p>
        </div>
    </ng-container>
</div>

<!--modale disattiva fase -->
<modal id="disablePhase" (onClose)="closeDisableModal()" (onConfirm)="closeDisableModal()"
    [confirmLabel]="('generic.CONFIRM' | translate)">
    <modal-text-content [modalId]="disableModalData.modalId" [title]="disableModalData.title"
        [text]="disableModalData.text">
    </modal-text-content>
</modal>

<!--modale attiva fase -->
<modal id="activatePhase" (onClose)="closeActivateModal()" (onConfirm)="closeActivateModal()"
    [confirmLabel]="('generic.CONFIRM' | translate)">
    <modal-text-content [modalId]="activateModalData.modalId" [title]="activateModalData.title"
        [text]="activateModalData.text">
    </modal-text-content>
</modal>