<page-container>
    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
            [title]="('process.decline.SIDENAV_TITLE' | translate)"
            [description]="('process.decline.SIDENAV_DESCR' | translate)">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingObjective">
                <div class="form-container">
                    <!-- Titolo dell'obiettivo  -->
                    <input-container [isObbligatory]="true" [tooltipModalMessage]="goalTitleTooltip"
                        [isValued]="goalTitle && goalTitle.trim().length" [isObbligatory]="true"
                        title="{{'process.decline.GOAL_TITLE' | translate}}">
                        <europ-input [bindValue]="goalTitle" (onModelChanged)="goalTitleChanged($event)"
                            placeholder="{{'process.decline.GOAL_TITLE_PLACEHOLDER' | translate}}">
                        </europ-input>
                    </input-container>
                    <!-- Target -->
                    <input-container [isObbligatory]="true" [tooltipModalMessage]="targetTooltip"
                        [isValued]="target && target.trim().length" [isObbligatory]="true"
                        title="{{'process.decline.TARGET' | translate}}">
                        <europ-text-area [bindValue]="target" (onModelChanged)="targetChanged($event)"
                            placeholder="{{'process.decline.TARGET_PLACEHOLDER' | translate}}">
                        </europ-text-area>
                    </input-container>
                    <!-- Target -->
                    <input-container [isObbligatory]="true" [tooltipModalMessage]="defineDateTooltip"
                        [isValued]="selectedDate" [isObbligatory]="true"
                        title="{{'process.decline.DEFINE_DATE' | translate}}">
                        <europ-datepicker [minDate]="today" [value]="selectedDate"
                            (dateChanged)="selectedDateChanged($event)"
                            placeholder="{{'process.decline.DEFINE_DATE_PLACEHOLDER' | translate}}">
                        </europ-datepicker>
                    </input-container>
                    <div class="button-container">
                        <europ-button (onButtonClicked)="goToUpdateObjective()"
                            [disabled]="isLoadingObjective || !isObbligatoryDataInserted()" type="primary default"
                            size="large"
                            [label]="(objectiveId && objectiveId.length) ? ('process.decline.UPDATE' | translate) : ('process.decline.CREATE' | translate)">
                        </europ-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingObjective">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un processo -->
<modal id="defineObjectives" (onCancel)="goBackBrowser()" (onClose)="closeUpdateModal()"
    (onConfirm)="(objectiveId && objectiveId.length) ? updateObjective() : createObjective()"
    cancelLabel="{{modalUpdateTextCloseTextButton}}" confirmLabel="{{modalUpdateTitleConfirmTextButton}}">
    <modal-text-content [modalId]="modalUpdateId" [title]="modalUpdateTitle" [subtitle]="modalUpdateSubtitle"
        [text]="modalUpdateText">
    </modal-text-content>
</modal>