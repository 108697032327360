import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
  collaborator: CollaboratorState
}

export interface CollaboratorState {
}

// Store iniziale
const initialState: CollaboratorState = {
};


export const createCollaboratorReducer = createReducer(
  initialState
)

export function collaboratorReducer(state: CollaboratorState | undefined, action: Action) {
  return createCollaboratorReducer(state, action);
}