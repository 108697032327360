import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'europ-accordion',
  templateUrl: 'europ-accordion.component.html',
  styleUrls: ['./europ-accordion.component.scss'],
  animations: [
    trigger(
      'showIf', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ]
    )
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EuropAccordionComponent implements OnInit {
  @Input() title: string = '';
  @Input() statusData: any;
  @Input() badgeNumber: number = 0;
  @Input() isNew: boolean = false;
  @Input() infoIcon: boolean = true;
  @Input() attrAriaLabel: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isAccordionOpened: boolean = false;
  @Input() tooltipModalMessage?: ApplicationModalMessage;
  @Input() isChallengeAccordion?: boolean;
  @Input() isObjectiveTable?: boolean;
  @Input() noInfo?: boolean;
  @Input() grayBackground?: boolean;
  @Input() withOutRightIcon?: boolean;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  isMobile: boolean = false;

  constructor(private store: Store<fromApp.AppState>) {
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit() { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  openAccordion() {
    this.isAccordionOpened = !this.isAccordionOpened;
  }

  // Apre la modale info
  openModalMessage() {
    if (this.tooltipModalMessage) {
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage }));
    }
  }

  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }
}