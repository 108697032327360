import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { GetMyManagerUserDataResponse, ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse, SenecaResponse, UpdateEvidenceFeedbackForUserResponse } from 'atfcore-commonclasses';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';

@Component({
  selector: 'app-collaborator-createUpdateEvidence',
  templateUrl: './createUpdateEvidence.component.html',
  styleUrls: ['./createUpdateEvidence.component.scss']
})
export class CreateUpdateEvidenceComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: number = 0;
  userId: string = '';
  personDetails: any;
  isFetchingCreateEvidence: boolean = false;
  isFetchingUpdateEvidence: boolean = false;
  createEvidence$: Subscription | null = null;
  updateEvidence$: Subscription | null = null;
  getEvidenceById$: Subscription | null = null;
  modalCreateUpdateEvidenceTitle: string = '';
  modalCreateUpdateEvidenceSubtitle: string = '';
  modalCreateUpdateEvidenceText: string = '';
  modalCreateUpdateEvidenceTitleConfirmTextButton: string = '';
  modalCreateUpdateEvidenceTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  today = new Date();

  objectiveToAssociateTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  contestTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  osservationsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalCreateUpdateEvidenceId: string = '';

  goalId: string = '';

  evidenceId: string = '';
  isFetchingGetEvidence: boolean = false;
  evidence = {
    goalId: '',
    comment: '',
    observations: '',
    feedbackId: ''
  }
  evidenceCopy = {
    goalId: '',
    comment: '',
    observations: '',
    feedbackId: ''
  }
  objectiveOptions: any[] = [];
  isLoadingManagerInfo: boolean = false;
  getManagerData$: Subscription = new Subscription();
  managerData: any;
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToEvidence$: Subscription = new Subscription();
  objectiveAssociated: any;
  objectiveAssociatedCopy: any;
  isImpersonate: boolean = false;

  constructor(
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;
              this.evidenceId = params.evidenceId;

              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.personDetails = loggedUser && loggedUser.user;
                    this.getManagerData();
                    this.getGoalsToAssociate();
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.INFO',
        'evidences.ASSOCIATE_TO_OBJECTIVE',
        'evidences.ASSOCIATE_TO_OBJECTIVE_INFO',
        'evidences.CONTEST',
        'evidences.CONTEST_INFO',
        'evidences.OBSERVATIONS',
        'evidences.OBSERVATIONS_INFO',
      ])
      .subscribe(translations => {
        this.objectiveToAssociateTooltipModal = {
          modalId: "d1005",
          title: translations['generic.INFO'],
          subtitle: translations['evidences.ASSOCIATE_TO_OBJECTIVE'],
          text: translations['evidences.ASSOCIATE_TO_OBJECTIVE_INFO']
        };
        this.contestTooltipModal = {
          modalId: "d1006",
          title: translations['generic.INFO'],
          subtitle: translations['evidences.CONTEST'],
          text: translations['evidences.CONTEST_INFO']
        };
        this.osservationsTooltipModal = {
          modalId: "d1007",
          title: translations['generic.INFO'],
          subtitle: translations['evidences.OBSERVATIONS'],
          text: translations['evidences.OBSERVATIONS_INFO']
        };
        this.formatModalCreateUpdateEvidence();
      })
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociate() {
    this.isLoadingGoalsToAssociate = true;
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    this.getGoalsToAssociateToEvidence$ = this.collaboratorService.getGoalsToAssociateWithEvidence()
      .subscribe((data: SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.objectiveOptions = data.response;

          if (this.goalId && this.goalId.length && !this.evidenceId) {
            let indexOfAssociatedObjective = 0;
            for (let i = 0; i < this.objectiveOptions.length; i++) {
              if (this.goalId == this.objectiveOptions[i].goalId) {
                indexOfAssociatedObjective = i;
              }
            }
            this.objectiveAssociated = this.objectiveOptions[indexOfAssociatedObjective];

            this.objectiveAssociatedCopy = JSON.parse(JSON.stringify(this.objectiveAssociated));
          }

          if (this.personDetails && this.personDetails.userId && this.evidenceId) {
            this.getEvidenceById();
          }
        } else {
          this.objectiveOptions.length = 0;
        }
        this.isLoadingGoalsToAssociate = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsToAssociate = false;
      });
  }

  // Recupera i dati del manager
  getManagerData() {
    this.isLoadingManagerInfo = true;
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((managerData: SenecaResponse<GetMyManagerUserDataResponse>) => {
        if (managerData && managerData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + managerData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.managerData = managerData.response;
        }
        this.isLoadingManagerInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerInfo = false;
      });
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return ((this.objectiveAssociated && this.objectiveAssociated.goalId) != (this.objectiveAssociatedCopy && this.objectiveAssociatedCopy.goalId)) || this.evidence.comment != this.evidenceCopy.comment
      || this.evidence.observations != this.evidenceCopy.observations;
  }

  // Formatta i testi della modale per la creazione/aggiornamento dell'evidenza
  formatModalCreateUpdateEvidence(isBack?: boolean) {
    this.modalCreateUpdateEvidenceId = this.getModalId(isBack);
    this.modalCreateUpdateEvidenceTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdateEvidenceSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdateEvidenceText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdateEvidenceTitleConfirmTextButton = this.getConfirmLabelCreateUpdateEvidenceModal(isBack);
    this.modalCreateUpdateEvidenceTextCloseTextButton = this.getCancelLabelCreateUpdateEvidenceModal();
  }

  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'd1008';
    } else {
      if (isBack) {
        if (this.evidenceId && this.evidenceId.length) {
          return 'd1009';
        } else {
          return 'd1010';
        }
      } else {
        if (this.evidenceId) {
          return 'd1011';
        } else {
          return 'd1012';
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.objectiveAssociated && this.evidence.comment && this.evidence.comment.trim().length
      && this.evidence.observations && this.evidence.observations.trim().length
  }

  // Testo per il modale crea/aggiorna evidenza
  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('evidences.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('evidences.GO_BACK');
      } else {
        if (this.evidenceId) {
          return this.translate.instant('evidences.UPDATE_EVIDENCE');
        } else {
          return this.translate.instant('evidences.INSERT_EVIDENCE');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.evidenceId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('evidences.YOU_ARE_INSERTING_AN_EVIDENCE');
    } else if (this.evidenceId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('evidences.YOU_ARE_UPDATING_AN_EVIDENCE');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.evidenceId && this.evidenceId.length) {
        return this.translate.instant('evidences.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_UPDATE');
      } else {
        return this.translate.instant('evidences.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
      }
    } else {
      if (isBack) {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.YOU_ARE_UPDATING') + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_UPDATE_THIS_EVIDENCE');
        } else {
          return this.translate.instant('evidences.YOU_ARE_INSERTING_TO') + this.managerData.forename + ' ' + this.managerData.surname + ' ' + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' + this.translate.instant('evidences.EVIDENCE_RULES_IN_MODAL') + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_INSERT_THIS_EVIDENCE');
        }
      } else {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.YOU_ARE_UPDATING') + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_UPDATE_THIS_EVIDENCE');
        } else {
          return this.translate.instant('evidences.YOU_ARE_INSERTING_TO') + this.managerData.forename + ' ' + this.managerData.surname + ' ' + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' + this.translate.instant('evidences.EVIDENCE_RULES_IN_MODAL') + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_INSERT_THIS_EVIDENCE');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateEvidenceModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateEvidenceModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.UPDATE_EVIDENCE_BACK');
        } else {
          return this.translate.instant('evidences.INSERT_EVIDENCE_BACK');
        }
      } else {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.UPDATE_EVIDENCE');
        } else {
          return this.translate.instant('evidences.INSERT_EVIDENCE');
        }
      }
    }
  }

  // Recupera l'evidenza dal evidenceId
  getEvidenceById() {
    this.isFetchingGetEvidence = true;

    this.getEvidenceById$ = this.collaboratorService.getEvidenceFeedback(this.runningYear, this.evidenceId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d011",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.evidence = data.response;

          this.evidenceCopy = JSON.parse(JSON.stringify(this.evidence));

          let indexOfAssociatedObjective = 0;
          for (let i = 0; i < this.objectiveOptions.length; i++) {
            if (this.evidence.goalId == this.objectiveOptions[i].goalId) {
              indexOfAssociatedObjective = i;
            }
          }
          this.objectiveAssociated = this.objectiveOptions[indexOfAssociatedObjective];

          this.objectiveAssociatedCopy = JSON.parse(JSON.stringify(this.objectiveAssociated));
        }
        this.isFetchingGetEvidence = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "d012",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetEvidence = false;
        })
  }

  // Gestisce quando crei/aggiorni un'evidenza
  goToUpdateEvidence(isBack?: boolean) {
    if (!this.checkIfDataIsChanged() && isBack) {
      this.goBackBrowser();
    } else {
      this.formatModalCreateUpdateEvidence(isBack);
      this.openModalForCreateUpdateEvidence();
    }
  }

  // Crea evidenza
  createEvidence() {
    this.closeModalCreateUpdateEvidence();
    if (!this.isObbligatoryDataInserted()) {
      this.closeModalCreateUpdateEvidence();
    } else {
      this.isFetchingCreateEvidence = true;
      this.closeModalCreateUpdateEvidence();

      let evidence: evidenceObjectForCreate = {
        goalId: this.objectiveAssociated.goalId,
        comment: this.evidence.comment,
        observations: this.evidence.observations
      }

      this.createEvidence$ = this.collaboratorService.createEvidenceFeedback(evidence)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "d013",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "d014",
              title: this.translate.instant("evidences.EVIDENCE_CREATED"),
              text: this.translate.instant("evidences.EVIDENCE_CREATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
          this.goBackBrowser();
          this.isFetchingCreateEvidence = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "d015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingCreateEvidence = false;
          })
    }
  }

  // Aggiorna evidenza
  updateEvidence() {
    if (!this.isObbligatoryDataInserted()) {
      this.closeModalCreateUpdateEvidence();
    } else {
      this.isFetchingUpdateEvidence = true;
      this.closeModalCreateUpdateEvidence();

      let evidenceToUpdate = {
        feedbackId: this.evidence.feedbackId,
        comment: this.evidence.comment,
        observations: this.evidence.observations,
        goalId: this.objectiveAssociated && this.objectiveAssociated.goalId
      }


      this.updateEvidence$ = this.collaboratorService.updateEvidenceFeedback(evidenceToUpdate)
        .subscribe((data: SenecaResponse<UpdateEvidenceFeedbackForUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "d016",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "d017",
              title: this.translate.instant("evidences.EVIDENCE_UPDATED"),
              text: this.translate.instant("evidences.EVIDENCE_UPDATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
          }
          this.isFetchingUpdateEvidence = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "d018",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingUpdateEvidence = false;
          })
    }
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdateEvidence() {
    this.modalService.open('modalCreateUpdateEvidenceForCollaborator');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdateEvidence() {
    this.modalService.close('modalCreateUpdateEvidenceForCollaborator');
  }

  changeAssociatedObjective(objective: any) {
    this.objectiveAssociated = objective;
  }

  // Il contesto è cambiato
  evidenceContestChanged(text: string) {
    this.evidence.comment = text;
  }

  // La osservazione è cambiata
  osservationChanged(text: string) {
    this.evidence.observations = text;
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    if (this.createEvidence$) {
      this.createEvidence$.unsubscribe();
    }
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    if (this.updateEvidence$) {
      this.updateEvidence$.unsubscribe();
    }
    if (this.getEvidenceById$) {
      this.getEvidenceById$.unsubscribe();
    }
  }
}

export interface evidenceObjectForCreate {
  goalId: string,
  comment: string,
  observations: string;
  recipientUserId?: string
}