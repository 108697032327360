<div class="admin-table-container column-spacing">
    <p class="section-title">{{ ('process.objectives.PAGE_TITLE' | translate) }}</p>
    <!-- anagrafica -->
    <div class="gray-box-container">
        <div class="gray-box">
            <div class="text-info">
                <p class="number">800</p>
                <p class="description" translate="process.objectives.TOTAL_OBJECTIVES"></p>
            </div>
        </div>
        <!-- Inclusi -->
        <div class="gray-box">
            <div class="text-info">
                <p class="number">600</p>
                <p class="description" [innerHTML]="('process.objectives.DECLINED_OBJECTIVES' | translate)"></p>
            </div>
        </div>
        <!-- Esclusi -->
        <div class="gray-box">
            <div class="text-info">
                <p class="number">200</p>
                <p class="description" [innerHTML]="('process.objectives.TO_BE_DECLINED_OBJECTIVES' | translate)"></p>
            </div>
        </div>
    </div>
</div>

<div class="accordion-container">
    <!-- Accordion per le strutture -->
    <ng-container *ngIf="!isLoadingStructureList">
        <ng-container *ngFor="let structure of structureList">
            <europ-accordion [title]="structure.name" [tooltipModalMessage]="structure.tootlip"
                [statusData]="structure.statusData">
                <div class="main-content-wrapper white-background">
                    <child-content [tabList]="structure.contentTabList"
                        (onTabChanged)="onContentTabChanged($event, structure)">
                        <div class="button-container">
                            <europ-button type="primary bordless" [label]="'goal.ADD_OBJECTIVES' | translate"
                                (onButtonClicked)="addObjective()"></europ-button>
                            <europ-button type="primary" [label]="'process.objectives.SAVE_OBJECTIVES' | translate"
                                (onButtonClicked)="saveObjective()"></europ-button>
                            <europ-button type="secondary" [label]="'goal.SHARE_OBJECTIVES' | translate"
                                (onButtonClicked)="shareObjectives()">
                            </europ-button>
                        </div>
                    </child-content>
                </div>
            </europ-accordion>

        </ng-container>
    </ng-container>

    <ng-container *ngIf="isLoadingStructureList">
        <div class="loader spinner-big"></div>
    </ng-container>
</div>