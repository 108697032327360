<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="sendRevisionAction(true)" [isBackButton]="true"
      [title]="'midTerm.addMidTermReview.TITLE' | translate"
      [description]="'midTerm.addMidTermReview.DESCRIPTION' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserGoals">
        <div *ngFor="let goal of goalList" fxLayout="column" class="goal-list">
          <objective-for-mid-term-review [goal]="goal" [isOperations]="isOperations" [isInnerHtml]="true">
          </objective-for-mid-term-review>
          <div class="divisor-vertical-line"></div>
        </div>
        <!-- Review fatta, readonly dati + commento manager -->
        <ng-container *ngIf="alreadySent">
          <mid-term-recap [user]="personDetails" [date]="userRevisionDate" [status]="userStatusForRecap"
            [observation]="userOsservation"></mid-term-recap>
          <div class="divisor-vertical-line"></div>
        </ng-container>

        <input-container [isLoading]="isLoadingUserGoals" [tooltipModalMessage]="objectiveOsservationTooltipModal"
          [isValued]="osservation && osservation.trim().length" [isObbligatory]="true"
          title="{{'midTerm.addMidTermReview.OSSERVATION_TEXTAREA_TITLE_MANAGER' | translate}}">
          <ckeditor #ckeditor debounce="500" [(ngModel)]="osservation" [config]="editorConfig">
          </ckeditor>
        </input-container>

        <div class="button-container">
          <europ-button (onButtonClicked)="sendRevisionAction()" [disabled]="isLoadingUserGoals" type="primary default"
            size="large" [label]="'generic.INSERT' | translate">
          </europ-button>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingUserGoals">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per card Mid Term Review -->
<modal id="objectiveFeedbackModalTooltip" (onConfirm)="closeObjectiveFeedbackTooltip()" [confirmLabel]="'OK'"
  (onClose)="closeObjectiveFeedbackTooltip()">
  <modal-text-content [modalId]="objectiveFeedbackTooltipModal.modalId" [title]="objectiveFeedbackTooltipModal.title"
    [text]="objectiveFeedbackTooltipModal.text">
  </modal-text-content>
</modal>

<!-- Modale per l'invio della revisione se non ci sono dati obbligatori -->
<modal id="modalCreateRevisionNoDataForManager" (onCancel)="goBackBrowser()"
  (onClose)="closeModalCreateRevisionNoDataForManager()" (onConfirm)="closeModalCreateRevisionNoDataForManager()"
  cancelLabel="{{'goalSetting.createGoal.GO_BACK_YES' | translate}}"
  confirmLabel="{{'generic.NO_REMAIN_IN_PAGE' | translate}}">
  <modal-text-content [modalId]="'1052'"
    [title]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA' | translate) : ('evidences.OBBLIGATORY_DATA_NOT_INSERTED' | translate) "
    [text]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA_TEXT' | translate) : ('midTerm.addMidTermReview.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION' | translate)">
  </modal-text-content>
</modal>

<!-- Modale per l'invio della revisione se non ci sono dati obbligatori -->
<modal id="modalCreateRevisionForManager"
  (onCancel)="isFromBack ? goBackBrowser() : closeModalCreateRevisionForManager()"
  (onClose)="closeModalCreateRevisionForManager()" (onConfirm)="sendRevision()"
  [cancelLabel]="'generic.ANNULL' | translate" [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content [modalId]="'d1060'" [title]="'midTerm.addMidTermReview.CONFIRM_REVISION' | translate"
    [subtitle]="'midTerm.addMidTermReview.CONFIRM_REVISION_SUBTITLE' | translate"
    [text]="'midTerm.addMidTermReview.CONFIRM_REVISION_TEXT' | translate">
  </modal-text-content>
  <div class="modal-recap-container">
    <ng-container *ngIf="alreadySent">
      <mid-term-recap [user]="personDetails" [date]="userRevisionDate" [observation]="userOsservation"
        [status]="userStatusForRecap">
      </mid-term-recap>
    </ng-container>
    <ng-container *ngIf="loggedUser">
      <mid-term-recap [user]="loggedUser" [observation]="osservation">
      </mid-term-recap>
    </ng-container>
  </div>
</modal>