import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminHomeComponent } from './home/home.component';
import { adminReducer } from './ngrx/admin.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminEffects } from './ngrx/admin.effects';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { AdminService } from '../shared/services/admin.service';
import { ProcessSetupComponent } from './processSetup/processSetup.component';
import { CreateUpdateProcessComponent } from './createUpdateProcess/createUpdateProcess.component';
import { ProcessSetupDetailsComponent } from './processSetupDetails/processSetupDetails.component';
import { ProcessSetupParticipantComponent } from './processSetupParticipant/processSetupParticipant.component';
import { ProcessSetupObjectivesComponent } from './processSetupObjectives/processSetupObjectives.component';
import { DeclineObjectivesComponent } from './declineObjectives/declineObjectives.component';
import { ProcessSetupPhasesComponent } from './processSetupPhases/processSetupPhases.component';
import { PeopleAnagComponent } from './peopleAnag/peopleAnag.component';
import { StructureAnagComponent } from './structure-anag/structure-anag.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { CalibrationComponent } from './calibration/calibration.component';
import { CalibrationUserListComponent } from './calibration-user-list/calibration-user-list.component';
import { AdminDefinePerformanceLevelManagerComponent } from './definePerformanceLevel/definePerformanceLevel.component';
import { AdminConfirmPerformanceLevelManagerComponent } from './confirmPerformanceLevel/confirmPerformanceLevel.component';
import { CalibrationMatrixComponent } from './calibration-matrix/calibration-matrix.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AdminComponent,
    AdminHomeComponent,
    MonitoringComponent,
    ProcessSetupComponent,
    CreateUpdateProcessComponent,
    ProcessSetupDetailsComponent,
    ProcessSetupParticipantComponent,
    ProcessSetupObjectivesComponent,
    DeclineObjectivesComponent,
    ProcessSetupPhasesComponent,
    PeopleAnagComponent,
    StructureAnagComponent,
    ImpersonateComponent,
    CalibrationComponent,
    CalibrationUserListComponent,
    AdminDefinePerformanceLevelManagerComponent,
    AdminConfirmPerformanceLevelManagerComponent,
    CalibrationMatrixComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    DragDropModule,
    StoreModule.forFeature('admin', adminReducer),
    EffectsModule.forFeature([AdminEffects])
  ],
  providers: [
    AdminService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AdminModule { }
