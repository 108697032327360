<div class="box-preview" fxLayout="column" fxLayoutAlign="center center">
  <div class="preview-container">
    <div *ngIf="doc.file && !doc.file.fileExtension || imageErrors" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" class="drag-and-drop" fxLayout="column" fxLayoutAlign="center center">
      <div class="file-upload-container">
        <p class="text-big" translate="uploadFile.CHARGED_WRONG_FILE"></p>
        <a fxLayout="row" fxLayoutAlign="start center" (click)="uploadDoc()">
          <h4 class="text-big-link blue-upload bold-text">
            {{'uploadFile.UPDATE_FILE' | translate}}
          </h4>
        </a>
      </div>
    </div>
    <div *ngIf="(doc.file && doc.file.fileExtension) && !isToReset" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" class="drag-and-drop" fxLayout="column" fxLayoutAlign="center center">
      <svg-icon class="big-size" [applyClass]="true" src="assets/img/icons/file/{{doc.file.fileExtension}}.svg">
      </svg-icon>
      <div class="file-upload-container">
        <p class="text-big">{{doc.name}}</p>
        <p class="text-small">{{doc.file.fileExtension}} file - {{doc.file.sizeRounded}}kB</p>
        <a fxLayout="row" fxLayoutAlign="start center" (click)="uploadDoc()">
          <h4 class="text-big-link blue-upload bold-text">
            {{'uploadFile.UPDATE_FILE' | translate}}
          </h4>
        </a>
      </div>
    </div>

    <div *ngIf="!blob || isToReset" class="drag-and-drop" (drop)="handleFileInputOnDrug($event)"
      (dragover)="allowDrag($event)" fxLayout="column" fxLayoutAlign="center center">
      <svg-icon class="big-size" [applyClass]="true" src="assets/img/icons/upload-file-cloud.svg"></svg-icon>
      <div class="file-upload-container">
        <p class="title-section">{{'uploadFile.DRAG_FILE' | translate}}</p>
        <p class="text-small">{{'uploadFile.OR' | translate}}</p>
        <a fxLayout="row" fxLayoutAlign="start center" (click)="uploadDoc()">
          <h4 class="text-big-link blue-upload bold-text">
            {{'uploadFile.CHOOSE_FILES' | translate}}
          </h4>
        </a>
      </div>
    </div>
  </div>
</div>
<div fxHide fxLayout="row" fxLayoutAlign="start center" (click)="uploadDoc()">
  <input type="file" id="uploadFile" (change)="handleFileInput($event.target)" [accept]="acceptedMimeTypes">
</div>