import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { parseBoolean } from 'src/commonclasses';

declare let kWidget: any;

@Component({
  selector: 'kaltura-player',
  templateUrl: './kaltura-player.component.html',
  styleUrls: ['./kaltura-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KalturaPlayerComponent implements OnInit, OnDestroy {
  @Input() isDisabled: boolean = false;
  @Input() itemId: string = '';
  @Input() playerVisible: boolean = false;
  @Input() seekTo: string = '';
  @Input() kalturaScript: string = '';
  @Input() customId: string = '';
  @Input() inModal: boolean = false;

  dynamicEmbed: boolean = false;
  kdp: any;
  adminMode = false;
  playerLoaded = false;
  // Lo script può essere di due tipi: Auto o Dynamic. Se è Dynamic allora è composto da 2 pezzi.
  kalturaScriptParts1: string = '';
  kalturaScriptParts2: string = '';

  lastInProgressUpdate: number = 0;

  @ViewChild("contentPlayer") contentPlayerElement: any;

  @Output() itemInProgress = new EventEmitter<any>();
  @Output() itemConsumed = new EventEmitter<any>();

  constructor(protected _sanitizer: DomSanitizer,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.customId = this.customId || 'contentPlayer';
    this.playerVisible = parseBoolean(this.playerVisible);
    this.dynamicEmbed = false;
    if (this.kalturaScript) {
      let kalturaScriptParts = this.kalturaScript.split("$$|||$$");
      if (kalturaScriptParts.length == 2) {
        this.dynamicEmbed = true;
        this.kalturaScriptParts1 = kalturaScriptParts[0];
        this.kalturaScriptParts2 = kalturaScriptParts[1];
      }
      else {
        this.kalturaScriptParts1 = this.kalturaScript;
      }
    }
    else {
      this.playerVisible = false;
    }

    if (this.playerVisible) {
      // Appendo lo script di kaltura
      this.loadScript(this.extractScriptUrl(this.kalturaScriptParts1));
      // E chiamo il metodo che registra gli hooks
      if (!this.adminMode) {
        this.registerKalturaHooks(this);
      }
    }
  }

  extractScriptUrl(scriptText: string): any {
    //create the DOM element 
    let _div: Element = this.renderer.createElement('div');
    _div.innerHTML = scriptText;
    let scriptElem = _div.querySelector("script");
    return scriptElem && scriptElem.src;
  }

  loadScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      //load script
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptUrl;
      if ((<any>script).readyState) {  //IE
        (<any>script).onreadystatechange = () => {
          if ((<any>script).readyState === "loaded" || (<any>script).readyState === "complete") {
            (<any>script).onreadystatechange = null;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          }
        };
      } else {  //Others
        script.onload = () => {
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
      }
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
    })
  }

  // Alla distruzione dell scope, faccio l'unbind degli eveni, altrimeti tutti gli oggetti visitati riceveranno tutti gli eventi
  ngOnDestroy() {
    // Dato che Kaltura sembra non eliminare i listener correttamente, elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple.
    this.itemId = '';
    // Poi distruggo il widget
    if (kWidget && this.kdp) {
      this.kdp.kUnbind(".allListener");
      if (this.dynamicEmbed) {
        kWidget.destroy(this.kdp);
        kWidget = null;
      }
    }
  }

  registerKalturaHooks(_this: any) {
    try {
      // finchè non viene caricato il player rilancio il timeout
      if (kWidget) {
        _this.playerLoaded = true;
        // Se il widget è dinamico appendo il secondo pezzo di script, che crea il player vero e proprio
        if (_this.dynamicEmbed) {
          // this.contentPlayerElement.nativeElement.innerHTML += this._sanitizer.bypassSecurityTrustHtml(this.kalturaScriptParts2);
          // this.appendToContentPlayer(this.kalturaScriptParts2);
          _this.loadScript(_this.extractScriptUrl(_this.kalturaScriptParts2));
        }

        kWidget.addReadyCallback((playerId: string) => {
          _this.kdp = document.getElementById(playerId);
          _this.kdp.kBind("playerPlayEnd", function () {
            _this.itemConsumed.emit();
          });
        });

        // E poi mi metto in ascolto delle callback
        kWidget.addReadyCallback((playerId: string) => {
          if (_this.itemId) {
            let ignoreFirstSeek = false;
            _this.kdp = document.getElementById(playerId);
            _this.kdp = document.getElementById(playerId);
            _this.kdp.kBind("playerStateChange.allListener", (mediaPlayerState: string) => {
              if (mediaPlayerState == "playing" || mediaPlayerState == "paused") {
                let now = new Date().getTime();
                _this.lastInProgressUpdate = now;
                let totalTime = _this.kdp.evaluate("{duration}");
                let currentTime = _this.kdp.evaluate("{video.player.currentTime}");
                // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi questa combinazione la ignoro
                if (!(mediaPlayerState == "paused" && currentTime >= totalTime * 0.99)) {
                  // Non mi interessa l'esito di questa operazione (se va male, amen)
                  if (!!_this.itemId) {
                    _this.playerService.updateStatusFromPlayer(_this.itemId, mediaPlayerState, totalTime, currentTime).subscribe();
                  }

                  _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
                }
              }
            });
            _this.kdp.kBind("playerUpdatePlayhead.allListener", () => {
              // Questo evento viene alzato quando il video aggiorna la barra di stato. Va tracciato questo evento e non il playerReady perché sui
              // dispositivi mobile e spesso anche sui browser si pianta. Kaltura suggerisce di tracciare il cambio della barra di stato come
              // da esempio: http://player.kaltura.com/modules/KalturaSupport/tests/SeekApi.qunit.html
              // Se devo quindi riprendo da dov'ero
              let now = new Date().getTime();
              if (_this.seekTo) {
                ignoreFirstSeek = true;
                _this.kdp.sendNotification("doSeek", _this.seekTo);
                _this.seekTo = null;
                _this.lastInProgressUpdate = now;
              }
              else {
                // Se sono passati più di 5 secondi dall'ultima volta che sono passato per questo evento, invio lo stato di avanzamento al sistema
                let totalTime = _this.kdp.evaluate("{duration}");
                let currentTime = _this.kdp.evaluate("{video.player.currentTime}");
                // Evito di salvarmi le informazione di avanzamento quando sono troppo prossimo alla fine per evitare conflitti con il completato
                if ((!_this.lastInProgressUpdate || _this.lastInProgressUpdate + 5000 < now) && currentTime < totalTime * 0.95) {
                  _this.lastInProgressUpdate = now;
                  // Non mi interessa l'esito di questa operazione (se va male, amen)
                  if (!!_this.itemId) {
                    _this.playerService.updateStatusFromPlayer(_this.itemId, "playing", totalTime, currentTime).subscribe();
                  }
                }
              }
            });
            _this.kdp.kBind("seeked.allListener", (seeked: number) => {
              if (ignoreFirstSeek) {
                ignoreFirstSeek = false;
              }
              else {
                let now = new Date().getTime();
                _this.lastInProgressUpdate = now;
                let totalTime = _this.kdp.evaluate("{duration}");
                let currentTime = _this.kdp.evaluate("{video.player.currentTime}");
                // Non mi interessa l'esito di questa operazione (se va male, amen)
                if (!!_this.itemId) {
                  _this.playerService.updateStatusFromPlayer(_this.itemId, "seeked", totalTime, currentTime).subscribe();
                }
              }
            });
            _this.kdp.kBind("playerPlayEnd.allListener", () => {
              let now = new Date().getTime();
              _this.lastInProgressUpdate = now;
              // Segnalo il termine della fruizione
              _this.itemConsumed.emit(true);
              let totalTime = _this.kdp.evaluate("{duration}");
              let currentTime = _this.kdp.evaluate("{video.player.currentTime}");
              if (!!_this.itemId) {
                _this.playerService.updateStatusFromPlayer(_this.itemId, "end", totalTime, currentTime).subscribe();
              }
            });
          }
        });
      }
      else {
        setTimeout(() => {
          _this.registerKalturaHooks(_this);
        }, 200);
      }
      _this.cdr.detectChanges();
    }
    catch (e) {
      setTimeout(() => {
        this.registerKalturaHooks(this);
      }, 200);
    }
  }
}