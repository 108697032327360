import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calibration-recap',
  templateUrl: 'calibration-recap.component.html',
  styleUrls: ['./calibration-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalibrationRecapComponent implements OnInit {
  @Input() user: any;
  @Input() midTermData: any;
  @Input() finalEvaluationData: any;
  @Input() isManager: boolean = false;
  @Input() whiteBackground: boolean = false;
  @Input() isCalibrationUser?: boolean;
  
  MID_STATUS_SRC = {
    'LATE': "assets/img/icons/red-face.svg",
    'ON_LINE': "assets/img/icons/yellow-face.svg",
    'WELL_ADVANCED': "assets/img/icons/green-face.svg"
  }
  constructor() {
  }

  ngOnInit() {
  }

}