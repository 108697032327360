<div class="page-content-wrapper hide-matrix">

  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('calibration.TITLE' | translate)"
    [adminPageSubtitle]="('calibration.PROCESS_LIST' | translate)" [adminBack]="true" [hasTabs]="false"
    [counter]="totalUsers" [counterLabel]="'calibration.SELECTED_USERS' | translate">
  </header-dossier>
  <!-- Ricerca -->
  <div class="search-wrapper">
    <div class="search-btn-wrapper">
      <europ-input (onIconClicked)="filterList()" typeInput="text" [bindValue]="searchedText"
        (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
        [placeholder]="'process.details.SEARCH_BY' | translate" [isSearchBar]="true">
      </europ-input>
      <europ-button type="primary link" size="medium" iconLeft="assets/img/icons/file/xls.svg" label="Report"
        [disabled]="isDownloadingReport" (onButtonClicked)="downloadReport()"></europ-button>
    </div>
    <div class="distribution-box">
      <div class="label-wrapper">
        <p translate="calibration.BOX_DISTRIBUTION"></p>
        <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer" [applyClass]="true"
          (click)="openBoxDistributionTooltip()">
        </svg-icon>
      </div>
      <div class="percentage-wrapper" *ngIf="!isLoadingUsers">
        <div class="box box-1">{{ grayBox }}%</div>
        <div class="box box-2">{{ yellowBox }}%</div>
        <div class="box box-3">{{ greenBox }}%</div>
      </div>
    </div>
  </div>
  <!-- Filtri -->
  <div class="search-filters-container" *ngIf="!noData">
    <div class="filters-recap-container margin2">
      <div class="left-column">
        <ng-container *ngIf="manager">
          <p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p>
          <p class="gray-text">{{ ('calibration.TEAM' | translate) + manager.surname + ' ' + manager.forename }}</p>
        </ng-container>
        <ng-container *ngIf="filtersText">
          <p class="placeholder" [innerHTML]="filtersText"></p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="main-content-wrapper admin">
    <ng-container *ngIf="isLoadingUsers">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingUsers">

      <div class="matrix-lock">
        <svg-icon src="assets/img/icons/lock.svg" class="locker" [applyClass]="true"></svg-icon>
      </div>

      <ng-container *ngIf="noData">
        <div class="center-container">
          <p translate="calibration.NO_VALID_USER"></p>
        </div>
      </ng-container>
      <ng-container *ngIf="!noData">
        <!--ninebox-calibration-->
        <div class="ninebox-calibration-wrapper">
          <div class="levels-wrapper">
            <div class="box-level">
              <p translate="evaluations.UNSATISFACTORY"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.IMPROVABLE"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.MEET"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.EXCEEDED"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.EXCEPTIONAL"></p>
            </div>
          </div>
          <div class="boxes-wrapper">
            <div class="columns-wrapper">
              <ng-container *ngFor="let key of keyList; let last = last">
                <!-- Contenuto colonna -->
                <!-- [cdkDropListData]="matrixData[key]" [cdkDropListConnectedTo]="matrixData[key]"-->
                <div class="box-ninebox"
                  [ngClass]="{'box-1': key == 'UNSATISFACTORY' || key == 'IMPROVABLE', 'box-2': key == 'MEET', 'box-3': key == 'EXCEEDED' || key == 'EXCEPTIONAL'}"
                  cdkDropListOrientation="vertical" cdkDropList (cdkDropListDropped)="drop($event, key)"
                  [cdkDropListData]="matrixData[key]" [cdkDropListConnectedTo]="getConnectedList(key)"
                  id="{{ 'column-' + key }}">
                  <!-- Placeholder nessun utente-->
                  <ng-container *ngIf="!matrixData[key] || !matrixData[key].length">
                    <p class="no-data-box" translate="calibration.NO_ONE_PRESENT"></p>
                  </ng-container>
                  <!-- dati utenti -->
                  <ng-container *ngIf="matrixData[key] && matrixData[key].length">
                    <div class="tag-action-container" *ngFor="let user of matrixData[key]" cdkDrag [cdkDragData]="user"
                      (cdkDragStarted)="setSelected(user)">
                      <!-- Tag utente -->
                      <div id="{{ user.userId }}" class="tag-user" [ngClass]="{'new': user.edited}">
                        <avatar-img [user]="user" size="small"></avatar-img>
                        <div class="name-payout">
                          <p class="name" (click)="openUserMenu(user)" (clickOutside)="closeUserMenu(user)">{{
                            user.forename + ' ' + user.surname}}</p>
                          <ng-container *ngIf="user.payout || user.payout == 0">
                            <p class="payout">Payout {{ user.payout }}%</p>
                          </ng-container>
                          <ng-container
                            *ngIf="!user.payout && user.payout != 0 && (user.finalEvaluationPayout || user.finalEvaluationPayout == 0)">
                            <p class="payout">Payout {{ user.finalEvaluationPayout }}%</p>
                          </ng-container>
                        </div>
                        <svg-icon src="assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                          [applyClass]="true" cdkDragHandle>
                        </svg-icon>
                      </div>
                      <!-- Menu tag utente -->
                      <ng-container *ngIf="user.isMenuOpen">
                        <div class="tag-dropdown-menu">
                          <europ-dropdown-item (click)="onMenuOptionsClicked(menuOptions[0], user)" class="full-width"
                            [item]="menuOptions[0]">
                          </europ-dropdown-item>
                          <europ-dropdown-item (click)="onMenuOptionsClicked(menuOptions[1], user)" class="full-width"
                            [item]="menuOptions[1]">
                          </europ-dropdown-item>
                        </div>
                      </ng-container>
                    </div>

                  </ng-container>
                </div>
                <div *ngIf="!last" class="separator"></div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="action-container">
          <!-- Compara nella matrice -->
          <europ-button [label]="'calibration.CONFIRM_SESSION' | translate" type="primary"
            (onButtonClicked)="confirmSession()"></europ-button>
          <!-- Conferma PL -->
          <europ-button [label]="'calibration.CONFIRM_PL' | translate" type="secondary"
            (onButtonClicked)="confirmCalibration()">
          </europ-button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="unusable">
  <div class="page-content">
    <svg-icon class="logo-container" [applyClass]="true" src="assets/img/icons/app-logo.svg">
    </svg-icon>
    <p>
      <span class="small-font-weight" translate="404.WE_ARE_SORRY"></span><br>
      <span translate="genericError.PAGE_NOT_USABLE" class="bold-text"></span>
    </p>
    <div>
      <p translate="genericError.MATRIX_ERROR"></p>
    </div>
    <div class="back-button-container">
      <europ-button type="primary default" size="large" label="{{'generic.GO_BACK' | translate}}"
        (onButtonClicked)="redirectService.goBackBrowser()"></europ-button>
    </div>
  </div>
</div>

<!-- Tooltip per box distribuzione matrice -->
<modal id="box-distribution-tooltip" (onClose)="closeBoxDistributionTooltip()">
  <modal-text-content modalId="cal022" [title]="'calibration.BOX_DISTRIBUTION' | translate"
    [text]="'calibration.tooltip.BOX_DISTRIBUTION_DESCR' | translate">
  </modal-text-content>
</modal>