<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="sendRevisionAction(true)" [isBackButton]="true"
      [title]="'performance.ACCORDION_TITLE' | translate" [description]="'performance.SIDENAV_SUBTITLE' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserGoals && !isLoadingManagerInfo">
        <div *ngFor="let goal of goalList" fxLayout="column" class="goal-list">
          <objective-for-mid-term-review [goal]="goal" [isOperations]="isOperations" [isInnerHtml]="true">
          </objective-for-mid-term-review>
          <ng-container *ngIf="getUserGoalEvaluation(goal)">
            <evaluation [evaluation]="getUserGoalEvaluation(goal)"></evaluation>
          </ng-container>
          <div class="divisor-vertical-line"></div>
        </div>
        <!-- recap dati utente e mid term review -->
        <mid-term-recap [user]="personDetails" [date]="userRevisionDate" [status]="statusForRecap"
          [observation]="osservation"></mid-term-recap>
        <!-- Risposta del manager alla review -->
        <ng-container *ngIf="managerAnswer">
          <div class="divisor-vertical-line"></div>
          <mid-term-recap [user]="managerData" [date]="managerRevisionDate" [observation]="managerOsservation">
          </mid-term-recap>
        </ng-container>
        <div class="performance-level-input">
          <!-- Osservazioni -->
          <input-container [numberCharsTextArea]="charsFinalObservation" [isFromTextArea]="true"
            [isLoading]="isLoadingUserGoals || isLoadingManagerInfo" [tooltipModalMessage]="observationTooltip"
            [isValued]="finalObservation && finalObservation.trim().length" [isObbligatory]="true"
            title="{{'performance.INSERT_OBSERVATION' | translate}}">
            <europ-text-area [bindValue]="finalObservation" [maxlength]="600"
              (onModelChanged)="finalObservationChanged($event)"
              placeholder="{{'performance.OBSERVATION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>

          <!-- Valutazione -->
          <div class="pl-mark-card-container">
            <div class="title-container">
              <p translate="performance.INSERT_VALUE"></p>
              <span *ngIf="!selectedMark">*</span>
              <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer"
                [applyClass]="true" (click)="openMarkCardInfoModal()">
              </svg-icon>
            </div>
            <card-performance class="full-width" (onItemChange)="selectPerformanceLevel($event)" [bindValue]="selectedMark">
            </card-performance>
          </div>

          <!-- Percentuale payout STI -->
          <ng-container *ngIf="isSti">
            <input-container [disabled]="!selectedMark" [isLoading]="isLoadingUserGoals || isLoadingPayoutOptions"
              [tooltipModalMessage]="payoutTooltip" [isValued]="selectedPayout && selectedPayout.id"
              [isObbligatory]="true" title="{{'performance.SET_PAYOUT' | translate}}" [isObbligatory]="true">
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changePayoutPercentage($event)"
                [selectedItem]="selectedPayout" [items]="payoutOptions" [searchable]="false" bindLabel="id">
              </europ-select>
            </input-container>
          </ng-container>


          <div class="action-container">
            <europ-button type="primary" [label]="'performance.VALUE' | translate" [disabled]="!confirmEnabled()"
              (onButtonClicked)="openConfirmModal()">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingUserGoals || isLoadingManagerInfo">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'invio della revisione se non ci sono dati obbligatori -->
<modal id="modalCreateRevisionNoDataForCollaborator" (onCancel)="goBackBrowser()"
  (onClose)="closeModalCreateRevisionNoDataForCollaborator()"
  (onConfirm)="closeModalCreateRevisionNoDataForCollaborator()"
  cancelLabel="{{'goalSetting.createGoal.GO_BACK_YES' | translate}}"
  confirmLabel="{{'generic.NO_REMAIN_IN_PAGE' | translate}}">
  <modal-text-content [modalId]="'1052'"
    [title]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA' | translate) : ('evidences.OBBLIGATORY_DATA_NOT_INSERTED' | translate) "
    [text]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA_TEXT' | translate) : ('midTerm.addMidTermReview.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION' | translate)">
  </modal-text-content>
</modal>

<!-- Modale per l'invio della revisione -->
<modal id="modalCreateRevisionForCollaborator"
  (onCancel)="isFromBack ? goBackBrowser() : closeModalCreateRevisionForCollaborator()"
  (onClose)="closeModalCreateRevisionForCollaborator()" (onConfirm)="sendRevision()"
  [cancelLabel]="'generic.ANNULL' | translate" [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content [modalId]="'d1060'" [title]="'midTerm.addMidTermReview.BACK_WITH_DATA' | translate"
    [text]="'midTerm.addMidTermReview.BACK_WITH_DATA_TEXT' | translate">
  </modal-text-content>
  <ng-container *ngIf="loggedUser && cardSelected">
    <div class="modal-recap-container">
      <mid-term-recap [user]="loggedUser" [observation]="osservation" [status]="cardSelected.id">
      </mid-term-recap>
    </div>
  </ng-container>
</modal>

<modal id="recapConfirm" (onCancel)="closeConfirmModal()" (onClose)="closeConfirmModal()"
  (onConfirm)="closeConfirmModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <ng-container *ngIf="!isSti && selectedMark">
    <p class="modal-title">
      {{ ('performance.modals.CONFIRM_OBSERVATION' | translate)}}</p>
    <div class="confirmation-container">
      <svg-icon src="/assets/img/section/performance-level.svg" [applyClass]="true"></svg-icon>
      <div class="value-container">
        <evaluation [scale]="5" [evaluation]="selectedMark"></evaluation>
      </div>
      <ng-container *ngIf="finalObservation">
        <div class="observation-container">
          <p class="subtitle" translate="performance.modals.YOUR_OBSERVATION"></p>
          <p class="text">{{finalObservation}}</p>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <!-- modale per utenti sti con payout-->
  <ng-container *ngIf="isSti && selectedPayout && selectedPayout.id && selectedMark">
    <p class="modal-title">
      {{ ('performance.modals.CONFIRM_OBSERVATION' | translate)}}</p>
    <div class="confirmation-container">
      <svg-icon src="/assets/img/section/performance-level.svg" [applyClass]="true"></svg-icon>
      <div class="value-container">
        <evaluation [scale]="5" [evaluation]="selectedMark"></evaluation>
        <div class="payout-label">
          <p>{{selectedPayout.title + ' ' + ('performance.modals.OF_PAYOUT' | translate)}}</p>
        </div>
      </div>
      <ng-container *ngIf="finalObservation">
        <div class="observation-container">
          <p class="subtitle" translate="performance.modals.YOUR_OBSERVATION"></p>
          <p class="text">{{finalObservation}}</p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</modal>

<modal id="modal-info" (onClose)="closeModalInfo()" (onConfirm)="closeModalInfo()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <p class="modal-title center" translate="evaluations.modals.INFO_TITLE"></p>
  <div class="divisor-line"></div>
  <div class="performance-level-mark-info-container">
    <div class="title-row">
      <evaluation evaluation="UNSATISFACTORY" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.UNSATISFACTORY"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="IMPROVABLE" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.IMPROVABLE"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="MEET" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.MEET"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEEDED" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEEDED"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEPTIONAL" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEPTIONAL"></p>
  </div>
</modal>