<div *ngIf="!isLoading" class="input-wrapper"
  [ngClass]="{'disabled': disabled, 'error': errorText && errorText.length, 'readonly': readonly, 'readonlyPlatform': (readonlyPlatform || readonlyPlatformInput || readonlyPlatformSelect), 'valued': isValued}">
  <div class="title-label">
    <p class="text-label-form">
      <span>{{ title }}</span>
      <span *ngIf="isObbligatory && !isValued">*</span>
    </p>
    <svg-icon *ngIf="!isWithoutInfoIcon" (click)="openModalMessage()" src="/assets/img/icons/info.svg"
      class="info-icon medium-size cursor-pointer" [applyClass]="true">
      <!-- <div>
        <ng-template #tooltipTemplate>
          <europ-pop-up></europ-pop-up>
        </ng-template>
      </div> -->
    </svg-icon>
  </div>
  <div class="input-text-wrapper">
    <ng-content></ng-content>
    <div class="instruction">
      <ng-container *ngIf="(instructions || isFromTextArea) && (!errorText || !errorText.length)">
        <p *ngIf="instructions">{{ instructions }}</p>
        <p *ngIf="isFromTextArea && !disabled"
          [innerHTML]="'Numero massimo caratteri consentiti ' + '<b>' + (numberCharsTextArea || 0) + '.</b>'">
        </p>
      </ng-container>
      <ng-container *ngIf="errorText">
        <p>{{ errorText }}</p>
      </ng-container>
      <ng-container *ngIf="readonlyPlatformInput">
        <a (click)="inputToEdit()">{{'goalSetting.updatePMGoal.EDIT_TITLE' | translate}}</a>
      </ng-container>
      <ng-container *ngIf="readonlyPlatformSelect">
        <a (click)="selectToEdit()">{{'goalSetting.updatePMGoal.EDIT_WEIGHT' | translate}}</a>
      </ng-container>
    </div>
    <div *ngIf="fromEuropUpload" class="upload-file-button-container">
      <europ-button type="primary" label="{{'generic.CHARGE' | translate}}" size="medium"></europ-button>
    </div>
  </div>
</div>

<div class="loader-input-container" *ngIf="isLoading">
  <div class="first-gray-container"></div>
  <div class="second-gray-container"></div>
  <div class="third-gray-container">
    <div class="first"></div>
    <div class="second"></div>
  </div>
</div>