import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'europ-dropdown-item',
  templateUrl: 'europ-dropdown-item.component.html',
  styleUrls: ['./europ-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropDropdownItemComponent implements OnInit {
  @Input() item?: any = {
    id: '',
    label: '',
    icon: '',
    isChecked: false
  };
  @Input() notSeeDescription: boolean = false;
  @Input() isCheckbox: boolean = false;
  @Input() disabled: boolean = false;
  @Input() filterModal: boolean = false;
  @Input() isMenuItem?: boolean;
  @Input() innerHtmlDescription?: boolean;
  @Output() onItemCheck: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  emitOnItemCheck() {
    this.item.isChecked = !this.item.isChecked;
    this.onItemCheck.emit(this.item);
    this.cdr.detectChanges();
  }

}