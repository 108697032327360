<div *ngIf="runningYear" class="wrapper-layout-manager">
  <!-- Sidebar con il menù principale -->
  <!-- [ngClass]="{'open-on-mobile': isTeamButtonClicked}" -->
  <ng-container *ngIf="!isLoadingTeam && !isLoadingCurrentPhase">
    <team-sidebar class="team-sidebar" *ngIf="runningPhase && showSidebar()" [users]="subordinatesWithPhaseStatuses"
      [activeUserId]="activeUserId" [currentPhaseId]="runningPhase.phaseId" (userClicked)="onUserClicked($event)">
    </team-sidebar>
  </ng-container>

  <div class="router-outlet-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>