import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as fromManager from "../../manager/ngrx/manager.reducers";
import * as ManagerActions from "../../manager/ngrx/manager.actions";
import * as CoreActions from "../../core/ngrx/core.actions";
import { GetPerformanceAssessmentBannerPhaseResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, JwtPayload, PerformanceAssessmentPhase, SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ManagerService } from '../../shared/services/manager.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EngagementDetailValues, PerfCareAssessmentPhaseKeys } from 'atfcore-commonclasses';

@Component({
  selector: 'app-manager-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class ManagerHomeComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "";
  bannerImage: string = '';
  updateUserAck$: any;
  userAcknowledges: any;
  runningPhase: any;
  isLoadingCurrentPhase: boolean = true;
  currentYear = new Date().getFullYear();

  notesDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  }

  applauseDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  }

  dashboardDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  }
  peerFeedbackAllowed$: Subscription = new Subscription;
  peerFeedbackAllowed: boolean = false;
  isCollaborator: boolean = false;
  // box mid term review
  isLoadingMidTermData: boolean = false;
  getMidTermReviewBoxData$: Subscription = new Subscription;
  midTermData: any;


  constructor(
    private store: Store<fromApp.AppState>,
    private managerStore: Store<fromManager.ManagerState>,
    public translate: TranslateService,
    public router: Router,
    public modalService: ModalService,
    public managerService: ManagerService,
    private authService: AuthService,
    public redirectService: RedirectService
  ) {
    // se avevo un utente attivo lo rimuovo
    this.store.dispatch(ManagerActions.RemoveActiveUserId());
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;

      if (this.runningYear) {
        // Recupero dai servizi la lista di sottoposti
        this.managerStore.dispatch(ManagerActions.RetrieveSuboardinatesWithPhaseStatuses());
      }
    });

    this.peerFeedbackAllowed$ = this.store.select(fromApp.getPeerFeedbackAllowed)
      .subscribe((peerFeedbackAllowed) => {
        this.peerFeedbackAllowed = peerFeedbackAllowed;
      })

    // Recupero la lista di subordinati con lo stato delle fasi
    this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).
      subscribe((subordinatesWithPhaseStatuses) => {
        this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
      });

    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.store.select(fromApp.getUserAcknowledges);
    const isCollaborator$: Observable<boolean> = this.store.select(fromApp.getIsCollaborator);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, userAck$, getRunningPhase$, isCollaborator$])
      .subscribe(
        ([loggedUser, userAck, runningPhase, isCollaborator]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.runningPhase = runningPhase;
            this.userAcknowledges = userAck;
            this.phaseName = this.runningPhase.phaseKey; // 'CONTINUOS_FEEDBACK'  
            this.bannerImage = this.getBannerImage(this.phaseName);
          }
          this.isCollaborator = isCollaborator;
          this.getMidTermReviewBoxData();
        });
  }

  ngOnInit() {
    this.notesDisabledModal = {
      modalId: "145",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.applauseDisabledModal = {
      modalId: "146",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.dashboardDisabledModal = {
      modalId: "147",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
  }

  goToSendClapPage() {
    this.redirectService.goToSendClapPageManager();
  }

  goToTakeNote() {
    this.redirectService.goToCreatePrivateNoteForManager();
  }

  // TODO solo per test
  resetOnboardingAck() {
    if (this.updateUserAck$) {
      this.updateUserAck$.unsubscribe();
    }
    let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
    acks.data['PERFORMANCE_CARE_ONBOARDING'] = null
    this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
      subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "096",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          window.location.reload();
        }
      })
  }

  goToMidTermList() {
    this.router.navigate(['/manager/midTermList'])
  }

  getBannerImage(phaseName: string) {
    if (phaseName == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW) {
      return 'assets/img/banner/bg-banner-mid-term.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
      return 'assets/img/banner/bg-banner-final-evaluation.png';
    } else if (phaseName == 'CONTINUOS_FEEDBACK') {
      return 'assets/img/banner/bg-banner-feedback.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.POST_CALIBRATION) {
      return 'assets/img/banner/bg-banner-final-evaluation.png';
    } else {
      return 'assets/img/banner/bg-banner-gs.png';
    }
  }

  goToFeedbackRequests() {
    this.router.navigate(['/manager/feedbackRequest/' + this.runningYear]);
  }

  getMidTermReviewBoxData() {
    this.isLoadingMidTermData = true;
    if (this.getMidTermReviewBoxData$) {
      this.getMidTermReviewBoxData$.unsubscribe();
    }
    this.getMidTermReviewBoxData$ = this.managerService.getMidTermReviewBoxData(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.midTermData = data.response;
          let startDate = moment(this.midTermData.phaseStartDateForManager).format('DD/MM/YYYY');
          let endDate = moment(this.midTermData.phaseEndDateForManager).format('DD/MM/YYYY');
          //if (startDate && endDate) {
          //  this.midTermData.dateDescription = this.translate.instant('midTerm.home.AVAILABLE_FROM') + ' <b>' + startDate + '</b>';
          //  let today = moment().format();
          //  if (moment(today).isBefore(startDate)) {
          //    this.midTermData.isPhaseActive = false;
          //  } else {
          //    this.midTermData.isPhaseActive = true;
          //  }
          //}
          if (this.midTermData.toCompleteMidTermReviewCount > 0) {
            this.midTermData.status = 'to_complete';
          } else {
            this.midTermData.status = 'completed';
          }
          this.midTermData.home = true;

        }
        this.isLoadingMidTermData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMidTermData = false;
      });
  }

  ngOnDestroy() {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.peerFeedbackAllowed$) {
      this.peerFeedbackAllowed$.unsubscribe();
    }
  }
}