import { Renderer2, HostListener, Directive, ElementRef, ViewContainerRef, ContentChild, Input } from
  '@angular/core';

@Directive({ selector: '[tooltipDirective]' })
export class TooltipDirective {

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef) { }

  @ContentChild("tooltipTemplate") private tooltipTemplateRef: any;
  @Input() placement: string = 'bottom';
  @Input() openType: string = 'onFocus';
  offset: number = 10;
  tooltip: any;
  view: any;
  documentHeight: number = 0;
  hostPos: any = 0;
  tooltipPos: any = 0;
  scrollPos: any = 0;

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.openType == 'onFocus') {
      this.createTooltip();
    }
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    let countPopups = document.querySelectorAll('europ-pop-up') && document.querySelectorAll('europ-pop-up').length;
    if (this.openType == 'onClick') {
      if (countPopups == 1 && this.viewContainerRef && this.checkIfOpenPopUp(event)) {
        return;
      } else {
        if (this.checkIfOpenPopUp(event) && countPopups == 0) {
          this.createTooltip();
        } else {
          this.viewContainerRef.clear();
        }
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.openType == 'onFocus') {
      if (this.viewContainerRef) {
        this.viewContainerRef.clear();
      }
    }
  }

  // Crea il tooltip
  createTooltip() {
    this.documentHeight = document.documentElement.scrollHeight;
    this.view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
    this.view.rootNodes.forEach((node: any) =>
      this.renderer.appendChild(this.elementRef.nativeElement, node));

    this.renderer.setStyle(this.view.rootNodes[0], 'opacity', '0');
    this.setPosition();
  }

  checkIfOpenPopUp(event: any) {
    let target = (event.target as HTMLElement);
    let targetParent = (event.target.parentElement as HTMLElement);
    if (target.classList.contains('close-icon-pop-up') || (targetParent && targetParent.tagName == 'svg' && targetParent.classList.contains('close-icon-pop-up'))) {
      return false;
    }
    return true;
  }

  // Setta la posizione del tooltip
  setPosition() {
    this.hostPos = this.elementRef.nativeElement.getBoundingClientRect();
    this.tooltipPos = this.view.rootNodes[0].getBoundingClientRect();
    this.scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left

    if (this.placement === 'bottom') {
      top = this.hostPos.bottom + this.offset;
      left = this.hostPos.left + (this.hostPos.width - this.tooltipPos.width) / 2;
    }

    if ((this.documentHeight != document.documentElement.scrollHeight) || (this.tooltipPos.bottom > document.documentElement.offsetHeight)) {
      this.setAutoPosition('top');
    } else if (left < 0) {
      this.setAutoPosition('right');
    } else if (this.tooltipPos.right > document.documentElement.offsetWidth) {
      this.setAutoPosition('left');
    }
    this.renderer.setStyle(this.view.rootNodes[0], 'opacity', '1');
  }

  setAutoPosition(placement?: string) {
    let top, left;
    if (placement == 'top') {
      top = this.hostPos.top - this.tooltipPos.height - this.offset;
      left = this.hostPos.left + (this.hostPos.width - this.tooltipPos.width) / 2;
      if (left < 0) {
        left = 10;
      }
      else if (this.tooltipPos.right > document.documentElement.offsetWidth) {
        left = document.documentElement.offsetWidth - this.tooltipPos.width - this.offset;
      }
      else {
        let popupClassBottomFlag = document.querySelector(".arrow-bottom-container");
        if (popupClassBottomFlag) {
          popupClassBottomFlag.className = popupClassBottomFlag.className += ' visible';
        }
      }
    } else if (placement == 'right') {
      top = this.hostPos.top + (this.hostPos.height - this.tooltipPos.height) / 2;
      if (top < 0) {
        top = 10;
      }
      left = this.hostPos.right + this.offset;
    } else if (placement == 'left') {
      top = this.hostPos.top + (this.hostPos.height - this.tooltipPos.height) / 2;
      if (top < 0) {
        top = 10;
      }
      left = this.hostPos.left - this.tooltipPos.width - this.offset;
    }

    let popupClassTopFlag = document.querySelector(".arrow-top-container");
    if (popupClassTopFlag) {
      popupClassTopFlag.className = 'arrow-top-container';
    }
    this.renderer.setStyle(this.view.rootNodes[0], 'top', `${top + this.scrollPos}px`);
    this.renderer.setStyle(this.view.rootNodes[0], 'left', `${left}px`);
  }
}
