// utilities per l'upload di file

// Recupera la stringa riguardante l'estensione del file (europ-upload)
export const GetFileExtensionString = (fileType: string) => {
  if (fileType == '2') {
    return "image"
  } else if (fileType == '0') {
    return "pdf"
  }
  switch (fileType) {
    case "application/pdf":
      return "pdf";
    case "image/png":
      return "png";
    case "image/jpeg" || "image/pjpeg":
      return "jpeg";
    case "message/rfc822":
      return "eml";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation" || "application/mspowerpoint" || "application/powerpoint" || "application/vnd.ms-powerpoint" || "application/x-mspowerpoint":
      return "ppt";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || "application/excel" || "application/vnd.ms-excel" || "application/x-excel" || "application/x-msexcel":
      return "xls";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/msword":
      return "doc";
    case "text/plain":
      return "txt";
    case "text/rtf":
      return "rtf";
    case "image/tiff":
      return 'tiff';
  }
  return "";
};

export const ACCEPTED_MIME_TYPES =
  ".eml, .doc, .docx, .pdf, .png, .ppt, .pptx, .xls, .xlsx, .jpg, .jpeg, .msg, .txt, .rtf"

export enum FileType {
  PDF,
  DOC,
  IMAGE
}
