import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { PerfCareUserPhaseGoal, SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, PerfCareGoalRentStatuses, PerfCareMacroGoalTypes, UpdateUserGoalForManagerResponse, GetGoalByIdForManagerResponse, PerfCareGoalDivisions, GetUserGoalWeightSumForManagerResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { Subordinateservice } from '../services/subordinates.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { GoalUtils } from 'src/app/utils/goal.utils';

@Component({
  selector: 'app-manager-createUpdateGoalPlatformManager',
  templateUrl: './createUpdateGoalPlatformManager.component.html',
  styleUrls: ['./createUpdateGoalPlatformManager.component.scss']
})
export class CreateUpdateGoalPlatformManagerComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  goal: PerfCareUserPhaseGoal = {
    goalId: '',
    creationUserId: '',
    phaseId: '',
    userId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL
  };

  isFetchingUpdateGoal: boolean = false;
  updateGoal$: Subscription | null = null;
  isFetchingGetGoal: boolean = false;
  getGoal$: Subscription | null = null;
  goalId: string = '';
  goalCopy: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL
  };
  modalUpdateGoalTitle: string = '';
  modalUpdateGoalText: string = '';
  modalUpdateGoalTitleConfirmTextButton: string = '';
  modalUpdateGoalTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  weightOptions: any[] = [];
  weightOptionSelected: any = null
  isFetchingWeightSums: boolean = false;
  weightSum: number = 0;
  getWeightSum$: Subscription = new Subscription();
  differenceWeightSum: number = 0;
  today = new Date();
  isSharedGoal: boolean = false;
  inputTitleEditable: boolean = false;
  selectWeightEditable: boolean = false;

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  weightTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  deadlineTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  targetTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  resultTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  valutationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalUpdateGoalId: string = '';
  valutationOptions: any[] = [];
  sidenavTitle: string = '';
  ratingSelected: any;
  isLoadingTotalWeightSection: boolean = false;
  getTotalWeightSection$: Subscription = new Subscription();
  totalWeightSection: number = 0;
  ratingObservationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isImpersonate: boolean = false;

  constructor(
    private subordinatesService: Subordinateservice,
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;

              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];

                if (this.chargeSelectedUser && this.personDetails) {
                  this.getGoalById();
                }

                // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                if (!this.personDetails) {
                  this.chargeSelectedUser = false;
                  this.getPersonDetails();
                }
              });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'goalSetting.updatePMGoal.ACHIEVED',
        'goalSetting.updatePMGoal.NOT_ACHIEVED',
        'goalSetting.updatePMGoal.EXCEEDED'
      ])
      .subscribe(translations => {
        this.titleTooltipModal = {
          modalId: "401",
          title: "Informazioni input",
          subtitle: "Titolo goal",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.weightTooltipModal = {
          modalId: "402",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.deadlineTooltipModal = {
          modalId: "403",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.targetTooltipModal = {
          modalId: "40",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.valutationTooltipModal = {
          modalId: "405",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        }
        this.resultTooltipModal = {
          modalId: "406",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.ratingObservationTooltipModal = {
          modalId: "407",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };

        this.modalUpdateGoalId = this.getModalSaveId();
        this.formatModalUpdateGoalData();

        this.valutationOptions = [
          {
            id: 'ACHIEVED',
            title: translations['goalSetting.updatePMGoal.ACHIEVED']
          },
          {
            id: 'NOT_ACHIEVED',
            title: translations['goalSetting.updatePMGoal.NOT_ACHIEVED']
          },
          {
            id: 'EXCEEDED',
            title: translations['goalSetting.updatePMGoal.EXCEEDED']
          },
        ]
      })
  }

  // Se oggi è il giorno di deadline o dopo
  isTodayAfterDeadlineToday() {
    if (this.goal && this.goal.deadlineDate) {
      if (moment(this.today).isSameOrAfter(this.goal.deadlineDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Formatta i testi della modale per l'update dell'obiettivo
  formatModalUpdateGoalData(isBack?: boolean) {
    this.modalUpdateGoalTitle = this.getModalSaveTitle(isBack);
    this.modalUpdateGoalText = this.getModalSaveDescription(isBack);
    this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal(isBack);
    this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal(isBack);
  }

  findWithAttr(array: any, attr: string, value: any) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  // Peso totale che deve avere la sezione
  getTotalWeightSection() {
    this.isLoadingTotalWeightSection = true;
    if (this.getTotalWeightSection$) {
      this.getTotalWeightSection$.unsubscribe();
    }
    this.getTotalWeightSection$ = this.managerService.getObjectiveWeightsByProfile(this.userId, this.runningYear)
      .subscribe((tabList: SenecaResponse<any>) => {
        if (tabList && (tabList.error || !tabList.response)) {
          const messageObj: ApplicationModalMessage = {
            modalId: "112",
            text: this.translate.instant("errors." + ((tabList && tabList.error) || tabList)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let isHalfStep = false;
          for (let i = 0; i < tabList.response.length; i++) {
            for (let q = 0; q < tabList.response[i].goalTypes.length; q++) {
              let goalType = tabList.response[i].goalTypes[q];
              if (goalType && goalType.allowedMultipleForUser === 2.5) {
                isHalfStep = true;
              }
              if (goalType.goalType == this.goal.macroGoalType) {
                this.totalWeightSection = goalType.weight;
                break;
              }
            }
          }
          this.weightOptions = this.getWeightOptions(isHalfStep);
          this.getUserGoalWeightSumForManager();
        }
        this.isLoadingTotalWeightSection = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTotalWeightSection = false;
      });
  }

  // Recupera la somma pesi obiettivi
  getUserGoalWeightSumForManager() {
    this.isFetchingWeightSums = true;
    this.getWeightSum$ = this.managerService.getUserGoalWeightSumForManager(this.runningYear.toString(), this.personDetails.userId, this.goal.macroGoalType, (this.goal.consuntivation && this.goal.consuntivation.order))
      .subscribe((data: SenecaResponse<GetUserGoalWeightSumForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "073",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.weightSum = data.response || 0;
          this.differenceWeightSum = this.totalWeightSection - this.weightSum;
          let indexToHandleWeights = this.findWithAttr(this.weightOptions, 'id', (this.totalWeightSection - this.weightSum + (this.goal.weight || 0)));
          for (let i = indexToHandleWeights + 1; i < this.weightOptions.length; i++) {
            this.weightOptions[i].disabled = true;
          }
          this.weightOptions[0].disabled = false;
          this.weightOptions = this.weightOptions;
        }
        this.isFetchingWeightSums = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "074",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingWeightSums = false;
        })
  }

  // Se hai selezionato 0 nel peso
  isWeightEscluded() {
    if ((this.goal && this.goal.weight) == 0) {
      return true;
    } else {
      return false;
    }
  }

  getModalSaveId() {
    return '400';
  }

  // Se il risultato del goal è obbligatorio
  isObbligatoryResultGoal() {
    if (!this.isWeightEscluded() && this.isTodayAfterDeadlineToday() && this.goal.rating && this.goal.rating.length && (!this.goal.result || !this.goal.result)) {
      return true;
    } else {
      return false;
    }
  }

  // Testo per il modale Salva obiettivo
  getModalSaveTitle(isBack?: boolean) {
    if ((!this.goal.title || !this.goal.title.trim().length) || this.isObbligatoryResultGoal()) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.UPDATE_GOAL');
      }
    }
  }

  // Descrizione per il modale Aggiorna obiettivo
  getModalSaveDescription(isBack?: boolean) {
    if ((!this.goal.title || !this.goal.title.trim().length) || this.isObbligatoryResultGoal()) {
      return this.translate.instant('goalSetting.updatePMGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
    } else {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL');
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelUpdateGoalModal(isBack?: boolean) {
    if ((!this.goal.title || !this.goal.title.trim().length) || this.isObbligatoryResultGoal()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK');
    } else {
      return this.translate.instant('goalSetting.createGoal.GO_BACK');
    }
  }

  // Testo modale pulsante aggiorna
  getConfirmLabelUpdateGoalModal(isBack?: boolean) {
    if ((!this.goal.title || !this.goal.title.trim().length) || this.isObbligatoryResultGoal()) {
      return this.translate.instant('goalSetting.createGoal.YES_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        return this.translate.instant('generic.SAVE_AND_GO_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.YES_UPDATE_THE_GOAL');
      }
    }
  }

  // Recupara l'utente dal suo userId
  getPersonDetails() {
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.runningYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "075",
            text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.personDetails = personDetailsData.response;
          this.getGoalById();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "076",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Quando si scrive sull'input del titolo obiettivo
  goalTitleChanged(text: string) {
    this.goal.title = text;
  }

  // Quando si scrive sull'input del target obiettivo
  goalTargetChanged(text: string) {
    this.goal.target = text;
  }

  // Quando si scrive sull'input del risultato stato obiettivo
  goalResultChanged(text: string) {
    this.goal.result = text;
  }

  // Cambio nella select valutazione
  changeValutationTag(data: any) {
    this.ratingSelected = data;
    this.goal.rating = data.id;
  }

  // Quando si scrive sull'input del peso obiettivo
  goalWeightChanged(weightOption: any) {
    let weightSelected = this.weightOptions.filter((x: any) => {
      return x.id == weightOption.id;
    })
    this.weightOptionSelected = weightSelected && weightSelected[0];
    this.goal.weight = this.weightOptionSelected.id;
    if (this.goalId && this.goalId.length) {
      this.differenceWeightSum = this.totalWeightSection - (this.weightSum - <number>(this.goalCopy.weight || 0) || 0) - (this.goal.weight || 0);
    } else {
      this.differenceWeightSum = this.totalWeightSection - (this.weightSum || 0) - (this.goal.weight || 0);
    }
    if (this.goal.weight == 0) {
      this.goal.target = '';
      this.ratingSelected = null;
      this.goal.rating = '';
      this.goal.result = '';
    }
  }

  // Rendi l'input del titolo obiettivo editabile
  inputTitleToEditable() {
    this.inputTitleEditable = true;
  }

  // Rendi la select del peso obiettivo editabile
  selectWeightToEditable() {
    this.selectWeightEditable = true;
  }

  goalRatingObservationChanged(text: string) {
    this.goal.ratingObservation = text;
  }

  // Recupera l'obiettivo dal goalId
  getGoalById() {
    this.isFetchingGetGoal = true;

    this.getGoal$ = this.managerService.getGoalByIdForManager(this.runningYear.toString(), this.goalId, this.personDetails.userId)
      .subscribe((data: SenecaResponse<GetGoalByIdForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "081",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goal = data.response;
          if (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
            this.isSharedGoal = true;
          }
          if (this.goal.rating) {
            let optionRating = this.valutationOptions.filter((x: any) => {
              return x.id == this.goal.rating;
            })
            if (optionRating && optionRating.length)
              this.ratingSelected = optionRating[0];
          }
          let weightSelected = this.weightOptions.filter((x: any) => {
            return x.id == this.goal.weight;
          })
          this.weightOptionSelected = weightSelected && weightSelected[0];
          this.goalCopy = JSON.parse(JSON.stringify(data.response));
          this.sidenavTitle = (<number>(this.goal && this.goal.consuntivation && this.goal.consuntivation.order) + 1) + '° ' + GoalUtils.calculateMesterAndMonths(this.translate, this.goal) + ' <br>' + GoalUtils.calculateMesterAndMonths(this.translate, this.goal, true);
          this.getTotalWeightSection();
        }
        this.isFetchingGetGoal = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "082",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetGoal = false;
        })
  }

  // Gestisce quando aggiorni un'obiettivo
  goToUpdateGoal(isBack?: boolean) {
    this.formatModalUpdateGoalData(isBack);
    this.openModalForUpdateGoal();
  }

  // Aggiorna obiettivo
  updateGoal(goNext?: boolean) {
    if ((!this.goal.title || !this.goal.title.trim().length) || this.isObbligatoryResultGoal()) {
      this.closeModalGoalUpdate();
    } else {
      this.isFetchingUpdateGoal = true;
      this.closeModalGoalUpdate();
      this.goal.userId = this.personDetails.userId;

      this.updateGoal$ = this.managerService.updateGoal(this.runningYear, this.goal)
        .subscribe((data: SenecaResponse<UpdateUserGoalForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "085",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "086",
              title: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED"),
              text: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            if (goNext) {
              this.getGoalById();
            } else {
              this.goBackBrowser()
            }
          }
          this.isFetchingUpdateGoal = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "087",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingUpdateGoal = false;
          })
    }
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdateGoal() {
    this.modalService.open('modalUpdateGoalForManager');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per il salvataggio dell'obiettivo
  closeModalGoalUpdate() {
    this.modalService.close('modalUpdateGoalForManager');
  }


  getWeightOptions(halfStep?: boolean) {
    if (halfStep) {
      return [
        {
          id: 0,
          title: '0',
          disabled: false
        },
        {
          id: 10,
          title: '10',
          disabled: false
        },
        {
          id: 12.5,
          title: '12.5',
          disabled: false
        },
        {
          id: 15,
          title: '15',
          disabled: false
        },
        {
          id: 17.5,
          title: '17.5',
          disabled: false
        },
        {
          id: 20,
          title: '20',
          disabled: false
        },
        {
          id: 22.5,
          title: '22.5',
          disabled: false
        },
        {
          id: 25,
          title: '25',
          disabled: false
        },
        {
          id: 27.5,
          title: '27.5',
          disabled: false
        },
        {
          id: 30,
          title: '30',
          disabled: false
        },
        {
          id: 32.5,
          title: '32.5',
          disabled: false
        },
        {
          id: 35,
          title: '35',
          disabled: false
        },
        {
          id: 37.5,
          title: '37.5',
          disabled: false
        },
        {
          id: 40,
          title: '40',
          disabled: false
        },
        {
          id: 42.5,
          title: '42.5',
          disabled: false
        },
        {
          id: 45,
          title: '45',
          disabled: false
        },
        {
          id: 47.5,
          title: '47.5',
          disabled: false
        },
        {
          id: 50,
          title: '50',
          disabled: false
        },
        {
          id: 52.5,
          title: '52.5',
          disabled: false
        },
        {
          id: 55,
          title: '55',
          disabled: false
        },
        {
          id: 57.5,
          title: '57.5',
          disabled: false
        },
        {
          id: 60,
          title: '60',
          disabled: false
        },
        {
          id: 62.5,
          title: '62.5',
          disabled: false
        },
        {
          id: 65,
          title: '65',
          disabled: false
        },
        {
          id: 67.5,
          title: '67.5',
          disabled: false
        },
        {
          id: 70,
          title: '70',
          disabled: false
        }
      ]
    } else {
      return [
        {
          id: 0,
          title: '0',
          disabled: false
        },
        {
          id: 10,
          title: '10',
          disabled: false
        },
        {
          id: 15,
          title: '15',
          disabled: false
        },
        {
          id: 20,
          title: '20',
          disabled: false
        },
        {
          id: 25,
          title: '25',
          disabled: false
        },
        {
          id: 30,
          title: '30',
          disabled: false
        },
        {
          id: 35,
          title: '35',
          disabled: false
        },
        {
          id: 40,
          title: '40',
          disabled: false
        },
        {
          id: 45,
          title: '45',
          disabled: false
        },
        {
          id: 50,
          title: '50',
          disabled: false
        },
        {
          id: 55,
          title: '55',
          disabled: false
        },
        {
          id: 60,
          title: '60',
          disabled: false
        },
        {
          id: 65,
          title: '65',
          disabled: false
        },
        {
          id: 70,
          title: '70',
          disabled: false
        }
      ]
    }
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.updateGoal$) {
      this.updateGoal$.unsubscribe();
    }
    if (this.getGoal$) {
      this.getGoal$.unsubscribe();
    }
  }
}