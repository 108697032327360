import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SenecaResponse, SetMyCompetencesResponse, CloseMyCompetencesQuestionsResponse } from 'src/commonclasses';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";

@Component({
  selector: 'app-qualify-competences',
  templateUrl: './qualifyCompetences.component.html',
  styleUrls: ['./qualifyCompetences.component.scss']
})
export class QualifyCompetencesComponent implements OnInit, OnDestroy {
  runningYear$: Subscription;
  runningYear: number = 0;
  applicationLang: string = '';
  applicationLang$: Subscription;
  loggedUser: any;
  userId: any;
  totalSteps: number = 10;
  currentStep: number = 1;
  hasPrev: boolean = false;
  hasNext: boolean = true;
  isLoadingCareTags: boolean = false; competenceList: any;
  getBehaviourCareTag$: Subscription = new Subscription;
  currentPercentage: number = 10;
  isLoadingNext: boolean = false;
  qualifyCompetencesTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  closeMyCompetence$: Subscription = new Subscription;
  isImpersonate: boolean = false;
  lockCloseButton: boolean = false;

  constructor(
    public translate: TranslateService,
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private collaboratorService: CollaboratorService,
  ) {
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.store.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
      }
    });

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });

    this.store.select(fromApp.getLoggedUser)
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.userId = this.loggedUser.userId;
          }
        });
  }

  ngOnInit() {
    this.translate.get(
      [
        "modals.QUALIFY_COMPETENCES.TITLE",
        "modals.QUALIFY_COMPETENCES.SUBTITLE",
        "modals.QUALIFY_COMPETENCES.TEXT"
      ])
      .subscribe(translations => {
        this.getCareTagAndEvaluation();
        this.qualifyCompetencesTooltipModal = {
          modalId: "op032",
          title: translations["modals.QUALIFY_COMPETENCES.TITLE"],
          subtitle: translations["modals.QUALIFY_COMPETENCES.SUBTITLE"],
          text: translations["modals.QUALIFY_COMPETENCES.TEXT"]
        };
      }
      )
  }

  getCareTagAndEvaluation() {
    this.isLoadingCareTags = true;
    let promiseArray = [
      this.collaboratorService.getBehaviourOptions('', '', true).toPromise(),
      this.collaboratorService.getCompetenceForQualifications(this.runningYear.toString()).toPromise(),
    ]

    Promise.all(promiseArray).then((results: any) => {
      if (results[0] && results[0].response) {
        this.competenceList = results[0].response.rows || [];
        this.totalSteps = results[0].response.totalRowsCount || 0;
        this.currentStep = 0;
      }

      if (results[1] && results[1].response) {
        for (let i = 0; i < results[1].response.length; i++) {
          for (let j = 0; j < this.competenceList.length; j++) {
            if (this.competenceList[j].tagId === results[1].response[i].behaviorTagId) {
              this.competenceList[j].evaluation = results[1].response[i].careAttributeAreaType;
              this.competenceList[j].alreadySaved = true;
              this.currentStep++;
            }
          }
        }
        if (this.currentStep > 1) {
          this.hasPrev = true;
        }
        if (this.currentStep + 1 < this.totalSteps) {
          this.hasNext = true;
        } else {
          this.hasNext = false;
        }
        this.currentPercentage = Math.floor((this.currentStep + 1) * 100 / this.totalSteps);
      }
      this.isLoadingCareTags = false;
    })
      .catch((err: any) => {
        this.redirectService.goToErrorPage(err && err.message || null);
      })
  }

  closeCompetenceEvaluation() {
    if (!this.competenceList[this.currentStep].alreadySaved) {
      this.lockCloseButton = true;
      this.saveCompetenceEvaluation(true)
    } else {
      this.isLoadingCareTags = true;
      if (this.closeMyCompetence$) {
        this.closeMyCompetence$.unsubscribe();
      }
      this.collaboratorService.closeMyCompetenceQuestions(this.runningYear.toString())
        .subscribe((data: SenecaResponse<CloseMyCompetencesQuestionsResponse>) => {
          if (data.error || !data.response) {
            const messageObj: ApplicationModalMessage = {
              modalId: "op005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.redirectService.goBackBrowser();
          }
          this.isLoadingCareTags = false;
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "op006",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCareTags = false;
        });
    }
  }

  setCompetenceEvaluation(evaluation: any) {
    this.competenceList[this.currentStep].evaluation = evaluation;
  }


  saveCompetenceEvaluation(isClose?: boolean) {
    this.collaboratorService.setMyCompetence(this.runningYear.toString(), [{
      tagId: this.competenceList[this.currentStep].tagId,
      areaType: this.competenceList[this.currentStep].evaluation
    }])
      .subscribe((data: SenecaResponse<SetMyCompetencesResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "op007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingNext = false;
        } else {
          this.competenceList[this.currentStep].alreadySaved = true;
          if (isClose) {
            this.closeCompetenceEvaluation();
          } else {
            if (this.currentStep < this.totalSteps) {
              this.currentStep += 1;
              this.hasPrev = true;
            }
            if (this.currentStep + 1 < this.totalSteps) {
              this.hasNext = true;
            } else {
              this.hasNext = false;
            }
            this.currentPercentage = Math.floor((this.currentStep + 1) * 100 / this.totalSteps);
            this.isLoadingNext = false;
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "op008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingNext = false;
      })
  }

  nextCompetence() {
    this.isLoadingNext = true;
    this.saveCompetenceEvaluation();
  }

  prevCompetence() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
      this.hasNext = true;
    }
    if (this.currentStep > 0) {
      this.hasPrev = true;
    } else {
      this.hasPrev = false;
    }
    this.currentPercentage = Math.floor((this.currentStep + 1) * 100 / this.totalSteps);
  }


  isComplete() {
    if (this.competenceList && this.competenceList.length) {
      let evaluations = this.competenceList.filter((x: any) => !!x.evaluation);
      if (evaluations.length === this.totalSteps) {
        return true;
      }
    }
    return false;
  }

  goBack() {
    this.redirectService.goBackBrowser();
  }

  // Apre modale info del qualifica competenze
  openHowFeelModal() {
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.qualifyCompetencesTooltipModal }));
  }

  ngOnDestroy(): void {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
    if (this.getBehaviourCareTag$) {
      this.getBehaviourCareTag$.unsubscribe();
    }
    if (this.closeMyCompetence$) {
      this.closeMyCompetence$.unsubscribe();
    }
  }
}
