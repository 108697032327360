<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('calibration.TITLE' | translate)"
    [adminPageSubtitle]="('calibration.PROCESS_LIST' | translate)" [adminBack]="true" [hasTabs]="false">
  </header-dossier>
  <div class="main-content-wrapper admin">
    <!-- Home processi -->
    <ng-container *ngIf="!processYear">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.YEAR' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.OPEN_CLOSE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PHASE_HIGH' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                  <th>
                    <div class="sorting" (click)="orderBy('status')">
                      <p>{{ ('adminTable.STATUS' | translate)}}</p>
                      <div class="arrows-container">
                        <svg-icon class="arrow" [applyClass]="true" src="/assets/img/icons/sorting-arrow-up.svg">
                        </svg-icon>
                        <svg-icon class="arrow" [applyClass]="true" src="/assets/img/icons/sorting-arrow-down.svg">
                        </svg-icon>
                      </div>
                    </div>
                  </th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                            slice:0:processData.numRecords | 
                            paginate: { id: 'processList',
                                        itemsPerPage: processData.numRecords,
                                        currentPage: processData.page,
                                        totalItems: processData.counter }">
                  <td>{{ process.year }}</td>
                  <td>{{ process.title }}</td>
                  <td>
                    <div class="date">
                      <p>{{ process.startDate ? (process.startDate | date: 'dd/MM/YYYY') : '--'}}
                      </p>
                      <p>{{ process.endDate ? (process.endDate | date: 'dd/MM/YYYY') : '--'}}</p>
                    </div>
                  </td>
                  <td>
                    {{ process.activePhaseKey ?
                    ( 'phases.' + process.activePhaseKey | translate) : '--'}}
                  </td>
                  <td>
                    <div class="count">
                      {{ process.usersInGroupCount || 0 }}
                    </div>
                  </td>
                  <td>
                    <item-list-team-status type="inProgress"></item-list-team-status>
                  </td>
                  <td class="action">
                    <svg-icon class="more-icon" src="assets/img/icons/more-horizontal.svg" [applyClass]="true"
                      (click)="openOptionsMenu(process)">
                    </svg-icon>
                    <ng-container *ngIf="process.isMenuOpen">
                      <div class="dropdown-menu">
                        <europ-dropdown-item (click)="onMenuOptionsClicked(option, process)" [ngClass]="{'last': last}"
                          class="full-width" *ngFor="let option of process.menuOptions; let last = last"
                          [item]="option">
                        </europ-dropdown-item>
                      </div>
                    </ng-container>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </europ-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>