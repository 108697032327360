import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tab-light',
  templateUrl: 'tab-light.component.html',
  styleUrls: ['./tab-light.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabLightComponent implements OnInit {
  @Input() tabTitle: string = '';
  @Input() attrAriaLabel: string = '';
  @Input() isDisabled: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  emitOnModelChanged(data?: any) {
    this.onClick.emit(data);
  }
}