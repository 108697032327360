import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse, User } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ManagerService } from 'src/app/shared/services/manager.service';
import * as moment from 'moment';

@Component({
  selector: 'app-mid-term-list',
  templateUrl: './midTermList.component.html',
  styleUrls: ['./midTermList.component.scss']
})
export class MidTermListComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  isLoadingUsersData: boolean = false;
  getMidTermUsersList$: Subscription = new Subscription;
  usersData: any;
  isLoadingMidTermData: boolean = false;
  getMidTermReviewBoxData$: Subscription = new Subscription;
  midTermData: any;
  userFeedbackModal: any;
  MID_STATUS_SRC = {
    'LATE': "assets/img/icons/red-face.svg",
    'ON_LINE': "assets/img/icons/yellow-face.svg",
    'WELL_ADVANCED': "assets/img/icons/green-face.svg"
  }
  translations: any;
  getLoggedUser$: Subscription = new Subscription;
  loggedUser: any;
  dataForModal: any;
  excludedModalTitle: string = '';
  excludedModalDescr: string = '';

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public modalService: ModalService,
    public managerService: ManagerService,
    public redirectService: RedirectService
  ) {
    this.getLoggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser: any) => {
      this.loggedUser = loggedUser.user;
    })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      this.getMidTermReviewBoxData()
      this.getMidTermUsersList();
    });
  }

  ngOnInit() {
    this.translate.get([
      'midTerm.list.MODAL_TITLE',
      'teamList.modals.PEER_REQ1',
      'teamList.modals.PEER_REQ2',
      'teamList.modals.CLAP_SENT',
      'teamList.modals.INTERVIEW',
      'teamList.modals.EVIDENCES',
      'teamList.modals.TOTAL_FEEDBACK',
      'midTerm.list.NOT_REVIEWABLE_TITLE',
      'midTerm.list.NOT_REVIEWABLE_DESCR'
    ]).subscribe((translations) => {
      this.translations = translations;
    });
  }

  getMidTermReviewBoxData() {
    this.isLoadingMidTermData = true;
    if (this.getMidTermReviewBoxData$) {
      this.getMidTermReviewBoxData$.unsubscribe();
    }
    this.getMidTermReviewBoxData$ = this.managerService.getMidTermReviewBoxData(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.midTermData = data.response;
          if (this.midTermData.toCompleteMidTermReviewCount > 0) {
            this.midTermData.status = 'to_complete';
          } else {
            this.midTermData.status = 'completed';
          }
        }
        this.isLoadingMidTermData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMidTermData = false;
      });
  }

  getMidTermUsersList() {
    this.isLoadingUsersData = true;
    if (this.getMidTermUsersList$) {
      this.getMidTermUsersList$.unsubscribe();
    }
    this.getMidTermUsersList$ = this.managerService.manageMidTermReviewData(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.usersData = data.response;
        }
        this.isLoadingUsersData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsersData = false;
      });
  }

  openFeedbackModal(feedback: any, user: User) {
    if (feedback) {
      let text = '';
      let peerFeedback = '';
      if (feedback && feedback.requestedPeerFeedbackCount > 0) {
        peerFeedback = '<b>' + feedback.requestedPeerFeedbackCount + '</b>' + this.translations['teamList.modals.PEER_REQ1'] + '<b>' + feedback.requestedFeedbackNotesWithResponseCount + ' </b> ' + this.translations['teamList.modals.PEER_REQ2'] + '</b><br>';
      }
      if (feedback) {
        if (peerFeedback && peerFeedback.length) {
          text += peerFeedback;
        }
        text += this.translations['teamList.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseCount || '-') + '</b><br>';
        text += this.translations['teamList.modals.INTERVIEW'] + ' <b>' + (feedback.createdFeedbackDialogueCount || '-') + '</b><br>';
        text += this.translations['teamList.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceCount || '-') + '</b><br>';
      }
      this.userFeedbackModal = {
        modalId: 'mt001',
        title: this.translations['midTerm.list.MODAL_TITLE'] + ' ' + user.forename + ' ' + user.surname,
        subtitle: (feedback.totalFeedbackCount || 0) + ' ' + this.translations['teamList.modals.TOTAL_FEEDBACK'],
        description: text
      }
      this.modalService.open('feedbackModal');
    }

  }

  closeFeedbackModal() {
    this.modalService.close('feedbackModal');
    this.userFeedbackModal = null;
  }

  goToMidTermReview(userData: any) {
    if (userData && userData.user) {
      let operationsModule = window.location.href.indexOf('managerOperations') > -1;
      this.redirectService.goToMidTermManager(userData.user.userId, operationsModule);
    }
  }

  openDetailsModal(userData: any) {
    if (userData) {
      this.dataForModal = {
        userObservation: userData.userPhase.midTermReviewUserObservation,
        userDate: moment(userData.userPhase.midTermReviewUserObservationDate).format('DD.MM.YY'),
        userStatus: userData.userPhase.midTermReviewUserGoalAdvancement,
        managerObservation: userData.userPhase.midTermReviewManagerObservation,
        managerDate: moment(userData.userPhase.midTermReviewManagerObservationDate).format('DD.MM.YY'),
        user: userData.user,
        manager: this.loggedUser
      }
      setTimeout(() => {
        this.modalService.open('midTermDetails');
      }, 100)
    }
  }

  closeDetailsModal() {
    this.modalService.close('midTermDetails')
  }

  openExcludedModal(user: any) {
    this.excludedModalTitle = this.translations['midTerm.list.NOT_REVIEWABLE_TITLE'];
    this.excludedModalDescr = user.forename + ' ' + user.surname + ' ' + this.translations['midTerm.list.NOT_REVIEWABLE_DESCR'];
    this.modalService.open('excludedModal')
  }

  closeExcludedModal() {
    this.modalService.close('excludedModal')
    this.excludedModalTitle = '';
    this.excludedModalDescr = '';
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getMidTermUsersList$) {
      this.getMidTermUsersList$.unsubscribe();
    }
    if (this.getMidTermReviewBoxData$) {
      this.getMidTermReviewBoxData$.unsubscribe();
    }
    if (this.getLoggedUser$) {
      this.getLoggedUser$.unsubscribe();
    }
  }
}