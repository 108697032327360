<div class="dropdown-container" [ngClass]="{'disabled': item.disabled, 'menu': isMenuItem, 'filter-modal': filterModal}"
  (click)="emitOnItemCheck()">
  <ng-container *ngIf="!isCheckbox">
    <div class="image-container" *ngIf="item.icon || item.avatar || item.initials">
      <svg-icon *ngIf="item.icon" class="regular-size blue-primary" [src]="item.icon" [applyClass]="true">
      </svg-icon>
      <avatar-img *ngIf="item.avatar || item.initials" size="small" [src]="item.avatar" [initials]="item.initials">
      </avatar-img>
    </div>
    <div class="text-container">
      <p class="text-default">{{ item.name || item.title }}</p>
      <p *ngIf="item.description && !notSeeDescription && !innerHtmlDescription" class="text-small">{{
        item.description}}</p>
      <p *ngIf="item.description && !notSeeDescription && innerHtmlDescription" [innerHTML]="item.description"
        class="text-small full-width whitespace-normal"></p>
    </div>
  </ng-container>
  <ng-container *ngIf="isCheckbox">
    <europ-check [label]="item.name" [checked]="item.isChecked">
    </europ-check>
  </ng-container>
</div>