import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { AdminComponent } from './admin.component';
import { CreateUpdateProcessComponent } from './createUpdateProcess/createUpdateProcess.component';
import { DeclineObjectivesComponent } from './declineObjectives/declineObjectives.component';
import { CalibrationComponent } from './calibration/calibration.component';
import { AdminHomeComponent } from './home/home.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { PeopleAnagComponent } from './peopleAnag/peopleAnag.component';
import { ProcessSetupComponent } from './processSetup/processSetup.component';
import { ProcessSetupDetailsComponent } from './processSetupDetails/processSetupDetails.component';
import { ProcessSetupObjectivesComponent } from './processSetupObjectives/processSetupObjectives.component';
import { StructureAnagComponent } from './structure-anag/structure-anag.component';
import { CalibrationUserListComponent } from './calibration-user-list/calibration-user-list.component';
import { AdminDefinePerformanceLevelManagerComponent } from './definePerformanceLevel/definePerformanceLevel.component';
import { AdminConfirmPerformanceLevelManagerComponent } from './confirmPerformanceLevel/confirmPerformanceLevel.component';
import { CalibrationMatrixComponent } from './calibration-matrix/calibration-matrix.component';

const adminRoutes: Routes = [
  {
    path: '', component: AdminComponent, children: [
      { path: 'home', component: AdminHomeComponent, canActivate: [AdminGuard] },
      { path: 'monitoring', component: MonitoringComponent, canActivate: [AdminGuard] },
      { path: 'monitoring/:processYear', component: MonitoringComponent, canActivate: [AdminGuard] },
      { path: 'processSetup', component: ProcessSetupComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/participantList/:processYear', component: ProcessSetupDetailsComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/objectiveDefinition/:processYear', component: ProcessSetupDetailsComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/phaseDefinition/:processYear', component: ProcessSetupDetailsComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateProcess', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateProcess/:processId', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/defineObjectives', component: DeclineObjectivesComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/defineObjectives/:objectiveId', component: DeclineObjectivesComponent, canActivate: [AdminGuard] },
      { path: 'peopleAnagraphics', component: PeopleAnagComponent, canActivate: [AdminGuard] },
      { path: 'peopleAnagraphics/:userId', component: PeopleAnagComponent, canActivate: [AdminGuard] },
      { path: 'structureAnag', component: StructureAnagComponent, canActivate: [AdminGuard] },
      { path: 'structureAnag/:processYear', component: StructureAnagComponent, canActivate: [AdminGuard] },
      { path: 'impersonate', component: ImpersonateComponent, canActivate: [AdminGuard] },
      { path: 'calibration', component: CalibrationComponent, canActivate: [AdminGuard] },
      { path: 'calibrationUserList/:processYear', component: CalibrationUserListComponent, canActivate: [AdminGuard] },
      { path: 'definePerformanceLevel/:processYear/:userId', component: AdminDefinePerformanceLevelManagerComponent, canActivate: [AdminGuard] },
      { path: 'confirmPerformanceLevel/:userId', component: AdminConfirmPerformanceLevelManagerComponent, canActivate: [AdminGuard] },
      { path: 'calibrationMatrix/:processYear', component: CalibrationMatrixComponent, canActivate: [AdminGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
