/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
*/

import { createReducer, on, Action } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import jwt_decode from "jwt-decode";
import { JwtPayload, RequiredAuth } from '../../../../commonclasses';

export interface ProfileState {
  loggedUser: any;
  editedUser: any;
  isManager: boolean;
  isCollaborator: boolean;
  isAdmin: boolean;
  isStaff: boolean;
  isOperation: boolean;
  isSti: boolean;
  isChief: boolean;
  peerFeedbackAllowed: boolean;
  langs: any[];
}

const initialState: ProfileState = {
  loggedUser: null,
  editedUser: null,
  isManager: false,
  isCollaborator: false,
  isAdmin: false,
  isStaff: false,
  isOperation: false,
  isSti: false,
  isChief: false,
  peerFeedbackAllowed: false,
  langs: []
};

export const createProfileReducer = createReducer(
  initialState,
  on(ProfileActions.UpdateUser, (state, action) => {
    const oldUserInfo = state.loggedUser;
    const updatedUser = {
      ...oldUserInfo,
      ...action.loggedUser
    };
    const user = updatedUser;
    return {
      ...state,
      loggedUser: user,
      editedUser: null
    };
  }),
  on(ProfileActions.CancelLoggedUser, (state, action) => {
    // Cancello le informazioni sull'utente loggato
    return {
      ...state,
      loggedUser: null
    };
  }),
  on(ProfileActions.SaveAvailableLangs, (state, action) => {
    // Salva le lingue disponibili a sistema recuperate tramite servizi
    return {
      ...state,
      langs: action.payload
    };
  }),
  on(ProfileActions.UpdateUserOptions, (state, action) => {
    let loggedUserCopy = JSON.parse(JSON.stringify(state.loggedUser));
    loggedUserCopy.user.userOptions = action
    return {
      ...state,
      loggedUser: loggedUserCopy
    };
  }),
  on(ProfileActions.DecodeToken, (state, action) => {
    const loggedUser: JwtPayload = jwt_decode(action.payload);
    let isManager: boolean = false;
    let isCollaborator: boolean = false;
    let isAdmin: boolean = false;
    let isStaff: boolean = false;
    let isSti: boolean = false;
    let isOperation: boolean = false;
    let peerFeedbackAllowed: boolean = false;
    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
      for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
        if (loggedUser.auths[i].indexOf(RequiredAuth.PERFORMANCECARE_MANAGER_STAFF) > -1 || loggedUser.auths[i].indexOf(RequiredAuth.PERFORMANCECARE_USER_STAFF) > -1) {
          isStaff = true;
        }
        if (loggedUser.auths[i].indexOf(RequiredAuth.PERFORMANCECARE_USER_STAFF_STI) > -1) {
          isSti = true;
        }
        if (loggedUser.auths[i].indexOf(RequiredAuth.PERFORMANCECARE_MANAGER_OPERATIONS) > -1 || loggedUser.auths[i].indexOf(RequiredAuth.PERFORMANCECARE_USER_OPERATIONS) > -1) {
          isOperation = true;
        }
        if (loggedUser.auths[i].includes(RequiredAuth.PERFORMANCECARE_MANAGER_COMMON)) {
          isManager = true;
        } else if (loggedUser.auths[i].includes(RequiredAuth.PERFORMANCECARE_USER_COMMON)) {
          isCollaborator = true;
        } else if (loggedUser.auths[i] === RequiredAuth.PERFORMANCECARE_PLATFORM_MANAGER) {
          isAdmin = false;
        } else if (loggedUser.auths[i] === RequiredAuth.PERFORMANCECARE_ADMIN) {
          isAdmin = true;
        }
        if (loggedUser.auths[i].indexOf(RequiredAuth.PERFORMANCECARE_CAN_CREATE_PEER_FEEDBACK) > -1) {
          peerFeedbackAllowed = true;
        }
      }
    }
    return {
      ...state,
      isManager: isManager,
      isCollaborator: isCollaborator,
      isAdmin: isAdmin,
      isStaff: isStaff,
      isSti: isSti,
      isOperation: isOperation,
      peerFeedbackAllowed: peerFeedbackAllowed,
      loggedUser: {
        ...state.loggedUser,
        ...loggedUser
      }
    };
  }),
  on(ProfileActions.SetIsChief, (state, action) => {
    // Distingue i profili collaboratori dei dirigenti
    return {
      ...state,
      isChief: action.payload
    };
  }),
)

export function profileReducer(state: ProfileState | undefined, action: Action) {
  return createProfileReducer(state, action);
}

export const getLoggedUser = (state: ProfileState) => state.loggedUser;
export const getIsManager = (state: ProfileState) => state.isManager;
export const getIsCollaborator = (state: ProfileState) => state.isCollaborator;
export const getIsAdmin = (state: ProfileState) => state.isAdmin;
export const getIsStaff = (state: ProfileState) => state.isStaff;
export const getIsOperation = (state: ProfileState) => state.isOperation;
export const getIsSti = (state: ProfileState) => state.isSti;
export const getIsChief = (state: ProfileState) => state.isChief;
export const getAvailableLangs = (state: ProfileState) => state.langs;
export const getPeerFeedbackAllowed = (state: ProfileState) => state.peerFeedbackAllowed;