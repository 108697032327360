<div class="calibration-container">
  <ng-container *ngIf="!isComplete">
    <div class="icon-container">
      <svg-icon src="assets/img/section/performance-level.svg"></svg-icon>

      <ng-container
        *ngIf="(calibrationLevelValue && calibrationLevelValue.length) || (calibrationLevelObservations && calibrationLevelObservations.length) || (payout && payout.id)">
        <div class="pl-value-container">
          <evaluation [evaluation]="calibrationLevelValue" [scale]="5" customClass="big"></evaluation>
          <ng-container *ngIf="calibrationLevelObservations || payout">
            <div class="divisor-line"></div>
            <div class="payout-wrapper">
              <ng-container *ngIf="calibrationLevelObservations && calibrationLevelObservations.length">
                <p class="read" translate="performance.READ_OBSERVATIONS" (click)="emitOnReadObservation($event)">
                </p>
              </ng-container>

              <p *ngIf="payout && payout.id" class="payout">
                {{ payout.id }}% {{ 'performance.modals.OF_PAYOUT' | translate | uppercase }}
              </p>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="text-wrapper full-width">
      <h1 class="title" translate="performance.ACCORDION_TITLE"></h1>
      <p class="full-width" [innerHTML]="phaseDescr || description"></p>
    </div>
    <!-- Confema osservazione -->
    <ng-container *ngIf="canConfirm">
      <div class="confirm-container">
        <europ-button type="primary" [label]="'calibration.CONFIRM_EVALUATION' | translate"
          (onButtonClicked)="emitConfirmEvaluation($event)">
        </europ-button>
      </div>
    </ng-container>
  </ng-container>

  <!-- Se completato -->
  <ng-container *ngIf="isComplete">
    <div class="text-wrapper no-margin full-width">
      <h1 class="title" translate="performance.ACCORDION_TITLE"></h1>
      <h1 class="title">{{ year }}</h1>
    </div>
    <div class="icon-container">
      <svg-icon src="assets/img/section/performance-level.svg"></svg-icon>
      <div class="pl-value-container">
        <ng-container *ngIf="calibrationLevelValue">
          <evaluation [evaluation]="calibrationLevelValue" [scale]="5" customClass="big"></evaluation>
        </ng-container>
        <ng-container *ngIf="isComplete && !calibrationLevelValue">
          <p class="not-done" translate="calibration.NOT_DONE_EVAL"></p>
        </ng-container>

        <ng-container *ngIf="calibrationLevelObservations || payout || sti">
          <div class="divisor-line"></div>
          <ng-container *ngIf="calibrationLevelObservations">
            <p class="read" translate="performance.READ_OBSERVATIONS" (click)="emitOnReadObservation($event)">
            </p>
          </ng-container>
          <div class="payout-wrapper" *ngIf="(payout && payout.id >= 0) || sti >= 0">
            <p *ngIf="payout" class="payout">{{ payout.id }}% {{ 'performance.modals.OF_PAYOUT' | translate |
              uppercase}}
            </p>
            <p *ngIf="sti" class="sti">{{ sti | number : '1.2-2'}} €</p>
          </div>
        </ng-container>
      </div>
    </div>
    <!--div class="confirm-container">
      <europ-button type="primary" [label]="'generic.EDIT' | translate"
        (onButtonClicked)="emitConfirmEvaluation($event)">
      </europ-button>
    </div-->
  </ng-container>
</div>