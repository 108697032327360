import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
    selector: 'admin-process-setup-phases',
    templateUrl: './processSetupPhases.component.html',
    styleUrls: ['./processSetupPhases.component.scss']
})
export class ProcessSetupPhasesComponent implements OnDestroy {
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;
    currentYear = new Date().getFullYear();

    processYear: string = '';

    isLoadingPhases: boolean = true;
    phasesData: any[] = [];
    menuOptions: any[] = [];
    disableModalData: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };
    activateModalData: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };

    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.processYear = params.processYear;
                                    this.getPhasesData();
                                })
                    }
                });
    }

    ngOnInit() {
        this.translate.get([
            'process.phases.ACTIVATE',
            'process.phases.DISABLE',
            'process.phases.DISABLE_PHASE_TITLE',
            'process.phases.DISABLE_PHASE_TEXT',
            'process.phases.ACTIVATE_PHASE_TITLE',
            'process.phases.ACTIVATE_PHASE_TEXT'
        ]).subscribe((translations) => {
            this.disableModalData = {
                modalId: 'ps024',
                title: translations['process.phases.DISABLE_PHASE_TITLE'],
                text: translations['process.phases.DISABLE_PHASE_TEXT']
            }

            this.activateModalData = {
                modalId: 'ps025',
                title: translations['process.phases.ACTIVATE_PHASE_TITLE'],
                text: translations['process.phases.ACTIVATE_PHASE_TEXT']
            }

            this.menuOptions = [
                {
                    id: 'activate',
                    title: translations['process.phases.ACTIVATE'],
                    icon: '/assets/img/icons/arrow-left.svg',
                },
                {
                    id: 'disable',
                    title: translations['process.phases.DISABLE'],
                    icon: '/assets/img/icons/arrow-right.svg',
                }
            ]
        })
    }

    getPhasesData() {
        setTimeout(() => {
            this.phasesData = [
                {
                    id: 1,
                    name: "Goal setting",
                    startDate: moment().format(),
                    endDate: moment().format(),
                    status: "ACTIVE",
                    isMenuOpen: false
                },
                {
                    id: 2,
                    name: "Piano di sviluppo",
                    startDate: moment().format(),
                    endDate: moment().format(),
                    status: "ACTIVE",
                    isMenuOpen: false
                },
                {
                    id: 3,
                    name: "Mid term review",
                    startDate: moment().format(),
                    endDate: moment().format(),
                    status: "DISABLED",
                    isMenuOpen: false
                },
                {
                    id: 4,
                    name: "Valutazione finale",
                    startDate: moment().format(),
                    endDate: moment().format(),
                    status: "DISABLED",
                    isMenuOpen: false
                },
                {
                    id: 5,
                    name: "Calibration",
                    startDate: moment().format(),
                    endDate: moment().format(),
                    status: "ACTIVE",
                    isMenuOpen: false
                }
            ]
            this.isLoadingPhases = false;
        }, 1000);
    }

    // menu fase
    // dalla tabella apre il menu con le azioni 
    openCloseMenu(phase: any) {
        phase.isMenuOpen = !phase.isMenuOpen;
    }

    // gestisce le opzioni del menu utente
    onMenuOptionsClicked(options: any, phase: any) {
        phase.isMenuOpen = false;
        if (options.id == 'activate') {
            this.openActivateModal();
        } else {
            this.openDisableModal();
        }
    }

    openDisableModal() {
        this.modalService.open('disablePhase')
    }

    closeDisableModal(confirm?: boolean) {
        this.modalService.close('disablePhase');
        if (confirm) {
            console.log("Carica")
        }
    }

    openActivateModal() {
        this.modalService.open('activatePhase')
    }

    closeActivateModal(confirm?: boolean) {
        this.modalService.close('activatePhase');
        if (confirm) {
            console.log("Carica")
        }
    }

    editProcess() {
        this.router.navigate(['admin/createUpdateProcess/' + this.processYear]);
    }

    orderBy(fieldName: string) {
        // console.log("fieldName", fieldName);
    }

    ngOnDestroy() {
    }
}