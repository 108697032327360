import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { GetSubordinatesWithPhaseStatusesForManagerResponse } from '../../../../commonclasses';

@Component({
  selector: 'team-sidebar',
  templateUrl: './team-sidebar.component.html',
  styleUrls: ['./team-sidebar.component.scss'],
})
export class TeamSidebarComponent {
  @Input() users: GetSubordinatesWithPhaseStatusesForManagerResponse[] | null = null;
  @Input() activeUserId: string | null = null;
  @Input() currentPhaseId?: string;
  @Output() userClicked: EventEmitter<any> = new EventEmitter<any>();

  searchedText: string | null = null;
  searchResults: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  constructor(
    public cdr: ChangeDetectorRef,
  ) {
  }

  onUserSearch(text: any) {
    if (this.users && this.searchedText) {
      this.searchResults = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].forename && this.users[i].surname &&
          (this.users[i].forename.toLowerCase().indexOf(this.searchedText) != -1 || this.users[i].surname.toLowerCase().indexOf(this.searchedText) != -1)) {
          this.searchResults.push(this.users[i]);
        }
      }
    } else {
      this.searchResults = [];
    }
  }

  onUserClicked(user: any) {
    this.userClicked.emit(user);
    this.cdr.detectChanges();
  }

  userId(index: number, el: GetSubordinatesWithPhaseStatusesForManagerResponse) {
    return el.userId;
  }
}
