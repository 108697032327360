import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as fromApp from "../ngrx/app.reducers";
import { Router } from '@angular/router';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { JwtPayload, SenecaResponse, User } from 'src/commonclasses';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import * as CoreActions from '../core/ngrx/core.actions';
import * as ProfileActions from '../core/profile/ngrx/profile.actions';
import { Subordinateservice } from '../manager/services/subordinates.service';
import jwtDecode from 'jwt-decode';

@Component({
  selector: "app-profile-page",
  templateUrl: "./profilePage.component.html",
  styleUrls: ["./profilePage.component.scss"]
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  showApplicationLoader: boolean = false;
  applicationModalMessageText: string = '';
  applicationModalMessageTitle: string = '';
  withScrolledBody: boolean = true;
  applicationModalButtonCloseText: string = '';
  isFetchingLangs: boolean = true;
  loggedUser: any;
  initials: string = '';
  isManager: boolean = false;
  isCollaborator: boolean = false;
  isAdmin: boolean = false;
  isOperation: boolean = false;
  isLoadingUser: boolean = true;
  isLoadingBadges: boolean = true;
  uploadAreaModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  badgeObject: any = {};
  fileObject: any;
  newImage: boolean = false;
  isManagerProfile: boolean = false;
  getBadgeList$: Subscription = new Subscription;
  runningYear: number = 0;
  runningYear$: Subscription;
  translations: any;
  badgeForModal: BadgeObject | null = null;
  loggedUser$: Subscription = new Subscription;
  uploadAvatar$: Subscription = new Subscription;
  isImpersonate: boolean = false;

  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private modalService: ModalService,
    private translate: TranslateService,
    private subordinateService: Subordinateservice,
    private router: Router,
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
    this.isLoadingUser = true;
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager)
        const isCollaborator$: Observable<boolean> = this.store.select(fromApp.getIsCollaborator);
        const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
        const isOperation$: Observable<boolean> = this.store.select(fromApp.getIsOperation);
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const combinedSelectes$ = combineLatest([loggedUser$, isManager$, isCollaborator$, isAdmin$, isOperation$])
          // const combinedSelectes$ = combineLatest([isManager$, isCollaborator$, isAdmin$, isOperation$])
          .subscribe(
            ([loggedUser, isManager, isCollaborator, isAdmin, isOperation]) => {
              if (loggedUser && loggedUser.user) {
                this.loggedUser = loggedUser && loggedUser.user;
                if (this.loggedUser) {
                  this.isLoadingUser = false;
                }
              }
              this.isManager = isManager;
              this.isCollaborator = isCollaborator;
              this.isAdmin = isAdmin;
              this.isOperation = isOperation;
              if (window.location.href.indexOf('manager') >= 0) {
                this.isManagerProfile = true;
              }
            });
      }
    })
  }

  ngOnInit() {
    this.translate.get(
      [
        "badges.ONBOARDING",
        "badges.ONE_MOTIVATOR",
        "badges.ONE_INVOLVED",
        "badges.ONE_CONSCIOUS",
        "badges.ONE_ENTERPRISING",
        "badges.ONE_OBSERVER",
        "badges.ONE_AMBITIOUS",
        "badges.PROACTIVE",
        "badges.PREPARED",
        "badges.TWO_CONSCIOUS",
        "badges.TWO_OBSERVER",
        "badges.TWO_AMBITIOUS",
        "badges.CHAMPION",
        "badges.THREE_ENTERPRISING",
        "badges.THREE_MOTIVATOR",
        "badges.THREE_OBSERVER",
        "badges.THREE_AMBITIOUS",
        "badges.THREE_CONSCIOUS",
        "badges.IO_CENTRO",
        "badges.GOLDEN",
        "profile.modals.UPLOAD_AREA_TITLE",
        "profile.modals.UPLOAD_AREA_TITLE_SUB",
        "profile.modals.UPLOAD_AREA_TITLE_DESCR",
      ])
      .subscribe(translations => {
        this.translations = translations;
        if (this.isCollaborator) {
          this.getBadgeList();
        }
      })
  }

  onChangeImage() {
    this.newImage = true;
    this.modalService.open('changeImage');
  }

  closeChangeImageModal(confirm?: boolean) {
    this.modalService.close('changeImage');
    if (!confirm) {
      this.newImage = true;
      this.fileObject = null;
    } else {
      this.isLoadingUser = true;

      if (this.uploadAvatar$) {
        this.uploadAvatar$.unsubscribe();
      }

      this.uploadAvatar$ = this.subordinateService.uploadAvatar(this.fileObject)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pp002",
              title: this.translate.instant("generic.WARNING"),
              text: this.translate.instant("errors." + data.error),
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUser = false;
          } else {
            let newAvatarOptions = data?.body?.response?.userOptions;
            if (newAvatarOptions) {
              // // this.store.dispatch(ProfileActions.UpdateUserOptions(newAvatar));
              // this.store.dispatch(CoreActions.StartRenewTokenPolling({ payload: { redirectUrl: window.location.href.split('#')[1], forceRefreshUser: true } }));
              let updateUserOptionsPromise = this.updateUserOptions(newAvatarOptions);
              updateUserOptionsPromise.then((newFullToken: any) => {
                this.store.dispatch(ProfileActions.DecodeToken({ payload: newFullToken }));
                sessionStorage.setItem('token', newFullToken);
                let decodedJwt = jwtDecode(newFullToken)
                this.store.dispatch(ProfileActions.UpdateUser({ loggedUser: decodedJwt }));
                // this.store.dispatch(CoreActions.StartRenewTokenPolling({ payload: { forceRefreshUser: true } }));
                this.isLoadingUser = false;
              })
                .catch(() => {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "pp003",
                    title: this.translate.instant("generic.WARNING"),
                    text: this.translate.instant("errors." + data.error),
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  this.isLoadingUser = false;
                })
            }
            // 
            this.newImage = true;
            this.fileObject = null;
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pp004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUser = false;
        });
    }
  }

  updateUserOptions(userOptions: any) {
    return new Promise((resolve, reject) => {
      // Creo il collegamento tra l'iniziativa e la (non) scelta della proposta
      this.authService.updateUserOptions(userOptions)
        .subscribe(
          (senecaResponse: SenecaResponse<string>) => {
            if (senecaResponse.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pp005",
                title: this.translate.instant("generic.WARNING"),
                text: this.translate.instant("errors." + senecaResponse.error),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              reject();
            } else {
              resolve(senecaResponse.response);
            }
          },
          (err) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pp006",
              title: this.translate.instant("generic.WARNING"),
              text: this.translate.instant("errors." + err),
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            reject();
          });
    })
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  getBadgeList() {
    this.isLoadingBadges = true;

    if (this.getBadgeList$) {
      this.getBadgeList$.unsubscribe();
    }

    this.getBadgeList$ = this.subordinateService.listMyBadges(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pp007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
          this.isLoadingBadges = false;
        } else {
          BadgeUtils.getProfileBadgeObject(data.response, this.translations).then((response) => {
            this.badgeObject = response;
            this.isLoadingBadges = false;
          });
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pp008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      });

  }

  openBadgeDetails(badge: any) {
    this.badgeForModal = badge;
    this.modalService.open('badge-details');
  }

  closeBadgeDetails() {
    this.badgeForModal = null;
    this.modalService.close('badge-details')
  }

  openBadgeInfoModal() {
    this.modalService.open('badge-info');
  }

  closeBadgeInfoModal() {
    this.modalService.close('badge-info')
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }

    if (this.getBadgeList$) {
      this.getBadgeList$.unsubscribe();
    }
  }
}

export class BadgeObject {
  badgeId: string = '';
  badgeType: string = '';
  title: string = '';
  image: string = '';
  isActive: boolean = false;
  starNumberArray: number[] = [];
  date: string | null = null;
}