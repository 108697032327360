import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { PerfCareDevelopmentAction, SenecaResponse, CreateDevelopmentActionForUserResponse, UpdateDevelopmentActionForUserResponse } from 'src/commonclasses';

@Component({
  selector: 'app-collaborator-createUpdateDevelopmentActionCollaborator',
  templateUrl: './createUpdateDevelopmentActionCollaborator.component.html',
  styleUrls: ['./createUpdateDevelopmentActionCollaborator.component.scss']
})
export class CreateUpdateDevelopmentActionCollaboratorComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  developAction: any = {
    developmentActionId: '',
    creationUserId: '',
    phaseId: '',
    userId: '',
    title: '',
    description: '',
    status: ''
  };
  isFetchingCreateDevelopmentAction: boolean = false;
  isFetchingUpdateDevelopmentAction: boolean = false;
  createDevelopAction$: Subscription | null = null;
  updateDevelopAction$: Subscription | null = null;
  isFetchingGetDevelopAction: boolean = false;
  getDevelopAction$: Subscription | null = null;
  developmentActionId: string = '';
  developActionCopy: any = {
    developmentActionId: '',
    creationUserId: '',
    phaseId: '',
    userId: '',
    title: '',
    description: '',
    status: ''
  };
  modalCreateUpdateDevelopActionTitle: string = '';
  modalCreateUpdateDevelopActionText: string = '';
  modalCreateUpdateDevelopActionTitleConfirmTextButton: string = '';
  modalCreateUpdateDevelopActionTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  today = new Date();

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  deadlineTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  categoryTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalCreateUpdateDevelopActionId: string = '';

  goalId: string = '';
  isImpersonate: boolean = false;

  constructor(
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;
              this.developmentActionId = params.developmentActionId;

              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.personDetails = loggedUser && loggedUser.user;
                    if (this.personDetails && this.personDetails.userId && this.developmentActionId) {
                      this.getDevelopmentActionById();
                    }
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'goalSetting.updatePMGoal.ACHIEVED',
        'goalSetting.updatePMGoal.NOT_ACHIEVED',
        'generic.INFO',
        'developmentPlan.TITLE_INFO_SUB',
        'developmentPlan.TITLE_INFO_DESCR',
        'developmentPlan.DESCR_INFO_SUB',
        'developmentPlan.DESCR_INFO_DESCR',
        'developmentPlan.DATE_INFO_SUB',
        'developmentPlan.DATE_INFO_DESCR',
        'developmentPlan.CATEGORY_INFO_SUB',
        'developmentPlan.CATEGORY_INFO_DESCR'
      ])
      .subscribe(translations => {
        this.titleTooltipModal = {
          modalId: "d005",
          title: translations['generic.INFO'],
          subtitle: translations['developmentPlan.TITLE_INFO_SUB'],
          text: translations['developmentPlan.TITLE_INFO_DESCR']
        };
        this.descriptionTooltipModal = {
          modalId: "d006",
          title: translations['generic.INFO'],
          subtitle: translations['developmentPlan.DESCR_INFO_SUB'],
          text: translations['developmentPlan.DESCR_INFO_DESCR']
        };
        this.deadlineTooltipModal = {
          modalId: "d007",
          title: translations['generic.INFO'],
          subtitle: translations['developmentPlan.DATE_INFO_SUB'],
          text: translations['developmentPlan.DATE_INFO_DESCR']
        };
        this.categoryTooltipModal = {
          modalId: "d008",
          title: translations['generic.INFO'],
          subtitle: translations['developmentPlan.CATEGORY_INFO_SUB'],
          text: translations['developmentPlan.CATEGORY_INFO_DESCR']
        };
        this.formatModalCreateUpdateDevelopActionData();
      })
  }

  // Formatta i testi della modale per la creazione/aggiornamento dell'azione di sviluppo
  formatModalCreateUpdateDevelopActionData(isBack?: boolean) {
    this.modalCreateUpdateDevelopActionId = this.getModalId(isBack);
    this.modalCreateUpdateDevelopActionTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdateDevelopActionText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdateDevelopActionTitleConfirmTextButton = this.getConfirmLabelCreateUpdateDevelopActionModal(isBack);
    this.modalCreateUpdateDevelopActionTextCloseTextButton = this.getCancelLabelCreateUpdateDevelopActionModal(isBack);
  }

  // Recupera il modalId per la modale di crea/aggiorna azione di sviluppo
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'd019';
    } else {
      if (isBack) {
        if (this.developmentActionId && this.developmentActionId.length) {
          return 'd020';
        } else {
          return 'd021';
        }
      } else {
        if (this.developmentActionId) {
          return 'd022';
        } else {
          return 'd023';
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    if (this.developAction.title && this.developAction.title.trim().length && this.developAction.description && this.developAction.description.trim().length && this.developAction.startDate) {
      return true;
    } else {
      return false;
    }
  }

  // Testo per il modale crea/aggiorna azione di sviluppo
  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_BACK');
      } else {
        if (this.developmentActionId) {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.UPDATE_DEVELOP_ACTION');
        } else {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.CREATE_DEVELOP_ACTION');
        }
      }
    }
  }

  // Descrizione per il modale crea/aggiorna azione di sviluppo
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.developmentActionId && this.developmentActionId.length) {
        return this.translate.instant('developmentPlan.createUpdateDevelopAction.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_UPDATE');
      } else {
        return this.translate.instant('developmentPlan.createUpdateDevelopAction.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
      }
    } else {
      if (isBack) {
        if (this.developmentActionId && this.developmentActionId.length) {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.DO_YOU_WANT_UPDATE_DEVELOP_ACTION_BACK');
        } else {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.DO_YOU_WANT_CREATE_DEVELOP_ACTION_BACK');
        }
      } else {
        if (this.developmentActionId && this.developmentActionId.length) {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.DO_YOU_WANT_UPDATE_DEVELOP_ACTION');
        } else {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.DO_YOU_WANT_CREATE_DEVELOP_ACTION');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateDevelopActionModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('goalSetting.createGoal.GO_BACK');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateDevelopActionModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        return this.translate.instant('generic.SAVE_AND_GO_BACK');
      } else {
        if (this.developmentActionId && this.developmentActionId.length) {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.YES_UPDATE_DEVELOP_ACTION');
        } else {
          return this.translate.instant('developmentPlan.createUpdateDevelopAction.YES_CREATE_DEVELOP_ACTION');
        }
      }
    }
  }

  // Quando si scrive sull'input del titolo azione di sviluppo
  developActionTitleChanged(text: string) {
    this.developAction.title = text;
  }

  // Quando si scrive sull'input della descrizione azione di sviluppo
  developActionDescriptionChanged(text: string) {
    this.developAction.description = text;
  }

  // Quando si scrive sull'input della data di deadline azione di sviluppo
  developActionDeadlineDateChanged(date: Date) {
    this.developAction.startDate = date;
  }

  // Quando si scrive sull'input della categoria azione di sviluppo
  developActionCategoryChanged(text: string) {
    this.developAction.category = text;
  }

  // Recupera l'azione di sviluppo dal developmentActionId
  getDevelopmentActionById() {
    this.isFetchingGetDevelopAction = true;

    this.getDevelopAction$ = this.collaboratorService.getDevelopActionByIdForCollaborator(this.runningYear.toString(), this.developmentActionId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d011",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.developAction = data.response;
          this.developActionCopy = JSON.parse(JSON.stringify(data.response));
        }
        this.isFetchingGetDevelopAction = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "d012",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetDevelopAction = false;
        })
  }

  // Gestisce quando crei/aggiorni un'azione di sviluppo
  goToUpdateDevelopAction(isBack?: boolean) {
    this.formatModalCreateUpdateDevelopActionData(isBack);
    this.openModalForCreateUpdateDevelopAction();
  }

  // Crea azione di sviluppo
  createDevelopAction() {
    if (!this.isObbligatoryDataInserted()) {
      this.closeModalCreateUpdateDevelopAction();
    } else {
      this.isFetchingCreateDevelopmentAction = true;
      this.closeModalCreateUpdateDevelopAction();
      this.developAction.userId = this.personDetails.userId;

      this.updateDevelopAction$ = this.collaboratorService.createDevelopAction(this.runningYear, this.developAction)
        .subscribe((data: SenecaResponse<CreateDevelopmentActionForUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "d013",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "d014",
              title: this.translate.instant("developmentPlan.createUpdateDevelopAction.DEVELOP_ACTION_CREATED"),
              text: this.translate.instant("developmentPlan.createUpdateDevelopAction.DEVELOP_ACTION_CREATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
          }
          this.isFetchingCreateDevelopmentAction = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "d015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingCreateDevelopmentAction = false;
          })
    }
  }

  // Aggiorna azione di sviluppo
  updateDevelopAction() {
    if (!this.isObbligatoryDataInserted()) {
      this.closeModalCreateUpdateDevelopAction();
    } else {
      this.isFetchingUpdateDevelopmentAction = true;
      this.closeModalCreateUpdateDevelopAction();
      this.developAction.userId = this.personDetails.userId;

      this.updateDevelopAction$ = this.collaboratorService.updateDevelopAction(this.runningYear, this.developAction)
        .subscribe((data: SenecaResponse<UpdateDevelopmentActionForUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "d016",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "d017",
              title: this.translate.instant("developmentPlan.createUpdateDevelopAction.DEVELOP_ACTION_UPDATED"),
              text: this.translate.instant("developmentPlan.createUpdateDevelopAction.DEVELOP_ACTION_UPDATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
          }
          this.isFetchingUpdateDevelopmentAction = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "d018",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingUpdateDevelopmentAction = false;
          })
    }
  }

  // Apre la modale per la creazione/aggiornamento dell'azione di sviluppo
  openModalForCreateUpdateDevelopAction() {
    this.modalService.open('modalCreateUpdateDevelopActionForCollaborator');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'azione di sviluppo
  closeModalCreateUpdateDevelopAction() {
    this.modalService.close('modalCreateUpdateDevelopActionForCollaborator');
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.updateDevelopAction$) {
      this.updateDevelopAction$.unsubscribe();
    }
    if (this.createDevelopAction$) {
      this.createDevelopAction$.unsubscribe();
    }
    if (this.getDevelopAction$) {
      this.getDevelopAction$.unsubscribe();
    }
  }
}