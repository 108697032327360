import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, PerfCarePrivateNoteFeedback } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { Subordinateservice } from '../services/subordinates.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';

@Component({
  selector: 'app-manager-createUpdatePrivateNote',
  templateUrl: './createUpdatePrivateNote.component.html',
  styleUrls: ['./createUpdatePrivateNote.component.scss']
})
export class CreateUpdatePrivateNoteManagerComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  modalCreateUpdatePrivateNoteId: string = '';
  modalCreateUpdatePrivateNoteTitle: string = '';
  modalCreateUpdatePrivateNoteSubtitle: string = '';
  modalCreateUpdatePrivateNoteText: string = '';
  modalCreateUpdatePrivateNoteTitleConfirmTextButton: string = '';
  modalCreateUpdatePrivateNoteTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  today = new Date();

  whoNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whatToNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whyNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  isFetchingPrivateNotes: boolean = false;
  noteId: string = '';
  note: PerfCarePrivateNoteFeedback = {
    feedbackId: '',
    senderUserId: '',
    recipientUserId: '',
    comment: '',
    motivation: '',
    feedbackType: ''
  };
  noteCopy: PerfCarePrivateNoteFeedback = {
    feedbackId: '',
    senderUserId: '',
    recipientUserId: '',
    comment: '',
    motivation: '',
    feedbackType: ''
  };

  searchUserText: string = '';
  userCardData: {
    fromRecord: number,
    numRecords: number,
    counter: number,
    currentPage: number,
    list: UserCard[]
  } = {
      fromRecord: 0,
      numRecords: 10,
      counter: 0,
      currentPage: 1,
      list: []
    };

  selectedUserList: any[] = [];
  isFetchingUsers: boolean = false;
  createPrivateNote$: Subscription = new Subscription;
  getPrivateNote$: Subscription = new Subscription;
  updatePrivateNote$: Subscription = new Subscription;
  isImpersonate: boolean = false;

  constructor(
    private subordinatesService: Subordinateservice,
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.noteId = params.noteId;
              if (this.userId) {
                // Recupero la persona selezionata, se è presente nello store
                this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                  this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                    return person.userId === this.userId;
                  })[0];

                  // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                  if (!this.personDetails) {
                    this.getPersonDetails();
                  } else {
                    if (this.noteId) {
                      this.getNote()
                    }
                  }
                });
              } else {
                // se non sto creando un appunto per un utente specifico prendo la lista utenti
                this.getUsers();
              }
            }
          );
      }
    });
  }


  ngOnInit() {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'privateNotes.createUpdate.WHAT_TO_NOTE',
        'privateNotes.modals.WHAT_TO_NOTE_INFO',
        'privateNotes.createUpdate.WHY_NOTE',
        'privateNotes.modals.WHY_NOTE_INFO',
        'privateNotes.createUpdate.WHO_NOTE',
        'privateNotes.modals.WHO_NOTE_INFO',
      ])
      .subscribe(translations => {
        this.whatToNoteModal = {
          modalId: "pn001",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['privateNotes.createUpdate.WHAT_TO_NOTE'],
          text: translations['privateNotes.modals.WHAT_TO_NOTE_INFO']
        };
        this.whyNoteModal = {
          modalId: "pn002",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['privateNotes.createUpdate.WHY_NOTE'],
          text: translations['privateNotes.modals.WHY_NOTE_INFO']
        };
        this.whoNoteModal = {
          modalId: "pn013",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['privateNotes.createUpdate.WHO_NOTE'],
          text: translations['privateNotes.modals.WHO_NOTE_INFO']
        };
      })
  }


  // Recupara l'utente dal suo userId
  getPersonDetails() {
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.runningYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn003",
            text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.personDetails = personDetailsData.response;
          this.getNote();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pn004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  getNote() {
    this.isFetchingPrivateNotes = true;

    if (this.getPrivateNote$) {
      this.getPrivateNote$.unsubscribe();
    }
    this.getPrivateNote$ = this.managerService.getPrivateNote(this.noteId, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else if (data.response) {
          this.note = data.response;
          this.noteCopy = JSON.parse(JSON.stringify(this.note));
        }
        this.isFetchingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn018",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
          this.isFetchingPrivateNotes = false;
        })
  }


  createPrivateNote() {
    this.closeModalCreateUpdatePrivateNote();
    if (this.isObbligatoryDataInserted()) {
      this.isFetchingPrivateNotes = true;
      if (this.createPrivateNote$) {
        this.createPrivateNote$.unsubscribe();
      }
      let userIds = [];
      if (this.userId) {
        userIds = [this.userId];
      } else {
        userIds = this.selectedUserList.map((x: any) => x.id);
      }

      this.createPrivateNote$ = this.managerService.createPrivateNote([this.note], userIds)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn019",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            const messageObj = {
              modalId: "pn021",
              title: this.translate.instant("privateNotes.modals.NOTE_CREATED"),
              text: this.translate.instant("privateNotes.modals.NOTE_CREATED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser()
          }
          this.isFetchingPrivateNotes = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn020",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingPrivateNotes = false;
          })
    }
  }

  updatePrivateNote() {
    this.closeModalCreateUpdatePrivateNote();
    if (this.isObbligatoryDataInserted()) {
      this.isFetchingPrivateNotes = true;
      if (this.updatePrivateNote$) {
        this.updatePrivateNote$.unsubscribe();
      }

      this.updatePrivateNote$ = this.managerService.updatePrivateNote(this.note, this.userId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn016",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            const messageObj = {
              modalId: "pn022",
              title: this.translate.instant("privateNotes.modals.NOTE_UPDATED"),
              text: this.translate.instant("privateNotes.modals.NOTE_UPDATED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser()
          }
          this.isFetchingPrivateNotes = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn025",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingPrivateNotes = false;
          })
    }
  }

  whatChanged(text: string) {
    this.note.comment = text;
  }

  whyChanged(text: string) {
    this.note.motivation = text;
  }

  searchUsers() {
    this.getUsers(true);
  }


  // Ripristina i dai della lista utenti per l'applauso
  resetSendClapUsersData(notDeleteSelected?: boolean) {
    this.userCardData.fromRecord = 0;
    this.userCardData.numRecords = 16;
    this.userCardData.currentPage = 1;
    this.userCardData.counter = 0;
    this.userCardData.list = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }
  // Recupera una lista di utenti per gli applausi
  getUsers(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetSendClapUsersData(true);
    }
    // Avvio il loader
    this.isFetchingUsers = true;
    this.managerService.countTeamUsers(this.runningYear, this.searchUserText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.userCardData.counter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.userCardData.currentPage && this.userCardData.numRecords) {
                fromRecord = (this.userCardData.currentPage - 1) * this.userCardData.numRecords;
              } else {
                fromRecord = 0;
              }

              if (this.userCardData.counter) {
                return this.managerService.listTeamUsers(fromRecord, this.userCardData.numRecords, this.runningYear, this.searchUserText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "pn010",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isFetchingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "pn011",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.userCardData.list = UserDataUtils.getUserCardData(data.response) || [];
          }
          this.isFetchingUsers = false;
        }
        , (err: any) => {
          this.isFetchingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn012",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Cambia la paginazione alla lista degli utenti per applausi aggiunti
  usersPageChanged(newPage: any) {
    this.userCardData.currentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsers();
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  // Gestisce quando crei/aggiorni un'evidenza
  goToUpdatePrivateNote(isBack?: boolean) {
    if (!this.checkIfDataIsChanged() && isBack) {
      this.goBackBrowser();
    } else {
      this.formatModalCreateUpdatePrivateNote(isBack);
      this.openModalForCreateUpdatePrivateNote();
    }
  }

  formatModalCreateUpdatePrivateNote(isBack?: boolean) {
    this.modalCreateUpdatePrivateNoteId = this.getModalId(isBack);
    this.modalCreateUpdatePrivateNoteTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdatePrivateNoteSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdatePrivateNoteText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdatePrivateNoteTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
    this.modalCreateUpdatePrivateNoteTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
  }


  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'pn005';
    } else {
      if (isBack) {
        if (this.noteId && this.noteId.length) {
          return 'pn006';
        } else {
          return 'pn007';
        }
      } else {
        if (this.noteId) {
          return 'pn008';
        } else {
          return 'pn009';
        }
      }
    }
  }

  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('generic.GO_BACK');
      } else {
        if (this.noteId) {
          return this.translate.instant('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE');
        } else {
          return this.translate.instant('privateNotes.createUpdate.INSERT_PRIVATE_NOTE');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.noteId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('privateNotes.modals.YOU_ARE_INSERTING_A_NOTE');
    } else if (this.noteId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('privateNotes.modals.YOU_ARE_UPDATING_A_NOTE');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.noteId && this.noteId.length) {
        return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      } else {
        return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      }
    } else {
      if (isBack) {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_UPDATE_NOTE_BACK');
        } else {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_CREATE_NOTE_BACK');
        }
      } else {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_UPDATE_NOTE');
        } else {
          return this.translate.instant('privateNotes.modals.DO_YOU_WANT_CREATE_NOTE');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateNoteModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.modals.UPDATE_NOTE_BACK');
        } else {
          return this.translate.instant('privateNotes.modals.INSERT_NOTE_BACK');
        }
      } else {
        if (this.noteId && this.noteId.length) {
          return this.translate.instant('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE');
        } else {
          return this.translate.instant('privateNotes.createUpdate.INSERT_PRIVATE_NOTE');
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    if (this.userId) {
      return this.note && this.note.comment && this.note.comment.trim().length > 0 && this.note.motivation && this.note.motivation.trim().length > 0;
    } else {
      return this.note && this.note.comment && this.note.comment.trim().length > 0 && this.note.motivation && this.note.motivation.trim().length > 0 && this.selectedUserList && this.selectedUserList.length > 0;
    }
  }

  checkIfDataIsChanged() {
    return this.note && this.noteCopy && ((this.note.comment != this.noteCopy.comment) || (this.note.motivation != this.noteCopy.motivation));
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdatePrivateNote() {
    this.modalService.open('modalCreateUpdatePrivateNote');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdatePrivateNote() {
    this.modalService.close('modalCreateUpdatePrivateNote');
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getPrivateNote$) {
      this.getPrivateNote$.unsubscribe();
    }
    if (this.createPrivateNote$) {
      this.createPrivateNote$.unsubscribe();
    }
    if (this.updatePrivateNote$) {
      this.updatePrivateNote$.unsubscribe();
    }
  }
}