<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container
    *ngIf="!isLoadingYearList && !isLoadingPersonDetails && !isLoadingInterview && !isLoadingManagerInfo && personDetails">
    <header-dossier [user]="personDetails" (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [isChief]="isChief"
      (onNewInterview)="goToInterview()" (onAnnullInterview)="goToDeleteInterview($event)"  [interview]="interview"
      [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)">
    </header-dossier>
    <div class="main-content-wrapper" [ngClass]="{'no-sti-padding': !isFeedbackSection}">
      <ng-container *ngIf="isObjectiveSection && !isChief">
        <!-- Contenitore fase Goal setting -->
        <ng-container
          *ngIf="!isLoadingUserGoals && !isLoadingManagerInfo && !isFetchingMoveCompetence && !isLoadingQualified">
          <child-content>
            <!-- Performance level e calibration -->
            <ng-container *ngIf="!isLoadingMyEvaluation && !isLoadingUserCalibration">
              <ng-container *ngIf="calibrationActive">
                <!-- Post calibration -->
                <europ-accordion [title]="('performance.ACCORDION_TITLE' | translate)"
                  [statusData]="postCalibrationData.status" [tooltipModalMessage]="performanceLevelTooltip"
                  [isAccordionOpened]="performanceLevelOpened" [grayBackground]="true">
                  <div class="challenge-accordion-container no-padding">
                    <calibration-level [calibrationLevelValue]="postCalibrationData.level"
                      [calibrationLevelObservations]="postCalibrationData.observations"
                      [payout]="postCalibrationData.payout" [year]="selectedYear.id"
                      (onReadObservations)="openPerformanceLevelModal()" [sti]="postCalibrationData.stiAmount"
                      [isComplete]="postCalibrationData.isComplete || isProcessClosed"
                      [description]="('calibration.WAIT_PROCESS' | translate)">
                    </calibration-level>
                  </div>
                </europ-accordion>
              </ng-container>


              <!-- Performance Level -->
              <ng-container *ngIf="perfromanceLevelActive && !calibrationActive">
                <europ-accordion [title]="('performance.ACCORDION_TITLE' | translate)" [isChallengeAccordion]="true"
                  [tooltipModalMessage]="performanceLevelTooltip" [isAccordionOpened]="performanceLevelOpened"
                  [grayBackground]="true" [statusData]="">
                  <div class="challenge-accordion-container">
                    <ng-container *ngIf="!performanceLevelDone">
                      <no-data [title]="('performance.ACCORDION_TITLE' | translate)" [fullSizeImg]="true"
                        src="/assets/img/section/performance-level.svg"
                        [description]="('performance.ACCORDION_PLACEHOLDER' | translate)">
                      </no-data>
                      <ng-container *ngIf="!performanceLevelDone">
                        <div class="button-container"
                          *ngIf="currentContentTab && currentContentTab.id == 'INDIVIDUAL_TAB'">
                          <europ-button type="primary" size="medium" [label]="'performance.DEFINE_LEVEL' | translate"
                            (onButtonClicked)="definePerformanceLevel()">
                          </europ-button>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="performanceLevelDone">
                      <ng-container *ngIf="!noEvaluationPost">
                        <no-data [title]="('performance.ACCORDION_TITLE' | translate)" [fullSizeImg]="true"
                          src="/assets/img/section/performance-level.svg"
                          [performanceLevelValue]="performanceLevelEvaluation"
                          [description]="('performance.POST_EVALUATION_PLACEHOLDER' | translate)">
                        </no-data>
                        <!-- Valutazione utente -->
                        <div class="valutation-container" *ngIf="performanceLevelEvaluation">
                          <div class="final-evaluation-row white-background">
                            <div class="left-side">
                              <avatar-img [user]="loggedUser" size="small"></avatar-img>
                              <p (click)="openFinalEvaluationModal(null, true, true)" class="user-name">
                                {{'final.SELF_OBS_BY' | translate }}
                                {{ loggedUser.forename[0] + '. ' + loggedUser.surname }}
                              </p>
                              <evaluation [evaluation]="performanceLevelEvaluation" [scale]="5" customClass="medium">
                              </evaluation>
                            </div>
                            <p class="compiled">
                              {{ ('final.COMPILED_IN' | translate) + ( performanceLevelEvaluationDate | date:
                              'dd.MM.YYYY') }}
                            </p>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="noEvaluationPost">
                        <no-data [title]="('performance.ACCORDION_TITLE' | translate)" [fullSizeImg]="true"
                          src="/assets/img/section/performance-level.svg"
                          [performanceLevelValue]="performanceLevelEvaluation"
                          [description]="('performance.POST_NO_EVALUATION_PLACEHOLDER' | translate)">
                        </no-data>
                      </ng-container>
                    </ng-container>
                  </div>
                </europ-accordion>
              </ng-container>

            </ng-container>


            <!-- Sfida -->
            <ng-container *ngIf="challengeList && challengeList.length">
              <ng-container *ngFor="let challenge of challengeList">
                <goal-card [isOperations]="true" [isInnerHtml]="true" [goal]="challenge"
                  (onDetailClicked)="onChallengeDetailClicked($event)"
                  (finalEvaluationClicked)="openFinalEvaluationModal($event, false)">
                </goal-card>
              </ng-container>
            </ng-container>
            <europ-accordion [isNew]="numberOfNews ? true : false" [title]="careAccordionTitle"
              [tooltipModalMessage]="operationsTooltipModal"  [isChallengeAccordion]="true"
              [isAccordionOpened]="careAccordionOpened" [statusData]="careAccordionStatus">
              <div class="accordion-content">
                <!-- No data placeholder -->
                <ng-container *ngIf="noData || !hasDoneQualification">
                  <no-data [title]="('collaboratorOperations.CARE_PLACEHOLDER_TITLE' | translate)" [src]="noDataImage"
                    [description]="noDataPlaceholder">
                  </no-data>
                </ng-container>
                <!-- Post qualifica -->
                <ng-container *ngIf="!noData && hasDoneQualification">
                  <!-- Messaggio post valutazione -->
                  <div *ngIf="!noData && hasDoneQualification && !numberOfNews" class="message-container">
                    <svg-icon src="./assets/img/section/op-competence-qualified.svg" class="section-icon"
                      [applyClass]="true"></svg-icon>
                    <div class="message-content">
                      <p class="title" translate="collaboratorOperations.QUALIFIED_COMPETENCE">
                      </p>
                      <p class="descr" [innerHTML]="qualifiedMessage"></p>
                    </div>
                  </div>
                  <!-- Messaggio dei feedbacks -->
                  <div *ngIf="!noData && hasDoneQualification && numberOfNews" class="feedback-message-container">
                    <div class="circle-div"></div>
                    <p>
                      {{managerData.forename + ('collaboratorOperations.HAS_INSERTED' | translate)
                      + numberOfNews +
                      ('collaboratorOperations.FEEDBACK' | translate)}}
                    </p>
                  </div>
                  <!-- Mi sento forte in -->
                  <div class="competence-level-section">
                    <svg-icon class="level-image" src="/assets/img/icons/radio/feel-strong.svg" [applyClass]="true">
                    </svg-icon>
                    <div class="level-content">
                      <p class="level-title" translate="qualifyCompetences.FEEL_STRONG"></p>
                      <div class="tag-competence-content">
                        <ng-container *ngFor="let competence of strengthCompetences; trackBy competenceTagId">
                          <tag-competence [isChallengeCreated]="challengeList && challengeList.length"
                            [isComplete]="competence.isComplete" [isEditable]="competence.isEdit && isInCurrentYear()"
                            [leftIcon]="competence.isCompleteWithIconComment ? 'assets/img/icons/chat.svg' : ''"
                            [title]="competence.title"
                            (onOptionClicked)="onCompetenceOptionsClicked($event, competence)">
                          </tag-competence>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <!-- La mia zona di comfort -->
                  <div class="competence-level-section">
                    <svg-icon class="level-image" src="/assets/img/icons/radio/comfort.svg" [applyClass]="true">
                    </svg-icon>
                    <div class="level-content">
                      <p class="level-title" translate="qualifyCompetences.COMFORT_ZONE"></p>
                      <div class="tag-competence-content">
                        <ng-container *ngFor="let comfort of comfortCompetences; trackBy competenceTagId">
                          <tag-competence [isChallengeCreated]="challengeList && challengeList.length"
                            [isComplete]="comfort.isComplete" [isEditable]="comfort.isEdit && isInCurrentYear()"
                            [leftIcon]="comfort.isCompleteWithIconComment ? 'assets/img/icons/chat.svg' : ''"
                            [title]="comfort.title" (onOptionClicked)="onCompetenceOptionsClicked($event, comfort)">
                          </tag-competence>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <!-- Vorrei migliorare in -->
                  <div class="competence-level-section last-container">
                    <svg-icon class="level-image" src="/assets/img/icons/radio/get-better.svg" [applyClass]="true">
                    </svg-icon>
                    <div class="level-content">
                      <p class="level-title" translate="qualifyCompetences.IMPROVE"></p>
                      <div class="tag-competence-content">
                        <ng-container *ngFor="let competence of improvementCompetences; trackBy competenceTagId">
                          <tag-competence [isChallengeCreated]="challengeList && challengeList.length"
                            [title]="competence.title" [isComplete]="competence.isComplete"
                            [isEditable]="competence.isEdit && isInCurrentYear()"
                            [leftIcon]="competence.isCompleteWithIconComment ? 'assets/img/icons/chat.svg' : ''"
                            (onOptionClicked)="onCompetenceOptionsClicked($event, competence)">
                          </tag-competence>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="button-container"
                  *ngIf="!hasDoneQualification && !isInFinalEvaluationPhase() && isInCurrentYear()">
                  <europ-button type="primary" [label]="'collaboratorOperations.QUALIFY_SKILL' | translate"
                    (onButtonClicked)="goToQualifySkill()">
                  </europ-button>
                </div>
              </div>
            </europ-accordion>


            <!-- Sezione KPI e competenze tecniche -->
            <goal-card *ngIf="kpiGoal" [goal]="kpiGoal" (onDetailClicked)="onGoalDetailClicked($event)">
            </goal-card>
            <goal-card *ngIf="techGoal" [goal]="techGoal" (onDetailClicked)="onGoalDetailClicked($event)">
            </goal-card>

            <div class="button-container"
              *ngIf="!noData && hasDoneQualification && !devGoalShared() && isInCurrentYear()">
              <europ-button type="secondary" [label]="'collaboratorOperations.SHARE_WITH_MANAGER' | translate"
                (onButtonClicked)="shareObjectives()">
              </europ-button>
            </div>

            <!-- Accordion obiettivi di ruolo -->
            <ng-container
              *ngIf="!isLoadingUserGoals && currentContentTab.id=='INDIVIDUAL_TAB' && goalList && goalList.length">
              <europ-accordion
                [title]="currentContentTab.goalTypes && currentContentTab.goalTypes[0] && currentContentTab.goalTypes[0].accordionTitle"
                [tooltipModalMessage]="roleGoalsTooltipModal" [isChallengeAccordion]="true"
                [isAccordionOpened]="goalAccordionOpened">
                <div class="challenge-accordion-container">

                  <!-- Contenitore obiettivi manager -->
                  <ng-container *ngFor="let goal of goalList">
                    <goal-card [goal]="goal" (onDetailClicked)="onGoalDetailClicked($event)"
                      (finalEvaluationClicked)="openFinalEvaluationModal($event, false)">
                    </goal-card>
                  </ng-container>
                  <!-- Placeholder se non ho obiettivi manager -->
                  <ng-container *ngIf="!goalList || !goalList.length">
                    <no-data [title]="('goal.PAGE_TITLE' | translate)" src="/assets/img/section/goal-setting.svg"
                      [description]="noDataPlaceholder">
                    </no-data>
                  </ng-container>
                </div>
              </europ-accordion>
            </ng-container>
          </child-content>
        </ng-container>

        <!-- Caricamento tab child content -->
        <ng-container
          *ngIf="isLoadingUserGoals || isLoadingManagerInfo || isFetchingMoveCompetence || isLoadingQualified">
          <child-content>
            <div class="loader spinner-medium"></div>
          </child-content>
        </ng-container>
        <!-- Se il profilo utente non è definito-->
        <ng-container *ngIf="!isLoadingTab && (!contentTabList || !contentTabList.length)">
          <child-content>
            <no-data [title]="('generic.WARNING' | translate)" src="/assets/img/section/goal-setting.svg"
              [description]="'errors.USER_TAB_NOT_FOUND' | translate">
            </no-data>
          </child-content>
        </ng-container>
      </ng-container>

      <!-- Contenitore dati caricati sezione feedback -->
      <ng-container *ngIf="isFeedbackSection">
        <child-content *ngIf="!isLoadingFeedbackTabsCounters" [tabList]="contentFeedbackTabList"
          (onTabChanged)="onContentFeedbackTabChanged($event)">
          <!-- Tab applausi / Smart feedback -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='CLAP_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingFeedbackClaps">
              <div class="loader spinner-medium"></div>
            </ng-container>
            <!-- Lista applausi -->
            <ng-container *ngIf="!isLoadingFeedbackClaps">
              <div class="sent-received-tab-container">
                <tab-light [tabTitle]="('feedbackSection.RECEIVED' | translate)" [isDisabled]="!isReceiving"
                  (onClick)="changeApplauseTab(true)"></tab-light>
                <tab-light [tabTitle]="('feedbackSection.SENT' | translate)" [isDisabled]="isReceiving"
                  (onClick)="changeApplauseTab(false)"></tab-light>
              </div>
              <div *ngIf="feedbackClapList && feedbackClapList.length" class="challenge-accordion-container">
                <ng-container *ngFor="let clap of feedbackClapList">
                  <card-feedback [isClap]="true" [loggedUser]="loggedUser" [readonly]="!isInCurrentYear()"
                    (onEditObject)="goToEditClap($event, clap.recipientUser)" [isReceiving]="isReceiving"
                    (onDeleteObject)="goToDeleteClap($event, clap.recipientUser)" [object]="clap"
                    [isHistory]="!isInCurrentYear()">
                  </card-feedback>
                </ng-container>
              </div>
              <!-- Placeholder nessun applauso -->
              <ng-container *ngIf="!isLoadingTab && !feedbackClapList || !feedbackClapList.length">
                <!-- Nessun applauso ricevuto -->
                <ng-container *ngIf="isReceiving">
                  <no-data [title]="('feedbackSection.smartFeedback.PAGE_TITLE' | translate)"
                    src="/assets/img/section/clap-section-placeholder.svg"
                    [description]="('feedbackSection.smartFeedback.COLLABORATOR_RECEIVED_PLACEHOLDER' | translate)">
                  </no-data>
                </ng-container>
                <!-- Nessun applauso inviato -->
                <ng-container *ngIf="!isReceiving">
                  <no-data [title]="('feedbackSection.smartFeedback.PAGE_TITLE' | translate)"
                    src="/assets/img/section/clap-section-placeholder.svg"
                    [description]="('feedbackSection.smartFeedback.COLLABORATOR_SENT_PLACEHOLDER' | translate)">
                  </no-data>
                </ng-container>
              </ng-container>
              <!-- Pulsanti -->
              <div class="button-container development" *ngIf="isInCurrentYear()">
                <europ-button type="primary" [label]="('feedbackSection.SEND_CLAP' | translate)"
                  (onButtonClicked)="sendClap()"></europ-button>
              </div>
            </ng-container>
          </ng-container>

          <!-- Tab evidenze-->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='EVIDENCES_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingFeedbackEvidences">
              <div class="loader spinner-medium"></div>
            </ng-container>
            <ng-container *ngIf="!isLoadingFeedbackEvidences">
              <div *ngIf="evidencesList && evidencesList.length" class="challenge-accordion-container">
                <ng-container *ngFor="let evidence of evidencesList">
                  <card-evidence [object]="evidence" [loggedUser]="loggedUser" [isHistory]="!isInCurrentYear()"
                    (onDeleteFile)="deleteFileOnEvidence($event, evidence.feedbackId)"
                    (onEdit)="goToEditEvidence($event)" (onUploadFile)="onFileUpload(evidence.feedbackId)"
                    (onFileDownload)="downloadFileOnEvidences($event, evidence.feedbackId)"
                    [isHistory]="!isInCurrentYear()">
                  </card-evidence>
                </ng-container>
              </div>
              <!-- Placeholder se non ho evidenze -->
              <ng-container *ngIf="!evidencesList || !evidencesList.length">
                <no-data *ngIf="managerData" [title]="('evidences.PLACEHOLDER_PAGE_TITLE' | translate)"
                  src="/assets/img/section/share-goal-female.svg"
                  [description]="('evidences.PLACEHOLDER_PAGE_DESCRIPTION_COLLABORATOR' | translate)">
                </no-data>
              </ng-container>
              <!-- Pulsanti -->
              <div class="button-container development" *ngIf="isInCurrentYear()">
                <europ-button type="primary" [label]="('evidences.INSERT_EVIDENCE' | translate)"
                  (onButtonClicked)="goToAddEvidence()"></europ-button>
              </div>
            </ng-container>
          </ng-container>

          <!-- Appunti privati -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='PRIVATE_NOTES_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingPrivateNotes">

              <!-- Lista appunti privati -->
              <ng-container *ngIf="privateNotesList && privateNotesList.length">
                <card-notes *ngFor="let note of privateNotesList" [note]="note" [loggedUser]="loggedUser"
                  (onDelete)="openDeleteNoteModal($event)" (onEdit)="goToEditPrivateNote($event)"></card-notes>
              </ng-container>

              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                <no-data [title]="('privateNotes.TITLE' | translate)"
                  src="/assets/img/section/private-notes-placeholder.svg"
                  [description]="('privateNotes.collaborator.PLACEHOLDER' | translate)">
                </no-data>
              </ng-container>

              <!-- Pulsanti -->
              <div class="button-container development">
                <europ-button type="primary" [label]="('privateNotes.INSERT_NOTE' | translate)"
                  (onButtonClicked)="createPrivateNote()"></europ-button>
              </div>
            </ng-container>

          </ng-container>

          <!-- Colloqui -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='INTERVIEW_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingInterviewsList">
              <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingInterviewsList">

              <!-- Lista Colloqui -->
              <ng-container *ngIf="interviewList && interviewList.length">
                <card-interview *ngFor="let interview of interviewList" [interview]="interview"
                  (onCancel)="goToDeleteInterview($event)"></card-interview>
              </ng-container>

              <!-- Placeholder se non Colloqui -->
              <ng-container *ngIf="!interviewList || !interviewList.length">
                <no-data [title]="('interview.TITLE' | translate)" src="/assets/img/section/feedback.svg"
                  [description]="('interview.PLACEHOLDER' | translate)">
                </no-data>
              </ng-container>

              <!-- Pulsanti -->
              <div class="button-container development" *ngIf="isInCurrentYear()">
                <europ-button type="primary" [label]="('interview.CREATE_INTERVIEW' | translate)"
                  (onButtonClicked)="goToInterview()"></europ-button>
              </div>
            </ng-container>
          </ng-container>

          <!-- Peer Feedback -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='PEER_FEEDBACK_TAB'">
            <!--ng-container *ngIf="peerFeedbackAllowed">
              <div class="sent-received-tab-container">
                <tab-light [tabTitle]="('peerFeedback.SENT' | translate)" [isDisabled]="!peerSent"
                  (onClick)="changePeerTab(true)"></tab-light>
                <tab-light [tabTitle]="('peerFeedback.RECEIVED' | translate)" [isDisabled]="peerSent"
                  (onClick)="changePeerTab(false)"></tab-light>
              </div>
            </ng-container-->

            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPeerFeedback">
              <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingPeerFeedback">

              <!-- Lista peerfeedback -->
              <ng-container *ngIf="peerFeedbackList && peerFeedbackList.length">
                <card-peer *ngFor="let feedback of peerFeedbackList" [object]="feedback" [loggedUser]="personDetails"
                  [isHistory]="!isInCurrentYear()" (onFeedbackSend)="goToPeerFeedbackAnswer($event)" [isManager]="false"
                  (onEdit)="createPeerFeedback($event)"
                  [isReceived]="feedback.approverFeedbackUser.userId == loggedUser.userId"
                  (onShowRequest)="openShowFeedbackRequest($event)" (onDelete)="openDeletePeerFeedbackModal($event)">
                </card-peer>
              </ng-container>

              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!peerFeedbackList || !peerFeedbackList.length">
                <no-data [title]="('peerFeedback.TITLE' | translate)"
                  src="/assets/img/section/private-notes-placeholder.svg"
                  [description]="peerFeedbackAllowed ? peerFeedbackPlaceholder : 'Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità.'">
                </no-data>
              </ng-container>

              <!-- Pulsanti -->
              <div class="button-container development" *ngIf="peerFeedbackAllowed && isInCurrentYear()">
                <europ-button type="primary" [label]="('peerFeedback.createUpdate.INSERT_FEEDBACK' | translate)"
                  (onButtonClicked)="createPeerFeedback()"></europ-button>
              </div>
            </ng-container>
          </ng-container>

        </child-content>


        <ng-container *ngIf="isLoadingFeedbackTabsCounters">
          <child-content>
            <div class="loader spinner-medium"></div>
          </child-content>
        </ng-container>
      </ng-container>

      <!-- Contenitore dati caricati sezione piano di sviluppo -->
      <ng-container *ngIf="isIndividualPlanSection && !isChief">
        <child-content [isDevelopmentSection]="true">
          <!-- Loader contenuto -->
          <ng-container *ngIf="isLoadingDevelopmentActions || isLoadingDeleteDevelopAction">
            <div class="loader spinner-medium"></div>
          </ng-container>
          <!-- Lista azioni di sviluppo -->
          <ng-container *ngIf="!isLoadingDevelopmentActions && !isLoadingDeleteDevelopAction">
            <div *ngIf="developmentActionsList && developmentActionsList.length" class="challenge-accordion-container">
              <div class="development-action-box">
                <div class="box-content">
                  <div class="img-content"
                    style="background-image:url('assets/img/icons/homeIcons/development-plan-img.png')">
                  </div>
                  <div class="development-box-text">
                    <p class="title" translate="developmentPlan.PAGE_TITLE"></p>
                    <p class="description" [innerHTML]="'developmentPlan.BOX_DESCRIPTION_COLLABORATOR' | translate">
                    </p>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let action of developmentActionsList">
                <card-action [isCollaborator]="true" [object]="action" (onEditAction)="goToEditAction($event)"
                  (onUpdateStatusToDevelopAction)="openModalUpdateStatusDevelopAction($event)"
                  (onInsertCommentToDevelopAction)="openInsertCommentToDevelopAction($event)"
                  (onReadComment)="showActionComment($event)" (onDeleteAction)="openDeleteModalDevelopAction($event)"
                  (onDeleteComment)="openDeleteCommentModalDevelopAction($event)"
                  (onEditComment)="openEditCommentToDevelopAction($event)" [loggedUserId]="userId"
                  [isHistory]="!isInCurrentYear()">
                </card-action>
              </ng-container>
            </div>
            <!-- Placeholder se non ho azioni di sviluppo -->
            <ng-container *ngIf="!developmentActionsList || !developmentActionsList.length">
              <no-data [title]="('developmentPlan.PAGE_TITLE' | translate)"
                src="/assets/img/section/development-plan.svg" [description]="noDataDevelopmentActionsPlaceholder">
              </no-data>
            </ng-container>
            <!-- Pulsanti -->
            <div class="button-container development" *ngIf="isInCurrentYear()">
              <europ-button [disabled]="isLoadingCanCreateDevelopmentActions" type="primary"
                [label]="'developmentPlan.ADD_ACTION' | translate" (onButtonClicked)="addDevelopmentAction()">
              </europ-button>
              <europ-button [disabled]="isFetchingCanShareActions" (onButtonClicked)="openDevelopActionsShareModal()"
                *ngIf="developmentActionsList && developmentActionsList.length && canShareDevActions" type="secondary"
                [label]="'developmentPlan.SHARE_DEVELOPMENT_ACTIONS' | translate">
              </europ-button>
            </div>
          </ng-container>
        </child-content>
      </ng-container>
    </div>

  </ng-container>
  <!-- Loader caricamento dati utente -->
  <ng-container
    *ngIf="isObjectiveSection && (isLoadingPersonDetails || isLoadingInterview || isLoadingManagerInfo) || isLoadingYearList">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale condivisione -->
<modal id="confirmShareModal" (onClose)="closeShareModal()" (onConfirm)="confirmShareModal()"
  confirmLabel="{{ 'generic.SHARE' | translate}}">
  <modal-text-content [noBackground]="true" [image]="shareModalContent.image" [title]="shareModalContent.title"
    [subtitle]="shareModalContent.subtitle" [text]="shareModalContent.text">
  </modal-text-content>
</modal>

<!-- Modale per errore condivisione -->
<modal id="errorShareModal" (onClose)="closeErrorModal()" (onConfirm)="closeErrorModal()"
  confirmLabel="{{ 'generic.GOT_IT' | translate}}">
  <modal-text-content [noBackground]="true" [image]="shareModalContent.image" [title]="shareModalContent.title"
    [subtitle]="shareModalContent.subtitle" [text]="shareModalContent.text">
  </modal-text-content>
</modal>

<!-- Modale per annullare il colloquio -->
<modal id="deleteInterviewModal" [cancelLabel]="'No'" (onCancel)="closeModalDeleteInterview()"
  (onClose)="closeModalDeleteInterview()" (onConfirm)="deleteInterview()" confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="601" title="{{ 'goalSetting.organizeInterview.DELETE_INTERVIEW' | translate}}"
    text="{{ 'goalSetting.organizeInterview.DELETE_INTERVIEW_TEXT' | translate}}">
  </modal-text-content>
</modal>


<modal id="moveCompetence" (onClose)="closeMoveCompetenceModal()" confirmLabel="{{ 'generic.EDIT' | translate}}"
  (onConfirm)="moveCompetence()">
  <div class="competence-detail-modal-wrapper" *ngIf="competenceForModal">
    <p class="title" translate="collaboratorOperations.EDIT_COMPETENCE"></p>
    <div class="description-wrapper">
      <div class="care-content"
        [innerHTML]="'<h3>' + competenceForModal.title + '</h3>' + competenceForModal.description">
      </div>
    </div>
    <!-- Contenitore card valutazione -->
    <div class="card-wrapper">
      <card-radio class="full-width" (onItemChange)="setCompetenceEvaluation($event)"
        [bindValue]="competenceForModal.evaluation"></card-radio>
    </div>
  </div>
</modal>

<!-- Modale dettaglio competenza -->
<modal id="competenceDetails" (onClose)="closeCompetenceDetailsModal()" (onConfirm)="closeCompetenceDetailsModal()"
  confirmLabel="{{'generic.CLOSE' | translate}}">
  <div class="competence-detail-modal-wrapper">
    <p class="title" translate="managerOperations.modals.COMPETENCE_DETAIL"></p>
    <div class="description-wrapper" [ngClass]="{'min-height100': isFetchingGetGoalById}">
      <div *ngIf="competenceForModal" class="care-content"
        [innerHTML]="'<h3>' + (competenceForModal && competenceForModal.behaviorTag && competenceForModal.behaviorTag.title) + '</h3>' + (competenceForModal.behaviorTag && competenceForModal.behaviorTag.description)">
      </div>
    </div>
    <input-container [isWithoutInfoIcon]="true" [isLoading]="isFetchingGetGoalById" [readonly]="true"
      title="{{('collaboratorOperations.FEEDBACK_OF' | translate) + (managerData && managerData.forename)}}">
      <p>{{competenceForModal.managerComment || ('collaboratorOperations.NOT_PRESENT_MANAGER_COMMENT' |
        translate)}}</p>
    </input-container>
  </div>
</modal>

<!-- Modale per eliminare l'applauso -->
<modal id="deleteClapModal" [cancelLabel]="'No'" (onCancel)="closeDeleteClapModal()" (onClose)="closeDeleteClapModal()"
  (onConfirm)="deleteClap()" confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="1001d" title="{{ 'feedbackSection.DELETE_CLAP' | translate}}"
    text="{{ 'feedbackSection.DELETE_CLAP_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per aggiungere un commento all'azione di sviluppo -->
<modal id="insertCommentDevelopmentPlanModal"
  [isDisabled]="developAction && (!newActionComment || !newActionComment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeInserCommentModalDevelopAction(true)"
  (onClose)="closeInserCommentModalDevelopAction(true)" (onConfirm)="addCommentToDevelopAction()"
  confirmLabel="{{ 'developmentPlan.INSERT_COMMENT_ACTION' | translate}}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.INSERT_COMMENT_TITLE' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="developAction">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="newActionComment && newActionComment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.INSERT_COMMENT_TITLE' | translate}}">
      <europ-text-area [bindValue]="newActionComment" [maxlength]="600"
        (onModelChanged)="developActionCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per modificare un commento all'azione di sviluppo -->
<modal id="editCommentDevelopmentPlanModal"
  [isDisabled]="commentToEdit && (!commentToEdit.comment || !commentToEdit.comment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeEditCommentModalDevelopAction(true)"
  (onClose)="closeEditCommentModalDevelopAction(true)" (onConfirm)="updateCommentForDevelopAction()"
  confirmLabel="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate }}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="commentToEdit">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="commentToEdit.comment && commentToEdit.comment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}">
      <europ-text-area [bindValue]="commentToEdit.comment" [maxlength]="600"
        (onModelChanged)="developActionEditCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per cancellare il commento dell'azione di sviluppo -->
<modal id="deleteDevelopActionCommentModal" [cancelLabel]="'No'" (onCancel)="closeDeleteCommentModalDevelopAction()"
  (onClose)="closeDeleteCommentModalDevelopAction()" (onConfirm)="deleteCommentDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d032" title="{{ 'developmentPlan.DELETE_COMMENT' | translate}}"
    text="{{ 'developmentPlan.DELETE_COMMENT_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per aggiornare lo stato di avanzamento dell'azione di sviluppo -->
<modal id="updateStatusDevelopmentPlanModal" [isDisabled]="!developAction || !currentActionStatus"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeModalUpdateStatusDevelopAction()"
  (onClose)="closeModalUpdateStatusDevelopAction()" (onConfirm)="updateStatusDevelopAction()"
  confirmLabel="{{ 'developmentPlan.UPDATE_STATUS_ACTION' | translate}}">
  <modal-text-content modalId="d032" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.UPDATE_STATUS_MODAL_TITLE' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <input-container class="margin-bottom24" [instructions]="'developmentPlan.IMPOST_STATE' | translate"
    [isWithoutInfoIcon]="true" [isValued]="currentActionStatus && currentActionStatus.id" [isObbligatory]="true"
    title="{{'developmentPlan.UPDATE_STATUS_OF_THIS_ACTION' | translate}}">
    <europ-select [placeholder]="'developmentPlan.CHOOSE_STATUS_PLACEHOLDER' | translate"
      (onSelectedItem)="changeDevelopActionStatus($event)" [selectedItem]="currentActionStatus"
      [items]="developActionStatusesOptions" [searchable]="true" bindLabel="title"></europ-select>
  </input-container>
</modal>

<!-- Modale per condividere le azioni di sviluppo -->
<modal id="developActionsShareModal" (onCancel)="closeDevelopActionsShareModal()"
  (onClose)="closeDevelopActionsShareModal()" (onConfirm)="shareDevelopActions()"
  confirmLabel="{{ 'generic.SHARE' | translate}}" [cancelLabel]="'generic.ANNULL' | translate">
  <modal-text-content modalId="031" *ngIf="managerData" [noBackground]="true"
    image="assets/img/section/development-plan.svg" [title]="'developmentPlan.SHARE_DEVELOPMENT_PLAN' | translate"
    [subtitle]="'developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN' | translate"
    [text]="('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_1' | translate) + managerData.forename + ' ' + managerData.surname + ('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_2' | translate)">
  </modal-text-content>
</modal>

<!-- Modale per cancellare l'azione di sviluppo -->
<modal id="deleteDevelopmentAction" [cancelLabel]="'No'" (onCancel)="closeDeleteModalDevelopAction()"
  (onClose)="closeDeleteModalDevelopAction()" (onConfirm)="deleteDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d150" title="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION' | translate}}"
    text="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale aggiunta/modifica file -->
<modal id="uploadFileOnEvidences"
  [isDisabled]="(!fileObject || !fileObject.fileExtension || !fileTitle || !fileTitle.trim().length)"
  (onClose)="closeUploadFileModal()" (onConfirm)="confirmUploadFile()" [confirmLabel]="('generic.CONFIRM' | translate)">
  <div class="file-upload-modal-wrapper">
    <p class="title align-center" translate="generic.CHARGE_FILE"></p>
    <!-- Upload file -->
    <ng-container>
      <input-container [isWithoutInfoIcon]="true" [isValued]="fileTitle && fileTitle.trim().length"
        [isObbligatory]="true" title="{{'managerOperations.modals.FILE_TITLE' | translate}}">
        <europ-input [bindValue]="fileTitle" (onModelChanged)="onFileTitleChanged($event)"
          placeholder="{{'managerOperations.modals.TITLE_PLACEHOLDER' | translate}}">
        </europ-input>
      </input-container>
      <input-container [instructions]="'goalSetting.UPLOAD_FILES_ACCEPTED' | translate"
        [isValued]="fileObject && fileObject.fileExtension" [isWithoutInfoIcon]="true" [isObbligatory]="true"
        title="{{'managerOperations.modals.FILE_IN_AREA' | translate}}">
        <europ-upload [isToReset]="isToResetUploadComponent" (onUpload)="assignFile($event)"></europ-upload>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Modale elimina file -->
<modal id="deleteFileModal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeleteFileModalOnEvidence(false)" (onClose)="closeDeleteFileModalOnEvidence(false)"
  (onConfirm)="closeDeleteFileModalOnEvidence(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="opm017" [title]="'managerOperations.modals.DELETE_FILE_TITLE' | translate"
    [text]="'managerOperations.modals.DELETE_FILE_TEXT' | translate">
  </modal-text-content>
</modal>

<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale eliminazione peer feedback -->
<modal id="deletePeerFeedback" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeletePeerFeedbackModal()" (onClose)="closeDeletePeerFeedbackModal(false)"
  (onConfirm)="closeDeletePeerFeedbackModal(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pf013" [title]="'peerFeedback.modals.DELETE_FEEDBACK_TITLE' | translate"
    [text]="'peerFeedback.modals.DELETE_FEEDBACK_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale eliminazione peer feedback -->
<modal id="deletePeerFeedback" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeletePeerFeedbackModal()" (onClose)="closeDeletePeerFeedbackModal(false)"
  (onConfirm)="closeDeletePeerFeedbackModal(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pf013" [title]="'peerFeedback.modals.DELETE_FEEDBACK_TITLE' | translate"
    [text]="'peerFeedback.modals.DELETE_FEEDBACK_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale mostra richiesta peer feedback -->
<modal id="modalShowRequest" (onClose)="closeShowFeedbackRequest()" (onConfirm)="closeShowFeedbackRequest()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <div class="peer-feedback-modal-request">
    <div class="title-code-wrapper full-width">
      <div class="title-container" translate="peerFeedback.card.REQUEST_TITLE"></div>
    </div>
    <div class="confirm-request-content" *ngIf="feedbackForRequestModal">
      <div class="section users">
        <div class="title" translate="peerFeedback.modals.REQUEST_FROM"></div>
        <div class="content">
          <div class="mini-user-card">
            <avatar-img size="small" [user]="feedbackForRequestModal.recipientUser"></avatar-img>
            <p class="name">{{ feedbackForRequestModal.recipientUser.forename + ' ' +
              feedbackForRequestModal.recipientUser.surname}}</p>
          </div>
        </div>
      </div>
      <div class="section" *ngIf="feedbackForRequestModal.goal">
        <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
        <div class="content">{{ feedbackForRequestModal.goal && feedbackForRequestModal.goal.title}}</div>
      </div>
      <div class="section">
        <div class="title" translate="peerFeedback.modals.WHY"></div>
        <div class="content">{{ feedbackForRequestModal.request }}</div>
      </div>
    </div>
  </div>
</modal>

<!-- Modale per valutazione finale -->
<modal id="finalEvaluationModal" (onClose)="closeFinalEvaluationModal()" (onConfirm)="closeFinalEvaluationModal()"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <div class="final-evaluation-modal-container" *ngIf="finalEvaluationForModal">
    <p class="modal-title"
      translate="{{personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS' : 'final.TITLE'}}">
    </p>
    <div class="divisor-line"></div>
    <evaluation [evaluation]="finalEvaluationForModal.evaluationScore" customClass="big"></evaluation>
    <div class="divisor-line"></div>
    <div class="observation-container">
      <p class="title">
        {{ ('final.SELF_OBS_BY' | translate) + finalEvaluationForModal.user.forename + ' ' +
        finalEvaluationForModal.user.surname
        }}
      </p>
      <p class="descr">{{ ('final.INSERT_DATE' | translate) + (finalEvaluationForModal.creationDate | date:
        'dd/MM/YYYY') }}</p>
      <p class="title" translate="final.OBSERVATION"></p>
      <p class="descr">{{finalEvaluationForModal.observation}}</p>
    </div>
  </div>
</modal>


<!-- Modale per il performance level -->
<modal id="performance-level-modal" (onClose)="closerPerformanceLevelModal()"
  (onConfirm)="closerPerformanceLevelModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <ng-container *ngIf="postCalibrationData">
    <p class="modal-title">
      {{ ('calibration.modals.CONFIRM_EVAL' | translate)}}</p>
    <div class="confirmation-container calibration">
      <div class="observation-container">
        <p class="subtitle" translate="calibration.modals.OBSERVATIONS"></p>
        <p class="text">{{ postCalibrationData.observations || ('calibration.NO_OBSERVATION' | translate)}}</p>
      </div>
      <div class="value-container">
        <evaluation customClass="big" [scale]="5" [evaluation]="postCalibrationData.level"></evaluation>
        <ng-container *ngIf="postCalibrationData.payout">
          <div class="payout-label">
            <p>{{postCalibrationData.payout.title + ' ' + ('performance.modals.OF_PAYOUT' | translate)}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</modal>