<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="sendRevisionAction(true)" [isBackButton]="true"
      [title]="'midTerm.addMidTermReview.TITLE' | translate"
      [description]="'midTerm.addMidTermReview.DESCRIPTION' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserGoals && !isLoadingManagerInfo">
        <div *ngFor="let goal of goalList" fxLayout="column" class="goal-list">
          <objective-for-mid-term-review [goal]="goal" [isOperations]="isOperations" [isInnerHtml]="true">
          </objective-for-mid-term-review>
          <div class="divisor-vertical-line"></div>
        </div>
        <!-- Se sto facendo la review -->
        <ng-container *ngIf="!alreadySent">
          <!-- Stato avanzamento -->
          <div class="objective-feedback-cards-title" fxLayout="row" fxLayoutAlign="start center">
            <p class="text-label-form primary-color" translate="midTerm.addMidTermReview.FEEDBACK_CARDS_TITLE">
            </p>
            <p *ngIf="!cardSelected" class="text-label-form primary-color">*</p>
            <svg-icon (click)="openObjectiveFeedbackTooltip()" src="/assets/img/icons/info.svg"
              class="info-icon medium-size cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>
          <care-cards [isFromMidTermReview]="true" [items]="careCards" (onCheck)="onSelectCareCard($event)">
          </care-cards>
          <div class="divisor-vertical-line"></div>
          <!-- Osservazioni -->
          <input-container [isLoading]="isLoadingUserGoals" [tooltipModalMessage]="objectiveOsservationTooltipModal"
            [isValued]="osservation && osservation.trim().length" [isObbligatory]="true"
            title="{{'midTerm.addMidTermReview.OSSERVATION_TEXTAREA_TITLE' | translate}}">
            <europ-text-area [bindValue]="osservation" [maxlength]="600" (onModelChanged)="osservationChanged($event)"
              placeholder="{{'midTerm.addMidTermReview.OSSERVATION_TEXTAREA_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <div class="button-container">
            <europ-button (onButtonClicked)="sendRevisionAction()" [disabled]="isLoadingUserGoals"
              type="primary default" size="large" [label]="'midTerm.addMidTermReview.SEND_REVISION' | translate">
            </europ-button>
          </div>
        </ng-container>
        <!-- Review fatta, readonly dati + commento manager -->
        <ng-container *ngIf="alreadySent">
          <mid-term-recap [user]="loggedUser" [date]="userRevisionDate" [status]="statusForRecap"
            [observation]="osservation"></mid-term-recap>
        </ng-container>
        <!-- Risposta del manager alla review -->
        <ng-container *ngIf="managerAnswer">
          <div class="divisor-vertical-line"></div>
          <mid-term-recap [user]="managerData" [date]="managerRevisionDate" [observation]="managerOsservation">
          </mid-term-recap>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoadingUserGoals || isLoadingManagerInfo">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per card Mid Term Review -->
<modal id="objectiveFeedbackModalTooltip" (onConfirm)="closeObjectiveFeedbackTooltip()" [confirmLabel]="'OK'"
  (onClose)="closeObjectiveFeedbackTooltip()">
  <modal-text-content [modalId]="objectiveFeedbackTooltipModal.modalId" [title]="objectiveFeedbackTooltipModal.title"
    [text]="objectiveFeedbackTooltipModal.text">
  </modal-text-content>
</modal>

<!-- Modale per l'invio della revisione se non ci sono dati obbligatori -->
<modal id="modalCreateRevisionNoDataForCollaborator" (onCancel)="goBackBrowser()"
  (onClose)="closeModalCreateRevisionNoDataForCollaborator()"
  (onConfirm)="closeModalCreateRevisionNoDataForCollaborator()"
  cancelLabel="{{'goalSetting.createGoal.GO_BACK_YES' | translate}}"
  confirmLabel="{{'generic.NO_REMAIN_IN_PAGE' | translate}}">
  <modal-text-content [modalId]="'1052'"
    [title]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA' | translate) : ('evidences.OBBLIGATORY_DATA_NOT_INSERTED' | translate) "
    [text]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA_TEXT' | translate) : ('midTerm.addMidTermReview.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION' | translate)">
  </modal-text-content>
</modal>

<!-- Modale per l'invio della revisione -->
<modal id="modalCreateRevisionForCollaborator"
  (onCancel)="isFromBack ? goBackBrowser() : closeModalCreateRevisionForCollaborator()"
  (onClose)="closeModalCreateRevisionForCollaborator()" (onConfirm)="sendRevision()"
  [cancelLabel]="'generic.ANNULL' | translate" [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content [modalId]="'d1060'" [title]="'midTerm.addMidTermReview.CONFIRM_REVISION' | translate"
    [subtitle]="'midTerm.addMidTermReview.CONFIRM_REVISION_SUBTITLE' | translate"
    [text]="'midTerm.addMidTermReview.CONFIRM_REVISION_TEXT' | translate">
  </modal-text-content>
  <ng-container *ngIf="loggedUser && cardSelected">
    <div class="modal-recap-container">
      <mid-term-recap [user]="loggedUser" [observation]="osservation" [status]="cardSelected.id">
      </mid-term-recap>
    </div>
  </ng-container>
</modal>