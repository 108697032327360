import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentBannerPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as AdminActions from '../ngrx/admin.actions';
import { UrlService } from 'src/app/shared/services/url.service';


@Component({
  selector: 'app-admin-calibration-matrix',
  templateUrl: './calibration-matrix.component.html',
  styleUrls: ['./calibration-matrix.component.scss']
})
export class CalibrationMatrixComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];
  translations: any;

  searchedText: string = '';
  usersNumRecords: number = 100;
  usersFromRecord: number = 0;
  usersCurrentPage: number = 1;
  usersCounter: number = 0;

  isLoadingUsers: boolean = false;

  managerId: string = '';

  matrixData: any = {};
  keyList = [
    "UNSATISFACTORY",
    "IMPROVABLE",
    "MEET",
    "EXCEEDED",
    "EXCEPTIONAL"
  ]
  selectedUser: any;
  manager: any;
  userList: any;
  confirmCalibration$: any;
  noData: boolean = false;
  totalUsers: any;
  grayBox: number = 0;
  yellowBox: number = 0;
  greenBox: number = 0;
  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  filtersText: string = '';
  processYear: number = 0;
  totalUserIds: any[] = [];
  userIdList: any;
  payoutResponse: any;
  isLoadingPayoutOptions: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private urlService: UrlService,
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    const matrixData$: Observable<any> = this.store.select(fromApp.getMatrixData);
    const filtersText$: Observable<string> = this.store.select(fromApp.getFiltersText);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$, matrixData$, filtersText$])
      .subscribe(
        ([loggedUser, runningPhase, matrixData, filtersText]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;

            let sessionManager = sessionStorage.getItem('manager');
            if (sessionManager) {
              let tmpManager = JSON.parse(sessionManager);
              if (tmpManager) {
                this.manager = tmpManager;
              }
            }
            let sessionIds = sessionStorage.getItem('userIdList');
            if (sessionIds) {
              let tmpIdList = JSON.parse(sessionIds || '');
              // recupero la lista utenti (selezione libera)
              if (tmpIdList && tmpIdList.length) {
                this.userIdList = tmpIdList;
              } else {
                this.userIdList = null;
              }
            }

            this.filtersText = filtersText;

            this.matrixData = {
              "UNSATISFACTORY": [],
              "IMPROVABLE": [],
              "MEET": [],
              "EXCEEDED": [],
              "EXCEPTIONAL": [],
            }
            this.route.params.subscribe((params: Params) => {
              this.processYear = params.processYear
              this.getUsersList();
              // TODO sostituire se serve 
              // this.getPayoutOptions()
            })
          }
        });
  }

  ngOnInit() {
    this.translate.get([
      'calibration.menu.ACCESS',
      'calibration.menu.SHOW',
      'calibration.EDIT_PL',
      'calibration.ACCESS_USER_DATA'
    ]).subscribe((translations) => {
      this.translations = translations;
      this.menuOptions = [
        {
          id: 'edit',
          title: translations['calibration.EDIT_PL'],
          icon: '/assets/img/icons/edit.svg',
        },
        {
          id: 'access',
          title: translations['calibration.ACCESS_USER_DATA'],
          icon: '/assets/img/icons/clipboard.svg',
        }
      ]
      this.cdr.detectChanges();
    })
  }


  orderBy(fieldName: string) {
    // console.log("fieldName", fieldName);
  }

  // Apre il menù con le opzioni per il processo
  openOptionsMenu(process: any) {
    process.isMenuOpen = !process.isMenuOpen;
  }

  // ricerca
  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.filterList();
  }

  filterList() {
    this.isLoadingUsers = true;
    let tempMatrix: any = {
      "UNSATISFACTORY": [],
      "IMPROVABLE": [],
      "MEET": [],
      "EXCEEDED": [],
      "EXCEPTIONAL": [],
    }
    if (this.searchedText && this.searchedText.length) {
      let tempSearch = this.searchedText.toLowerCase();
      if (this.userList && this.userList.length) {
        for (let i = 0; i < this.userList.length; i++) {
          let user = this.userList[i];
          if (user.forename.toLowerCase().includes(tempSearch) || user.surname.toLowerCase().includes(tempSearch) || user.cid.toLowerCase().includes(tempSearch)) {
            if (user.calibrationRating) {
              let calibrationRating = user.calibrationRating == 'SOLID' ? 'MEET' : user.calibrationRating;
              tempMatrix[calibrationRating].push(JSON.parse(JSON.stringify(user)));
            } else if (user.finalEvaluationRating) {
              let finalEvaluationRating = user.finalEvaluationRating == 'SOLID' ? 'MEET' : user.finalEvaluationRating;
              tempMatrix[finalEvaluationRating].push(JSON.parse(JSON.stringify(user)));
            }
          }
        }
        this.noData = true;
        let check = this.userList.map((user: any) => user.finalEvaluationRating);
        for (let i = 0; i < check.length; i++) {
          if (!!check[i]) {
            this.noData = false;
          }
        }
      }
      this.matrixData = tempMatrix;
    } else {
      this.formatMatrixData();
    }
    this.isLoadingUsers = false;
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersNumRecords = item.id;
    this.getUsersList(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.matrixData = {
      "UNSATISFACTORY": [],
      "IMPROVABLE": [],
      "MEET": [],
      "EXCEEDED": [],
      "EXCEPTIONAL": [],
    }
  }


  getPayoutOptions() {
    this.isLoadingPayoutOptions = true;
    this.adminService.getPayoutRange()
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pl010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.payoutResponse = {};
          for (let i = 0; i < data.response.length; i++) {
            if (data.response[i].moreOrEqualPayout) {
              let tmpEvaluation = [];
              for (let j = data.response[i].moreOrEqualPayout; j < data.response[i].lessPayout; j++) {
                tmpEvaluation.push({
                  id: j,
                  title: j + '%'
                })
              }
              this.payoutResponse[data.response[i].finalEvaluation] = tmpEvaluation;
            } else {
              this.payoutResponse[data.response[i].finalEvaluation] = [{
                id: data.response[i].payout,
                title: data.response[i].payout + '%'
              }]
            }
          }
        }
        this.getUsersList();
        this.isLoadingPayoutOptions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pl011",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPayoutOptions = false;
      });
  }

  // Recupera una lista di utenti 
  getUsersList(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetUserData();
    }
    // Avvio il loader
    this.isLoadingUsers = true;
    this.adminService.countCalibrationUser(this.processYear, this.searchedText, this.manager && this.manager.userId ? this.manager.userId : undefined, null, this.userIdList)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersCounter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.usersCurrentPage && this.usersNumRecords) {
                fromRecord = (this.usersCurrentPage - 1) * this.usersNumRecords;
              } else {
                fromRecord = 0;
              }
              if (this.usersCounter) {
                return this.adminService.listCalibrationUser(this.processYear, this.searchedText, fromRecord, this.usersNumRecords, this.manager && this.manager.userId ? this.manager.userId : undefined, this.userIdList);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response && data.response.length) {
            this.userList = data.response;

            this.formatMatrixData()
          } else {
            // Nessun risultato
            this.matrixData = {
              "UNSATISFACTORY": [],
              "IMPROVABLE": [],
              "MEET": [],
              "EXCEEDED": [],
              "EXCEPTIONAL": [],
            }
            this.cdr.detectChanges();
            this.isLoadingUsers = false;
          }
        }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }


  // Formatta i dati per la matrice e controlla se ci sono utenti validi
  formatMatrixData() {
    this.totalUsers = 0;
    this.totalUserIds = [];
    this.matrixData = {
      "UNSATISFACTORY": [],
      "IMPROVABLE": [],
      "MEET": [],
      "EXCEEDED": [],
      "EXCEPTIONAL": [],
    }
    if (this.userList && this.userList.length) {
      for (let i = 0; i < this.userList.length; i++) {
        let user = this.userList[i];
        if (user.calibrationRating && user.calibrationStatus != "CALIBRATION_DISABLED") {
          this.totalUsers++;
          this.totalUserIds.push(user.userId)
          let calibrationRating = user.calibrationRating == 'SOLID' ? 'MEET' : user.calibrationRating;
          this.matrixData[calibrationRating].push(JSON.parse(JSON.stringify(user)));
        } else if (user.finalEvaluationRating && user.finalEvaluationStatus != "TO_COMPLETE" && user.finalEvaluationStatus != "DISABLED") {
          this.totalUsers++;
          this.totalUserIds.push(user.userId)
          let finalEvaluationRating = user.finalEvaluationRating == 'SOLID' ? 'MEET' : user.finalEvaluationRating;
          this.matrixData[finalEvaluationRating].push(JSON.parse(JSON.stringify(user)));
        }
      }

      this.grayBox = Math.round(((this.matrixData["UNSATISFACTORY"].length + this.matrixData["IMPROVABLE"].length) / this.totalUsers) * 100) || 0;
      this.yellowBox = Math.round((this.matrixData["MEET"].length / this.totalUsers) * 100) || 0;
      this.greenBox = Math.round(((this.matrixData["EXCEEDED"].length + this.matrixData["EXCEPTIONAL"].length) / this.totalUsers) * 100) || 0;

      this.noData = true;
      let check = this.userList.map((user: any) => user.finalEvaluationRating);
      for (let i = 0; i < check.length; i++) {
        if (!!check[i]) {
          this.noData = false;
        }
      }
    }
    this.isLoadingUsers = false;
  }

  // Gestisce lo spostamento dell'utente da una colonna all'altra
  // Nel caso in cui venga spostato in una colonna diversa dall'originale
  // salvo la nuova valutazione
  drop(event: CdkDragDrop<any[]>, key: string) {

    if (key == 'SOLID') {
      key = 'MEET';
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (event.previousContainer.id != event.container.id) {
        this.selectedUser.edited = true;
      }
      this.isLoadingUsers = true;

      let payout = null;
      if (this.selectedUser.stiAmout) {
        payout = this.payoutResponse[key][0]?.id;
      }

      let calibration = {
        calibrationId: this.selectedUser.calibrationId,
        userId: this.selectedUser.userId,
        adminObservation: this.selectedUser.managerObservation,
        adminObservationLevel: key,
        payout: payout,
        confirmed: false
      }

      this.adminService.setCalibration(this.processYear, [calibration])
        .subscribe((setEvaluation: SenecaResponse<any>) => {
          if (setEvaluation && setEvaluation.error || !setEvaluation.response) {
            let error = setEvaluation.error ? this.translate.instant("errors." + setEvaluation.error) : this.translate.instant('errors.SOMETHING_WENT_WRONG');
            const messageObj: ApplicationModalMessage = {
              modalId: "cal008",
              text: error,
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUsers = false;
          } else if (setEvaluation && setEvaluation.response) {
          }
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 3000);
          }

          this.grayBox = Math.round(((this.matrixData["UNSATISFACTORY"].length + this.matrixData["IMPROVABLE"].length) / this.totalUsers) * 100) || 0;
          this.yellowBox = Math.round((this.matrixData["MEET"].length / this.totalUsers) * 100) || 0;
          this.greenBox = Math.round(((this.matrixData["EXCEEDED"].length + this.matrixData["EXCEPTIONAL"].length) / this.totalUsers) * 100) || 0;
          this.isLoadingUsers = false;
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal009",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUsers = false;
        });
    }
  }

  // Ritorna le liste collegate alla colonna attuale
  getConnectedList(listKey: string) {
    let connectedList: any = [];
    for (let i = 0; i < this.keyList.length; i++) {
      if (this.keyList[i] != listKey) {
        connectedList.push('column-' + this.keyList[i]);
      }
    }
    return connectedList;
  }

  // Quando comincio a trascinare un utente lo salvo, in questo modo posso salvare le modifiche
  setSelected(user: any) {
    this.selectedUser = user;
  }

  // Salvo ad ogni spostamento degli utenti, quindi il conferma sessione di fatto è un back
  confirmSession() {
    this.redirectService.goBackBrowser();
  }

  // Conferma i dati della calibration per tutti gli utenti valutati
  confirmCalibration() {
    this.isLoadingUsers = true;
    if (this.confirmCalibration$) {
      this.confirmCalibration$.unsubscribe()
    }

    // prendo userId da lista utenti e filtro i valutati
    let userIds = []
    for (let i = 0; i < this.userList.length; i++) {
      if (!!this.userList[i].calibrationRating || !!this.userList[i].finalEvaluationRating) {
        userIds.push(this.userList[i].userId);
      }
    }

    this.confirmCalibration$ = this.adminService.massiveConfirmCalibration(this.processYear, userIds)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal012",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.redirectService.goBackBrowser();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal013",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsers = false;
      });
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.exportCalibrationReport(this.processYear, this.totalUserIds)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  openBoxDistributionTooltip() {
    this.modalService.open('box-distribution-tooltip');
  }

  closeBoxDistributionTooltip() {
    this.modalService.close('box-distribution-tooltip');
  }

  // per gestire clickOutside
  closeUserMenu(user: any) {
    user.isMenuOpen = false;
  }

  // Apre menu utente
  openUserMenu(user: any) {
    user.isMenuOpen = !user.isMenuOpen;
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, user: any, isConfirm?: boolean) {
    user.isMenuOpen = false;
    if (options.id == 'edit') {
      this.router.navigate(['admin/definePerformanceLevel/' + this.processYear + '/' + user.userId])
    } else {
      this.impersonate(user.userId)
    }
  }

  impersonate(userId: string) {
    this.isLoadingUsers = true;
    this.adminService.impersonateUserForAdmin(userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let url = this.urlService.getApplicationUrl();
          // const redirectUrl = 
          let redirectUrl = '';
          if (window.location.href.indexOf('localhost') >= 0) {
            redirectUrl = 'http://localhost:4200/#/impersonateRedirect?token=' + data.response;
          } else {
            redirectUrl = url.url + '/#/impersonateRedirect?token=' + data.response;
          }

          window.open(redirectUrl, '_blank');
        }
        this.isLoadingUsers = false;
      },
        (err) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a013",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        })
  }

  ngOnDestroy() {
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    if (this.confirmCalibration$) {
      this.confirmCalibration$.unsubscribe()
    }
  }
}
