/*
*  Componente che gestisce le notifiche dell'applicazione
*/

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SenecaResponse, GetNotificationListResponse, PerfCareNotification } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnDestroy {
  isFetchingNotificationsToRead: boolean = false;
  isFetchingNotificationsReaded: boolean = false;
  notificationsCounterReaded: number = 0;
  notificationsCounterToRead: number = 0;
  notificationsToRead: PerfCareNotification[] = [];
  notificationsReaded: PerfCareNotification[] = [];
  getNotificationsToRead$: Subscription = new Subscription();
  getNotificationsReaded$: Subscription = new Subscription();
  markNotificationAsRead$: Subscription = new Subscription();
  isFetchingMarkNotificationAsRead: boolean = false;
  notifyToDelete: any;
  isFetchingMarkNotificationAllAsRead: boolean = false;
  markNotificationAllAsRead$: Subscription = new Subscription();
  isFetchingDeleteNotification: boolean = false;
  deleteNotification$: Subscription = new Subscription();
  deleteAllNotifications$: Subscription = new Subscription();
  isFetchingDeleteNotificationAll: boolean = false;
  result$: Subscription = new Subscription();
  isImpersonate: boolean = false;
  runningYear: number = 0;

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService) {

    this.store.select(fromApp.getRunningYear)
      .subscribe((runningYear) => {
        this.runningYear = runningYear;
      })

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
  }

  ngOnInit() {
    this.onGetNotificationsToRead();
    this.onGetNotificationsReaded();
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Apri la modale di lettura notifica
  openDeleteModalNotification(notify: any) {
    this.notifyToDelete = notify;
    this.modalService.open("modalDeleteNotification");
  }

  // Chiude la modale di lettura notifica
  closeDeleteModalNotification() {
    this.notifyToDelete = null;
    this.modalService.close('modalDeleteNotification');
  }

  // Recupera la lista delle notifiche da leggere
  onGetNotificationsToRead() {
    this.isFetchingNotificationsToRead = true;

    this.getNotificationsToRead$ = this.authService.getNotificationList(0, 0, true, true, false)
      .subscribe((data: SenecaResponse<GetNotificationListResponse>) => {
        if (data && data.error) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
        } else if (data && data.response && data.response.notifications) {
          this.notificationsToRead = data.response.notifications;
        }
        this.isFetchingNotificationsToRead = false;
      },
        (err: string) => {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "084",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.isFetchingNotificationsToRead = false;
        })
  }

  // Vai al dettaglio notifica
  goToDetail(notify: any) {
    if (notify.notificationStatus == 'TO_READ') {
      this.notifyToDelete = notify;
      this.markNotificationAsRead(notify);
    } else {
      window.open(notify.action, "_self");
    }
  }

  // Recupera la lista delle notifiche lette
  onGetNotificationsReaded() {
    this.isFetchingNotificationsReaded = true;

    this.getNotificationsReaded$ = this.authService.getNotificationList(0, 0, true, false, true)
      .subscribe((data: SenecaResponse<GetNotificationListResponse>) => {
        if (data && data.error) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
        } else if (data && data.response && data.response.notifications) {
          this.notificationsReaded = data.response.notifications;
        }
        this.isFetchingNotificationsReaded = false;
      },
        (err: string) => {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "084",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.isFetchingNotificationsReaded = false;
        })
  }

  // Segna come letta una notifica da leggere
  markNotificationAsRead(notify?: any) {

    let notifyToDeleteId = this.notifyToDelete && this.notifyToDelete.notificationQueueId;
    this.closeDeleteModalNotification();
    this.isFetchingMarkNotificationAsRead = true;

    this.markNotificationAsRead$ = this.authService.markNotificationAsRead(notifyToDeleteId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
        } else {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.onGetNotificationsToRead();
          this.onGetNotificationsReaded();
          if (notify && notify.notificationStatus) {
            window.open(notify.action, "_self");
            this.notifyToDelete = null;
          }
        }
        this.isFetchingMarkNotificationAsRead = false;
      },
        (err: string) => {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "084",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.isFetchingMarkNotificationAsRead = false;
        })
  }

  // Chiudi la modale di lettura di tutte le notifiche
  closeReadAllNotificationsModalNotification() {
    this.modalService.close("modalReadAllNotifications");
  }

  // Apri la modale di lettura di tutte le notifiche
  openReadAllNotificationsModalNotification() {
    this.modalService.open("modalReadAllNotifications");
  }

  // Segna tutte le notifiche come lette
  markNotificationAllAsRead() {
    this.closeReadAllNotificationsModalNotification();
    this.isFetchingMarkNotificationAllAsRead = true;

    this.markNotificationAllAsRead$ = this.authService.markNotificationAllAsRead()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
        } else {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.onGetNotificationsToRead();
          this.onGetNotificationsReaded();
        }
        this.isFetchingMarkNotificationAllAsRead = false;
      },
        (err: string) => {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "084",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.isFetchingMarkNotificationAllAsRead = false;
        })
  }

  // Cancella una notifica già letta
  deleteNotification() {

    let notifyToDeleteId = this.notifyToDelete && this.notifyToDelete.notificationQueueId;
    this.closeDeleteModalNotificationReaded();
    this.isFetchingDeleteNotification = true;

    this.deleteNotification$ = this.authService.deleteNotificationByIds(notifyToDeleteId)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
        } else if (data && data.response) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.onGetNotificationsToRead();
          this.onGetNotificationsReaded();
        }
        this.isFetchingDeleteNotification = false;
      },
        (err: string) => {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "084",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.isFetchingDeleteNotification = false;
        })
  }

  // Cancella tutte le notifiche già lette
  deleteNotificationsAll() {
    this.closeDeleteModalNotificationReadedAll();
    this.isFetchingDeleteNotificationAll = true;

    this.deleteAllNotifications$ = this.authService.deleteAllNotifications()
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
        } else if (data && data.response) {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "083",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.onGetNotificationsToRead();
          this.onGetNotificationsReaded();
        }
        this.isFetchingDeleteNotificationAll = false;
      },
        (err: string) => {
          /* const messageObj: ApplicationModalMessage = {
            modalId: "084",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj })); */
          this.isFetchingDeleteNotificationAll = false;
        })
  }

  // Chiude la modale di elimina la notifiche già letta
  closeDeleteModalNotificationReaded() {
    this.notifyToDelete = null;
    this.modalService.close('modalDeleteNotificationReaded');
  }

  // Apre la modale di elimina la notifiche già letta
  openDeleteModalNotificationReaded(notify: any) {
    this.notifyToDelete = notify;
    this.modalService.open('modalDeleteNotificationReaded');
  }

  // Chiude la modale di elimina tutte le notifiche già lette
  closeDeleteModalNotificationReadedAll() {
    this.modalService.close('modalDeleteAllNotificationsReaded');
  }

  // Apre la modale di elimina tutte le notifiche già lette
  openDeleteModalNotificationReadedAll() {
    this.modalService.open('modalDeleteAllNotificationsReaded');
  }

  // Se è attivo il placeholder generico
  isActiveGeneralPlaceholder() {
    if ((!this.notificationsToRead || !this.notificationsToRead.length) && (!this.notificationsReaded || !this.notificationsReaded.length) && !this.isFetchingMarkNotificationAsRead && !this.isFetchingMarkNotificationAllAsRead && !this.isFetchingDeleteNotification && !this.isFetchingDeleteNotificationAll && !this.isFetchingNotificationsToRead && !this.isFetchingNotificationsReaded) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.getNotificationsToRead$) {
      this.getNotificationsToRead$.unsubscribe();
    }
    if (this.getNotificationsReaded$) {
      this.getNotificationsReaded$.unsubscribe();
    }
    if (this.markNotificationAsRead$) {
      this.markNotificationAsRead$.unsubscribe();
    }
    if (this.markNotificationAllAsRead$) {
      this.markNotificationAllAsRead$.unsubscribe();
    }
    if (this.deleteNotification$) {
      this.deleteNotification$.unsubscribe();
    }
    if (this.deleteAllNotifications$) {
      this.deleteAllNotifications$.unsubscribe();
    }

  }
}