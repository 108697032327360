import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { PerfCareUserPhaseGoal, GetPerformanceCareTagsResponse, SenecaResponse, CreateUserGoalForUserResponse, PerfCareGoalRentStatuses, PerfCareMacroGoalTypes, GetMyGoalByIdResponse, UpdateMyGoalResponse, GetBehaviorCareTagsResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { TabSection } from 'src/app/utils/classes.utils';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Tag, PerfCareTag } from '../../../commonclasses';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';

@Component({
  selector: 'app-collaborator-createUpdateGoal',
  templateUrl: './createUpdateGoal.component.html',
  styleUrls: ['./createUpdateGoal.component.scss']
})
export class CreateUpdateGoalCollaboratorComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  userId: string = '';
  personDetails: any;
  tabSectionsList: TabSection[] = [];
  goal: PerfCareUserPhaseGoal = {
    goalId: '',
    creationUserId: '',
    phaseId: '',
    userId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.DEVELOPMENT
  };
  isHowSection: boolean = false;
  isFetchingCareOptions: boolean = false;
  getCareOptions$: Subscription | null = null;
  careOptionsCounter: number = 0;
  careOptions: PerfCareTag[] = [];
  isFetchingBehaviourOptions: boolean = false;
  getBehaviourOptions$: Subscription | null = null;
  behaviourOptionsCounter: number = 0;
  behaviourOptions: PerfCareTag[] = [];
  goalCreated: CreateUserGoalForUserResponse = [];
  createGoal$: Subscription | null = null;
  isFetchingCreateGoal: boolean = false;
  isFetchingUpdateGoal: boolean = false;
  updateGoal$: Subscription | null = null;
  isFetchingGetGoal: boolean = false;
  getGoal$: Subscription | null = null;
  goalId: string = '';
  goalCopy: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.DEVELOPMENT
  };
  modalUpdateGoalTitle: string = '';
  modalUpdateGoalText: string = '';
  confirmModalUpdateGoalButton: any;
  isBackButton: boolean = false;
  goBackNoSave: boolean = false;
  charsGoalDescription: number = 600;
  charsGoalObjectiveDescription: number = 600;
  charsGoalHowToUseDescription: number = 600;
  charsGoalObjectiveRelevantMotivation: number = 600;
  maxTextAreaChars: number = 600;
  goToGoalObjectiveList: boolean = false;
  modalUpdateGoalTitleConfirmTextButton: string = '';
  modalUpdateGoalTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  isEdit: boolean = false;
  isSharedGoal: boolean = false;

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objCompletedTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  careTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  behaviourTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  applyCareTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalUpdateGoalId: string = '';
  isImpersonate: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;

              this.appStore.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.personDetails = loggedUser && loggedUser.user;
                    // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                    if (this.personDetails && this.personDetails.userId && this.goalId) {
                      this.isEdit = true;
                      this.getGoalById();
                    }
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.WHAT',
        'generic.HOW',
        'goalSetting.createGoal.WHAT_TAB',
        'goalSetting.createGoal.HOW_TAB',
        'goalSetting.createGoal.CARE_ATTRIBUTES',
        'goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION',
        'generic.TITLE',
        'generic.DESCRIPTION',
        'goalSetting.createGoal.OBJECTIVE_TITLE_COLLABORATOR',
        'goalSetting.createGoal.CHOOSE_TITLE_FOR_YOUR_CHALLENGE',
        'goalSetting.createGoal.CHALLENGE_DESCRIPTION',
        'goalSetting.createGoal.DESCRIBE_CHALLENGE_DESCRIPTION',
        'goalSetting.createGoal.OBJECTIVE_DESCRIPTION_COLLABORATOR',
        'goalSetting.createGoal.WHAT_AND_HOW',
        'goalSetting.createGoal.INSERT_OBJECTIVE_COLLABORATOR_DESCRIPTION'
      ])
      .subscribe(translations => {
        this.confirmModalUpdateGoalButton = document.getElementById('modalUpdateGoalForUser');
        this.tabSectionsList = [
          {
            id: 'whatTab',
            attrAriaLabel: translations['goalSetting.createGoal.WHAT_TAB'],
            title: translations['generic.WHAT'],
            isActive: true,
            isCompleted: this.isWhatTabCompleted()
          },
          {
            id: 'howTab',
            attrAriaLabel: translations['goalSetting.createGoal.HOW_TAB'],
            title: translations['generic.HOW'],
            isCompleted: this.isHowTabCompleted()
          }
        ];

        this.titleTooltipModal = {
          modalId: "127",
          title: translations['generic.TITLE'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_TITLE_COLLABORATOR'],
          text: translations['goalSetting.createGoal.CHOOSE_TITLE_FOR_YOUR_CHALLENGE']
        };

        this.descriptionTooltipModal = {
          modalId: "128",
          title: translations['generic.DESCRIPTION'],
          subtitle: translations['goalSetting.createGoal.CHALLENGE_DESCRIPTION'],
          text: translations['goalSetting.createGoal.DESCRIBE_CHALLENGE_DESCRIPTION']
        };
        this.objCompletedTooltipModal = {
          modalId: "129",
          title: translations['goalSetting.createGoal.OBJECTIVE_DESCRIPTION_COLLABORATOR'],
          subtitle: translations['goalSetting.createGoal.WHAT_AND_HOW'],
          text: translations['goalSetting.createGoal.INSERT_OBJECTIVE_COLLABORATOR_DESCRIPTION']
        };
        this.careTooltipModal = {
          modalId: "130",
          title: translations['goalSetting.createGoal.CARE_ATTRIBUTES'],
          text: translations['goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION']
        };
        this.formatBehaviorTextModal();
        /* this.behaviourTooltipModal = {
          modalId: "131",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };
        this.applyCareTooltipModal = {
          modalId: "132",
          title: "Informazioni input",
          subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius.",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        }; */
        this.modalUpdateGoalId = this.getModalSaveId();
        this.modalUpdateGoalTitle = this.getModalSaveTitle();
        this.modalUpdateGoalText = this.getModalSaveDescription();
        this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
        this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
      })
  }

  getModalSaveId() {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
      if (this.goalId && this.goalId.length) {
        return "162";
      } else {
        return "161";
      }
    } else {
      if (this.goalId && this.goalId.length) {
        return "160";
      } else {
        return "159";
      }
    }
  }

  // Formatta il testo della modale Behavior
  formatBehaviorTextModal(modalBehaviorText?: string) {
    this.behaviourTooltipModal = {
      modalId: "124",
      withScrolledBody: false,
      title: this.goal.careTag ? this.translate.instant('goalSetting.createGoal.BEHAVIORS') : this.translate.instant('goalSetting.createGoal.NO_CARE_ATTRIBUTE_SELECTED'),
      text: this.goal.careTag ? modalBehaviorText : this.translate.instant('goalSetting.createGoal.NO_CARE_ATTRIBUTE_SELECTED_DESCRIPTION'),
    }
    this.applyCareTooltipModal = {
      modalId: "125",
      title: this.goal.careTag ? this.translate.instant('goalSetting.createGoal.HOW_TO_USE_CARE_COLLABORATOR') : this.translate.instant('goalSetting.createGoal.NO_BEHAVIOR_ATTRIBUTE_SELECTED'),
      text: this.goal.careTag ? this.translate.instant('goalSetting.createGoal.HOW_TO_USE_CARE_COLLABORATOR_DESCRIPTION') : this.translate.instant('goalSetting.createGoal.NO_BEHAVIOR_ATTRIBUTE_SELECTED_DESCRIPTION'),
    };
  }

  // Testo per il modale Salva sfida
  getModalSaveTitle() {
    if ((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT))) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
        if (this.goalId && this.goalId.length) {
          return this.translate.instant('goalSetting.createGoal.UPDATE_GOAL_COLLABORATOR');
        } else {
          return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_COLLABORATOR');
        }
      } else {
        if (this.goalId && this.goalId.length) {
          return this.translate.instant('goalSetting.createGoal.UPDATE_GOAL_COLLABORATOR');
        } else {
          return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_COLLABORATOR_IN_DRAFT');
        }
      }
    }
  }

  // Descrizione per il modale Aggiorna sfida
  getModalSaveDescription() {
    if ((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT))) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_IS_SHARED_COLLABORATOR');
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
        if (this.goalId && this.goalId.length) {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL_COLLABORATOR');
        } else {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_COLLABORATOR');
        }
      } else {
        if (this.goalId && this.goalId.length) {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL_COLLABORATOR');
        } else {
          return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_IN_DRAFT_COLLABORATOR');
        }
      }
    }
  }

  // Testo per il modale Salva/aggiorna obiettivo quando schiacci Torna indietro
  getModalSaveTitleBack() {
    if ((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT))) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
        return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.SAVE_GOAL_IN_DRAFT_BACK');
      }
    }
  }

  // Descrizione per il modale Salva/aggiorna obiettivo quando schiacci Torna indietro
  getModalSaveDescriptionBack() {
    if ((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT))) {
      return this.translate.instant('goalSetting.createGoal.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_IS_SHARED_COLLABORATOR');
    } else {
      if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
        return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_BACK_COLLABORATOR');
      } else {
        return this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL_IN_DRAFT_BACK_COLLABORATOR');
      }
    }
  }

  // Testo per il pulsante Salva all'atto della creazione di un obiettivo
  getButtonSaveText() {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
      return this.translate.instant('goalSetting.createGoal.CREATE_GOAL_COLLABORATOR');
    } else {
      return this.translate.instant('goalSetting.createGoal.CREATE_GOAL_IN_DRAFT_COLLABORATOR');
    }
  }

  // Testo per il pulsante Salva all'atto della modifica di un obiettivo
  getButtonUpdateText() {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted() || this.isSharedGoal) {
      return this.translate.instant('generic.SAVE');
    } else {
      return this.translate.instant('goalSetting.createGoal.SAVE_IN_DRAFT');
    }
  }

  // Testo modale pulsante close
  getCancelLabelUpdateGoalModal(isBack?: boolean) {
    if (this.goalId && this.goalId.length) {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      }
    } else {
      if (isBack) {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      } else {
        return this.translate.instant('goalSetting.createGoal.GO_BACK');
      }
    }
  }

  // Testo modale pulsante conferma
  getConfirmLabelUpdateGoalModal(isBack?: boolean) {
    if (this.goalId && this.goalId.length) {
      if ((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT))) {
        return this.translate.instant('goalSetting.createGoal.YES_REMAIN_IN_PAGE');
      } else {
        if (isBack) {
          if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
            return this.translate.instant('generic.SAVE_AND_GO_BACK');
          } else {
            return this.translate.instant('generic.SAVE_AND_GO_BACK_DRAFT_COLLABORATOR');
          }
        } else {
          return this.translate.instant('goalSetting.createGoal.YES_UPDATE_CHALLENGE');
        }
      }
    } else {
      if (isBack) {
        if (this.isWhatTabCompleted() && this.isHowTabCompleted()) {
          return this.translate.instant('generic.SAVE_AND_GO_BACK');
        } else {
          return this.translate.instant('generic.SAVE_AND_GO_BACK_DRAFT_COLLABORATOR');
        }
      } else {
        return this.translate.instant('goalSetting.createGoal.YES_SAVE_CHALLENGE');
      }
    }
  }

  // Se non hai inserito nessun dato
  noDataInserted() {
    if (!this.goal.title && !this.goal.description && !this.goal.objectiveDescription &&
      !this.goal.careTag && !this.goal.behaviorTag && !this.goal.howToUseCareTag) {
      return true;
    }
    return false;
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateGoalModal() {
    this.modalUpdateGoalId = this.getModalSaveId();
    this.modalUpdateGoalTitle = this.getModalSaveTitle();
    this.modalUpdateGoalText = this.getModalSaveDescription();
    this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
    this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
    this.openModalForUpdateGoal();
  }

  // Quando switcho tab Cosa/Come
  onTabClicked(tab: TabSection) {
    switch (tab.id) {
      case 'whatTab':
        this.goToWhatSection()
        break;
      case 'howTab':
        this.goToHowSection()
        break;
    }
  }

  // Controlla se mettere completa la tab Cosa
  checkWhatTabCompleted() {
    this.tabSectionsList[0].isCompleted = this.isWhatTabCompleted();
  }

  // Controlla se mettere completa la tab Come
  checkHowTabCompleted() {
    this.tabSectionsList[1].isCompleted = this.isHowTabCompleted();
  }

  // Quando si scrive sulla textare della rilevante motivazione dell'obiettivo
  goalObjectRelevantMotivationChanged(text: string) {
    this.goal.objectiveRelevantMotivation = text;
    this.charsGoalObjectiveRelevantMotivation = this.maxTextAreaChars - text.length;
    this.checkHowTabCompleted();
  }

  // Quando si scrive sull'input del titolo obiettivo
  goalTitleChanged(text: string) {
    this.goal.title = text;
    this.checkWhatTabCompleted();
  }

  // Quando si scrive sulla textarea della descrizione obiettivo
  goalDescriptionChanged(text: string) {
    this.goal.description = text;
    this.charsGoalDescription = this.maxTextAreaChars - text.length;
    this.checkWhatTabCompleted();
  }

  // Quando si scrive sulla textarea della descrizione obiettivo(la seconda)
  goalObjectiveDescriptionChanged(text: string) {
    this.goal.objectiveDescription = text;
    this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
    this.checkWhatTabCompleted();
  }

  // Vai alla sezione Come
  goToHowSection() {
    this.isHowSection = true;
    this.tabSectionsList[1].isActive = true;
    this.getCareOptions();
    if (this.goal && this.goal.careTag) {
      this.getBehaviourOptions();
    }
  }

  // Vai alla sezione Cosa
  goToWhatSection() {
    this.isHowSection = false;
    this.tabSectionsList[1].isActive = false;
  }

  // Quando selezioni un altro Care tag
  changeCareTag(tag: Tag) {
    this.goal.careTag = tag;
    this.goal.careTagId = tag.tagId;
    this.goal.behaviorTag = undefined;
    this.goal.behaviorTagId = '';
    //this.getBehaviourOptions();
    this.checkHowTabCompleted();
  }

  // Quando selezioni un altro Behaviour tag
  changeBehaviourTag(tag: Tag) {
    this.goal.behaviorTag = tag;
    this.goal.behaviorTagId = tag.tagId;
    this.checkHowTabCompleted();
  }

  // Quando selezioni un altro How to use Care tag
  goalHowToUseCareChanged(text: string) {
    this.goal.howToUseCareTag = text;
    this.charsGoalHowToUseDescription = this.maxTextAreaChars - this.goal.howToUseCareTag.length;
    this.checkHowTabCompleted();
  }

  // Quando la tab what è completata
  isWhatTabCompleted() {
    if (this.goal.title && this.goal.description && this.goal.objectiveDescription) {
      return true;
    } else {
      return false;
    }
  }

  // Quando la tab how è completata
  isHowTabCompleted() {
    if (this.goal.careTag && this.goal.howToUseCareTag) {
      return true;
    }
    return false;
  }

  // Recupera i tag Care
  getCareOptions() {
    this.isFetchingCareOptions = true;
    this.getCareOptions$ = this.collaboratorService.getCareOptions('', '', true)
      .subscribe((data: SenecaResponse<GetPerformanceCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "028",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.careOptionsCounter = data.response.totalRowsCount;
          this.careOptions = data.response.rows;
        }
        this.isFetchingCareOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "029",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCareOptions = false;
        })
  }

  // Recupera i tag Behaviour
  getBehaviourOptions() {
    this.isFetchingBehaviourOptions = true;
    this.getBehaviourOptions$ = this.collaboratorService.getBehaviourOptions('', '', true, (this.goal.careTagId || ''))
      .subscribe((data: SenecaResponse<GetBehaviorCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "030",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.behaviourOptionsCounter = data.response.totalRowsCount;
          this.behaviourOptions = data.response.rows;
          let modalBehaviorText = this.translate.instant("goalSetting.createGoal.MODAL_BEHAVIOR_TEXT_1") + ((this.goal && this.goal.careTag && this.goal.careTag.title) || '') + this.translate.instant("goalSetting.createGoal.MODAL_BEHAVIOR_TEXT_2");
          for (let i = 0; i < this.behaviourOptions.length; i++) {
            modalBehaviorText += '<h3>' + this.behaviourOptions[i].title + '</h3>' + this.behaviourOptions[i].description;
          }
          this.formatBehaviorTextModal(modalBehaviorText);
        }
        this.isFetchingBehaviourOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "031",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingBehaviourOptions = false;
        })
  }

  // Recupera l'obiettivo dal goalId
  getGoalById() {
    this.isFetchingGetGoal = true;

    this.getGoal$ = this.collaboratorService.getGoalByIdForUser(this.runningYear.toString(), this.goalId)
      .subscribe((data: SenecaResponse<GetMyGoalByIdResponse>) => {
        if (data && data.error) {
          this.goBackBrowser();
          const messageObj: ApplicationModalMessage = {
            modalId: "032",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goal = data.response;
          if (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
            this.isSharedGoal = true;
          }
          if (this.goal.description && this.goal.description.length) {
            this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
          }
          if (this.goal.objectiveDescription && this.goal.objectiveDescription.length) {
            this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
          }
          if (this.goal.howToUseCareTag && this.goal.howToUseCareTag.length) {
            this.charsGoalHowToUseDescription = this.maxTextAreaChars - this.goal.howToUseCareTag.length;
          }
          this.goalCopy = JSON.parse(JSON.stringify(data.response));
          this.checkHowTabCompleted();
          this.checkWhatTabCompleted();
        }
        this.isFetchingGetGoal = false;
      },
        (err: string) => {
          this.goBackBrowser();
          const messageObj: ApplicationModalMessage = {
            modalId: "033",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetGoal = false;
        })
  }

  // Gestisce quando crei un nuovo obiettivo
  goToCreateGoal(openModalAlways?: boolean) {
    if (this.isWhatTabCompleted() && this.isHowTabCompleted() && !openModalAlways) {
      this.modalUpdateGoalId = this.getModalSaveId();
      this.modalUpdateGoalTitle = this.getModalSaveTitle();
      this.modalUpdateGoalText = this.getModalSaveDescription();
      this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal(openModalAlways);
      this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal(openModalAlways);
      this.openModalForUpdateGoal();
    } else {
      if (this.checkIfHowSectionIsChanged() || this.checkIfWhatSectionIsChanged()) {
        if (openModalAlways) {
          this.modalUpdateGoalId = this.getModalSaveId();
          this.modalUpdateGoalTitle = this.getModalSaveTitleBack();
          this.modalUpdateGoalText = this.getModalSaveDescriptionBack();
        } else {
          this.modalUpdateGoalId = this.getModalSaveId();
          this.modalUpdateGoalTitle = this.getModalSaveTitle();
          this.modalUpdateGoalText = this.getModalSaveDescription();
        }
        this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal(openModalAlways);
        this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal(openModalAlways);
        this.openModalForUpdateGoal();
      } else {
        this.goBackBrowser();
      }
    }
  }

  // Gestisce quando aggiorni un'obiettivo
  goToUpdateGoal(openModalAlways?: boolean) {
    if (!openModalAlways) {
      this.modalUpdateGoalId = this.getModalSaveId();
      this.modalUpdateGoalTitle = this.getModalSaveTitle();
      this.modalUpdateGoalText = this.getModalSaveDescription();
      this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal();
      this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal();
      this.openModalForUpdateGoal();
    } else if (!this.noDataInserted() && (this.checkIfHowSectionIsChanged() || this.checkIfWhatSectionIsChanged())) {
      this.modalUpdateGoalId = this.getModalSaveId();
      this.modalUpdateGoalTitle = this.getModalSaveTitleBack();
      this.modalUpdateGoalText = this.getModalSaveDescriptionBack();
      this.modalUpdateGoalTitleConfirmTextButton = this.getConfirmLabelUpdateGoalModal(true);
      this.modalUpdateGoalTextCloseTextButton = this.getCancelLabelUpdateGoalModal(true);
      this.openModalForUpdateGoal();
    } else {
      this.goBackBrowser();
    }
  }

  // Aggiorna obiettivo
  updateGoal(goNext?: boolean) {
    if ((!this.isWhatTabCompleted() || !this.isHowTabCompleted()) && (this.goal && (this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER || this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER_DRAFT))) {
      this.closeModalGoalUpdate();
    } else {
      this.isFetchingUpdateGoal = true;
      this.closeModalGoalUpdate();
      this.goal.userId = this.personDetails.userId;
      /* this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER_DRAFT;
      if (this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.careTag && this.goal.behaviorTag && this.goal.howToUseCareTag) {
        this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER;
      } */
      this.updateGoal$ = this.collaboratorService.updateGoal(this.runningYear, this.goal)
        .subscribe((data: SenecaResponse<UpdateMyGoalResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "034",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj: ApplicationModalMessage = {
              modalId: "035",
              title: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED_COLLABORATOR"),
              text: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED_DESCRIPTION_COLLABORATOR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            if (goNext) {
              this.getGoalById();
            } else {
              this.goBackBrowser()
            }
          }
          this.isFetchingUpdateGoal = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "036",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingUpdateGoal = false;
          })
    }
  }

  // Crea obiettivo
  createGoal() {
    this.isFetchingCreateGoal = true;
    this.closeModalGoalUpdate();
    this.goal.userId = this.personDetails.userId;
    /* this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER_DRAFT;
    if (this.goal.title && this.goal.description && this.goal.objectiveDescription && this.goal.careTag && this.goal.behaviorTag && this.goal.howToUseCareTag) {
      this.goal.goalStatus = PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER;
    } */
    this.createGoal$ = this.collaboratorService.createGoal(this.runningYear, this.goal)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "037",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response && data.response.length) {
          const messageObj: ApplicationModalMessage = {
            modalId: "038",
            title: this.translate.instant("goalSetting.createGoal.GOAL_CREATED_COLLABORATOR"),
            text: this.translate.instant("goalSetting.createGoal.GOAL_CREATED_DESCRIPTION_COLLABORATOR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser()
        }

        this.isFetchingCreateGoal = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "039",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCreateGoal = false;
        })
  }

  // Controlla se è cambiato qualcosa nella sezione What
  checkIfWhatSectionIsChanged() {
    return this.goal.title != this.goalCopy.title || this.goal.description != this.goalCopy.description || this.goal.objectiveDescription != this.goalCopy.objectiveDescription;
  }

  // Controlla se è cambiato qualcosa nella sezione How
  checkIfHowSectionIsChanged() {
    return this.goal.careTagId != this.goalCopy.careTagId || this.goal.behaviorTagId != this.goalCopy.behaviorTagId ||
      this.goal.howToUseCareTag != this.goalCopy.howToUseCareTag;
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdateGoal() {
    this.modalService.open('modalUpdateGoalForUser');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.goToGoalObjectiveList = true;
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per il salvataggio dell'obiettivo
  closeModalGoalUpdate() {
    this.modalService.close('modalUpdateGoalForUser');
  }

  tabId(index: number, el: any) {
    return el.id;
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getCareOptions$) {
      this.getCareOptions$.unsubscribe();
    }
    if (this.getBehaviourOptions$) {
      this.getBehaviourOptions$.unsubscribe();
    }
    if (this.createGoal$) {
      this.createGoal$.unsubscribe();
    }
    if (this.updateGoal$) {
      this.updateGoal$.unsubscribe();
    }
    if (this.getGoal$) {
      this.getGoal$.unsubscribe();
    }
  }
}