<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="sidenavTitle" (onBackFunction)="goToUpdateGoal(true)" [isBackButton]="true"
      [title]="sidenavTitle" description="{{('goalSetting.updatePMGoal.SIDENAV_DESCRIPTION' | translate)}}">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingUpdateGoal">
        <div class="form-container">
          <input-container [readonlyPlatform]="true"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="deadlineTooltipModal" [isValued]="goal.deadlineDate"
            title="{{'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE' | translate}}">
            <europ-input [readonly]="true" [bindValue]="goal.deadlineDate | date: 'dd/MM/YYYY'"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <input-container (renderInputEditable)="inputTitleToEditable()" [readonlyPlatformInput]="true"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="titleTooltipModal" [isValued]="goal.title && goal.title.trim().length"
            [isObbligatory]="true" title="{{'goalSetting.createGoal.OBJECTIVE_TITLE' | translate}}">
            <europ-input [readonly]="!inputTitleEditable" [bindValue]="goal.title"
              (onModelChanged)="goalTitleChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_TITLE_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <input-container
            [instructions]="selectWeightEditable ? ('goalSetting.createGoal.REMAIN_TO_ASSIGN' | translate) + differenceWeightSum + '%' : ''"
            [readonlyPlatformSelect]="true" (renderSelectEditable)="selectWeightToEditable()" [readonlyPlatform]="true"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="weightTooltipModal" [isValued]="goal.weight"
            title="{{'goalSetting.createGoal.OBJECTIVE_WEIGHT' | translate}}">
            <europ-select [readonly]="!selectWeightEditable" (onSelectedItem)="goalWeightChanged($event)"
              [selectedItem]="weightOptionSelected" [items]="weightOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_WEIGHT_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>
          <input-container [disabled]="isWeightEscluded()"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="targetTooltipModal" [isValued]="goal.target && goal.target.trim().length"
            [isObbligatory]="true" title="{{'goalSetting.updatePMGoal.OBJECTIVE_TARGET' | translate}}">
            <europ-input [bindValue]="goal.target || ''" (onModelChanged)="goalTargetChanged($event)"
              placeholder="{{'goalSetting.updatePMGoal.OBJECTIVE_TARGET_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <!-- Rating / Valutazione -->
          <input-container [disabled]="!isTodayAfterDeadlineToday() || isWeightEscluded()"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="valutationTooltipModal" [isValued]="goal.rating" [isObbligatory]="true"
            title="{{'goalSetting.updatePMGoal.OBJECTIVE_VALUTATION' | translate}}">
            <europ-select (onSelectedItem)="changeValutationTag($event)" [selectedItem]="ratingSelected"
              [items]="valutationOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.updatePMGoal.OBJECTIVE_VALUTATION_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>
          <!-- Risultato -->
          <input-container
            [disabled]="isWeightEscluded() || !isTodayAfterDeadlineToday() || !goal.rating || !goal.rating.length"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="resultTooltipModal" [isValued]="goal.result && goal.result.length"
            [isObbligatory]="true" title="{{'goalSetting.updatePMGoal.INSERT_RESULT' | translate}}">
            <europ-input [bindValue]="goal.result || null" (onModelChanged)="goalResultChanged($event)"
              placeholder="{{'goalSetting.updatePMGoal.INSERT_RESULT_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <!-- Osservazioni sul risultato -->
          <input-container
            [disabled]="isWeightEscluded() || !isTodayAfterDeadlineToday() || !goal.rating || !goal.rating.length"
            [isLoading]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
            [tooltipModalMessage]="ratingObservationTooltipModal"
            [isValued]="goal.ratingObservation && goal.ratingObservation.trim().length" [isObbligatory]="false"
            title="{{'goalSetting.updatePMGoal.RATING_OBSERVATIONS' | translate}}">
            <europ-input [bindValue]="goal.ratingObservation || null"
              (onModelChanged)="goalRatingObservationChanged($event)"
              placeholder="{{'goalSetting.updatePMGoal.RATING_OBSERVATIONS_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <div class="button-container">
            <europ-button (onButtonClicked)="goToUpdateGoal()"
              [disabled]="isLoadingTotalWeightSection || isFetchingGetGoal || isFetchingUpdateGoal"
              type="primary default" size="large" label="{{'goalSetting.UPDATE_GOAL' | translate}}">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isFetchingUpdateGoal">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="modalUpdateGoalForManager" (onCancel)="goBackBrowser()" (onClose)="closeModalGoalUpdate()"
  (onConfirm)="updateGoal()" cancelLabel="{{modalUpdateGoalTextCloseTextButton}}"
  confirmLabel="{{modalUpdateGoalTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalUpdateGoalId" [title]="modalUpdateGoalTitle" [subtitle]="''"
    [text]="modalUpdateGoalText">
  </modal-text-content>
</modal>