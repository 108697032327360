<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('calibration.TITLE' | translate)" [adminBack]="true"
    [hasTabs]="false" [adminPageSubtitle]="'Performance Management 2021'" [adminActionLabel]="'Report'"
    (onAdminAction)="downloadReport()" [adminActionDisabled]="isDownloadingReport"
    adminActionLabel="assets/img/icons/file/xls.svg"></header-dossier>
  <div class="main-content-wrapper">
    <div class="admin-table-container column-spacing">

      <p class="section-title">{{ ('process.details.USER_LIST' | translate) }}</p>
      <!-- anagrafica -->
      <div class="gray-box-container">
        <ng-container *ngIf="!isLoadingCounters">
          <div class="gray-box">
            <div class="text-info">
              <p class="number">{{ counterData.usersWithFinalEvaluationToCompleteCount }}</p>
              <p class="description" translate="calibration.COUNTER_FINAL_EVALUATION"></p>
            </div>
          </div>
          <!-- Inclusi -->
          <div class="gray-box">
            <div class="text-info">
              <p class="number">{{ counterData.usersWithCalibrationToCompleteCount }}</p>
              <p class="description" translate="calibration.COUNTER_CALIBRATION"></p>
            </div>
          </div>
          <!-- Esclusi -->
          <div class="gray-box">
            <div class="text-info">
              <p class="number">{{ counterData.usersToConfirmFinalEvaluationCount }}</p>
              <p class="description" translate="calibration.COUNTER_PERFORMANCE_LEVEL"></p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isLoadingCounters">
          <div class="loader-container">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>
      </div>
      <!-- Barra di ricerca -->
      <div class="search-filters-container">
        <div class="admin-search-container bottom-filters">
          <div class="search-wrapper">
            <europ-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
              (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
              [placeholder]="'process.details.SEARCH_BY' | translate" [isSearchBar]="true">
            </europ-input>
          </div>
        </div>
        <!-- Recap filtri -->
        <div class="filters-recap-container">
          <div class="left-column">
            <ng-container *ngIf="!selectedFiltersNumber">
              <p class="placeholder" translate="adminSearch.NO_FILTER_ACTIVE"></p>
            </ng-container>
            <ng-container *ngIf="selectedFiltersNumber == 1">
              <p class="placeholder" [innerHTML]="'adminSearch.ONE_FILTER' | translate"></p>
            </ng-container>
            <ng-container *ngIf="selectedFiltersNumber > 1">
              <p class="placeholder"><b>{{selectedFiltersNumber}}</b>&nbsp;{{ 'adminSearch.MORE_FILTERS' | translate }}
              </p>
            </ng-container>
            <ng-container *ngIf="selectedFiltersNumber >= 1">
              <p class="delete-filters" translate="adminSearch.DELETE_FILTERS" (click)="clearFilters()"></p>
            </ng-container>
            <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
          </div>
          <div class="right-column" (click)="openFiltersSection()">
            <p class="manage-filters" translate="adminSearch.MANAGE_FILTERS"></p>
            <ng-container *ngIf="!isFilterOpened">
              <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
            </ng-container>
            <ng-container *ngIf="isFilterOpened">
              <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
            </ng-container>
          </div>
        </div>
        <!-- contenitore filtri -->
        <ng-container *ngIf="isFilterOpened">
          <div class="filters-container">
            <div class="filters-content">
              <div class="generic-container">
                <!-- Struttura -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureTooltipModal"
                  [isValued]="selectedFilters.structures" [isObbligatory]="true"
                  title="{{'calibration.filters.STRUCTURE' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'structures')"
                    [selectedItem]="selectedFilters.structures" [items]="filtersObject.structures" [searchable]="false"
                    bindLabel="id">
                  </europ-select>
                </input-container>
                <!-- Funzione -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="functionTooltipModal"
                  [isValued]="selectedFilters.funzione" [isObbligatory]="true"
                  title="{{'calibration.filters.FUNCTION' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'funzione')"
                    [selectedItem]="selectedFilters.funzione" [items]="filtersObject.funzione" [searchable]="true"
                    bindLabel="id">
                  </europ-select>
                </input-container>

              </div>

              <div class="generic-container">

                <!-- Mansione -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="jobTooltipModal"
                  [isValued]="selectedFilters.mansione" [isObbligatory]="true"
                  title="{{'calibration.filters.JOB' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'mansione')"
                    [selectedItem]="selectedFilters.mansione" [items]="filtersObject.mansione" [searchable]="true"
                    bindLabel="id">
                  </europ-select>
                </input-container>

                <!-- Area -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="areaTooltipModal"
                  [isValued]="selectedFilters.area" [isObbligatory]="true"
                  title="{{'calibration.filters.AREA' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'area')"
                    [selectedItem]="selectedFilters.area" [items]="filtersObject.area" [searchable]="true"
                    bindLabel="id">
                  </europ-select>
                </input-container>

              </div>


              <div class="generic-container">
                <!-- Struttura 3 livello -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel3TooltipModal"
                  [isValued]="selectedFilters.denominazioneSO3" [isObbligatory]="true"
                  title="{{'calibration.filters.STRUCTURE_LEVEL_3' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'denominazioneSO3')"
                    [selectedItem]="selectedFilters.denominazioneSO3" [items]="filtersObject.denominazioneSO3"
                    [searchable]="true" bindLabel="id">
                  </europ-select>
                </input-container>

                <!-- Struttura 5 livello -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel5TooltipModal"
                  [isValued]="selectedFilters.denominazioneSO5" [isObbligatory]="true"
                  title="{{'calibration.filters.STRUCTURE_LEVEL_5' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'denominazioneSO5')"
                    [selectedItem]="selectedFilters.denominazioneSO5" [items]="filtersObject.denominazioneSO5"
                    [searchable]="true" bindLabel="id">
                  </europ-select>
                </input-container>

              </div>

              <div class="generic-container">
                <!-- STI -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="stiFilterTooltipModal"
                  [isValued]="selectedFilters.sti" [isObbligatory]="true"
                  title="{{'calibration.filters.STI_FILTER' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'sti')"
                    [selectedItem]="selectedFilters.sti" [items]="filtersObject.sti" [searchable]="false"
                    bindLabel="id">
                  </europ-select>
                </input-container>
                <!-- Sesso -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="genderFilterTooltipModal"
                  [isValued]="selectedFilters.chiaveSesso" [isObbligatory]="true"
                  title="{{'calibration.filters.GENDER' | translate}}">
                  <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'chiaveSesso')"
                    [selectedItem]="selectedFilters.chiaveSesso" [items]="filtersObject.chiaveSesso"
                    [searchable]="false" bindLabel="id">
                  </europ-select>
                </input-container>
              </div>

              <div class="generic-container">
                <!-- Stato calibration -->
                <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="statusFilterTooltip"
                  [isValued]="selectedFilters.calibrationStatusTypes" [isObbligatory]="true"
                  title="{{'calibration.filters.STATUS' | translate}}">
                  <europ-select [inMinWidthForIe]="true"
                    (onSelectedItem)="selectFilter($event, 'calibrationStatusTypes')"
                    [selectedItem]="selectedFilters.calibrationStatusTypes"
                    [items]="filtersObject.calibrationStatusTypes" [searchable]="false" bindLabel="id">
                  </europ-select>
                </input-container>
                <div class="full-width"></div>
              </div>

              <div class="button-actions filters">
                <europ-button [label]="'generic.APPLY_FILTERS' | translate" type="primary"
                  (onButtonClicked)="applyFilters()" [disabled]="isLoadingUsers">
                </europ-button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingTranslations && !isLoadingUsers && usersList && usersList.length">

        <div class="table-content">
          <table>
            <thead>
              <tr>
                <th>
                  <europ-check (onCheck)="selectAll()"></europ-check>
                </th>
                <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                <th translate="{{ ('adminTable.CONTRIBUTORS' | translate)}}"></th>
                <th class="center" translate="{{ ('adminTable.FINAL_EVALUATION' | translate)}}"></th>
                <th class="center" translate="adminTable.CALIBRATION"></th>
                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of usersList | 
                          slice:0:usersNumRecords | 
                          paginate: { id: 'userList',
                                      itemsPerPage: usersNumRecords,
                                      currentPage: usersCurrentPage,
                                      totalItems: usersCounter }">
                <td>
                  <europ-check id="user.userId" [checked]="user.isChecked" (click)="selectUser(user)"></europ-check>
                </td>
                <td>{{ user.surname + ' ' + user.forename }}</td>
                <td>
                  <div class="count">
                    {{ user.cid | truncate: [14, '...'] }}
                  </div>
                </td>
                <td>
                  <!-- Sottoposti -->
                  <div class="team-filter">
                    <ng-container *ngIf="user.collaboratorCount > 0">
                      {{user.collaboratorCount}}&nbsp;<p class="table-filter-team" (click)="filterByManager(user)">{{
                        'adminTable.FILTER_TEAM' | translate }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="!user.collaboratorCount">
                      <p translate="calibration.NONE"></p>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <div class="status-evaluation">
                    <item-list-team-status styleItem="border" [type]="user.finalEvaluation.status"
                      [message]="user.finalEvaluation.label">
                    </item-list-team-status>
                    <ng-container *ngIf="user.finalEvaluationRating">
                      <p class="evaluation-text ">{{ ('evaluations.' + user.finalEvaluationRating) | translate }}</p>
                    </ng-container>
                    <ng-container *ngIf="!user.finalEvaluationRating">
                      <p class="evalaution-text">--</p>
                    </ng-container>
                    <ng-container *ngIf="user.finalEvaluationPayout || user.finalEvaluationPayout == 0">
                      <p class="evaluation-text ">
                        {{ user.finalEvaluationPayout }}% {{ 'performance.modals.OF_PAYOUT' | translate | uppercase }}
                      </p>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <div class="status-evaluation">
                    <item-list-team-status styleItem="border" [type]="user.calibration.status"
                      [message]="user.calibration.label"></item-list-team-status>
                    <ng-container *ngIf="user.calibrationRating">
                      <p class="evaluation-text ">{{ ('evaluations.' + user.calibrationRating) | translate }}</p>
                    </ng-container>
                    <ng-container *ngIf="!user.calibrationRating">
                      <p class="evaluation-text">--</p>
                    </ng-container>
                    <ng-container *ngIf="user.payout || user.payout == 0">
                      <p class="evaluation-text ">
                        {{ user.payout }}% {{ 'performance.modals.OF_PAYOUT' | translate | uppercase }}
                      </p>
                    </ng-container>
                  </div>
                </td>
                <td class="action">
                  <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                    (clickOutside)="closeMenu(user)" src="/assets/img/icons/more-horizontal.svg">
                  </svg-icon>
                  <ng-container *ngIf="user.isMenuOpen">
                    <div class="dropdown-menu">
                      <ng-container *ngIf="!calibrationEnded">
                        <!-- se non è finita la calibration-->
                        <ng-container *ngIf="user.calibrationRating && isMenuOptionValid(editMenuOption, user)">
                          <europ-dropdown-item (click)="onMenuOptionsClicked(editMenuOption, user)" class="full-width"
                            [item]="editMenuOption">
                          </europ-dropdown-item>
                        </ng-container>
                        <ng-container *ngIf="isMenuOptionValid(menuOptions[0], user) && !user.calibrationRating">
                          <europ-dropdown-item (click)="onMenuOptionsClicked(menuOptions[0], user)" class="full-width"
                            [item]="menuOptions[0]">
                          </europ-dropdown-item>
                        </ng-container>
                        <ng-container *ngIf="isMenuOptionValid(menuOptions[1], user)">
                          <europ-dropdown-item (click)="onMenuOptionsClicked(menuOptions[1], user)" class="full-width"
                            [item]="menuOptions[1]" [disabled]="!isMenuOptionValid(menuOptions[1], user)">
                          </europ-dropdown-item>
                        </ng-container>
                      </ng-container>
                      <!-- Accedi alla scheda utente, come impersonifica -->
                      <europ-dropdown-item (click)="onMenuOptionsClicked(menuOptions[2], user)" class="full-width"
                        [item]="menuOptions[2]">
                      </europ-dropdown-item>
                    </div>
                  </ng-container>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="bottom-actions">
          <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
          </table-pagination>
          <div class="per-page-selector">
            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
              [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
              [tablePagination]="true">
            </europ-select>
          </div>
        </div>
        <div class="button-actions sticky" *ngIf="selectedUserList && selectedUserList.length > 1">
          <div class="selected-container">
            <p class="selected-count">{{ selectedUserList.length || 0}}</p>
            <p class="selected-count-label" translate="generic.SELECTEDS"></p>
          </div>
          <!-- Compara nella matrice -->
          <europ-button [label]="'calibration.COMPARE_MATRIX' | translate" type="primary"
            (onButtonClicked)="goToMatrix(false)"></europ-button>
          <!-- Conferma PL -->
          <europ-button [label]="'calibration.CONFIRM_PL' | translate" type="secondary"
            (onButtonClicked)="openMassiveInclusion()" [disabled]="!canConfrimUserList()">
          </europ-button>
        </div>

      </ng-container>

      <ng-container *ngIf="!isLoadingUsers && (!usersList || !usersList.length)">
        <div class="admin-no-data-wrapper">
          <p translate="process.details.NO_DATA"></p>
        </div>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsers || isLoadingTranslations">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<!--modal id="recapConfirm" (onCancel)="closeConfirmModal()" (onClose)="closeConfirmModal()"
  (onConfirm)="closeConfirmModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
</modal-->
<modal id="massive-inclusion" (onClose)="closeMassiveInclusion()"
  [confirmLabel]="selectedUserList.length <= 1 ? null : ('generic.CONFIRM' | translate)"
  (onConfirm)="closeMassiveInclusion(true)"
  [cancelLabel]="selectedUserList.length <= 1 ? null : ('generic.CANCEL' | translate)"
  (onCancel)="closeMassiveInclusion()">
  <ng-container *ngIf="selectedUserList && selectedUserList.length > 1">
    <modal-text-content [modalId]="'d1060'" [title]="'calibration.modals.CONFIRM_PL_TITLE' | translate"
      [subtitle]="'calibration.modals.MASSIVE_PL_SUB' | translate" [text]="massiveModalText"></modal-text-content>
  </ng-container>
  <ng-container *ngIf="selectedUserList && selectedUserList.length <= 1 && massiveModalData">
    <p class="modal-title">
      {{ ('calibration.modals.CONFIRM_EVAL' | translate)}}</p>
    <div class="confirmation-container">
      <div class="observation-container">
        <p class="subtitle" translate="calibration.modals.OBSERVATIONS"></p>
        <p class="text">{{ massiveModalData.managerObservation || ('calibration.NO_OBSERVATION' | translate)}}</p>
      </div>
      <div class="value-container">
        <evaluation customClass="big" [scale]="5" [evaluation]="massiveModalData.level"></evaluation>
        <ng-container *ngIf="massiveModalData.payout">
          <div class="payout-label">
            <p>{{massiveModalData.payout + '% ' + ('performance.modals.OF_PAYOUT' | translate)}}</p>
          </div>
        </ng-container>
      </div>
      <!-- Osservazioni -->
      <!--input-container [numberCharsTextArea]="charsModalObservation" [isFromTextArea]="true"
        [tooltipModalMessage]="observationTooltip"
        [isValued]="massiveModalData.observation && massiveModalData.observation.trim().length" [isObbligatory]="true"
        title="{{'calibration.INSERT_OBSERVATIONS' | translate}}">
        <europ-text-area [bindValue]="massiveModalData.observation" [maxlength]="600"
          (onModelChanged)="massiveModalObservationChanged($event)"
          placeholder="{{'performance.OBSERVATION_PLACEHOLDER' | translate}}">
        </europ-text-area>
      </input-container-->
    </div>
    <div class="modal-action-container single-button">
      <!--europ-button [label]="'generic.CANCEL' | translate" type="primary bordless"
        (onButtonClicked)="closeMassiveInclusion()"></europ-button-->
      <europ-button [label]="'calibration.CONFIRM_EVALUATION' | translate" type="primary"
        (onButtonClicked)="confirmSingle()">
      </europ-button>
    </div>
  </ng-container>
</modal>