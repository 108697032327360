import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareMidTermReviewGoalAdvancementStatus, GetMyManagerUserDataResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, ParseUtils } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { Subordinateservice } from '../services/subordinates.service';

@Component({
  selector: 'app-manager-postCalibrationEvaluation',
  templateUrl: './postCalibrationEvaluation.component.html',
  styleUrls: ['./postCalibrationEvaluation.component.scss']
})
export class PostCalibrationEvaluationComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserGoalList$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;
  isFromBack: boolean = false;
  sendRevision$: Subscription = new Subscription;
  isLoadingManagerInfo: boolean = false;
  managerData: any;
  isOperations: boolean = false;
  backWithData: boolean = false;

  charsFinalObservation: number = 600;
  finalObservation: string = '';
  observationTooltip: ApplicationModalMessage = {
    modalId: 'pl002',
    title: '',
    text: ''
  }
  userId: string = '';
  isLoadingPayoutOptions: boolean = false;
  isLoadingUserFinalEvaluation: boolean = false;
  getFinalEvaluation$: Subscription = new Subscription;
  result$: Subscription = new Subscription;
  runningPhase: any;
  personDetails: any;
  getPersonDetails$: Subscription = new Subscription;
  isLoadingPersonDetails: boolean = false;
  isSti: boolean = false;
  sidenavDescription: string = '';
  managerEvaluation: { level: any; observations: any; date: any; } = {
    level: null,
    observations: null,
    date: null
  };
  // TODO Aggiornare finalEvaluation
  calibrationUserData = {
    user: {
      forename: 'Calibration',
      surname: ' ',
      userOptions: {
        avatarImage: 'assets/img/badge/centro.svg'
      }
    },
    finalEvaluation: {}
  }
  userData: any;
  userFinalEvaluation: { level: any; observations: any; date: any; } = {
    level: null,
    observations: null,
    date: null
  };
  selectedYear: any;

  constructor(
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private subordinatesService: Subordinateservice
  ) {
    this.isOperations = window.location.href.indexOf('collaboratorOperations') > -1;
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.selectedYear = params.perfCareYear;
              const loggedUser$ = this.store.select(fromApp.getLoggedUser);
              const runningPhase$ = this.store.select(fromApp.getRunningPhase);
              const personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses);
              const combinedSelectes$ = combineLatest([loggedUser$, runningPhase$, personDetails$]);
              this.result$ = combinedSelectes$.subscribe(
                ([loggedUser, runningPhase, subordinatesList]) => {
                  this.loggedUser = loggedUser.user;
                  this.runningPhase = runningPhase;
                  this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                    return person.userId === this.userId;
                  })[0];

                  // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                  if (!this.personDetails) {
                    this.getPersonDetails();
                  }
                  if (this.personDetails) {
                    this.sidenavDescription = this.translate.instant('calibration.post.SIDENAV_DESCR_1') + this.personDetails.forename + ' ' + this.personDetails.surname + this.translate.instant('calibration.post.SIDENAV_DESCR_2');
                    this.isSti = !isNaN(ParseUtils.parseInt(this.personDetails.stiAmount));
                    if (this.loggedUser) {
                      this.managerData = this.loggedUser;
                      this.getCalibrationData();
                    }
                  }
                });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT',
        'midTerm.addMidTermReview.IN_RETARD',
        'midTerm.addMidTermReview.IN_LINE',
        'midTerm.addMidTermReview.GREAT_POINT',
        'performance.PAYOUT_PLACEHOLDER',
        'performance.INSERT_OBSERVATION',
        'performance.OBSERVATION_TOOLTIP',
        'performance.SET_PAYOUT',
        'performance.SET_PAYOUT_TOOLTIP',
      ])
      .subscribe(translations => {
        this.observationTooltip = {
          modalId: "pc001",
          title: translations['performance.INSERT_OBSERVATION'],
          text: translations['performance.OBSERVATION_TOOLTIP'],
        }
      });
  }

  getPersonDetails() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.selectedYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData && personDetailsData.error) {
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.sidenavDescription = this.translate.instant('calibration.post.SIDENAV_DESCR_1') + this.personDetails.forename + ' ' + this.personDetails.surname + this.translate.instant('calibration.post.SIDENAV_DESCR_2');
          this.isSti = !isNaN(ParseUtils.parseInt(this.personDetails.stiAmount));
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.finalObservation && this.finalObservation.trim().length;
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return this.finalObservation && this.finalObservation.trim().length && this.managerEvaluation && this.managerEvaluation.observations.trim() != this.finalObservation.trim();
  }

  sendRevision() {
    // this.goBackBrowser();
    /*
    this.isLoadingUserGoals = true;
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    this.sendRevision$ = this.managerService.setMidTermReviewRevision(this.runningYear, this.osservation, this.cardSelected.id)
      .subscribe((data: any) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj = {
            modalId: "mt005",
            title: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS"),
            text: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
        }
        this.goBackBrowser();
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt006",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
      */
  }

  sendRevisionAction(isBack?: boolean) {
    if (this.checkIfDataIsChanged()) {
      this.modalService.open("recapConfirm");
    } else {
      this.goBackBrowser();
    }
  }

  getCalibrationData() {
    this.isLoadingUserFinalEvaluation = true;
    if (this.getFinalEvaluation$) {
      this.getFinalEvaluation$.unsubscribe();
    }
    this.getFinalEvaluation$ = this.managerService.getCalibrationDataForManager(this.selectedYear, this.userId)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error || !feData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          this.userData = feData.response.user;

          if (feData.response.finalEvaluation) {
            let finalEvaluation = feData.response.finalEvaluation;

            this.userFinalEvaluation = {
              level: finalEvaluation.subordinateObservationLevel,
              observations: finalEvaluation.subordinateObservation,
              date: finalEvaluation.creationDate
            };

            this.managerEvaluation = {
              level: finalEvaluation.managerObservationLevel,
              observations: finalEvaluation.managerObservation,
              date: finalEvaluation.creationDate
            }
            this.finalObservation = finalEvaluation.managerObservation;
          }
          if (feData.response.calibration) {
            let calibration = feData.response.calibration;
            this.calibrationUserData.finalEvaluation = {
              level: calibration.adminObservationLevel,
              observations: calibration.adminObservation,
              date: calibration.creationDate
            }
          }
          this.isLoadingUserFinalEvaluation = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserFinalEvaluation = false;
      });
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  finalObservationChanged(newText: string) {
    this.finalObservation = newText;
  }


  openConfirmModal() {
    this.modalService.open('recapConfirm')
  };

  closeConfirmModal(confirm?: boolean, cancel?: boolean) {
    this.modalService.close('recapConfirm');
    if (cancel) {
      this.redirectService.goBackBrowser();
    }
    if (confirm) {
      this.managerService.confirmPostCalibration(this.selectedYear, this.personDetails.userId, this.finalObservation)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error || !data.response) {
            let error = data.error ? this.translate.instant("errors." + data.error) : this.translate.instant('errors.SOMETHING_WENT_WRONG');
            const messageObj: ApplicationModalMessage = {
              modalId: "pl012",
              text: error,
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.goBackBrowser();
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pl013",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  getUserGoalEvaluation(goal: any) {
    let tmpData = goal.goalEvaluation?.find((x: any) => x.user?.userId == this.userId);
    return tmpData && tmpData.evaluationScore;
  }

  openMarkCardInfoModal() {
    const messageObj: ApplicationModalMessage = {
      modalId: "pl003",
      title: this.translate.instant('performance.INSERT_VALUE'),
      text: this.translate.instant("performance.VALUE_TOOLTIP"),
    }
    this.modalService.open('modal-info');
  }

  closeModalInfo() {
    this.modalService.close('modal-info');
  }

  ngOnDestroy() {
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    if (this.getFinalEvaluation$) {
      this.getFinalEvaluation$.unsubscribe();
    }
  }
}