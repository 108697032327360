<div class="admin-table-container column-spacing">

    <p class="section-title">{{ ('process.details.USER_LIST' | translate) }}</p>
    <!-- anagrafica -->
    <div class="gray-box-container">
        <div class="gray-box">
            <div class="text-info">
                <p class="number">800</p>
                <p class="description" translate="process.details.IN_ANAG"></p>
                <p class="link" translate="process.details.ACCESS_ANAG"></p>
            </div>
            <div class="excels">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg"></svg-icon>
                <p translate="process.details.DOWNLOAD_CURRENT_LIST"></p>
            </div>
        </div>
        <!-- Inclusi -->
        <div class="gray-box">
            <div class="text-info">
                <p class="number">600</p>
                <p class="description" [innerHTML]="('process.details.INCLUDED' | translate)"></p>
            </div>
            <div class="excels" (click)="openIncludePeopleModal()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg"></svg-icon>
                <p translate="process.details.INCLUDE_PEOPLE"></p>
            </div>
        </div>
        <!-- Esclusi -->
        <div class="gray-box">
            <div class="text-info">
                <p class="number">200</p>
                <p class="description" [innerHTML]="('process.details.EXCLUDED' | translate)"></p>
            </div>
            <div class="excels" (click)="openExcludePeopleModal()">
                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg"></svg-icon>
                <p translate="process.details.EXCLUDE_PEOPLE"></p>
            </div>
        </div>
    </div>
    <!-- Barra di ricerca -->
    <div class="admin-search-container">
        <div class="search-wrapper">
            <europ-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                [placeholder]="'process.details.SEARCH_BY' | translate" [isSearchBar]="true">
            </europ-input>
            <p (click)="openFiltersModal()" class="filter-recap" translate="process.details.FILTER_BY"></p>
        </div>
    </div>

    <!-- contenitore tabella -->
    <ng-container *ngIf="!isLoadingProcessData && processData && processData.list && processData.list.length">

        <div class="table-content process-structure">
            <table>
                <thead>
                    <tr>
                        <th translate="{{ ('process.details.NAME' | translate)}}"></th>
                        <th translate="{{ ('process.details.CID' | translate)}}"></th>
                        <th translate="{{ ('process.details.STI' | translate)}}"></th>
                        <th translate="{{ ('process.details.STI_VALUE' | translate)}}"></th>
                        <th translate="{{ ('process.details.DEFINITION' | translate)}}"></th>
                        <th translate="{{ ('process.details.STATUS' | translate)}}"></th>
                        <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of processData.list | 
                            slice:0:processData.numRecords | 
                            paginate: { id: 'userList',
                                    itemsPerPage: processData.numRecords,
                                    currentPage: processData.page,
                                    totalItems: processData.counter }">
                        <td>{{ user.surname + ' ' + user.forename }}</td>
                        <td>
                            <div class="count">
                                {{ user.cid }}
                            </div>
                        </td>
                        <td>
                            <p *ngIf="user.hasSTI && user.stiValue" class="sti on">STI ON</p>
                            <p *ngIf="user.hasSTI && !user.stiValue" class="sti off">STI OFF</p>
                            <p *ngIf="!user.hasSTI" class="sti no">NO STI</p>
                        </td>
                        <td>
                            <ng-container *ngIf="user.hasSTI">
                                <div class="date">
                                    <p class="value">{{ user.stiValue | number: '1.2-2' }}</p>
                                    <p class="date-text">{{ user.stiDueDate | date: 'dd/mm/YYYY'}}
                                    </p>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!user.hasSTI">
                                <p>--</p>
                            </ng-container>
                        </td>
                        <td>{{ user.denominazioneSO3 }}</td>
                        <td>
                            <item-list-team-status *ngIf="user.status == 'INCLUDED'" type="completed"
                                [message]="('process.details.STATUS_INCLUDED' | translate)">
                            </item-list-team-status>
                            <item-list-team-status *ngIf="user.status != 'INCLUDED'" type="toStart"
                                [message]="('process.details.STATUS_EXCLUDED' | translate)">
                            </item-list-team-status>
                        </td>
                        <td class="action">
                            <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                                src="/assets/img/icons/more-horizontal.svg">
                            </svg-icon>
                            <ng-container *ngIf="user.isMenuOpen">
                                <div class="dropdown-menu">
                                    <europ-dropdown-item (click)="onMenuOptionsClicked(option, user)"
                                        [ngClass]="{'last': last}" class="full-width"
                                        *ngFor="let option of menuOptions; let last = last" [item]="option">
                                    </europ-dropdown-item>
                                </div>
                            </ng-container>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="bottom-actions">
            <table-pagination id="userList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
                <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                    [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                    [tablePagination]="true">
                </europ-select>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="!isLoadingProcessData && (!processData || !processData.list || !processData.list.length)">
        <div class="admin-no-data-wrapper">
            <p translate="process.details.NO_DATA"></p>
        </div>
    </ng-container>
    <!-- loader -->
    <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div>











<!-- Modale gestione STI -->
<modal id="manageSTI" (onClose)="closeManageSTI()" (onConfirm)="closeManageSTI(true)"
    confirmLabel="{{'generic.CONFIRM' | translate}}">
    <div class="sti-modal-container" *ngIf="stiModalUser">
        <p class="title">{{ ('process.sti.TITLE' | translate) + stiModalUser.surname + ' ' + stiModalUser.forename }}
        </p>
        <div class="divisor-line"></div>
        <!-- STI attivo-->
        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="stiEnableTooltip"
            [isValued]="stiModalUser.hasSTI" [isObbligatory]="true" title="{{'process.sti.ENABLE' | translate}}">
            <europ-switch [leftLabelObject]="('process.sti.STI_OFF' | translate)"
                [rightLabelObject]="('process.sti.STI_ON' | translate)" [checked]="stiModalUser.hasSTI"
                (onSwitch)="switchSTI()"></europ-switch>
        </input-container>
        <!-- Importo STI -->
        <input-container [isWithoutInfoIcon]="false" [disabled]="!stiModalUser.hasSTI"
            [tooltipModalMessage]="stiValueTooltip" [isValued]="stiModalUser.stiValue" [isObbligatory]="true"
            title="{{'process.sti.GIVE_VALUE' | translate}}">
            <europ-input [bindValue]="stiModalUser.stiValue" (onModelChanged)="stiValueChanged($event)"
                placeholder="{{'process.sti.GIVE_VALUE_PLACEHOLDER' | translate}}">
            </europ-input>
        </input-container>
        <!-- Accordo firmato il / valido da -->
        <input-container [isWithoutInfoIcon]="false" [disabled]="!stiModalUser.hasSTI"
            [tooltipModalMessage]="stiDateTooltip" [isValued]="stiModalUser.stiDueDate" [isObbligatory]="true"
            title="{{'process.sti.SIGNED_DATE' | translate}}">
            <europ-datepicker [minDate]="today" [value]="stiModalUser.stiDueDate" (dateChanged)="stiDateChanged($event)"
                placeholder="{{'process.sti.SIGNED_DATE_PLACEHOLDER' | translate}}">
            </europ-datepicker>
        </input-container>
    </div>
</modal>

<!-- Modale conferma attivazione STI -->


<modal id="confirmSTI" (onClose)="closeConfirmSTI()" (onConfirm)="closeConfirmSTI()"
    [confirmLabel]="('process.sti.REASSIGN' | translate)" (onCancel)="closeConfirmSTI()"
    [cancelLabel]="('process.sti.KEEP' | translate)">
    <ng-container *ngIf="stiModalUser && confirmSTIModal">
        <modal-text-content [modalId]="confirmSTIModal.modalId" [title]="confirmSTIModal.title"
            [subtitle]="confirmSTIModal.subtitle" [text]="confirmSTIModal.text">
        </modal-text-content>
    </ng-container>
</modal>

<!-- Modale inclusione persone -->
<modal id="includePeople" (onClose)="closeIncludePeopleModal()" (onConfirm)="closeIncludePeopleModal()"
    [confirmLabel]="('process.manage.INCLUDE' | translate)">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.INCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="download-track-container">
            <europ-button (onButtonClicked)="downloadIncludeTrack()" [disabled]="isDownloadingIncludeTrack"
                type="primary bordless" size="medium" [label]="('process.manage.DOWNLOAD_TRACK' | translate)">
            </europ-button>
        </div>
        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="uploadIncludeTooltip"
            [isValued]="includeFileObject" [isObbligatory]="true"
            title="{{'process.manage.INCLUDE_TRACK' | translate}}">
            <europ-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event, true)"
                [acceptedMimeTypes]="'.xls .xlsx'"></europ-upload>
        </input-container>
    </div>
</modal>

<!-- Modale esclusione persone -->
<modal id="excludePeople" (onClose)="closeExcludePeopleModal()" (onConfirm)="closeExcludePeopleModal()"
    [confirmLabel]="('process.manage.EXCLUDE' | translate)">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.EXCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="download-track-container">
            <europ-button (onButtonClicked)="downloadExcludeTrack()" [disabled]="isDownloadingExcludeTrack"
                type="primary bordless" size="medium" [label]="('process.manage.DOWNLOAD_TRACK' | translate)">
            </europ-button>
        </div>
        <!-- Upload lista persone da Excludere -->
        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="uploadExcludeTooltip"
            [isValued]="excludeFileObject" [isObbligatory]="true"
            title="{{'process.manage.EXCLUDE_TRACK' | translate}}">
            <europ-upload [isToReset]="resetExcludeUpload" (onUpload)="assignFile($event)"
                [acceptedMimeTypes]="'.xls .xlsx'"></europ-upload>
        </input-container>
    </div>
</modal>

<!-- Modale filtri -->
<modal id="filtersModal" (onClose)="closeFiltersModal()" (onConfirm)="applyFilters()"
    [confirmLabel]="('generic.APPLY_FILTERS' | translate)" (onCancel)="clearFilters()"
    [cancelLabel]="('generic.DELETE_FILTERS' | translate)">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.EXCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="filter-recap">
            <p class="subtitle" translate="process.manage.APPLY_FILTERS"></p>
            <p>{{ 1 + ('process.manage.FILTER_ACTIVE' | translate ) }}</p>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per sti -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STI"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card" *ngFor="let sti of stiFilters" [ngClass]="{'selected': sti.isChecked}"
                    (click)="toggleFilter(sti)">
                    <p>{{ sti.title }}</p>
                </div>
            </div>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per stato -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STATUS"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card" *ngFor="let status of statusFilters" [ngClass]="{'selected': status.isChecked}"
                    (click)="toggleFilter(status)">
                    <p>{{ status.title }}</p>
                </div>
            </div>
        </div>
        <!-- Filtro per definizione (struttura) -->
        <div class="filter-container">
            <p class="name" translate="process.manage.DEFINITION"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <ng-container>
                    <div class="filter-card" *ngFor="let definition of definitionFilters"
                        [ngClass]="{'selected': definition.isChecked}" (click)="toggleFilter(definition)">
                        <p>{{ definition.title }}</p>
                    </div>
                </ng-container>
            </div>
            <p class="more-link" translate="process.manage.SHOW_ALL"></p>
            <p class="more-link" translate="process.manage.SHOW_LESS"></p>
        </div>
    </div>
</modal>