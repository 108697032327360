<div class="side-nav-bar"
  [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async), 'show-app-header': showAppHeader, 'has-impersonate-banner': hasImpersonateBanner }">
  <!-- Overlay -->
  <div class="side-nav-bar-overlay"
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }" (click)="onSidebarClose()"></div>

  <!-- side bar-->
  <div class="side-nav-bar-menu-container" fxLayout="column" [ngStyle]="getSideNavBarStyle((showSideNav | async))">

    <!-- Pulsante di chiusura -->
    <div *ngIf="!hideCloseButton">
      <span class="material-icons side-nav-bar-close" (click)="onSidebarClose()">
        <img alt="close" src="/assets/img/icons/x.svg" />
      </span>
    </div>

    <!-- Contenuto -->
    <div class="side-nav-bar-content-container">
      <ng-container *ngTemplateOutlet="sidenavTemplateRef"></ng-container>
    </div>
  </div>
</div>