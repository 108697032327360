<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="personDetails" (onBackFunction)="goToUpdateEvidence(true)" [isBackButton]="true"
      [title]="(evidenceId && evidenceId.length) ? ('evidences.UPDATE_THE_EVIDENCE' | translate) : ('evidences.INSERT_AN_EVIDENCE' | translate)"
      description="{{('evidences.SIDEBAR_COLLABORATOR_DESCRIPTION' | translate)}}">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingCreateEvidence && !isFetchingUpdateEvidence">
        <div class="form-container">
          <input-container
            [isLoading]="isFetchingGetEvidence || isLoadingGoalsToAssociate || isFetchingCreateEvidence || isFetchingUpdateEvidence"
            [tooltipModalMessage]="objectiveToAssociateTooltipModal" [isValued]="objectiveAssociated"
            title="{{'evidences.ASSOCIATE_TO_OBJECTIVE' | translate}}" [isObbligatory]="true"
            [readonly]="!!goalId || !!evidenceId">
            <europ-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
              [noDataFoundLabel]="'evidences.NO_OBJECTIVE_FOUND' | translate" [selectedItem]="objectiveAssociated"
              [items]="objectiveOptions" [loadingData]="isLoadingGoalsToAssociate" [searchable]="true" bindLabel="title"
              placeholder="{{'evidences.SELECT_OBJECTIVE' | translate}}"></europ-select>
          </input-container>
          <input-container [isObbligatory]="true"
            [isLoading]="isFetchingGetEvidence || isLoadingGoalsToAssociate || isFetchingCreateEvidence || isFetchingUpdateEvidence"
            [tooltipModalMessage]="contestTooltipModal" [isValued]="evidence.comment && evidence.comment.trim().length"
            [isObbligatory]="true" title="{{'evidences.CONTEST' | translate}}">
            <europ-text-area [bindValue]="evidence.comment" (onModelChanged)="evidenceContestChanged($event)"
              placeholder="{{'evidences.CONTEST_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <input-container [isObbligatory]="true"
            [isLoading]="isFetchingGetEvidence || isLoadingGoalsToAssociate || isFetchingCreateEvidence || isFetchingUpdateEvidence"
            [tooltipModalMessage]="osservationsTooltipModal"
            [isValued]="evidence.observations && evidence.observations.trim().length" [isObbligatory]="true"
            title="{{'evidences.WHAT_ARE_YOUR_OSSERVATIONS' | translate}}">
            <europ-text-area [bindValue]="evidence.observations" (onModelChanged)="osservationChanged($event)"
              placeholder="{{'evidences.WHAT_ARE_YOUR_OSSERVATIONS_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <div class="button-container">
            <europ-button (onButtonClicked)="goToUpdateEvidence()"
              [disabled]="isFetchingGetEvidence || isLoadingGoalsToAssociate || isFetchingCreateEvidence || isFetchingUpdateEvidence"
              type="primary default" size="large"
              [label]="(evidenceId && evidenceId.length) ? ('evidences.UPDATE_EVIDENCE' | translate) : ('evidences.INSERT_EVIDENCE' | translate)">
            </europ-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isFetchingCreateEvidence || isFetchingUpdateEvidence">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un'evidenza -->
<modal id="modalCreateUpdateEvidenceForCollaborator" (onCancel)="goBackBrowser()"
  (onClose)="closeModalCreateUpdateEvidence()"
  (onConfirm)="(evidenceId && evidenceId.length) ? updateEvidence() : createEvidence()"
  cancelLabel="{{modalCreateUpdateEvidenceTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdateEvidenceTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdateEvidenceId" [title]="modalCreateUpdateEvidenceTitle"
    [subtitle]="modalCreateUpdateEvidenceSubtitle" [text]="modalCreateUpdateEvidenceText">
  </modal-text-content>
</modal>