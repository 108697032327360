import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { catchError, switchMap, take } from 'rxjs/operators';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as AdminActions from "../ngrx/admin.actions";
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'admin-calibration-user-list',
  templateUrl: './calibration-user-list.component.html',
  styleUrls: ['./calibration-user-list.component.scss']
})
export class CalibrationUserListComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];
  processToDelete: any;

  processYear: number = 0;
  today = moment().format();

  isFilterOpened: boolean = false;

  usersFromRecord: number = 0;
  usersNumRecords: number = 5;
  usersCurrentPage: number = 0;
  usersCounter: number = 0;
  isLoadingUsers: boolean = false;
  selectedUserList: any[] = []
  searchedText: string = '';

  whoTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal014',
    title: '',
    text: ''
  };
  structureTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal015',
    title: '',
    text: ''
  };
  structureLevel3TooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal016',
    title: '',
    text: ''
  };
  jobTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal017',
    title: '',
    text: ''
  };
  stiFilterTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal018',
    title: '',
    text: ''
  };
  functionTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal019',
    title: '',
    text: ''
  };

  getCountersData$: Subscription = new Subscription();
  isLoadingCounters: boolean = false;
  counterData: any;

  usersList: any[] = [];
  managerId: any;
  translations: any;
  massiveModalText: any;
  massiveModalData: {
    user: any;
    observation: string;
    managerObservation: string;
    level: string;
    payout: number | null;
  } = {
      user: null,
      observation: '',
      managerObservation: '',
      level: '',
      payout: null
    };
  loadingMenu: boolean = true;
  charsModalObservation: number = 600;
  observationTooltip: ApplicationModalMessage = {
    modalId: 'cal020',
    title: '',
    text: ''
  }
  isLoadingFilters: boolean = true;
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};
  genderFilterTooltipModal: ApplicationModalMessage = {
    modalId: 'cal021',
    title: '',
    text: ''
  }
  selectedFiltersNumber: number = 0;
  areaTooltipModal: ApplicationModalMessage = {
    modalId: 'cal023',
    title: '',
    text: ''
  }
  structureLevel5TooltipModal: ApplicationModalMessage = {
    modalId: 'cal024',
    title: '',
    text: ''
  }
  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  calibrationStatusOptions: any;
  isStatusFilterMenuOpen: any;
  isLoadingTranslations: boolean = false;
  statusFilterCopy: any;
  editMenuOption: any;
  statusFilterTooltip: ApplicationModalMessage = {
    modalId: 'cal026',
    title: '',
    text: ''
  }
  runningPhase: any;
  calibrationEnded: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    private modalService: ModalService,
    private urlService: UrlService,
    private cdr: ChangeDetectorRef
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[2];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.runningPhase = runningPhase;
            let today = moment();
            this.calibrationEnded = today.isAfter(this.runningPhase.phaseEndDate)
            this.route.params
              .subscribe(
                (params: Params) => {
                  this.processYear = params.processYear;
                  this.usersNumRecords = this.selectedRows.id;
                  this.getFilters();
                  this.getCountersData();
                  this.getUsersList();
                })
          }
        });
  }

  ngOnInit() {
    sessionStorage.setItem('manager', '');
    sessionStorage.setItem('userIdList', '');
    this.isLoadingTranslations = true;
    this.translate.get([
      'process.details.PERSON_DETAILS',
      'process.details.MANAGE_STI',
      'process.details.EXCLUDE_FROM_PROCESS',
      'process.sti.ENABLE_TITLE',
      'process.sti.ENABLE_INFO',
      'process.sti.GIVE_VALUE',
      'process.sti.GIVE_VALUE_INFO',
      'process.sti.SIGNED_DATE_TITLE',
      'process.sti.SIGNED_DATE_INFO',
      'process.sti.CONFIRM_ENABLE',
      'process.sti.CONFIRM_ENABLE_SUB',
      'process.sti.CONFIRM_ENABLE_TEXT',
      'process.manage.INCLUDE_TITLE',
      'process.manage.INCLUDE_INFO',
      'process.manage.EXCLUDE_TITLE',
      'process.manage.EXCLUDE_INFO',
      'headerDossier.processSetup.PARTICIPANT',
      'headerDossier.processSetup.OBJECTIVE_DEFINITION',
      'headerDossier.processSetup.PROCESS_PHASES',
      'process.filters.STI_ON',
      'process.filters.STI_NO',
      'process.filters.STI_OFF',
      'process.filters.EXCLUDED',
      'process.filters.ACTIVE',
      'calibration.DEFINE_PL',
      'calibration.CONFIRM_PL',
      'calibration.ACCESS_USER_DATA',
      'calibration.tooltip.WHO',
      'calibration.tooltip.WHO_DESCR',
      'calibration.filters.STRUCTURE',
      'calibration.filters.STRUCTURE_LEVEL',
      'calibration.filters.JOB',
      'calibration.filters.FUNCTION',
      'calibration.filters.STI_FILTER',
      'calibration.filters.DELETE_ALL_FILTERS',
      'calibration.filters.FILTER_ACTIVE',
      'calibration.tooltip.STRUCTURE_DESCR',
      'calibration.tooltip.STRUCTURE_LEVEL_DESCR',
      'calibration.tooltip.JOB_DESCR',
      'calibration.tooltip.FUNCTION_DESCR',
      'calibration.tooltip.STI_FILTER_DESCR',
      'calibration.tooltip.DELETE_ALL_FILTERS_DESCR',
      'calibration.tooltip.FILTER_ACTIVE_DESCR',
      'calibration.statuses.TO_COMPLETE',
      'calibration.statuses.TO_START',
      'calibration.statuses.TO_CONFIRM',
      'calibration.statuses.COMPELTED',
      'calibration.statuses.DISABLED',
      'calibration.modals.MASSIVE_PL_TEXT',
      'calibration.modals.CONTRIBUTORS',
      'performance.INSERT_OBSERVATION',
      'performance.OBSERVATION_TOOLTIP',
      'filter.ALL',
      'filter.M',
      'filter.F',
      'calibration.filters.GENDER',
      'calibration.tooltip.GENDER_DESCR',
      'calibration.filters.AREA',
      'calibration.tooltip.AREA_DESCR',
      'calibration.filters.STATUS',
      'calibration.tooltip.STATUS_DESCR',
      'calibration.filters.STRUCTURE_LEVEL_3',
      'calibration.tooltip.STRUCTURE_LEVEL_3_DESCR',
      'calibration.EDIT_PL'
    ]).subscribe((translations) => {
      this.translations = translations;

      this.observationTooltip = {
        modalId: "pl002",
        title: translations['performance.INSERT_OBSERVATION'],
        text: translations['performance.OBSERVATION_TOOLTIP'],
      }

      this.whoTooltipModal = {
        modalId: "cal001",
        title: translations['calibration.tooltip.WHO'],
        text: translations['calibration.tooltip.WHO_DESCR'],
      }

      this.structureTooltipModal = {
        modalId: "cal002",
        title: translations['calibration.filters.STRUCTURE'],
        text: translations['calibration.tooltip.STRUCTURE_DESCR'],
      }

      this.structureLevel3TooltipModal = {
        modalId: "cal003",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }
      this.structureLevel5TooltipModal = {
        modalId: "cal003",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }

      this.jobTooltipModal = {
        modalId: "cal004",
        title: translations['calibration.filters.JOB'],
        text: translations['calibration.tooltip.JOB_DESCR'],
      }

      this.functionTooltipModal = {
        modalId: "cal005",
        title: translations['calibration.filters.FUNCTION'],
        text: translations['calibration.tooltip.FUNCTION_DESCR'],
      }

      this.stiFilterTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.STI_FILTER'],
        text: translations['calibration.tooltip.STI_FILTER_DESCR'],
      }

      this.genderFilterTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.GENDER'],
        text: translations['calibration.tooltip.GENDER_DESCR'],
      }

      this.areaTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.AREA'],
        text: translations['calibration.tooltip.AREA_DESCR'],
      }

      this.statusFilterTooltip = {
        modalId: "cal025",
        title: translations['calibration.filters.STATUS'],
        text: translations['calibration.tooltip.STATUS_DESCR'],
      }

      this.editMenuOption = {
        id: 'define',
        title: translations['calibration.EDIT_PL'],
        icon: '/assets/img/icons/edit.svg',
      }

      this.menuOptions = [
        {
          id: 'define',
          title: translations['calibration.DEFINE_PL'],
          icon: '/assets/img/icons/edit.svg',
        },
        {
          id: 'confirm',
          title: translations['calibration.CONFIRM_PL'],
          icon: '/assets/img/icons/check-circle.svg',
        },
        {
          id: 'access',
          title: translations['calibration.ACCESS_USER_DATA'],
          icon: '/assets/img/icons/clipboard.svg',
        }
      ]

      this.isLoadingTranslations = false;
    })
  }

  // Associato alla direttiva clickOutside
  closeMenu(user: any) {
    user.isMenuOpen = false;
  }

  // menu utente
  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    user.isMenuOpen = !user.isMenuOpen
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, user: any, isConfirm?: boolean) {
    user.isMenuOpen = false;
    if (options.id == 'define') {
      this.router.navigate(['admin/definePerformanceLevel/' + this.processYear + '/' + user.userId])
    } else if (options.id == 'confirm') {
      this.openMassiveInclusion(user)
    } else {
      this.impersonate(user.userId)
    }
  }

  clearFilters() {
    this.selectedFilters = {
      chiaveSesso: this.filtersObject.chiaveSesso[0],
      denominazioneSO3: this.filtersObject.denominazioneSO3[0],
      denominazioneSO5: this.filtersObject.denominazioneSO5[0],
      area: this.filtersObject.area[0],
      funzione: this.filtersObject.funzione[0],
      mansione: this.filtersObject.mansione[0],
      structures: this.filtersObject.structures[0],
      sti: this.filtersObject.sti[0],
      calibrationStatusTypes: this.filtersObject.calibrationStatusTypes[0]
    }
    this.selectedFiltersNumber = 0;
    this.getUsersList(true);
  }

  orderBy(fieldName: string) {
  }

  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    this.selectedUserList = [];
    if (this.usersList && this.usersList.length > 0) {
      for (let i = 0; i < this.usersList.length; i++) {
        this.usersList[i].isChecked = this.allChecked;
        this.addRemoveUser(this.usersList[i]);
      }

    }
  }

  // apre i filtri
  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.getUsersList(true);
  }


  // ricerca
  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.getUsersList(true);
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersNumRecords = item.id;
    this.resetUserData();
    this.getUsersList(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.usersList = [];
  }

  // Recupera una lista di utenti
  getUsersList(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetUserData();
    }
    // Avvio il loader
    this.isLoadingUsers = true;
    this.adminService.countCalibrationUser(this.processYear, this.searchedText, this.managerId ? this.managerId : null, this.selectedFilters)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersCounter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.usersCurrentPage && this.usersNumRecords) {
                fromRecord = (this.usersCurrentPage - 1) * this.usersNumRecords;
              } else {
                fromRecord = 0;
              }

              if (this.usersCounter) {
                return this.adminService.listCalibrationUser(this.processYear, this.searchedText, fromRecord, this.usersNumRecords, this.managerId ? this.managerId : null, undefined, this.selectedFilters);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            for (let i = 0; i < data.response.length; i++) {
              let user = data.response[i];
              user.finalEvaluation = this.getFinalEvaluationStatus(user.finalEvaluationStatus);
              user.calibration = this.getCalibrationStatus(user.calibrationStatus)
              let selected = this.selectedUserList.find((tmp: any) => tmp.userId == user.userId);

              if (selected) {
                user.isChecked = true;
              }
            }
            this.usersList = data.response;
          }
          this.isLoadingUsers = false;
        }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Cambia la paginazione alla lista 
  usersPageChanged(newPage: any) {
    this.usersCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsersList();
  }

  // Check singolo utente
  selectUser(user: any) {
    user.isChecked = !user.isChecked;
    this.addRemoveUser(user);
    //this.selectedUserList = [];
    //for (let i = 0; i < this.usersList.length; i++) {
    //  if (this.usersList[i].isChecked) {
    //    this.selectedUserList.push(this.usersList[i]);
    //  }
    //}
  }

  addRemoveUser(user: any) {
    if (this.selectedUserList && this.selectedUserList.length) {
      if (!user.isChecked) {
        this.selectedUserList = this.selectedUserList.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedUserList.push(user);
      }
    } else {
      this.selectedUserList = [user];
    }

  }


  // Conversione stato valutazione finale utente
  getFinalEvaluationStatus(status: string) {
    switch (status) {
      case 'TO_COMPLETE':
        return {
          label: this.translations['calibration.statuses.TO_COMPLETE'],
          status: 'toStart'
        };
      case 'COMPLETED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }

  // Conversione stato calibration utente
  getCalibrationStatus(status: string) {
    switch (status) {
      case 'CALIBRATION_TODO':
        return {
          label: this.translations['calibration.statuses.TO_START'],
          status: 'toStart'
        };
      case 'CALIBRATION_TO_CONFIRM':
        return {
          label: this.translations['calibration.statuses.TO_CONFIRM'],
          status: 'inProgress'
        };
      case 'CALIBRATION_CONFIRMED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }


  selectFilter(data: any, id: any) {
    if (data.id == 'all') {
      this.selectedFiltersNumber--;
    }
    switch (id) {
      case 'structures':
        if (!this.selectedFilters.structures.value && this.selectedFilters.structures != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.structures = data;
        break;
      case 'denominazioneSO3':
        if (!this.selectedFilters.denominazioneSO3.value && this.selectedFilters.denominazioneSO3 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO3 = data;
        break;
      case 'denominazioneSO5':
        if (!this.selectedFilters.denominazioneSO5.value && this.selectedFilters.denominazioneSO5 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO5 = data;
        break;
      case 'mansione':
        if (!this.selectedFilters.mansione.value && this.selectedFilters.mansione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.mansione = data;
        break;
      case 'funzione':
        if (!this.selectedFilters.funzione.value && this.selectedFilters.funzione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.funzione = data;
        break;
      case 'area':
        if (!this.selectedFilters.area.value && this.selectedFilters.area != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.area = data;
        break;
      case 'sti':
        if (!this.selectedFilters.sti.value && this.selectedFilters.sti != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.sti = data;
        break;
      case 'chiaveSesso':
        if (!this.selectedFilters.chiaveSesso.value && this.selectedFilters.chiaveSesso != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.chiaveSesso = data;
        break;
      case 'calibrationStatusTypes':
        if (!this.selectedFilters.calibrationStatusTypes.value && this.selectedFilters.calibrationStatusTypes != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.calibrationStatusTypes = data;
        break;
      default:
        break;
    }
  }

  // ritorna i dati dei contatori relativi agli stati degli utenti nel processo
  getCountersData() {
    this.isLoadingCounters = true;

    if (this.getCountersData$) {
      this.getCountersData$.unsubscribe();
    }

    this.getCountersData$ = this.adminService.calibrationUsersCounter(this.processYear)
      .subscribe((data: SenecaResponse<number>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.counterData = data.response;
        }
        this.isLoadingCounters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCounters = false;
      });
  }


  // recupera il distinct dei filtri disponibili
  getFilters() {
    this.isLoadingFilters = true;

    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }

    this.getFiltersData$ = this.adminService.getDistinctFilters(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let all = { id: 'all', title: this.translations['filter.ALL'], value: null }
          let gender = [all, { id: 'M', title: this.translations['filter.M'], value: 'M' }, { id: 'F', title: this.translations['filter.F'], value: 'F' }]
          let denominazioneSO3: any = [all]
          if (data.response.denominazioneSO3 && data.response.denominazioneSO3.length) {
            for (let i = 0; i < data.response.denominazioneSO3.length; i++) {
              denominazioneSO3.push({
                id: data.response.denominazioneSO3[i],
                title: data.response.denominazioneSO3[i],
                value: data.response.denominazioneSO3[i]
              })
            }
          }
          let denominazioneSO5: any = [all]
          if (data.response.denominazioneSO5 && data.response.denominazioneSO5.length) {
            for (let i = 0; i < data.response.denominazioneSO5.length; i++) {
              denominazioneSO5.push({
                id: data.response.denominazioneSO5[i],
                title: data.response.denominazioneSO5[i],
                value: data.response.denominazioneSO5[i]
              })
            }
          }
          let funzione: any = [all]
          if (data.response.funzione && data.response.funzione.length) {
            for (let i = 0; i < data.response.funzione.length; i++) {
              funzione.push({
                id: data.response.funzione[i],
                title: data.response.funzione[i],
                value: data.response.funzione[i]
              })
            }
          }
          let mansione: any = [all]
          if (data.response.mansione && data.response.mansione.length) {
            for (let i = 0; i < data.response.mansione.length; i++) {
              mansione.push({
                id: data.response.mansione[i],
                title: data.response.mansione[i],
                value: data.response.mansione[i]
              })
            }
          }
          let area: any = [all]
          if (data.response.area && data.response.area.length) {
            for (let i = 0; i < data.response.area.length; i++) {
              area.push({
                id: data.response.area[i],
                title: data.response.area[i],
                value: data.response.area[i]
              })
            }
          }
          let structures: any = [all]
          if (data.response.structures && data.response.structures.length) {
            for (let i = 0; i < data.response.structures.length; i++) {
              structures.push({
                id: data.response.structures[i].structureId,
                title: data.response.structures[i].title,
                value: data.response.structures[i].structureId
              })
            }
          }
          this.filtersObject = {
            chiaveSesso: gender,
            denominazioneSO3: denominazioneSO3,
            denominazioneSO5: denominazioneSO5,
            funzione: funzione,
            mansione: mansione,
            area: area,
            structures: structures,
            sti: [
              all,
              {
                id: 'on',
                title: this.translations['process.filters.STI_ON'],
                value: 'WITH'
              },
              {
                id: 'off',
                title: this.translations['process.filters.STI_OFF'],
                value: 'WITHOUT'
              }
            ],
            calibrationStatusTypes: [
              all,
              {
                id: 'CALIBRATION_DISABLED',
                title: this.translations['calibration.statuses.DISABLED'],
                value: 'CALIBRATION_DISABLED'
              },
              {
                id: 'CALIBRATION_TODO',
                title: this.translations['calibration.statuses.TO_START'],
                value: 'CALIBRATION_TODO'
              },
              {
                id: 'CALIBRATION_TO_CONFIRM',
                title: this.translations['calibration.statuses.TO_CONFIRM'],
                value: 'CALIBRATION_TO_CONFIRM'
              },
              {
                id: 'CALIBRATION_CONFIRMED',
                title: this.translations['calibration.statuses.COMPELTED'],
                value: 'CALIBRATION_CONFIRMED'
              }]
          }
          this.selectedFilters = {
            chiaveSesso: this.filtersObject.chiaveSesso[0],
            denominazioneSO3: this.filtersObject.denominazioneSO3[0],
            denominazioneSO5: this.filtersObject.denominazioneSO5[0],
            funzione: this.filtersObject.funzione[0],
            mansione: this.filtersObject.mansione[0],
            area: this.filtersObject.area[0],
            structures: this.filtersObject.structures[0],
            sti: this.filtersObject.sti[0],
            calibrationStatusTypes: this.filtersObject.calibrationStatusTypes[0]
          }
          this.selectedFiltersNumber = 0;
        }
        this.isLoadingFilters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFilters = false;
      });
  }

  // Aggiorna il testo dell'osservazione quando confermo il PL di un singolo utente
  massiveModalObservationChanged(text: string) {
    this.massiveModalData.observation = text;
  }

  // redirect alla matrice, se da manager poi recupero i dati nella matrice
  // altrimenti metot nello store la lista degli utenti selezionati
  goToMatrix(fromManager?: boolean) {
    if (!fromManager) {
      // this.selectedUserList = [];
      // for (let i = 0; i < this.usersList.length; i++) {
      //   if (this.usersList[i].isChecked) {
      //     this.selectedUserList.push(this.usersList[i]);
      //   }
      // }
      let filter = '';
      if (!this.selectedFiltersNumber) {
        this.translate.instant('adminSearch.NO_FILTER_ACTIVE');
      } else if (this.selectedFiltersNumber == 1) {
        filter = this.translate.instant('adminSearch.ONE_FILTER');
      } else {
        filter = this.selectedFiltersNumber + ' ' + this.translate.instant('adminSearch.MORE_FILTERS');
      }
      //this.store.dispatch(AdminActions.SetMatrixDataUserList({ payload: this.selectedUserList }));
      // this.store.dispatch(AdminActions.SetFiltersForMatrix({ payload: filter }));
      let userIds = this.selectedUserList.map((user: any) => user.userId);
      sessionStorage.setItem('userIdList', JSON.stringify(userIds));
    }
    this.router.navigate(['admin/calibrationMatrix/' + this.processYear]);
  }


  // modale inclusione massiva, se ho un solo utente si adatta
  openMassiveInclusion(user?: any) {
    if (user) {
      // user.isChecked = true;
      this.massiveModalData.user = user;
      this.massiveModalData.managerObservation = user.managerObservation;
      this.massiveModalData.observation = user.adminObservation;
      this.massiveModalData.level = user.calibrationRating ? user.calibrationRating : user.finalEvaluationRating;
      this.massiveModalData.payout = user.payout >= 0 ? user.payout : null;
    } else {
      this.massiveModalText = this.translations['calibration.modals.MASSIVE_PL_TEXT'] + this.selectedUserList.length + this.translations['calibration.modals.CONTRIBUTORS']
    }

    this.modalService.open('massive-inclusion');
  }

  // chiude la modale di conferma e chiama i servizi
  closeMassiveInclusion(confirm?: boolean) {
    this.modalService.close('massive-inclusion');
    if (confirm) {
      if (this.selectedUserList && this.selectedUserList.length > 1) {
        this.confirmMassive();
      } else {
        this.confirmSingle();
      }
    }
  }

  // Conferma la calibration di un insieme di utenti
  confirmMassive() {
    this.isLoadingUsers = true;
    this.isLoadingCounters = true;
    let userIds = this.selectedUserList.map((user: any) => user.userId);

    this.adminService.massiveConfirmCalibration(this.processYear, userIds)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal009",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.getCountersData();
          this.getUsersList();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal010",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsers = false;
        this.isLoadingCounters = false;
      });
  }

  // Conferma la calibration di uno specifico utente
  confirmSingle() {
    this.isLoadingUsers = true;
    this.isLoadingCounters = true;
    let user = this.massiveModalData.user;

    let calibration = {
      calibrationId: user.calibrationId,
      userId: user.userId,
      adminObservation: user.managerObservation,
      adminObservationLevel: this.massiveModalData.level,
      payout: this.massiveModalData.payout,
      confirmed: true
    }
    this.closeMassiveInclusion()

    this.adminService.setCalibration(this.processYear, [calibration])
      .subscribe((setEvaluation: SenecaResponse<any>) => {
        if (setEvaluation && setEvaluation.error || !setEvaluation.response) {
          let error = setEvaluation.error ? this.translate.instant("errors." + setEvaluation.error) : this.translate.instant('errors.SOMETHING_WENT_WRONG');
          const messageObj: ApplicationModalMessage = {
            modalId: "cal008",
            text: error,
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.massiveModalData = {
            user: null,
            observation: '',
            managerObservation: '',
            level: '',
            payout: null
          };
          this.getCountersData();
          this.getUsersList();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "cal009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }


  filterByManager(user: any) {
    this.searchedText = '';

    sessionStorage.setItem('manager', JSON.stringify(user));

    // this.store.dispatch(AdminActions.SetMatrixDataManager({ payload: user }));

    this.goToMatrix(true);
  }

  impersonate(userId: string) {
    this.isLoadingUsers = true;
    this.adminService.impersonateUserForAdmin(userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let url = this.urlService.getApplicationUrl();
          // const redirectUrl = 
          let redirectUrl = '';
          if (window.location.href.indexOf('localhost') >= 0) {
            redirectUrl = 'http://localhost:4200/#/impersonateRedirect?token=' + data.response;
          } else {
            redirectUrl = url.url + '/#/impersonateRedirect?token=' + data.response;
          }

          window.open(redirectUrl, '_blank');
        }
        this.isLoadingUsers = false;
      },
        (err) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a013",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        })
  }

  isMenuOptionValid(option: any, user: any) {
    if (!user.calibration || user.calibration.status == 'disabled') {
      return false
    }
    return true;
  }

  canConfrimUserList() {
    let canConfirm = false;
    if (this.selectedUserList && this.selectedUserList.length) {
      canConfirm = true;
      for (let i = 0; i < this.selectedUserList.length; i++) {
        if (this.selectedUserList[i] && (!this.selectedUserList[i].calibration || this.selectedUserList[i].calibration.status == 'disabled' || this.selectedUserList[i].calibration.status == 'toStart')) {
          canConfirm = false;
        }
      }
    }
    return canConfirm;
  }


  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.exportCalibrationReport(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  onStatusFilterClicked(status: any) {
    //status.isChecked = !status.isChecked;
    if (status.isChecked) {
      this.selectedFiltersNumber++;
      this.selectedFilters.calibrationStatusTypes.push(status.value);
    } else {
      this.selectedFiltersNumber--;
      this.selectedFilters.calibrationStatusTypes = this.selectedFilters.calibrationStatusTypes.filter((tmp: any) => tmp != status.value)
    }
    this.getUsersList(true);
    this.isStatusFilterMenuOpen = false;
  }

  openStatusFilterMenu() {
    this.isStatusFilterMenuOpen = !this.isStatusFilterMenuOpen;
  }

  closeStatusFilterMenu() {
    this.isStatusFilterMenuOpen = false;
  }

  ngOnDestroy() {
    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }

    if (this.getCountersData$) {
      this.getCountersData$.unsubscribe();
    }

    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
  }
}


