import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { CollaboratorComponent } from './collaborator.component';
import { CollaboratorRoutingModule } from './collaborator-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CollaboratorHomeComponent } from './home/home.component';
import { collaboratorReducer } from './ngrx/collaborator.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CollaboratorEffects } from './ngrx/collaborator.effects';
import { Subordinateservice } from '../manager/services/subordinates.service';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { CollaboratorService } from '../shared/services/collaborator.service';
import { CollaboratorPersonDetailsComponent } from './personDetails/personDetails.component';
import { CollaboratorGoalDetailsComponent } from './goalDetails/goalDetails.component';
import { CreateUpdateGoalCollaboratorComponent } from './createUpdateGoal/createUpdateGoal.component';
import { CollaboratorOrganizeInterviewComponent } from './organizeInterview/organizeInterview.component';
import { CanDeactivateUpdateInterviewCollaboratorGuardService } from './services/can-deactivate-update-interview-collaborator-guard.component';
import { CanDeactivateUpdateGoalCollaboratorGuardService } from './services/can-deactivate-update-goal-collaborator-guard.component';
import { SendClapCollaboratorComponent } from './sendClap/sendClap.component';
import { CanDeactivateUpdateClapCollaboratorGuardService } from './services/can-deactivate-update-clap-collaborator-guard.component';
import { CreateUpdateDevelopmentActionCollaboratorComponent } from './createUpdateDevelopmentActionCollaborator/createUpdateDevelopmentActionCollaborator.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { AddMidTermReviewCollaboratorComponent } from './addMidTermReview/addMidTermReview.component';
import { FinalEvaluationCollaboratorComponent } from './finalEvaluation/finalEvaluation.component';
import { DefinePerformanceLevelCollaboratorComponent } from './definePerformanceLevel/definePerformanceLevel.component';

@NgModule({
  declarations: [
    CollaboratorComponent,
    CollaboratorHomeComponent,
    CollaboratorPersonDetailsComponent,
    CollaboratorGoalDetailsComponent,
    CreateUpdateGoalCollaboratorComponent,
    CollaboratorOrganizeInterviewComponent,
    SendClapCollaboratorComponent,
    CreateUpdateDevelopmentActionCollaboratorComponent,
    CreateUpdateEvidenceComponent,
    AddMidTermReviewCollaboratorComponent,
    FinalEvaluationCollaboratorComponent,
    DefinePerformanceLevelCollaboratorComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    CollaboratorRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forFeature('collaborator', collaboratorReducer),
    EffectsModule.forFeature([CollaboratorEffects])
  ],
  providers: [
    Subordinateservice,
    CollaboratorService,
    PersonDetailsGuard,
    CanDeactivateUpdateGoalCollaboratorGuardService,
    CanDeactivateUpdateInterviewCollaboratorGuardService,
    CanDeactivateUpdateClapCollaboratorGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CollaboratorModule { }
