<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.gt-xs="16px">

  <banner-home [phaseName]="phaseName" [backgroundSrc]="bannerImage" [isCollaborator]="true"
    [endDate]="runningPhase.phaseEndDateForUser ? runningPhase.phaseEndDateForUser : (runningPhase.deadlineDate ? runningPhase.deadlineDate : runningPhase.phaseEndDate)">
  </banner-home>

  <ng-container *ngIf="!isLoadingManagerInfo">
    <!-- Homepage collaboratori-->
    <div class="box-collection-container" *ngIf="!isChief">
      <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
        fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToPersonDetails()"
          imgPath="../../../../assets/img/icons/homeIcons/goals-img.png"
          title="{{'homeCards.collaborator.objectives.TITLE' | translate}}"
          description="homeCards.collaborator.objectives.DESCRIPTION">
        </box-home>
        <!--[isDisabled]="true" [disabledModalMessage]="feedbackDisabledModal"-->
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToFeedback()"
          imgPath="../../../../assets/img/icons/homeIcons/feedback-img.png"
          title="{{'homeCards.collaborator.feedbackCollection.TITLE' | translate}}"
          description="homeCards.collaborator.feedbackCollection.DESCRIPTION" [isNew]="false"></box-home>
        <!-- vai al piano di sviluppo -->
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToDevelopmentPlanPersonDetails()"
          imgPath="../../../../assets/img/icons/homeIcons/development-plan-img.png"
          title="{{'homeCards.collaborator.individualPlan.TITLE' | translate}}"
          description="homeCards.collaborator.individualPlan.DESCRIPTION" [isNew]="false"></box-home>
        <!-- Invia un applauso -->
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToSendApplause()"
          imgPath="../../../../assets/img/icons/homeIcons/applause-img.png"
          title="{{'homeCards.collaborator.applauseSend.TITLE' | translate}}"
          description="homeCards.collaborator.applauseSend.DESCRIPTION" [isNew]="false"></box-home>
        <!-- Richiedi feedback -->
        <ng-container *ngIf="peerFeedbackAllowed">
          <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToRequestFeedback()"
            imgPath="../../../../assets/img/icons/homeIcons/request-feedback-img.png"
            title="{{'homeCards.collaborator.feedbackRequest.TITLE' | translate}}"
            description="homeCards.collaborator.feedbackRequest.DESCRIPTION" [isNew]="false"></box-home>
        </ng-container>
        <ng-container *ngIf="!peerFeedbackAllowed">
          <box-home fxFlex.gt-sm="33" fxFlex.sm="50" [isDisabled]="true" [disabledModalMessage]="feedbackDisabledModal"
            imgPath="../../../../assets/img/icons/homeIcons/request-feedback-img.png"
            title="{{'homeCards.collaborator.feedbackRequest.TITLE' | translate}}"
            description="homeCards.collaborator.feedbackRequest.DESCRIPTION" [isNew]="false"></box-home>
        </ng-container>
        <!-- [isDisabled]="true" [disabledModalMessage]="interviewDisabledModal"-->
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToInterview()"
          imgPath="../../../../assets/img/icons/homeIcons/interview-img.png"
          title="{{'homeCards.collaborator.interviews.TITLE' | translate}}"
          description="homeCards.collaborator.interviews.DESCRIPTION" [isNew]="false"></box-home>
        <!-- Mid term review -->
        <ng-container *ngIf="isLoadingMidTermData">
          <div class="full-width">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoadingMidTermData">
          <mid-term-box-home fxFlex.sm="99" fxFlex.gt-sm="99" fxFlex.xs="99" fxFlex.xxs="99"
            imgPath="../../../../assets/img/icons/homeIcons/mid-term-img.png" title="{{'midTerm.TITLE' | translate}}"
            [midTermData]="midTermData" (onButtonClicked)="goToMidTerm()">
          </mid-term-box-home>
        </ng-container>
      </div>
    </div>
    <!-- Homepage per Chief -->
    <div class="box-collection-container" *ngIf="isChief">
      <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
        fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">
        <!-- Feedback -->
        <box-home fxFlex.gt-sm="50" fxFlex.sm="50" (onButtonClicked)="goToFeedback()"
          imgPath="../../../../assets/img/icons/homeIcons/feedback-img.png"
          title="{{'homeCards.collaborator.feedbackCollection.TITLE' | translate}}"
          description="homeCards.collaborator.feedbackCollection.DESCRIPTION" [isNew]="false"></box-home>
        <!-- Invia un applauso -->
        <box-home fxFlex.gt-sm="50" fxFlex.sm="50" (onButtonClicked)="goToSendApplause()"
          imgPath="../../../../assets/img/icons/homeIcons/applause-img.png"
          title="{{'homeCards.collaborator.applauseSend.TITLE' | translate}}"
          description="homeCards.collaborator.applauseSend.DESCRIPTION" [isNew]="false"></box-home>
        <!-- Richiedi Feedback -->
        <box-home fxFlex.gt-sm="50" fxFlex.sm="50" (onButtonClicked)="goToRequestFeedback()"
          imgPath="../../../../assets/img/icons/homeIcons/request-feedback-img.png"
          title="{{'homeCards.collaborator.feedbackRequest.TITLE' | translate}}"
          description="homeCards.collaborator.feedbackRequest.DESCRIPTION" [isNew]="false"></box-home>
        <!-- Appunti privati -->
        <box-home fxFlex.gt-sm="50" fxFlex.sm="50" (onButtonClicked)="goToTakeNote()"
          imgPath="../../../../assets/img/icons/homeIcons/notes-img.png"
          title="{{'homeCards.manager.notes.TITLE' | translate}}" description="homeCards.manager.notes.DESCRIPTION">
        </box-home>
        <!-- Mid term review -->
        <ng-container *ngIf="isLoadingMidTermData">
          <div class="full-width">
            <div class="loader spinner-big"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoadingMidTermData">
          <mid-term-box-home fxFlex.sm="99" fxFlex.gt-sm="99" fxFlex.xs="99" fxFlex.xxs="99"
            imgPath="../../../../assets/img/icons/homeIcons/mid-term-img.png" title="{{'midTerm.TITLE' | translate}}"
            [midTermData]="midTermData" (onButtonClicked)="goToMidTerm()">
          </mid-term-box-home>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoadingManagerInfo">
    <div class="box-collection-container">
      <div class="loader spinner-big"></div>
    </div>
  </ng-container>
  <!-- TODO solo finchè testiamo -->
  <!--div class="full-width" fxLayout="row" fxLayoutAlign="center center">
    <europ-button label="Reset Ack" (onButtonClicked)="resetOnboardingAck()" type="secondary bordless"></europ-button>
  </div-->
</div>