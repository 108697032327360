import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { CountUsersForImpersonateResponse, ListUsersForImpersonateResponse, PerfCareUserCalibration, SenecaResponse } from "../../../commonclasses";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';

@Injectable()
export class AdminService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  countPerformanceProcesses() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-performance-assessments-for-admin/')
  }


  listPerformanceProcesses(fromRecord: number, numRecords: number, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-performance-assessments-for-admin/', {
      params: httpParams
    });
  }

  processStructureDetail(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/monitoring-structures-for-admin/', {
      params: httpParams
    });
  }

  exportStructureReport(perfCareYear: string, preSharedKey?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/export-monitoring-structures-for-admin-report/', {
      params: httpParams
    });
  }



  // // Recupera le Behaviour options per gli obiettivi
  // getBehaviourOptions(fromRecord: string, numRecords: string, allData?: boolean, parentTagId?: string) {
  //     let httpParams = new HttpParams();
  //     httpParams = httpParams.append('fromRecord', fromRecord);
  //     httpParams = httpParams.append('numRecords', numRecords);
  //     if (allData) {
  //         httpParams = httpParams.append('allData', allData.toString());
  //     }
  //     if (parentTagId && parentTagId.length) {
  //         httpParams = httpParams.append('parentTagId', parentTagId);
  //     }
  //     return this.http.get<SenecaResponse<GetBehaviorCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-behavior-care-tags/', {
  //         params: httpParams
  //     });
  // }

  // // Crea un obiettivo
  // createGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
  //     return this.http.post<SenecaResponse<CreateUserGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-goal-for-manager', {
  //         perfCareYear: perfCareYear,
  //         perfCareUserPhaseGoals: goal
  //     });
  // }

  countUserImpersonate(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    return this.http.get<SenecaResponse<CountUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-users-for-impersonate/', {
      params: httpParams
    })
  }


  listUserImpersonate(fromRecord: number, numRecords: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    return this.http.get<SenecaResponse<ListUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-users-for-impersonate/', {
      params: httpParams
    });
  }

  impersonateUserForAdmin(userIdToImpersonate: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userIdToImpersonate', userIdToImpersonate);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/impersonate-user-for-admin/', {
      params: httpParams
    });
  }

  // contatori utenti calibration
  calibrationUsersCounter(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-calibration-users-count-for-admin', {
      params: httpParams
    });
  }

  countCalibrationUser(perfCareYear: number, searchedText: string, managerUserId?: string, filters?: any, userIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchedText);
    if (managerUserId) {
      httpParams = httpParams.append('managerUserId', managerUserId);
    }
    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }
    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('funzione', filters.funzione.value);
      } 0
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('area', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.sti && filters.sti.value) {
        httpParams = httpParams.append('sti', filters.sti.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('structureId', filters.structures.value);
      }
      if (filters.calibrationStatusTypes && filters.calibrationStatusTypes.value) {
        httpParams = httpParams.append('calibrationStatusTypes', filters.calibrationStatusTypes.value);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-calibration-users-for-admin', {
      params: httpParams
    });
  }


  // Lista utenti calibration
  listCalibrationUser(perfCareYear: number, searchedText: string, fromRecord: number, numRecords: number, managerUserId?: string, userIds?: string[], filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (managerUserId) {
      httpParams = httpParams.append('managerUserId', managerUserId);
    }

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('funzione', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('area', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.sti && filters.sti.value) {
        httpParams = httpParams.append('sti', filters.sti.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('structureId', filters.structures.value);
      }
      if (filters.calibrationStatusTypes && filters.calibrationStatusTypes.value) {
        httpParams = httpParams.append('calibrationStatusTypes', filters.calibrationStatusTypes.value);
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-calibration-users-for-admin', {
      params: httpParams
    });
  }

  // Ritorna range payout
  getPayoutRange() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-payout-range-by-final-evaluation', {});
  }

  // recap di tutti i dati necessari per definire la calibration
  getUserCalibrationData(perfCareYear: number | string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-calibration-data-for-admin', {
      params: httpParams
    });
  }

  setCalibration(perfCareYear: number, perfCareUserCalibrationToUpdate: any) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-calibration-for-admin', {
      perfCareYear: perfCareYear,
      perfCareUserCalibrationToUpdate: perfCareUserCalibrationToUpdate
    });
  }

  massiveConfirmCalibration(perfCareYear: number, userIdsToConfirmCalibration: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/massive-confirm-calibration-for-admin', {
      perfCareYear: perfCareYear,
      userIdsToConfirmCalibration: userIdsToConfirmCalibration
    });
  }

  getDistinctFilters(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-users-distincti-field-filter-for-admin', {
      params: httpParams
    });
  }


  exportCalibrationReport(perfCareYear: number, userIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (!!userIds) {
      if (userIds && userIds.length && Array.isArray(userIds)) {
        userIds && userIds.forEach(type => {
          httpParams = httpParams.append('userIds', type);
        });
      } else if (userIds && userIds.length && !Array.isArray(userIds)) {
        httpParams = httpParams.append('userIds', userIds);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/export-calibration-report', {
      params: httpParams
    });
  }
}