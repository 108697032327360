<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="personDetails"
      (onBackFunction)="(goalId && goalId.length) ? goToUpdateGoal(true): goToCreateGoal(true)" [isBackButton]="true"
      title="{{(isEdit ? (('goalSetting.createGoal.SIDENAV_TITLE_COLLABORATOR_IN_EDIT' | translate) + runningYear) :  (('goalSetting.createGoal.SIDENAV_TITLE_COLLABORATOR' | translate) + runningYear))}}"
      description="{{('goalSetting.createGoal.SIDENAV_DESCRIPTION_COLLABORATOR' | translate)}}">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingUpdateGoal && !isFetchingCreateGoal">
        <div *ngIf="!isHowSection" class="form-container-what">
          <div class="tabs-container">
            <tab-section *ngFor="let tab of tabSectionsList; trackBy tabId" (onClick)="onTabClicked($event)"
              [tab]="tab"></tab-section>
          </div>
          <input-container [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="titleTooltipModal" [isValued]="goal.title && goal.title.trim().length"
            [isObbligatory]="true" title="{{'goalSetting.createGoal.OBJECTIVE_TITLE_COLLABORATOR' | translate}}">
            <europ-input [bindValue]="goal.title" (onModelChanged)="goalTitleChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_TITLE_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-input>
          </input-container>
          <input-container [numberCharsTextArea]="charsGoalDescription" [isFromTextArea]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="goal.description && goal.description.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.DESCRIPTION' | translate}}">
            <europ-text-area [bindValue]="goal.description" [maxlength]="600"
              (onModelChanged)="goalDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.DESCRIPTION_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-text-area>
          </input-container>
          <input-container [numberCharsTextArea]="charsGoalObjectiveDescription" [isFromTextArea]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="objCompletedTooltipModal"
            [isValued]="goal.objectiveDescription && goal.objectiveDescription.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_DESCRIPTION_COLLABORATOR' | translate}}">
            <europ-text-area [bindValue]="goal.objectiveDescription" [maxlength]="600"
              (onModelChanged)="goalObjectiveDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_DESCRIPTION_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-text-area>
          </input-container>
          <div class="button-container">
            <europ-button [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
              iconRight="assets/img/icons/arrow-right.svg" type="primary default" size="large"
              label="{{'generic.HOW' | translate}}" (onButtonClicked)="goToHowSection()"></europ-button>
          </div>
        </div>

        <div *ngIf="isHowSection" class="form-container-how">
          <div class="tabs-container">
            <tab-section *ngFor="let tab of tabSectionsList; trackBy tabId" (onClick)="onTabClicked($event)"
              [tab]="tab"></tab-section>
          </div>
          <input-container
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal || isFetchingCareOptions"
            [tooltipModalMessage]="careTooltipModal" [isValued]="goal.careTag" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_CARE_COLLABORATOR' | translate}}">
            <europ-select (onSelectedItem)="changeCareTag($event)" [selectedItem]="goal.careTag" [items]="careOptions"
              [loadingData]="isFetchingCareOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_CARE_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>
          <!--input-container [disabled]="!goal.careTag"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal || isFetchingBehaviourOptions"
            [tooltipModalMessage]="behaviourTooltipModal" [isValued]="goal.behaviorTag"
            title="{{'goalSetting.createGoal.OBJECTIVE_BEHAVIOUR' | translate}}">
            <europ-select [notSeeDescription]="true" [disabled]="!goal.careTag"
              (onSelectedItem)="changeBehaviourTag($event)" [selectedItem]="goal.behaviorTag" [items]="behaviourOptions"
              [loadingData]="isFetchingCareOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_BEHAVIOUR_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container-->
          <input-container [numberCharsTextArea]="charsGoalHowToUseDescription" [isFromTextArea]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="applyCareTooltipModal"
            [isValued]="goal.howToUseCareTag && goal.howToUseCareTag.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.HOW_TO_USE_CARE_COLLABORATOR' | translate}}">
            <europ-text-area [bindValue]="goal.howToUseCareTag" [maxlength]="600"
              (onModelChanged)="goalHowToUseCareChanged($event)"
              placeholder="{{'goalSetting.createGoal.HOW_TO_USE_CARE_COLLABORATOR_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <!-- <input-container [numberCharsTextArea]="charsGoalObjectiveRelevantMotivation" [isFromTextArea]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="objCompletedTooltipModal"
            [isValued]="goal.objectiveRelevantMotivation && goal.objectiveRelevantMotivation.trim().length"
            [isObbligatory]="true" title="{{'goalSetting.createGoal.OBJECT_RELEVANT_MOTIVATION' | translate}}">
            <europ-text-area [bindValue]="goal.objectiveRelevantMotivation" [maxlength]="600"
              (onModelChanged)="goalObjectRelevantMotivationChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECT_RELEVANT_MOTIVATION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container> -->
          <div class="button-container-how">
            <europ-button [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal"
              iconLeft="assets/img/icons/arrow-left.svg" type="primary default" size="large"
              label="{{'generic.WHAT' | translate}}" (onButtonClicked)="goToWhatSection()"></europ-button>
            <europ-button
              [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingCreateGoal || noDataInserted()"
              type="primary default" size="large"
              label="{{(goalId && goalId.length) ? (getButtonUpdateText()) : (getButtonSaveText())}}"
              (onButtonClicked)="(goalId && goalId.length) ? goToUpdateGoal(): goToCreateGoal()">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isFetchingUpdateGoal || isFetchingCreateGoal">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="modalUpdateGoalForUser" (onCancel)="goBackBrowser()" (onClose)="closeModalGoalUpdate()"
  (onConfirm)="(goalId && goalId.length) ? updateGoal() : createGoal()"
  cancelLabel="{{modalUpdateGoalTextCloseTextButton}}" confirmLabel="{{modalUpdateGoalTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalUpdateGoalId" [title]="modalUpdateGoalTitle" [subtitle]="''"
    [text]="modalUpdateGoalText">
  </modal-text-content>
</modal>