<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="sendRevisionAction(true)" [isBackButton]="true"
      [title]="'calibration.post.SIDENAV_TITLE' | translate" [description]="sidenavDescription">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserFinalEvaluation && !isLoadingManagerInfo">
        <ng-container *ngIf="userData && userFinalEvaluation && userFinalEvaluation.level">
          <calibration-recap [user]="userData" [finalEvaluationData]="userFinalEvaluation"></calibration-recap>
          <div class="divisor-vertical-line"></div>
        </ng-container>
        <!-- recap dati utente e mid term review -->
        <calibration-recap [user]="managerData" [finalEvaluationData]="managerEvaluation"></calibration-recap>
        <!-- Risposta del manager alla review -->
        <div class="divisor-vertical-line"></div>
        <calibration-recap [user]="calibrationUserData.user" [whiteBackground]="true"
          [finalEvaluationData]="calibrationUserData.finalEvaluation">
        </calibration-recap>
        <div class="performance-level-input">
          <!-- Osservazioni -->
          <input-container [numberCharsTextArea]="charsFinalObservation" [isFromTextArea]="true"
            [isLoading]="isLoadingManagerInfo" [tooltipModalMessage]="observationTooltip"
            [isValued]="finalObservation && finalObservation.trim().length" [isObbligatory]="true"
            title="{{'calibration.post.CONFIRM_EVAL' | translate}}">
            <europ-text-area [bindValue]="finalObservation" [maxlength]="600"
              (onModelChanged)="finalObservationChanged($event)"
              placeholder="{{'performance.OBSERVATION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>

          <div class="action-container">
            <europ-button type="primary" [label]="'generic.CONFIRM' | translate" (onButtonClicked)="openConfirmModal()">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingManagerInfo || isLoadingUserFinalEvaluation">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale info -->
<modal id="modal-info" (onClose)="closeModalInfo()" (onConfirm)="closeModalInfo()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <p class="modal-title center" translate="evaluations.modals.INFO_TITLE"></p>
  <div class="divisor-line"></div>
  <div class="performance-level-mark-info-container">
    <div class="title-row">
      <evaluation evaluation="UNSATISFACTORY" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.UNSATISFACTORY"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="IMPROVABLE" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.IMPROVABLE"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="MEET" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.MEET"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEEDED" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEEDED"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEPTIONAL" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEPTIONAL"></p>
  </div>
</modal>

<!-- Modale di conferma osservazioni -->
<modal id="recapConfirm" (onCancel)="closeConfirmModal(false, true)" (onClose)="closeConfirmModal()"
  (onConfirm)="closeConfirmModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content modalId="pc002" [title]="('calibration.post.CONFIRM_TITLE' | translate)"
    [subtitle]="('calibration.post.CONFIRM_SUBTITLE' | translate)"
    [text]="('calibration.post.CONFIRM_TEXT' | translate)"></modal-text-content>
</modal>