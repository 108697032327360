import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SenecaResponse, DeleteMyGoalsResponse, GetMyManagerUserDataResponse, PerfCareDevelopmentActionStatuses, CanShareDevelopmentActionToManagerResponse } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription } from 'rxjs';
import { GoalUtils, NON_VALUABLE_TYPES } from 'src/app/utils/goal.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import * as moment from 'moment';
import { ActionUtils } from 'src/app/utils/action.utils';
import { PerfCareGoalRentStatuses, PerfCareUserPhaseGoal } from 'atfcore-commonclasses';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-goalDetails',
  templateUrl: './goalDetails.component.html',
  styleUrls: ['./goalDetails.component.scss']
})
export class CollaboratorGoalDetailsComponent implements OnInit, OnDestroy {
  personDetails: any;
  isLoadingPersonDetails = true;

  userId: string = '';
  runningYear$: Subscription;
  runningYear: number = 0;
  noDataPlaceholder: string = '';

  canShareGoals$: Subscription = new Subscription;
  goalId: any;
  isLoadingGoalData: boolean = true;
  getGoalData$: Subscription = new Subscription;
  goalData: any;

  deleteModalContent: ApplicationModalMessage = {
    modalId: 'deleteModal',
    title: '',
    text: ''
  };

  objectiveCompleteTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  feedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  evidencesTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  milestonesTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  evaluationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  idpTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  commentData?: { text: any; date: any; };
  managerData?: GetMyManagerUserDataResponse;
  // Piano di sviluppo
  developmentActionsList: any;
  developActionForModal: any;
  developActionModalText: string = '';
  addCommentToDevelopAction$: Subscription = new Subscription;
  developmentActionId: string = '';
  isLoadingCanCreateDevelopmentActions: boolean = false;
  canCreateDevelopmentActions$: Subscription = new Subscription;
  canCreateDevActions: boolean = false;
  developAction: any;
  developActionEdit: any;
  commentToEdit: any;
  actionForCommentDelete: any;
  commentIdToDelete: string = '';
  isLoadingDeleteDevelopAction: boolean = false;
  deleteDevelopAction$: Subscription = new Subscription;
  developActionIdToDelete: string = '';
  currentActionStatus: any;
  changeActionStatus$: Subscription = new Subscription;
  developActionStatusesOptions: { id: any; title: any; }[] = [];
  newActionComment: string = '';
  shareDevelopActions$: Subscription = new Subscription;
  isFetchingCanShareActions: boolean = false;
  canShareDevActions: boolean = false;
  canShareDevelopActions$: Subscription = new Subscription;
  evidencesList: any[] = [];
  isLoadingGetDownloadUrlOnEvidence: boolean = false;
  // peer feedback
  peerFeedbackList: any = [];
  peerFeedbackPlaceholder: string = '';
  peerFeedbackToDelete: string = '';
  getPeerFeedbackList$: Subscription = new Subscription;
  feedbackForRequestModal: any;
  deletePeerFeedback$: Subscription = new Subscription;
  peerFeedbackAllowed$: Subscription = new Subscription;
  peerFeedbackAllowed: boolean = false;
  // Valutazione finale
  today = moment().format();
  evaluationStatusData: any;
  finalEvaluationForModal: any;
  getRunningPhase$: Subscription;
  runningPhase: any;
  evalPhaseEnd: any;
  evaluationStatus = [PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED, PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE];
  isLoadingEvaluationDates: boolean = false;
  getFinalEvaluationDates$: Subscription = new Subscription;
  selectedYear: { id: any; name: string; } = {
    id: '',
    name: ''
  };
  excludeStatus = [
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE,
  ]

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private collaboratorService: CollaboratorService,
    private authService: AuthService,
    private router: Router,
    public redirectService: RedirectService,
    public modalService: ModalService) {

    this.peerFeedbackAllowed$ = this.store.select(fromApp.getPeerFeedbackAllowed)
      .subscribe((peerFeedbackAllowed) => {
        this.peerFeedbackAllowed = peerFeedbackAllowed;
      })

    this.getRunningPhase$ = this.store.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
      this.runningPhase = runningPhase;
    })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;
              this.developmentActionId = params.developmentActionId;

              if (params.perfCareYear) {
                this.selectedYear = {
                  id: params.perfCareYear,
                  name: 'Anno ' + params.perfCareYear
                }
              } else {
                this.selectedYear = {
                  id: 2021,
                  name: 'Anno ' + 2021
                }
              }

              this.isLoadingPersonDetails = true;
              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.personDetails = loggedUser && loggedUser.user;
                    this.isLoadingPersonDetails = false;
                    // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                    if (this.personDetails && this.personDetails.userId) {
                      this.isLoadingPersonDetails = false;
                      this.getFinalEvaluationDates()
                      if (this.developmentActionId) {
                        this.getDevelopmentActionById();
                      } else {
                        this.getGoalData();
                      }
                    }
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.INFORMATIONS',
        'goalSetting.WHAT_OBSERVE_WHEN_OBJECTIVE_REACHED',
        'goalSetting.DESCRIBE_ELEMENTS_YOU_OBSERVE',
        'goalSetting.FEEDBACK',
        'goalSetting.FEEDBACK_DESCRIPTION',
        'goalSetting.EVIDENCES',
        'goalSetting.EVIDENCES_DESCRIPTION',
        'goalSetting.PRATICABLE_STEPS',
        'goalSetting.PRATICABLE_STEPS_DESCRIPTION',
        'goalSetting.VALUTATION',
        'goalSetting.VALUTATION_DESCRIPTION',
        'goalSetting.DEVELOPMENT_PLAN',
        'goalSetting.DEVELOPMENT_PLAN_DESCRIPTION',
        "developmentPlan.statuses.toStart",
        "developmentPlan.statuses.inProgress",
        "developmentPlan.statuses.completed"
      ])
      .subscribe(translations => {
        this.objectiveCompleteTooltipModal = {
          modalId: "003",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.WHAT_OBSERVE_WHEN_OBJECTIVE_REACHED'],
          text: translations['goalSetting.DESCRIBE_ELEMENTS_YOU_OBSERVE']
        };
        this.feedbackTooltipModal = {
          modalId: "004",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.FEEDBACK'],
          text: translations['goalSetting.FEEDBACK_DESCRIPTION']
        };
        this.evidencesTooltipModal = {
          modalId: "005",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.EVIDENCES'],
          text: translations['goalSetting.EVIDENCES_DESCRIPTION']
        };
        this.milestonesTooltipModal = {
          modalId: "006",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.PRATICABLE_STEPS'],
          text: translations['goalSetting.PRATICABLE_STEPS_DESCRIPTION']
        };
        this.evaluationTooltipModal = {
          modalId: "007",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.VALUTATION'],
          text: translations['goalSetting.VALUTATION_DESCRIPTION']
        };
        this.idpTooltipModal = {
          modalId: "008",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.DEVELOPMENT_PLAN'],
          text: translations['goalSetting.DEVELOPMENT_PLAN_DESCRIPTION']
        };
        this.developActionStatusesOptions = [
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_START,
            title: translations["developmentPlan.statuses.toStart"]
          },
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_STARTED,
            title: translations["developmentPlan.statuses.inProgress"]
          },
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_COMPLETED,
            title: translations["developmentPlan.statuses.completed"]
          }
        ]
      })
  };

  // Recupera un booleano per sapere se si possono creare azioni di sviluppo
  canCreateActions() {
    this.isLoadingCanCreateDevelopmentActions = true;
    if (this.canCreateDevelopmentActions$) {
      this.canCreateDevelopmentActions$.unsubscribe();
    }

    this.canCreateDevelopmentActions$ = this.collaboratorService.canCreateDevelopmentActions(this.selectedYear.id)
      .subscribe((data: any) => {
        if (data.error) {
        } else if (data.response) {
          this.canCreateDevActions = true;
        } else {
          this.canCreateDevActions = false;
        }
        this.isLoadingCanCreateDevelopmentActions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCanCreateDevelopmentActions = false;
      }
      );
  }

  // Vai a creare l'azione di sviluppo associata all'obiettivo
  goToCreateActionToGoal() {
    if (this.canCreateDevActions) {
      this.router.navigate(['./collaborator/createUpdateDevelopmentAction/' + this.userId + '/' + this.goalId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "603",
        title: this.translate.instant("developmentPlan.ADD_DEVELOPMENT_PLAN"),
        subtitle: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION"),
        text: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION_TEXT_COLL"),
        confirmLabel: this.translate.instant("generic.CLOSE")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // Apre la modale per condividere le azioni di sviluppo
  openDevelopActionsShareModal() {
    this.modalService.open('developActionsShareModal');
  }

  // Chiude la modale per condividere le azioni di sviluppo
  closeDevelopActionsShareModal() {
    this.modalService.close('developActionsShareModal');
  }

  // Condividi le azioni di sviluppo
  shareDevelopActions() {
    this.closeDevelopActionsShareModal();
    this.isLoadingGoalData = true;
    if (this.shareDevelopActions$) {
      this.shareDevelopActions$.unsubscribe();
    }

    this.shareDevelopActions$ = this.collaboratorService.shareDevelopActions(this.selectedYear.id.toString())
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.canShareActions();
        this.getGoalData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalData = false;
      }
      );
  }

  // Se il collaboratore può condividere le azioni di sviluppo
  canShareActions() {
    this.isFetchingCanShareActions = true;

    this.canShareDevelopActions$ = this.collaboratorService.canShareDevelopmentActions(this.selectedYear.id)
      .subscribe((data: SenecaResponse<CanShareDevelopmentActionToManagerResponse>) => {
        if (data && data.error) {
          this.canShareDevActions = false;
          this.isFetchingCanShareActions = false;
        } else if (data && data.response) {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = true;
        } else {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = false;
        }
      }, (err?: any) => {
        this.isFetchingCanShareActions = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "000",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  // Recupera l'obiettivo dal goalId
  getGoalData() {
    this.isLoadingGoalData = true;
    if (this.getGoalData$) {
      this.getGoalData$.unsubscribe();
    }

    this.getGoalData$ = this.collaboratorService.getGoalByIdForUser(this.selectedYear.id.toString(), this.goalId)
      .subscribe((data: SenecaResponse<PerfCareUserPhaseGoal>) => {
        if (data && data.error) {
          this.goToPersonDetails();
          const messageObj: ApplicationModalMessage = {
            modalId: "040",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goalData = GoalUtils.formatGoalCardData(data.response, false, this.userId);
          this.developmentActionsList = ActionUtils.formatActionList(data.response.developmentAction, this.personDetails, true, this.translate);
          this.evidencesList = data.response.evidenceFeedbacks || [];
          this.peerFeedbackList = data.response.peerFeedbacks;
          this.peerFeedbackPlaceholder = this.translate.instant('peerFeedback.PLACEHOLDER_COLLABORATOR');
          if (this.evidencesList && this.evidencesList.length) {
            this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
              moment.locale(applicationLang);
              for (let i = 0; i < this.evidencesList.length; i++) {
                this.evidencesList[i].formattedCreationDate = moment(this.evidencesList[i].creationDate).fromNow();
              }
            })
          }
          if (this.goalData.managerComment) {
            this.commentData = {
              text: this.goalData.managerComment,
              date: moment(this.goalData.managerCommentDate).format('DD.MM.YYYY')
            }
          }
          if (this.goalData.isChallenge) {
            this.canCreateActions();
            this.canShareActions();
          }
          if (this.evaluationStatus.includes(this.goalData.goalStatus)) {
            this.evaluationStatusData = {
              status: this.goalData.status,
              statusMessage: this.goalData.statusMessage

            }
          }
        }
        if (this.commentData) {
          this.collaboratorService.getManagerByUserId().toPromise().then((manager) => {
            if (manager && manager.response) {
              this.managerData = manager.response;
            }
            this.isLoadingGoalData = false;
          })
        } else {
          this.isLoadingGoalData = false;
        }
      },
        (err: string) => {
          this.goToPersonDetails();
          const messageObj: ApplicationModalMessage = {
            modalId: "041",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalData = false;
        })
  }

  // Organizza colloquio
  goToInterview() {
    this.router.navigate(['./collaborator/organizeInterview/' + this.userId]);
  }

  // Vai alla lista obiettivi dell'utente in caso di errore o back
  goToPersonDetails() {
    this.redirectService.goBackBrowser();
  }

  // Vai alla modifica dell'obiettivo
  goToEditGoal() {
    this.router.navigate(["/collaborator/createUpdateGoal/" + this.userId + '/' + this.goalData.id]);
  }

  // Apre il modale per eliminare l'obiettivo
  deleteGoal() {
    this.deleteModalContent = {
      modalId: "142",
      title: this.translate.instant('goal.DELETE_MODAL_TITLE'),
      text: this.translate.instant('goal.DELETE_MODAL_CONTENT')
    }
    this.modalService.open('confirmDeleteForUser')
  }

  // Elimina l'obiettivo
  confirmDeleteGoal() {
    this.isLoadingGoalData = true;
    this.collaboratorService.deleteGoals(this.selectedYear.id, [this.goalId])
      .subscribe((result: SenecaResponse<DeleteMyGoalsResponse>) => {
        if (result && result.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "042",
            text: this.translate.instant("errors." + result.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (result && result.response) {
          this.goToPersonDetails();
        }
        this.isLoadingGoalData = false;
      },
        (err: string) => {
          this.goToPersonDetails();
          const messageObj: ApplicationModalMessage = {
            modalId: "043",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalData = false;
        })
  }

  getDevelopmentActionById() {
  }

  // funzioni commento azioni
  // Apre la modale per cancellare il commento dell'azione di sviluppo
  openDeleteCommentModalDevelopAction(actionObject: any) {
    this.actionForCommentDelete = actionObject.action;
    this.commentIdToDelete = actionObject.commentId;
    this.modalService.open('deleteDevelopActionCommentModal');
  }

  // Chiude la modale per cancellare il commento dell'azione di sviluppo
  closeDeleteCommentModalDevelopAction() {
    this.modalService.close('deleteDevelopActionCommentModal');
  }

  // Cancella commento dell'azione di sviluppo
  deleteCommentDevelopAction() {
    this.isLoadingGoalData = true;
    this.closeDeleteCommentModalDevelopAction();
    this.addCommentToDevelopAction$ = this.collaboratorService.deleteCommentToDevelopPlan(this.selectedYear.id.toString(), this.actionForCommentDelete.id, this.commentIdToDelete)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d033",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d034",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getGoalData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d035",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getGoalData();
      });
  }
  // Apre modale per aggiungere il commento all'azione di sviluppo
  openInsertCommentToDevelopAction(developAction: any) {
    this.developAction = developAction;
    this.newActionComment = '';
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + developAction.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      developAction.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + developAction.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + developAction.toStartDate + '</p>';
    this.modalService.open('insertCommentDevelopmentPlanModal');
  }

  // Aggiungi il commento all'azione di sviluppo
  addCommentToDevelopAction() {
    this.isLoadingGoalData = true;
    this.closeInserCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.collaboratorService.addCommentToDevelopPlan(this.selectedYear.id.toString(), this.developAction.id, this.newActionComment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d024",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d025",
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT_CORRECTLY"),
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.newActionComment = '';
        this.developAction = null;
        this.getGoalData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d026",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.newActionComment = '';
        this.developAction = null;
        this.getGoalData();
      });
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeInserCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('insertCommentDevelopmentPlanModal');
    if (cancel) {
      this.newActionComment = '';
      this.developAction = null;
    }
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionCommentChanged(text: string) {
    this.newActionComment = text;
  }

  // Apre modale per modificare il commento all'azione di sviluppo
  openEditCommentToDevelopAction(actionObject: any) {
    this.developActionEdit = actionObject.action;
    this.commentToEdit = actionObject.comment;
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + this.developActionEdit.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      this.developActionEdit.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + this.developActionEdit.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + this.developActionEdit.toStartDate + '</p>';
    this.modalService.open('editCommentDevelopmentPlanModal');
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeEditCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('editCommentDevelopmentPlanModal');
    if (cancel) {
      this.developActionEdit = null;
      this.commentToEdit = null;
    }
  }

  // Aggiungi il commento all'azione di sviluppo
  updateCommentForDevelopAction() {
    this.isLoadingGoalData = true;
    this.closeEditCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.collaboratorService.updateCommentForDevelopPlan(this.selectedYear.id.toString(), this.developActionEdit.id, this.commentToEdit.commentId, this.commentToEdit.comment)
      .subscribe((data: any) => {
        if (data && data.error) {
          this.newActionComment = '';
          const messageObj: ApplicationModalMessage = {
            modalId: "d036",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d037",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED_DESCR"),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getGoalData();
      }, (err?: any) => {
        this.newActionComment = '';
        const messageObj: ApplicationModalMessage = {
          modalId: "d038",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getGoalData();
      });
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionEditCommentChanged(text: string) {
    this.commentToEdit.comment = text;
  }


  // Mostra commento azione di sviluppo
  showActionComment(object: any) {
    let objectModal: ApplicationModalMessage = {
      modalId: "d031",
      title: this.translate.instant("developmentPlan.DEVELOP_ACTION_COMMENT"),
      subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + moment(object.creationDate).format('DD/MM/YYYY'),
      text: object.comment
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: objectModal }));
  }


  closeDeleteModal() {
    this.modalService.close('confirmDeleteForUser')
  }

  showComment() {
    if (this.commentData) {
      let comment: ApplicationModalMessage = {
        modalId: "158",
        title: this.translate.instant("modals.COMMENT_APPROVED.TITLE"),
        subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + this.commentData.date,
        text: this.commentData.text
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: comment }));
    }
  }

  // Vai a modificare l'azione di sviluppo
  goToEditAction(action: any) {
    this.router.navigate(['./collaborator/createUpdateDevelopmentActionEdit/' + this.userId + '/' + action.id]);
  }

  // Chiudi modale per la cancellazione dell'azione di sviluppo
  closeDeleteModalDevelopAction() {
    this.modalService.close("deleteDevelopmentAction");
    this.developActionIdToDelete = '';
  }

  // Apri modale per la cancellazione dell'azione di sviluppo
  openDeleteModalDevelopAction(developActionIdToDelete: string) {
    this.developActionIdToDelete = developActionIdToDelete;
    this.modalService.open("deleteDevelopmentAction");
  }

  // Cancella azione di sviluppo
  deleteDevelopAction() {

    let idToDelete = this.developActionIdToDelete;
    this.closeDeleteModalDevelopAction();
    this.isLoadingDeleteDevelopAction = true;
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }

    this.deleteDevelopAction$ = this.collaboratorService.deleteDevelopmentActionForUser(this.selectedYear.id.toString(), idToDelete)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.getGoalData();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingDeleteDevelopAction = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDeleteDevelopAction = false;
      }
      );
  }

  // Cambia option stato azione di sviluppo
  changeDevelopActionStatus(data: any) {
    this.currentActionStatus = data;
  }

  updateStatusDevelopAction() {
    this.isLoadingGoalData = true;
    this.modalService.close('updateStatusDevelopmentPlanModal')
    if (this.changeActionStatus$) {
      this.changeActionStatus$.unsubscribe();
    }
    this.changeActionStatus$ = this.collaboratorService.changeDevelopmentActionStatus(this.selectedYear.id.toString(), this.developAction.id, this.currentActionStatus.id)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d039",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d040",
            title: this.translate.instant("developmentPlan.STATUS_CHANGED"),
            text: this.translate.instant("developmentPlan.STATUS_CHANGED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.getGoalData();
        this.developAction = null;
        this.currentActionStatus = null;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d041",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.developAction = null;
        this.currentActionStatus = null;
        this.getGoalData();
      });
  }

  // Apri la modale per aggiornare l'avanzamento dell'azione di sviluppo
  openModalUpdateStatusDevelopAction(developAction: any) {
    this.developAction = developAction;
    this.currentActionStatus = this.developActionStatusesOptions.filter((x: any) => x.id == developAction.status)[0];
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + developAction.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      developAction.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + developAction.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + developAction.toStartDate + '</p>';
    this.modalService.open('updateStatusDevelopmentPlanModal');
  }

  // Chiudi la modale per aggiornare l'avanzamento dell'azione di sviluppo
  closeModalUpdateStatusDevelopAction() {
    this.modalService.close('updateStatusDevelopmentPlanModal');
    this.developAction = null;
    this.developActionModalText = '';
  }

  // Vai a creare l'evidenza associata all'obiettivo
  goToCreateEvidenceToGoal() {
    this.router.navigate(['./collaborator/createUpdateEvidence/' + this.userId + '/' + this.goalId]);
  }

  // Vai a gestire le evidenze
  goToManageEvidences() {
    this.router.navigate(['./collaborator/personDetails/feedback/evidences/' + this.userId]);
  }

  // Download file nell'evidenza
  downloadFileOnEvidences(file: any, feedbackId: string) {
    this.isLoadingGetDownloadUrlOnEvidence = true;

    this.collaboratorService.retrieveNewMaskedUrlForUploadForEvidence(file.uploadId, feedbackId, this.selectedYear.id.toString())
      .subscribe((data: any) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          let downloadUrl = this.authService.getMaskedUrlDownload(data.response, file.fileName);
          setTimeout(() => {
            document.location.assign(downloadUrl);
          }, 500)
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGetDownloadUrlOnEvidence = false;
        })
  }

  goToFinalEvaluation() {
    this.router.navigate(['collaborator/finalEvaluation/' + this.selectedYear.id + '/' + this.goalId]);
  }

  openFinalEvaluationModal(evaluation: any) {
    this.finalEvaluationForModal = evaluation;
    this.modalService.open('finalEvaluationModal');
  }

  closeFinalEvaluationModal() {
    this.modalService.close('finalEvaluationModal');
    this.finalEvaluationForModal = null;
  }

  finalEvaluationEnabled() {
    if (this.evaluationStatus.includes(this.goalData.goalStatus)) {
      return true;
    }
    return false;
  }

  // Funzioni peer feedback

  goToPeerFeedbackAnswer(feedbackId: string) {
    this.router.navigate(['/collaborator/createUpdatePeerFeedback/response/' + this.userId + '/' + feedbackId]);
  }

  createPeerFeedback(feedbackId?: string) {
    if (feedbackId) {
      this.redirectService.goToCreatePeerFeedback(this.userId, feedbackId);
    } else {
      this.redirectService.goToCreatePeerFeedback(this.userId);
    }
  }

  openDeletePeerFeedbackModal(id: string) {
    this.peerFeedbackToDelete = id;
    this.modalService.open('deletePeerFeedback');
  }

  closeDeletePeerFeedbackModal(confirm?: boolean) {
    this.modalService.close('deletePeerFeedback');
    if (confirm) {
      this.isLoadingGoalData = true;
      if (this.deletePeerFeedback$) {
        this.deletePeerFeedback$.unsubscribe();
      }
      this.deletePeerFeedback$ = this.collaboratorService.deletePeerFeedback(this.selectedYear.id, this.peerFeedbackToDelete)
        .subscribe(
          (data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pf021",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else if (data.response) {
              const messageObj: ApplicationModalMessage = {
                modalId: "d028",
                title: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED"),
                text: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED_DESCR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "d029",
                title: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_ERROR"),
                text: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_TEXT_ERROR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getGoalData();
          },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf022",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalData = false;
          }
        )
    }
    this.peerFeedbackToDelete = '';
  }

  openShowFeedbackRequest(feedback: any) {
    this.feedbackForRequestModal = feedback;
    this.modalService.open('modalShowRequest')
  }

  closeShowFeedbackRequest() {
    this.modalService.close('modalShowRequest')
    this.feedbackForRequestModal = null;
  }

  hasFinalEvaluation() {
    return NON_VALUABLE_TYPES.indexOf(this.goalData.type) < 0;
  }

  checkFinalEvaluationDatesValid() {
    if (this.runningPhase.phaseKey == 'FINAL_EVALUATION') {
      let phaseKickoff = moment(this.runningPhase.phaseStartDateForUser).format();
      let phaseDeadline = moment(this.runningPhase.phaseEndDateForUser).format();
      return moment(this.today).isSameOrAfter(phaseKickoff) && moment(this.today).isSameOrBefore(phaseDeadline);
    }
    return false;
  }

  getFinalEvaluationDates() {
    this.isLoadingEvaluationDates = true;
    if (this.getFinalEvaluationDates$) {
      this.getFinalEvaluationDates$.unsubscribe();
    }
    this.getFinalEvaluationDates$ = this.collaboratorService.getFinalEvaluationDate(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.response && data.response.finalEvaluationUserPhaseDates) {
          this.evalPhaseEnd = data.response.finalEvaluationUserPhaseDates.endDate;
        }
        this.isLoadingEvaluationDates = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pf022",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEvaluationDates = false;
      })
  }

  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  canAddEvidence() {
    if (this.excludeStatus.includes(this.goalData.goalStatus)) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getGoalData$) {
      this.getGoalData$.unsubscribe();
    }
    if (this.canCreateDevelopmentActions$) {
      this.canCreateDevelopmentActions$.unsubscribe();
    }
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }
    if (this.deletePeerFeedback$) {
      this.deletePeerFeedback$.unsubscribe();
    }
    if (this.peerFeedbackAllowed$) {
      this.peerFeedbackAllowed$.unsubscribe();
    }
    if (this.getFinalEvaluationDates$) {
      this.getFinalEvaluationDates$.unsubscribe();
    }
  }
}