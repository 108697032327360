<page-container>

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goToUpdatePeerFeedback(true)" [isBackButton]="true"
      [title]="'peerFeedback.createUpdate.SIDENAV_COLL_TITLE' | translate"
      [description]="'peerFeedback.createUpdate.SIDENAV_COLL_DESCRIPTION' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoadingFeedback">
        <div class="form-container">
          <!-- Se sta facendo una richiesta di feedback -->
          <ng-container *ngIf="!isResponse">
            <!-- Creazione -->
            <ng-container *ngIf="!feedbackId">
              <!-- user card -->
              <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
                [isValued]="selectedUserList && selectedUserList.length"
                [title]="'peerFeedback.createUpdate.WHO' | translate">
                <!-- ricerca -->
                <europ-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                  (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                  placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></europ-input>

                <p>
                  <span class="text-label-form primary-text-color" translate="peerFeedback.createUpdate.CHOOSE"></span>
                  <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length == 1"
                    class="text-label-form primary-text-color">{{ ' ' + selectedUserList.length + '
                    ' +
                    ('generic.SELECTED' | translate)}}</span>
                  <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length > 1"
                    class="text-label-form primary-text-color">{{ ' ' + selectedUserList.length + '
                    ' +
                    ('generic.SELECTEDS' | translate)}}</span>
                </p>
                <div class="user-card-collection-container">
                  <!-- Card utente -->
                  <div *ngIf="!isFetchingUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                    fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                    fxLayoutGap.lt-md="16px">
                    <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'feedbackUser',
                                  itemsPerPage: userCardData.numRecords,
                                  currentPage: userCardData.page,
                                  totalItems: userCardData.counter }" [user]="user"
                      [selectedUserList]="selectedUserList" [isFeedbackCard]="true" (onCheck)="selectUser($event)">
                    </user-card>
                  </div>
                  <div *ngIf="isFetchingUsers" class="full-width min-height500" fxLayout="row wrap"
                    fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                    <div class="loader spinner-medium"></div>
                  </div>
                  <div class="full-width"
                    *ngIf="!isFetchingUsers && userCardData && userCardData.list && userCardData.list.length"
                    fxLayout="row" fxLayoutAlign="center center">
                    <table-pagination id="feedbackUser" (pageChanged)="usersPageChanged($event)">
                    </table-pagination>
                  </div>
                </div>

              </input-container>
              <ng-container *ngIf="!isChief">
                <!-- Obiettivo su cui è richiesto il feedback -->
                <input-container [isLoading]="isLoadingFeedback || isLoadingGoalsToAssociate"
                  [tooltipModalMessage]="objectiveTitleModal" [isValued]="true"
                  title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                  <europ-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
                    [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                    [selectedItem]="selectedObjective" [items]="objectiveOptions"
                    [loadingData]="isLoadingGoalsToAssociate" [searchable]="true" bindLabel="title"
                    placeholder="{{'peerFeedback.createUpdate.SELECT_OBJECTIVE' | translate}}">
                  </europ-select>
                </input-container>
              </ng-container>
              <!-- Messaggio di richiesta -->
              <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
                [isValued]="true" [isObbligatory]="true"
                title="{{'peerFeedback.createUpdate.INSERT_MESSAGE' | translate}}">
                <europ-text-area [bindValue]="requestMessage" (onModelChanged)="requestMessageChanged($event)"
                  placeholder="{{'peerFeedback.createUpdate.INSERT_MESSAGE_PLACEHOLDER' | translate}}">
                </europ-text-area>
              </input-container>
              <div class="button-container">
                <europ-button (onButtonClicked)="goToUpdatePeerFeedback()"
                  [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary default" size="large"
                  [label]="'peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST' | translate">
                </europ-button>
              </div>
            </ng-container>
            <!-- Edit richiesta -->
            <ng-container *ngIf="feedbackId">
              <!-- Obiettivo su cui è richiesto il feedback -->
              <input-container [isObbligatory]="true" [isLoading]="isLoadingFeedback || isLoadingGoalsToAssociate"
                [tooltipModalMessage]="objectiveTitleModal" [isValued]="true"
                title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                <europ-select [innerHtmlDescription]="true" (onSelectedItem)="changeAssociatedObjective($event)"
                  [noDataFoundLabel]="'peerFeedback.createUpdate.NO_OBJECTIVE_FOUND' | translate"
                  [selectedItem]="selectedObjective" [items]="objectiveOptions"
                  [loadingData]="isLoadingGoalsToAssociate" [searchable]="true" bindLabel="title"
                  placeholder="{{'peerFeedback.createUpdate.SELECT_OBJECTIVE' | translate}}">
                </europ-select>
              </input-container>
              <!-- Messaggio di richiesta -->
              <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
                [isValued]="true" [isObbligatory]="true"
                title="{{'peerFeedback.createUpdate.INSERT_MESSAGE' | translate}}">
                <europ-text-area [bindValue]="feedbackObject.request" (onModelChanged)="requestMessageChanged($event)"
                  placeholder="{{'peerFeedback.createUpdate.INSERT_MESSAGE_PLACEHOLDER' | translate}}">
                </europ-text-area>
              </input-container>
              <div class="button-container">
                <europ-button (onButtonClicked)="goToUpdatePeerFeedback()"
                  [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary default" size="large"
                  [label]="('peerFeedback.createUpdate.UPDATE_FEEDBACK_REQUEST' | translate)">
                </europ-button>
              </div>
            </ng-container>

          </ng-container>
          <!-- Se sta rispondendo a richiesta di feedback -->
          <ng-container *ngIf="isResponse">

            <!-- Obiettivo su cui è richiesto il feedback -->
            <ng-container *ngIf="feedbackObject.goal && feedbackObject.goal.title">
              <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback"
                [tooltipModalMessage]="objectiveTitleModal" [isValued]="true" [readonly]="true"
                title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
                <!--europ-text-area [bindValue]="feedbackObject.objectiveTitle">
                    </europ-text-area-->
                <europ-input [bindValue]="feedbackObject.goal && feedbackObject.goal.title" [readonly]="true">
                </europ-input>
              </input-container>
            </ng-container>
            <!-- Messaggio di richiesta -->
            <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal"
              [isValued]="true" [readonly]="true" [isObbligatory]="false"
              title="{{'peerFeedback.createUpdate.REQUEST_MESSAGE' | translate}}">
              <!--europ-text-area [bindValue]="feedbackObject.message"></europ-text-area-->
              <europ-input [bindValue]="feedbackObject.request" [readonly]="true"></europ-input>
            </input-container>
            <!-- Quali sono le tue osservazioni? -->
            <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="observationsModal"
              [readonly]="false" [isObbligatory]="true"
              [isValued]="feedbackObject.approverObservation && feedbackObject.approverObservation.trim().length"
              title="{{'peerFeedback.createUpdate.OBSERVATIONS' | translate}}">
              <europ-text-area [bindValue]="feedbackObject.approverObservation"
                (onModelChanged)="observationsChanged($event)"
                placeholder="{{'peerFeedback.createUpdate.OBSERVATIONS_PLACEHOLDER' | translate}}">
              </europ-text-area>
            </input-container>
            <div class="button-container">
              <europ-button (onButtonClicked)="answerPeerFeedback()"
                [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary default" size="large"
                [label]="isUpdate ? ('peerFeedback.createUpdate.UPDATE_FEEDBACK' | translate) : ('peerFeedback.createUpdate.INSERT_FEEDBACK' | translate)">
              </europ-button>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isLoadingFeedback">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un appunto -->
<!-- (onConfirm)="(feedbackId && feedbackId.length) ? updatePeerFeedback() : createPeerFeedback()" -->
<modal id="modalCreateUpdatePeerFeedback" (onCancel)="goBackBrowser()" (onClose)="closeModalCreateUpdatePeerFeedback()"
  (onConfirm)="openConfrimRequest()" cancelLabel="{{modalCreateUpdatePeerFeedbackTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdatePeerFeedbackTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdatePeerFeedbackId" [title]="modalCreateUpdatePeerFeedbackTitle"
    [subtitle]="modalCreateUpdatePeerFeedbackSubtitle" [text]="modalCreateUpdatePeerFeedbackText">
  </modal-text-content>
</modal>

<modal id="modalConfrimRequest" (onCancel)="closeConfirmRequest()" (onClose)="closeConfirmRequest()"
  (onConfirm)="feedbackId && feedbackId.length > 0 ? updatePeerFeedback() : createPeerFeedback()"
  [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="feedbackId && feedbackId.length > 0 ? ('peerFeedback.modals.UPDATE_REQUEST' | translate) : ('peerFeedback.modals.SEND_REQUEST' | translate)">
  <div class="peer-feedback-modal-request">
    <div class="title-code-wrapper full-width">
      <div class="title-container" translate="peerFeedback.modals.REQUEST_FEEDBACK"></div>
    </div>
    <!-- Creazione -->
    <ng-container *ngIf="!feedbackId">
      <div class="confirm-request-content" *ngIf="selectedUserList && selectedUserList.length">
        <div class="section users">
          <div class="title" translate="peerFeedback.modals.TO_WHOM"></div>
          <div class="content">
            <div class="mini-user-card" *ngFor="let user of selectedUserList">
              <avatar-img size="small" [src]="user.avatar" [initials]="user.initials"></avatar-img>
              <p class="name">{{ user.name + ' ' + user.surname}}</p>
            </div>
          </div>
        </div>
        <div class="section" *ngIf="selectedObjective && selectedObjective.title">
          <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
          <div class="content">{{ selectedObjective && selectedObjective.title}}</div>
        </div>
        <div class="section">
          <div class="title" translate="peerFeedback.modals.WHY"></div>
          <div class="content">{{ requestMessage }}</div>
        </div>
      </div>
    </ng-container>
    <!-- Edit-->
    <ng-container *ngIf="feedbackId">
      <div class="confirm-request-content" *ngIf="feedbackObject">
        <div class="section users">
          <div class="title" translate="peerFeedback.modals.TO_WHOM"></div>
          <div class="content">
            <div class="mini-user-card" *ngIf="feedbackObject.approverFeedbackUser">
              <avatar-img size="small" [user]="feedbackObject.approverFeedbackUser"></avatar-img>
              <p class="name">
                {{ feedbackObject.approverFeedbackUser.forename + ' ' +
                feedbackObject.approverFeedbackUser.surname}}</p>
            </div>
          </div>
        </div>
        <div class="section" *ngIf="selectedObjective && selectedObjective.title">
          <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
          <div class="content">{{ selectedObjective && selectedObjective.title}}</div>
        </div>
        <div class="section">
          <div class="title" translate="peerFeedback.modals.WHY"></div>
          <div class="content">{{ feedbackObject.request }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</modal>