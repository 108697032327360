<div class="header-container">
  <div class="left-side">
    <ng-container *ngIf="isOnboardingDone">
      <!-- Se lato utente -->
      <ng-container *ngIf="!isAdminModule">

        <!-- Vista manager -->
        <ng-container *ngIf="isManager && !collaboratorView()">
          <div class="teams manager-background"
            [ngClass]="{'manager-background': isManager, 'collaborator-background': (!isManager || (isManager && collaboratorView())), 'expand': isManager && isTeamPage, 'user-active': isUserPageDetail}"
            (click)="emitOnManagerLogoClicked()">
            <div class="logo-description-wrapper">
              <div class="user-logo-wrapper">
                <svg-icon src="/assets/img/icons/users.svg" class="smaller-size white-svg" [applyClass]="true">
                </svg-icon>
              </div>
              <div class="user-logo-description">
                <p translate="header.TEAM"></p>
                <svg-icon *ngIf="isProfileOrTeamPage()" src="/assets/img/icons/vector-right.svg" class="arrow-icon"
                  [applyClass]="true">
                </svg-icon>
              </div>
            </div>
            <!-- Sezione che si apre con il nome della pagina -->
            <!--ng-container *ngIf="isManager && isTeamPage">
                <div class="page-name-container">
                    <svg-icon src="/assets/img/icons/vector-left.svg" class="arrow-icon" [applyClass]="true"></svg-icon>
                    <p>{{ isManager ? ('header.TEAM_PAGE' | translate) : ('header.DIARY_PAGE' | translate) }}</p>
                </div>
            </ng-container-->
          </div>
        </ng-container>

        <!-- Vista Collaboratore -->
        <ng-container *ngIf="!isManager || (isManager && collaboratorView())">
          <div class="teams collaborator-background" [ngClass]="{'user-active': isUserPageDetail}"
            (click)="emitOnUserLogoClicked()">
            <div class="logo-description-wrapper">
              <div class="user-logo-wrapper">
                <svg-icon src="/assets/img/icons/user.svg" class="smaller-size white-svg" [applyClass]="true">
                </svg-icon>
              </div>
              <div class="user-logo-description">
                <p translate="header.DIARY"></p>
                <svg-icon *ngIf="!redirectService.isThisCurrentPage('personDetails')"
                  src="/assets/img/icons/vector-right.svg" class="arrow-icon" [applyClass]="true">
                </svg-icon>
              </div>
            </div>
            <!-- Sezione che si apre con il nome della pagina -->
            <!--ng-container *ngIf="isManager && isTeamPage">
                            <div class="page-name-container">
                                <svg-icon src="/assets/img/icons/vector-left.svg" class="arrow-icon" [applyClass]="true"></svg-icon>
                                <p>{{ isManager ? ('header.TEAM_PAGE' | translate) : ('header.DIARY_PAGE' | translate) }}</p>
                            </div>
                        </ng-container-->
          </div>
        </ng-container>
      </ng-container>
      <!-- Se backoffice / lato admin -->
      <ng-container *ngIf="isAdminModule">
        <!-- Vista Admin -->
        <div class="teams collaborator-background" (click)="emitOnAppLogoClicked()">
          <div class="logo-description-wrapper">
            <div class="user-logo-wrapper">
              <svg-icon src="/assets/img/icons/user.svg" class="smaller-size white-svg" [applyClass]="true">
              </svg-icon>
            </div>
            <div class="user-logo-description">
              <p translate="header.HOME"></p>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>



    <div class="logo-app" (click)="emitOnAppLogoClicked()">
      <svg-icon [applyClass]="true" src="/assets/img/icons/app-logo.svg" class="logo"></svg-icon>
    </div>
  </div>
  <div class="right-side">
    <!-- Lato destro desktop -->
    <ng-container *ngIf="!isMobile">
      <ng-container *ngIf="isOnboardingDone && !isAdminModule">
        <svg-icon src="/assets/img/icons/help-circle.svg" class="medium-size blue-primary" [applyClass]="true"
          (click)="emitOnTooltipClicked()"></svg-icon>
        <div class="notification-container" id="notifications" (click)="emitOnNotificationClicked($event)">
          <svg-icon src="/assets/img/icons/bell.svg" class="medium-size blue-primary" [applyClass]="true">
          </svg-icon>
          <div class="number-container" *ngIf="notificationsCounter > 0">
            <badge-notifications [badgeNumber]="notificationsCounter" type="active"></badge-notifications>
          </div>
        </div>
      </ng-container>
      <div class="user-data-container">
        <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
        <div class="user-name-wrapper">
          <p>{{ userName }}</p>
          <ng-container *ngIf="!isAdminModule">
            <p *ngIf="isManager" class="manager"
              [innerHTML]="collaboratorView() ? ('header.COLLABORATOR' | translate) : ('header.MANAGER' | translate)">
            </p>
          </ng-container>
          <ng-container *ngIf="isAdminModule">
            <p class="manager" [innerHTML]="('header.ADMIN' | translate)"></p>
          </ng-container>
        </div>
        <svg-icon *ngIf="isOnboardingDone" class="medium-size blue-primary"
          [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
          (click)="toggleSidebar()"></svg-icon>

        <!-- Nuovo switch profilo -->
        <ng-container *ngIf="isOnboardingDone && !isLoadingProfiles && profileMenuItems && profileMenuItems.length > 1">
          <div class="enter-as-contirbutor" (click)="openChangeProfileMenu()"
            [ngClass]="{'disabled': isLoadingProfiles}">
            <p translate="menu.CHANGE_PROFILE"></p>
            <svg-icon src="assets/img/icons/arrow-right.svg" class="small-size blue-primary" [applyClass]="true">
            </svg-icon>
          </div>
          <!-- Cambio profilo 3.0 -->
          <ng-container *ngIf="!isLoadingProfiles && profileChangeMenuOpen">
            <div class="menu-item-wrapper">
              <ng-container *ngFor="let item of profileMenuItems">
                <europ-dropdown-item (click)="emitOnSwitchProfile(item.id)" class="full-width" [isMenuItem]="true"
                  [item]="item">
                </europ-dropdown-item>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <!-- Lato destro mobile -->
    <ng-container *ngIf="isMobile">
      <div class="user-data-container">
        <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
        <svg-icon class="medium-size blue-primary" *ngIf="isOnboardingDone || isAdminModule"
          [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
          (click)="toggleSidebar()"></svg-icon>
      </div>
    </ng-container>
  </div>
</div>