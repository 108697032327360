import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { UserAcknowledges } from '../../../commonclasses';
import { RedirectService } from './redirect.service';

@Injectable()
export class OnBoardingGuard implements CanActivate {

  userAck: UserAcknowledges = {
    userId: '',
    data: null
  };
  constructor(private store: Store<fromApp.AppState>,
    private redirectService: RedirectService) {
  }

  // Verifica se è presente il check sul video onboarding
  onboardingVideoChedked(userAcknowledges: UserAcknowledges): boolean {
    return userAcknowledges && userAcknowledges.data &&
      userAcknowledges.data['PERFORMANCE_CARE_ONBOARDING'] && userAcknowledges.data['PERFORMANCE_CARE_ONBOARDING'].length
  }

  // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.getUserAcknowledges)
      .pipe(
        filter((userAck) => {
          if (!!userAck) {
            this.userAck = userAck;
            return true;
          } else {
            return false;
          }
        }),
        map(() => {
          if (this.userAck && this.userAck.data) {
            if (!this.onboardingVideoChedked(this.userAck)) {
              this.redirectService.goToOnboardingVideo();
              return false;
            }
            return true;
          }
          return false;
        }),
      );
  }
}