import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SenecaResponse, GetBehaviorCareTagByIdResponse, PerfCareTag, CreateUserGoalForUserResponse, PerfCareUserPhaseGoal, GetMyGoalByIdResponse, PerfCareMacroGoalTypes, UpdateMyGoalResponse } from 'src/commonclasses';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as fromApp from "../../ngrx/app.reducers";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-set-operations-challenge',
  templateUrl: './setChallenge.component.html',
  styleUrls: ['./setChallenge.component.scss']
})
export class SetOperationsChallengeComponent implements OnInit, OnDestroy {
  runningYear$: Subscription;
  runningYear: number = 0;
  applicationLang: string = '';
  applicationLang$: Subscription;
  loggedUser: any;
  userId: any;
  totalSteps: number = 10;
  currentStep: number = 1;
  hasPrev: boolean = false;
  hasNext: boolean = true;
  isLoadingCareTags: boolean = false; competenceList: any;
  getBehaviourCareTag$: Subscription = new Subscription;
  currentPercentage: number = 10;
  isLoadingNext: boolean = false;
  tagId: string = '';
  isFetchingGetCareTag: boolean = false;
  getCareTag$: Subscription = new Subscription;
  careTag: PerfCareTag = {
    tagId: '',
    tagType: '',
    title: '',
    description: ''
  };
  challenge: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: ''
  };
  challengeWinComment: string = '';
  isFetchingCreateGoal: boolean = false;
  createUserGoal$: Subscription = new Subscription;

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  commentTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalCreateUpdateChallengeId: string = '';
  modalCreateUpdateChallengeTitle: string = '';
  modalCreateUpdateChallengeText: string = '';
  modalCreateUpdateChallengeTitleConfirmTextButton: string = '';
  modalCreateUpdateChallengeTextCloseTextButton: string = '';
  isEdit: boolean = false;
  getChallengeById$: Subscription = new Subscription;
  isImpersonate: boolean = false;

  constructor(
    public translate: TranslateService,
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private route: ActivatedRoute,
    private collaboratorService: CollaboratorService,
    private modalService: ModalService
  ) {
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.store.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
      }
    });

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;

      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.tagId = params.tagId;

              this.store.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.loggedUser = loggedUser && loggedUser.user;
                    if (window.location.href.indexOf('setUpdateCompetenceChallenge') > -1) {
                      this.isEdit = true;
                    }
                    // Se ho l'utente recupero il careTag
                    if (this.loggedUser && this.loggedUser.userId && this.tagId && !this.isEdit) {
                      this.getCareTagById();
                    } else if (this.loggedUser && this.loggedUser.userId && this.tagId && this.isEdit) {
                      this.getChallengeById();
                    }
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'setChallenge.CHALLENGE_TITLE',
        'setChallenge.CHALLENGE_DESCRIPTION',
        'setChallenge.CHALLENGE_COMMENT'
      ])
      .subscribe(translations => {
        this.titleTooltipModal = {
          modalId: "op019",
          title: translations['setChallenge.CHALLENGE_TITLE'],
          subtitle: 'Lorem ipsum',
          text: 'Lorem ipsum'
        };
        this.descriptionTooltipModal = {
          modalId: "op020",
          title: translations['setChallenge.CHALLENGE_DESCRIPTION'],
          subtitle: 'Lorem ipsum',
          text: 'Lorem ipsum'
        };
        this.commentTooltipModal = {
          modalId: "op021",
          title: translations['setChallenge.CHALLENGE_COMMENT'],
          subtitle: 'Lorem ipsum',
          text: 'Lorem ipsum'
        };
      })
  }

  // Gestione crea/aggiorna sfida
  goManageCreateEdit(isBack?: boolean) {
    this.formatCreateUpdateChallenge(isBack);
    this.openCreateEditGoal();
  }

  // Apre il modale di crea/aggiorna sfida
  openCreateEditGoal() {
    this.modalService.open('modalCreateUpdateChallenge');
  }

  // Chiude il modale di crea/aggiorna sfida
  closeCreateEditGoal() {
    this.modalService.close('modalCreateUpdateChallenge');
  }

  // Formatta i testi per la modale crea/aggiorna sfida
  formatCreateUpdateChallenge(isBack?: boolean) {
    this.modalCreateUpdateChallengeId = this.getModalSaveId();
    this.modalCreateUpdateChallengeTitle = this.getModalSaveTitle();
    this.modalCreateUpdateChallengeText = this.getModalSaveDescription();
    this.modalCreateUpdateChallengeTitleConfirmTextButton = this.getConfirmLabelCreateUpdateChallengeModal();
    this.modalCreateUpdateChallengeTextCloseTextButton = this.getCancelLabelCreateUpdateChallengeModal();
    this.openCreateEditGoal();
  }

  getModalSaveId() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      return 'op022';
    } else {
      if (this.isEdit) {
        return 'op023';
      } else {
        return 'op024';
      }
    }
  }

  // Formatta testo titolo modale
  getModalSaveTitle() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      return this.translate.instant('setChallenge.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (this.isEdit) {
        return this.translate.instant('setChallenge.UPDATE_CHALLENGE');
      } else {
        return this.translate.instant('setChallenge.CREATE_CHALLENGE');
      }
    }
  }

  // Formatta testo descrizione modale
  getModalSaveDescription() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      if (this.isEdit) {
        return this.translate.instant('setChallenge.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_UPDATE');
      } else {
        return this.translate.instant('setChallenge.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
      }
    } else {
      if (this.isEdit) {
        return this.translate.instant('setChallenge.DO_YOU_WANT_UPDATE_CHALLENGE');
      } else {
        return this.translate.instant('setChallenge.DO_YOU_WANT_CREATE_CHALLENGE');
      }
    }
  }

  // Formatta testo bottone conferma modale
  getConfirmLabelCreateUpdateChallengeModal() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      return this.translate.instant('setChallenge.REMAIN_PAGE');
    } else {
      if (this.isEdit) {
        return this.translate.instant('setChallenge.UPDATE');
      } else {
        return this.translate.instant('setChallenge.CREATE');
      }
    }
  }

  // Formatta testo bottone cancella modale
  getCancelLabelCreateUpdateChallengeModal() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      return this.translate.instant('setChallenge.YES_GO_BACK');
    } else {
      if (this.isEdit) {
        return this.translate.instant('setChallenge.NO_GO_BACK');
      } else {
        return this.translate.instant('setChallenge.NO_GO_BACK');
      }
    }
  }

  // Recupera la sfida dal suo id
  getChallengeById() {
    this.isFetchingGetCareTag = true;

    this.getChallengeById$ = this.collaboratorService.getGoalByIdForUser(this.runningYear.toString(), this.tagId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          this.goBack();
          const messageObj: ApplicationModalMessage = {
            modalId: "op026",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.challenge = data.response;
          this.challengeWinComment = this.challenge.describeWhatYouIntendToDoForWinChallenge || '';
        }
        this.isFetchingGetCareTag = false;
      },
        (err: string) => {
          this.goBack();
          const messageObj: ApplicationModalMessage = {
            modalId: "op027",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetCareTag = false;
        })
  }

  // Crea sfida partendo dal careTag
  createUserGoal() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      this.closeCreateEditGoal();
    } else {
      this.isFetchingCreateGoal = true;
      this.closeCreateEditGoal();

      let goalToCreate = {
        behaviorTagId: this.careTag.tagId,
        describeWhatYouIntendToDoForWinChallenge: this.challengeWinComment
      }

      this.createUserGoal$ = this.collaboratorService.createUserGoalForUser(this.runningYear.toString(), goalToCreate)
        .subscribe((data: SenecaResponse<CreateUserGoalForUserResponse[]>) => {
          if (data && data.error) {
            this.goBack();
            const messageObj: ApplicationModalMessage = {
              modalId: "op014",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response && data.response.length) {
            const messageObj: ApplicationModalMessage = {
              modalId: "op016",
              title: this.translate.instant("setChallenge.CHALLENGE_CREATED"),
              text: this.translate.instant("setChallenge.CHALLENGE_CREATED_CORRECTLY")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBack();
          }
          this.isFetchingCreateGoal = false;
        },
          (err: string) => {
            this.goBack();
            const messageObj: ApplicationModalMessage = {
              modalId: "op015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingCreateGoal = false;
          })
    }
  }

  // Aggiorna sfida partendo dal careTag
  updateUserGoal() {
    if (!this.challengeWinComment || !this.challengeWinComment.trim().length) {
      this.closeCreateEditGoal();
    } else {
      this.isFetchingCreateGoal = true;
      this.closeCreateEditGoal();

      this.challenge.describeWhatYouIntendToDoForWinChallenge = this.challengeWinComment;

      this.createUserGoal$ = this.collaboratorService.updateGoal(this.runningYear, this.challenge)
        .subscribe((data: SenecaResponse<UpdateMyGoalResponse>) => {
          if (data && data.error) {
            this.goBack();
            const messageObj: ApplicationModalMessage = {
              modalId: "op028",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            const messageObj: ApplicationModalMessage = {
              modalId: "op029",
              title: this.translate.instant("setChallenge.CHALLENGE_UPDATED"),
              text: this.translate.instant("setChallenge.CHALLENGE_UPDATED_CORRECTLY")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBack();
          }
          this.isFetchingCreateGoal = false;
        },
          (err: string) => {
            this.goBack();
            const messageObj: ApplicationModalMessage = {
              modalId: "op030",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingCreateGoal = false;
          })
    }
  }

  // Recupero info tag dal suo tagId
  getCareTagById() {
    this.isFetchingGetCareTag = true;

    this.getCareTag$ = this.collaboratorService.getCareTagById(this.tagId)
      .subscribe((data: SenecaResponse<GetBehaviorCareTagByIdResponse>) => {
        if (data && data.error) {
          this.goBack();
          const messageObj: ApplicationModalMessage = {
            modalId: "op017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response.length) {
          this.careTag = data.response[0];
        }
        this.isFetchingGetCareTag = false;
      },
        (err: string) => {
          this.goBack();
          const messageObj: ApplicationModalMessage = {
            modalId: "op018",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetCareTag = false;
        })
  }

  // Commento sfida che cambia
  challengeWinCommentChanged(text: string) {
    this.challengeWinComment = text;
  }

  goBack() {
    this.redirectService.goBackBrowser();
  }

  ngOnDestroy() {

  }
}