import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as moment from 'moment';

@Component({
    selector: 'admin-process-setup-decline-objectives',
    templateUrl: './declineObjectives.component.html',
    styleUrls: ['./declineObjectives.component.scss']
})
export class DeclineObjectivesComponent implements OnDestroy {
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;

    objectiveId: string = '';
    isLoadingObjective: boolean = true;

    goalTitle: string = '';
    goalTitleCopy: string = '';
    goalTitleTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };

    target: string = '';
    targetCopy: string = '';
    targetTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };

    selectedDate: any;
    selectedDateCopy: any;
    today = moment().format();
    defineDateTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    }; 
    modalUpdateId: string = '';
    modalUpdateTitle: string = '';
    modalUpdateSubtitle: string = '';
    modalUpdateText: string = '';
    modalUpdateTitleConfirmTextButton: string = '';
    modalUpdateTextCloseTextButton: string = '';


    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.objectiveId = params.objetiveId;

                                    if (this.objectiveId) {
                                        this.getObjective();
                                    } else {
                                        this.isLoadingObjective = false;
                                    }
                                })
                    }
                });
    }

    ngOnInit() {
        this.translate.get([
            'process.decline.GOAL_TITLE',
            'process.decline.GOAL_TITLE_INFO',
            'process.decline.TARGET',
            'process.decline.TARGET_INFO',
            'process.decline.DEFINE_DATE',
            'process.decline.DEFINE_DATE_INFO'

        ]).subscribe((translations) => {
            this.goalTitleTooltip = {
                modalId: 'ps016',
                title: translations['process.decline.GOAL_TITLE'],
                text: translations['process.decline.GOAL_TITLE_INFO'],
            };
            this.targetTooltip = {
                modalId: 'ps017',
                title: translations['process.decline.TARGET'],
                text: translations['process.decline.TARGET_INFO'],
            };
            this.defineDateTooltip = {
                modalId: 'ps018',
                title: translations['process.decline.DEFINE_DATE'],
                text: translations['process.decline.DEFINE_DATE_INFO'],
            };
        })
    }

    getObjective() {
        this.isLoadingObjective = true;

        setTimeout(() => {

            this.isLoadingObjective = false;
        })
    }

    goalTitleChanged(text: string) {
        this.goalTitle = text;
    }

    targetChanged(text: string) {
        this.target = text;
    }

    selectedDateChanged(newDate: Date) {
        this.selectedDate = newDate;
    }

    // gestisce creazione aggiornamento obiettivo
    goToUpdateObjective(isBack?: boolean) {
        if (!this.checkIfDataIsChanged() && isBack) {
            this.goBackBrowser();
        } else {
            this.formatUpdateModal(isBack);
            this.openUpdateModal();
        }
    }

    openUpdateModal() {
        this.modalService.open('defineObjectives');
    }

    closeUpdateModal() {
        this.modalService.close('defineObjectives');
    }

    formatUpdateModal(isBack?: boolean) {
        this.modalUpdateId = this.getModalId(isBack);
        this.modalUpdateTitle = this.getModalSaveTitle(isBack);
        this.modalUpdateSubtitle = this.getModalSaveSubtitle();
        this.modalUpdateText = this.getModalSaveDescription(isBack);
        this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
        this.modalUpdateTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
    }

    // Recupera il modalId per la modale di crea/aggiorna evidenza
    getModalId(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return 'ps019';
        } else {
            if (isBack) {
                if (this.objectiveId && this.objectiveId.length) {
                    return 'ps020';
                } else {
                    return 'ps021';
                }
            } else {
                if (this.objectiveId) {
                    return 'ps022';
                } else {
                    return 'ps023';
                }
            }
        }
    }

    getModalSaveTitle(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
        } else {
            if (isBack) {
                return this.translate.instant('generic.GO_BACK');
            } else {
                if (this.objectiveId) {
                    return this.translate.instant('process.decline.UPDATE_OBJECTIVE');
                } else {
                    return this.translate.instant('process.decline.INSERT_OBJECTIVE');
                }
            }
        }
    }

    // Sottotitolo per il modale crea/aggiorna evidenza
    getModalSaveSubtitle() {
        if (!this.objectiveId && this.isObbligatoryDataInserted()) {
            return this.translate.instant('process.decline.YOU_ARE_INSERTING');
        } else if (this.objectiveId && this.isObbligatoryDataInserted()) {
            return this.translate.instant('process.decline.YOU_ARE_UPDATING');
        } else {
            return '';
        }
    }

    // Descrizione per il modale crea/aggiorna evidenza
    getModalSaveDescription(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            if (this.objectiveId && this.objectiveId.length) {
                return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
            } else {
                return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
            }
        } else {
            if (isBack) {
                if (this.objectiveId && this.objectiveId.length) {
                    return this.translate.instant('process.decline.DO_YOU_WANT_UPDATE_BACK');
                } else {
                    return this.translate.instant('process.decline.DO_YOU_WANT_CREATE_BACK');
                }
            } else {
                if (this.objectiveId && this.objectiveId.length) {
                    return this.translate.instant('process.decline.DO_YOU_WANT_UPDATE');
                } else {
                    return this.translate.instant('process.decline.DO_YOU_WANT_CREATE');
                }
            }
        }
    }

    // Testo modale pulsante close
    getCancelLabelCreateUpdateNoteModal() {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
        } else {
            return this.translate.instant('generic.ANNULL');
        }
    }

    // Testo modale pulsante crea/aggiorna
    getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
        } else {
            if (isBack) {
                if (this.objectiveId && this.objectiveId.length) {
                    return this.translate.instant('process.decline.UPDATE_BACK');
                } else {
                    return this.translate.instant('process.decline.INSERT_BACK');
                }
            } else {
                if (this.objectiveId && this.objectiveId.length) {
                    return this.translate.instant('process.decline.UPDATE');
                } else {
                    return this.translate.instant('process.decline.SAVE');
                }
            }
        }
    }


    isObbligatoryDataInserted() {
        return this.goalTitle && this.goalTitle.trim().length && this.target && this.target.trim().length && this.selectedDate;
    }

    checkIfDataIsChanged() {
        return this.goalTitle && this.goalTitleCopy && this.goalTitle == this.goalTitleCopy && this.target && this.targetCopy && this.target == this.targetCopy && this.selectedDate && this.selectedDateCopy && this.selectedDate == this.selectedDateCopy;
    }

    createObjective() {

    }

    updateObjective() {

    }

    goBackBrowser() {
        this.redirectService.goBackBrowser();
    }

    ngOnDestroy() {
    }
}


