import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');

@Component({
    selector: 'app-admin-process-setup-details',
    templateUrl: './processSetupDetails.component.html',
    styleUrls: ['./processSetupDetails.component.scss']
})
export class ProcessSetupDetailsComponent implements OnDestroy {
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;
    currentYear = new Date().getFullYear();

    processYear: string = '';
    tabList: any[] = [];
    currentTab: string = 'participant';

    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        if (window.location.href.indexOf('participantList') >= 0) {
                            this.currentTab = 'participant';
                        } else if (window.location.href.indexOf('objectiveDefinition') >= 0) {
                            this.currentTab = 'definition';
                        } else if (window.location.href.indexOf('phaseDefinition') >= 0) {
                            this.currentTab = 'phases';
                        }
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.processYear = params.processYear;
                                })
                    }
                });
    }

    changeCurrentTab(id: any) {
        switch (id) {
            case 'participant':
                this.currentTab = 'participant';
                this.router.navigate(['/admin/processSetup/participantList/' + this.processYear]);
                break;
            case 'definition':
                this.currentTab = 'definition';
                this.router.navigate(['/admin/processSetup/objectiveDefinition/' + this.processYear]);
                break;
            case 'phases':
                this.currentTab = 'phases';
                this.router.navigate(['/admin/processSetup/phaseDefinition/' + this.processYear]);
                break;
        }
    }

    ngOnInit() {
        this.translate.get([
            'headerDossier.processSetup.PARTICIPANT',
            'headerDossier.processSetup.OBJECTIVE_DEFINITION',
            'headerDossier.processSetup.PROCESS_PHASES'
        ]).subscribe((translations) => {
            this.tabList = [
                {
                    id: 'participant',
                    name: translations['headerDossier.processSetup.PARTICIPANT']
                },
                {
                    id: 'definition',
                    name: translations['headerDossier.processSetup.OBJECTIVE_DEFINITION']
                },
                {
                    id: 'phases',
                    name: translations['headerDossier.processSetup.PROCESS_PHASES']
                }
            ]
        })
    }

    editProcess() {
        this.router.navigate(['admin/createUpdateProcess/' + this.processYear]);
    }

    orderBy(fieldName: string) {
        // console.log("fieldName", fieldName);
    }

    ngOnDestroy() {
    }
}