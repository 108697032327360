import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { PerfCareDevelopmentAction, PerfCareDevelopmentActionStatuses } from "src/commonclasses"


// Classe per la formattazione dei dati riguardanti gli obiettivi della fase di goal setting
export class ActionUtils {

  static formatActionList(actionList: any[] | undefined, personDetails: any, isManager: boolean, translate: TranslateService) {
    let tmpList = [];
    if (actionList && actionList.length) {
      for (let i = 0; i < actionList.length; i++) {
        tmpList.push({
          isDevelopmentAction: true,
          id: actionList[i].developmentActionId,
          iconTop: 'assets/img/icons/sprout.svg',
          type: 'developmentAction',
          user: personDetails,
          formattedCreationDate: moment(actionList[i].creationDate).fromNow(),
          statusObject: ActionUtils.getDevelopmentPlanStatus(actionList[i].status),
          title: actionList[i].title,
          typeText: actionList[i]?.category || 'Nessuna categoria',
          description: actionList[i].description,
          toStartDate: translate.instant('generic.BEFORE') + moment(actionList[i].startDate).format('DD/MM/YYYY'),
          comment: actionList[i].comment,
          creationUserId: actionList[i].creationUserId,
          creationUser: actionList[i].creationUser,
          status: actionList[i].status
        })
      }
    }
    return tmpList;
  }

  static getDevelopmentPlanStatus(status?: string) {
    switch (status) {
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_SUBORDINATE:
        return {
          status: 'toStart',
          statusMessage: 'developmentPlan.statuses.toShare'
        }
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_SUBORDINATE_DRAFT:
        return {
          status: 'toStart',
          statusMessage: 'developmentPlan.statuses.toShare'
        }
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_MANAGER:
        return {
          status: 'toStart',
          statusMessage: 'developmentPlan.statuses.toShare'
        }
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_MANAGER_DRAFT:
        return {
          status: 'toStart',
          statusMessage: 'developmentPlan.statuses.toShare'
        }
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_START:
        return {
          status: 'toStart',
          statusMessage: 'developmentPlan.statuses.toStart'
        }
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_STARTED:
        return {
          status: 'inProgress',
          statusMessage: 'developmentPlan.statuses.inProgress'
        }
      case PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_COMPLETED:
        return {
          status: 'completed',
          statusMessage: 'developmentPlan.statuses.completed'
        }
      default:
        return {
          status: 'toStart',
          statusMessage: 'developmentPlan.statuses.toStart'
        }
    }

  }
}