<div class="no-data-container"
  [ngClass]="{'height100': inNotifyPage, 'full-size-img': fullSizeImg, 'pl-margin': performanceLevelValue && performanceLevelValue.length }">
  <div class="icon-container">
    <svg-icon [src]="src"></svg-icon>
    <ng-container *ngIf="performanceLevelValue && performanceLevelValue.length">
      <div class="pl-value-container">
        <evaluation [evaluation]="performanceLevelValue" [scale]="5" customClass="big"></evaluation>
        <ng-container *ngIf="performanceLevelObservations || payout">
          <div class="divisor-line"></div>
          <div class="payout-wrapper">
            <p *ngIf="payout" class="payout">{{ payout }}% {{ 'performance.modals.OF_PAYOUT' | translate | uppercase }}
            </p>
            <ng-container *ngIf="performanceLevelObservations">
              <p class="read" translate="performance.READ_OBSERVATIONS" (click)="emitOnManagerObservation($event)"></p>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="text-wrapper full-width">
    <h1 class="title">{{ title }}</h1>
    <p class="full-width" [innerHTML]="description"></p>
  </div>
</div>