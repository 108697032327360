<!-- Sidebar con il menù principale -->
<sidebar [showAppHeader]="true" [hideCloseButton]="true" [sidenavTemplateRef]="navContent" direction="right"
  [navWidth]="325" [duration]="0.3" [hasImpersonateBanner]="showImpersonateBanner">
</sidebar>

<!-- Template con la sidebar principale (di destra) -->
<ng-template #navContent>
  <europ-dropdown-item [ngClass]="{'display-on-mobile': item.id=='tutorial'}" (click)="onHeaderOptionClicked(item)"
    class="full-width" *ngFor="let item of basicMenuItems; trackBy menuId" [isMenuItem]="true" [item]="item">
  </europ-dropdown-item>
  <!-- Voci menu manager -->

  <ng-container *ngIf="isManager">
    <!-- Voci menu manager -->
    <ng-container *ngIf="!collaboratorView()">
      <europ-dropdown-item [ngClass]="{'display-on-mobile': managerItem.id=='tutorial'}"
        (click)="onHeaderOptionClicked(managerItem)" class="full-width"
        *ngFor="let managerItem of managerMenuItems; trackBy menuId" [isMenuItem]="true" [item]="managerItem">
      </europ-dropdown-item>
      <europ-dropdown-item *ngIf="collaboratorRedirectMenuItem" fxShow.xs fxHide
        (click)="onHeaderOptionClicked(collaboratorRedirectMenuItem)" class="full-width" [isMenuItem]="true"
        [item]="collaboratorRedirectMenuItem">
      </europ-dropdown-item>
    </ng-container>
    <!-- Voci menu manager vista collaboratore -->
    <!-- <ng-container *ngIf="collaboratorView()">
      <europ-dropdown-item (click)="onHeaderOptionClicked(collItem)" class="full-width"
        *ngFor="let collItem of managerCollaboratorMenuItems; trackBy menuId" [isMenuItem]="true" [item]="collItem">
      </europ-dropdown-item>
      </ng-container> -->
    <ng-container *ngIf="!managerView()">
      <europ-dropdown-item fxShow.xs fxHide *ngIf="managerRedirectMenuItem"
        (click)="onHeaderOptionClicked(managerRedirectMenuItem)" class="full-width" [isMenuItem]="true"
        [item]="managerRedirectMenuItem">
      </europ-dropdown-item>
    </ng-container>
    <!--ng-container *ngIf="isAdmin && !managerView()">
      <europ-dropdown-item fxShow.xs fxHide *ngIf="managerRedirectMenuItem"
        (click)="goToAdminHome()" class="full-width" [isMenuItem]="true"
        [item]="managerRedirectMenuItem">
      </europ-dropdown-item>
    </ng-container-->
  </ng-container>

</ng-template>

<ng-container *ngIf="showImpersonateBanner && loggedUser">
  <div class="impersonate-header">
    <p
      [innerHTML]="('header.IMPERSONATING' | translate) + '<b>'+ loggedUser.forename + ' '+ loggedUser.surname +'</b>'">
    </p>
  </div>
</ng-container>

<!-- Header -->
<app-header *ngIf="showHeader() && !isLoadingMenuItems" [isSti]="isSti" [isOnboardingDone]="onboardingDone"
  [isManager]="isManager" [isOperations]="isOperations"  [isCollaborator]="isCollaborator" [isAdmin]="isAdmin"
  [tooltipModalMessage]="headerTooltip" (onUserLogoClicked)="onGoToMySection()" (onManagerLogoClicked)="goToMyTeam()"
  [isTeamPage]="isTeamPage" [showImpersonateBanner]="showImpersonateBanner">
</app-header>

<!-- App -->
<router-outlet *ngIf="!isFetchingLangs" [hidden]="showApplicationLoader"></router-outlet>
<!-- Footer -->

<!-- Modale generica con un messaggio (ad esempio di errore piuttosto che di successo) -->
<modal id="modalMessage" class="message-modal-index" [isMessageModal]="true" (onClose)="closeModalMessage()"
  (onCancel)="closeModalMessage()" (onConfirm)="closeModalMessage()" [cancelLabel]="applicationModalButtonCloseText"
  [noConfirmButton]="noConfirmButton" [confirmLabel]="confirmLabel ? confirmLabel : 'Ok'">
  <modal-text-content [kalturaCustomId]="kalturaCustomId" [modalWithKalturaScript]="modalWithKalturaScript"
    [withScrolledBody]="withScrolledBody" [modalId]="applicationModalId" [title]="applicationModalMessageTitle"
    [text]="applicationModalMessageText" [subtitle]="applicationModalMessageSubTitle"
    [image]="applicationModalMessageImage" [noBackground]="applicationModalMessageNoBackground">
  </modal-text-content>
</modal>

<!-- Modali per i badge -->
<ng-container *ngIf="applicationModalBadge && applicationModalBadge.length">
  <ng-container *ngFor="let badge of applicationModalBadge">
    <modal id="{{'modalBadge' + badge.badgeId}}" (onClose)="closeModalBadge(badge.badgeId)"
      (onCancel)="closeModalBadge(badge.badgeId)" (onConfirm)="closeModalBadge(badge.badgeId)"
      [confirmLabel]="closeBadgeModalLabel">
      <modal-text-content [badge]="badge"></modal-text-content>
    </modal>
  </ng-container>
</ng-container>