import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingComponent } from '../shared/components/onboarding/onboarding.component';
import { CollaboratorGuard } from '../shared/services/collaborator-guard.service';
import { OnboardingVideoComponent } from '../shared/components/onboarding-video/onboarding-video.component';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { OnBoardingGuard } from '../shared/services/onboarding-guard.service';
import { CollaboratorOperationsComponent } from './collaboratorOperations.component';
import { CollaboratorOperationsHomeComponent } from './home/home.component';
import { CollaboratorOperationsPersonDetailsComponent } from './personDetails/personDetails.component';
import { QualifyCompetencesComponent } from './qualifyCompetences/qualifyCompetences.component';
import { SetOperationsChallengeComponent } from './setChallenge/setChallenge.component';
import { CollaboratorOperationsGoalDetailsComponent } from './goalDetails/goalDetails.component';
import { CollaboratorOrganizeInterviewComponent } from '../collaborator/organizeInterview/organizeInterview.component';
import { CanDeactivateUpdateInterviewCollaboratorGuardService } from '../collaborator/services/can-deactivate-update-interview-collaborator-guard.component';
import { NotificationsComponent } from '../core/notifications/notifications.component';
import { CanDeactivateUpdateClapCollaboratorGuardService } from '../collaborator/services/can-deactivate-update-clap-collaborator-guard.component';
import { SendClapCollaboratorOperationsComponent } from './sendClap/sendClap.component';
import { CreateUpdateDevelopmentActionCollaboratorComponent } from '../collaborator/createUpdateDevelopmentActionCollaborator/createUpdateDevelopmentActionCollaborator.component';
import { ProfilePageComponent } from '../profilePage/profilePage.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { CreateUpdatePrivateNoteComponent } from '../collaborator/createUpdatePrivateNote/createUpdatePrivateNote.component';
import { AddMidTermReviewCollaboratorComponent } from '../collaborator/addMidTermReview/addMidTermReview.component';
import { FinalEvaluationCollaboratorComponent } from '../collaborator/finalEvaluation/finalEvaluation.component';
import { CreateUpdatePeerFeedbackComponent } from '../collaborator/createUpdatePeerFeedback/createUpdatePeerFeedback.component';
import { DefinePerformanceLevelCollaboratorComponent } from '../collaborator/definePerformanceLevel/definePerformanceLevel.component';

const collaboratorRoutes: Routes = [
  {
    path: '', component: CollaboratorOperationsComponent, children: [
      { path: 'home', component: CollaboratorOperationsHomeComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'profile', component: ProfilePageComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'onboarding', component: OnboardingComponent, canActivate: [CollaboratorGuard] },
      { path: 'onboardingVideo', component: OnboardingVideoComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },

      { path: 'personDetails/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'personDetails/feedback/smartFeedback/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/smartFeedback/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/evidences/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/evidences/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/privateNotes/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/privateNotes/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/interview/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/interview/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/peerFeedback/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/peerFeedback/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'personDetails/developmentPlan/:userId', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/developmentPlan/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'goalDetails/:userId/:goalId', component: CollaboratorOperationsGoalDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'goalDetails/:userId/:goalId/:perfCareYear', component: CollaboratorOperationsGoalDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/:userId/:perfCareYear', component: CollaboratorOperationsPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      
      { path: 'qualifyCompetences/:userId', component: QualifyCompetencesComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'setCompetenceChallenge/:userId/:tagId', component: SetOperationsChallengeComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'setUpdateCompetenceChallenge/:userId/:tagId', component: SetOperationsChallengeComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'organizeInterview/:userId', component: CollaboratorOrganizeInterviewComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewCollaboratorGuardService] },
      { path: 'organizeInterview/:userId/:interviewId', component: CollaboratorOrganizeInterviewComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewCollaboratorGuardService] },
      { path: 'sendApplause', component: SendClapCollaboratorOperationsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapCollaboratorGuardService] },
      { path: 'sendApplause/:userId', component: SendClapCollaboratorOperationsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapCollaboratorGuardService] },
      { path: 'sendApplause/:userId/:clapId', component: SendClapCollaboratorOperationsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapCollaboratorGuardService] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'qualifyCompetences/:userId', component: QualifyCompetencesComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'setCompetenceChallenge/:userId/:tagId', component: SetOperationsChallengeComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'setUpdateCompetenceChallenge/:userId/:tagId', component: SetOperationsChallengeComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'organizeInterview/:userId', component: CollaboratorOrganizeInterviewComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewCollaboratorGuardService] },
      { path: 'organizeInterview/:userId/:interviewId', component: CollaboratorOrganizeInterviewComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewCollaboratorGuardService] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId/:goalId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId/:goalId/:developmentActionId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentActionEdit/:userId/:developmentActionId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidence/:userId/:goalId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidenceOnEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidenceOnEvidence/:userId/:evidenceId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote', component: CreateUpdatePrivateNoteComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote/:userId', component: CreateUpdatePrivateNoteComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote/:userId/:noteId', component: CreateUpdatePrivateNoteComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/response/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'addMidTermReview', component: AddMidTermReviewCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'finalEvaluation/:perfCareYear/:goalId', component: FinalEvaluationCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'definePerformanceLevel/:perfCareYear', component: DefinePerformanceLevelCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(collaboratorRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class CollaboratorOperationsRoutingModule { }
