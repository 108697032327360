import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, switchMap, withLatestFrom, takeWhile } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCore from './core.reducers';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from '../../shared/services/url.service';
import * as CoreActions from './core.actions';
import * as ProfileActions from '../../core/profile/ngrx/profile.actions';
import { LangsService } from '../services/langs.service';
import { TranslateService } from '@ngx-translate/core';
import { timer, from, of } from 'rxjs';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import { AuthService } from '../../auth/services/auth.service';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { SenecaResponse, GetNotificationListResponse } from 'src/commonclasses';

@Injectable()
export class CoreEffects {
  loggedUser: any;
  constructor(private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private langsService: LangsService,
    private urlService: UrlService,
    private translate: TranslateService,
    private store: Store<fromCore.CoreState>) {
  }

  // Di default, inserisco la lingua del browser dell'utente, recupera grazie ad una funzione 
  defaultLang: string = this.langsService.getBrowserLang();
  // Verifica se l'utente è autenticato
  isAuthenticated: boolean = false;
  // Tiny token
  tinyToken: string = "";
  // Token intero
  tokenObj: any;
  authObject: any;
  forceRefreshUser?: boolean;
  redirectUrl?: string;

  /* // Effects che crea un observable (timer) che recupera il get delle prime notifiche lette
  startGetNotificationsInitialsPolling$ = createEffect((): any => this.actions$
      .pipe(
          ofType(CoreActions.StartGetNotificationsInitials)
          , switchMap(
              () => timer(0, 30000) // Il primo parametro è il delay iniziale. Nel nostro caso voglio che venga fatto subito, poiché potrebbe essere che l'utente sia già autenticato ma che abbia eseguito un refresh della pagina
                  .pipe(
                      withLatestFrom(this.store.select(fromApp.isAuthenticated)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello Store per vedere se l'utente è loggato
                      , map(([action, isAuthenticated]) => {
                          this.isAuthenticated = isAuthenticated;
                      })
                      , takeWhile(() => this.isAuthenticated), // Continuo con il loop del timer solamente finché l'utente è autenticato. Qualora eseguisse il logout, l'observable si distruggerebbe
                      switchMap(() => {
                          return from(this.authService.getNotificationList());
                      })
                      , switchMap(
                          (notificationsGet: SenecaResponse<GetNotificationListResponse>) => {
                              if (notificationsGet.error) {
                                  throw (new Error(notificationsGet.error));
                              } else {
                                  return [CoreActions.SetNotificationsInitials({ payload: (notificationsGet.response && notificationsGet.response.notifications) })];
                              }
                          })
                  )
          )
          , catchError((err, caught) => {
              // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e 
              // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
              // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
              // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
              // con il valore tornato dal catch.
              // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
              
              switchMap(campaign => 
                  this.advertService.startAd(campaign.name).pipe(
                      map(nameOfUploadedFile => new AdvertActions.StartAdDone(nameOfUploadedFile)),
                      catchError(err => of(new AdvertActions.StartAdFailed(err))
              )
              this.translate.setDefaultLang(this.defaultLang);
              if (err && err.message) {
                  // TODO-Alloy: da capire perché non traduce in lingua qua!
                  if (err.message == "OLD_TOKEN_NOT_FOUND") {
                      const messageObj: fromCore.ApplicationModalMessage = {
                          modalId: "068",
                          text: this.translate.instant("errors.OLD_TOKEN_NOT_FOUND"),
                          title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  } else {
                      const messageObj: fromCore.ApplicationModalMessage = {
                          modalId: "068",
                          text: this.translate.instant("err." + err.message),
                          title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  }
              }
              // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
              return caught;
          })
      )
  ) */

  // Effects che crea un observable (timer) che recupera il counter delle notifiche
  startCountNotificationsPolling$ = createEffect(() => this.actions$
    .pipe(
      ofType(CoreActions.StartCountNotifications)
      , switchMap(
        () => timer(0, 30000) // Il primo parametro è il delay iniziale. Nel nostro caso voglio che venga fatto subito, poiché potrebbe essere che l'utente sia già autenticato ma che abbia eseguito un refresh della pagina
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello Store per vedere se l'utente è loggato
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated), // Continuo con il loop del timer solamente finché l'utente è autenticato. Qualora eseguisse il logout, l'observable si distruggerebbe
            switchMap(() => {
              return from(this.authService.countNotifications(true));
            })
            , switchMap(
              (notificationsCounter: SenecaResponse<number>) => {
                if (notificationsCounter.error) {
                  throw (new Error(notificationsCounter.error));
                } else {
                  return [CoreActions.SetNotificationsCounter({ payload: notificationsCounter.response })];
                }
              })
          )
      )
      , catchError((err, caught) => {
        // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e 
        // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
        // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
        // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
        // con il valore tornato dal catch.
        // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
        /*
        switchMap(campaign => 
            this.advertService.startAd(campaign.name).pipe(
                map(nameOfUploadedFile => new AdvertActions.StartAdDone(nameOfUploadedFile)),
                catchError(err => of(new AdvertActions.StartAdFailed(err))
        ) */
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          // TODO-Alloy: da capire perché non traduce in lingua qua!
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "068",
              text: this.translate.instant("errors.OLD_TOKEN_NOT_FOUND"),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "068",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        }
        // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        return caught;
      })
    )
  )

  startRenewTokenPolling$ = createEffect(() => this.actions$
    .pipe(
      ofType(CoreActions.StartRenewTokenPolling)
      , map((action) => {
        this.redirectUrl = action && action.payload && action.payload.redirectUrl || '';
        this.forceRefreshUser = action && action.payload && action.payload.forceRefreshUser || false;
        return action.payload;
      })
      , switchMap(
        () => timer(0, 3000000)
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated))
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated),
            switchMap((): any => {
              let sessionStorageToken = sessionStorage.getItem('token');
              if (sessionStorageToken) {
                sessionStorage.removeItem('token');
                return from(this.authService.renewToken(sessionStorageToken, this.forceRefreshUser));
              } else {
                throw (new Error('TOKEN_NOT_FOUND'));
              }
            })
            , switchMap(
              (tinyTokenObj: any) => {
                if (tinyTokenObj.error) {
                  throw (new Error(tinyTokenObj.error));
                } else {
                  this.tinyToken = tinyTokenObj.response;
                  return from(this.authService.getJWTToken(tinyTokenObj.response));
                }
              })
            , map((tokenObj: any) => {
              if (tokenObj.error) {
                throw (new Error(tokenObj.error));
              } else {
                this.tokenObj = tokenObj.response;
                return this.store.dispatch(AuthActions.SetToken({ payload: this.tinyToken }));
              }
            })
            , map(() => {
              return this.store.dispatch(ProfileActions.DecodeToken({ payload: this.tokenObj }));
            })
            , withLatestFrom(this.store.select(fromApp.getLoggedUser), this.store.select(fromApp.getIsManager), this.store.select(fromApp.getIsAdmin), this.store.select(fromApp.getIsCollaborator))
            , switchMap(([action, loggedUser, isManager]) => {
              this.loggedUser = loggedUser;
              if (this.redirectUrl) {
                this.router.navigate([this.redirectUrl]);
              } else {
                // Se non ce l'ho nemmeno nel session storage, allora lo setto per evitare il redirect automatico in home page
                let sessionStorageRedirectUrl = sessionStorage.getItem('redirectUrl');
                if (!sessionStorageRedirectUrl) {
                  let url = this.router.url;
                  if (url) {
                    sessionStorage.setItem('redirectUrl', url);
                  }
                }
              }

              let langToUse = this.langsService.getUserLang(this.loggedUser.user);
              this.langsService.useLanguage(langToUse);
              return [CoreActions.SetApplicationLang({ payload: langToUse }), AuthActions.RetrieveUserAcknowledges(), CoreActions.GetRunningYear(), CoreActions.GetRunningPhase()];
            })
          )
      )
      , catchError((err, caught) => {
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "068",
              text: this.translate.instant("errors.OLD_TOKEN_NOT_FOUND"),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          } else {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "069",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        }
        return caught;
      })
    )
  )

  // Effect che recupera l'anno del processo in corso
  getRunningYear$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CoreActions.GetRunningYear)
        , switchMap(() => {
          return from(this.authService.getPerformanceAssessmentRunningYear());
        })
        , map(
          (data: any) => {
            if (data.error) {
              throw (new Error(data.error));
            } else {
              return CoreActions.SetRunningYear({ payload: data.response });
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "070",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      )
  )

  getRunningPhase$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(CoreActions.GetRunningPhase)
        , switchMap(() => {
          return from(this.authService.getPerformanceAssessmentRunningPhase())
        }),
        map(
          (data: any) => {
            if (data.error) {
              throw (new Error(data.error));
            } else {
              return CoreActions.SetRunningPhase({ payload: data.response });
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            const messageObj: fromCore.ApplicationModalMessage = {
              modalId: "071",
              text: this.translate.instant("errors." + err.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return caught;
        })
      )
  )

  coreActions$ = createEffect(() => this.actions$
    .pipe(
      ofType(CoreActions.GetAvailableLangs)
      , withLatestFrom(this.store.select(fromApp.getAvailableLangs))
      , switchMap(([action, storeLangs]) => {
        const retrievedLangs: any = storeLangs;
        if (retrievedLangs && retrievedLangs.length) {
          this.store.dispatch(CoreActions.GetAvailableLangsFinished());
        } else {
          return this.langsService.getAvailableLangs();
        }
      })
      , map(
        (senecaResponse: any) => {
          if (senecaResponse.response) {
            for (let i = 0, langsLength = senecaResponse.response.length; i < langsLength; i++) {
              if (senecaResponse.response[i] && senecaResponse.response[i].mandatory && senecaResponse.response[i].langCode) {
                this.defaultLang = senecaResponse.response[i].langCode.substring(0, 2);
                break;
              }
            }

            return this.store.dispatch(ProfileActions.SaveAvailableLangs({ payload: senecaResponse.response }));
          }
        }
      )
      , withLatestFrom(this.store.select(fromApp.getGlobalApplicationData))
      , switchMap(([action, savedGlobalApplicationData]) => {
        this.translate.setDefaultLang(this.defaultLang);
        return this.translate.use(this.defaultLang).pipe(
          map(() => savedGlobalApplicationData)
        );
      }),
      switchMap((savedGlobalApplicationData) => {
        if (!savedGlobalApplicationData) {
          const applicationUrl = this.urlService.getApplicationUrl();

          let newGlobalApplicationData = new GlobalApplicationData(
            applicationUrl.baseUrl,
            '../index.html',
            '../isMaintenance.xml',
            'eTicketing-user/?#/app/eTicketUserApp/eTicketing',
            '',
            false,
            false,
            [],
            [],
            false,
            '',
            ''
          );

          return [CoreActions.SetCoreApplicationData({ payload: newGlobalApplicationData }),
          CoreActions.GetAvailableLangsFinished(),
          CoreActions.SetDefaultLang({ payload: this.defaultLang }),
          ]
        } else {
          return [CoreActions.GetAvailableLangsFinished()];
        }
      })
      , catchError((err, caught) => {
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          const messageObj: fromCore.ApplicationModalMessage = {
            modalId: "072",
            text: this.translate.instant("errors." + err.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.store.dispatch(CoreActions.GetAvailableLangsFinished());
        return caught;
      })
    )
  )
}