<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goManageCreateEdit(true)" [isBackButton]="true"
      [title]="('setChallenge.NAV_TITLE' | translate) + runningYear"
      [description]="'setChallenge.NAV_DESCR' | translate"></sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <div class="form">
        <!-- Titolo competenza -->
        <input-container [tooltipModalMessage]="titleTooltipModal" [readonly]="true"
          [isLoading]="isFetchingGetCareTag || isFetchingCreateGoal"
          [isValued]="isEdit ? (challenge.title && challenge.title.trim().length) : (careTag.title && careTag.title.trim().length)"
          [isObbligatory]="true" [title]="('setChallenge.YOUR_CHALLENGE' | translate) + runningYear">
          <europ-input [bindValue]="isEdit ? challenge.title : careTag.title" placeholder="">
          </europ-input>
        </input-container>
        <!-- Descrizione competenza -->
        <input-container [tooltipModalMessage]="descriptionTooltipModal" [readonly]="true"
          [isLoading]="isFetchingGetCareTag || isFetchingCreateGoal"
          [isValued]="isEdit ? (challenge.description && challenge.description.trim().length) : (careTag.description && careTag.description.trim().length)"
          [isObbligatory]="true" [title]="'setChallenge.SPECIFIC_BEHAVIOUR' | translate">
          <europ-text-area [isInnerHTML]="true" [readonly]="true"
            [bindValue]="isEdit ? challenge.description : careTag.description" placeholder="">
          </europ-text-area>
        </input-container>
        <!-- Cosa fare per vincere la sfida -->
        <input-container [tooltipModalMessage]="commentTooltipModal"
          [isLoading]="isFetchingGetCareTag || isFetchingCreateGoal"
          [isValued]="challengeWinComment && challengeWinComment.trim().length" [isObbligatory]="true"
          [title]="'setChallenge.WIN_CHALLENGE' | translate">
          <europ-text-area [bindValue]="challengeWinComment" (onModelChanged)="challengeWinCommentChanged($event)"
            [placeholder]="'setChallenge.WIN_CHALLENGE_PLACEHOLDER' | translate">
          </europ-text-area>
        </input-container>
        <div class="button-container">
          <europ-button
            [disabled]="isFetchingGetCareTag || !challengeWinComment || !challengeWinComment.trim().length || isFetchingCreateGoal"
            type="primary default" size="large" label="{{'setChallenge.CONFIRM_CHALLENGE' | translate}}"
            (onButtonClicked)="goManageCreateEdit()"></europ-button>
        </div>
      </div>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento della sfida -->
<modal id="modalCreateUpdateChallenge" (onCancel)="goBack()" (onClose)="closeCreateEditGoal()"
  (onConfirm)="isEdit ? updateUserGoal() : createUserGoal()"
  cancelLabel="{{modalCreateUpdateChallengeTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdateChallengeTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdateChallengeId" [title]="modalCreateUpdateChallengeTitle" [subtitle]="''"
    [text]="modalCreateUpdateChallengeText">
  </modal-text-content>
</modal>