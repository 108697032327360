<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="sendRevisionAction(true)" [isBackButton]="true"
      [title]="'performance.ACCORDION_TITLE' | translate" [description]="'performance.SIDENAV_SUBTITLE' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserData">
        <div *ngFor="let goal of goalList" fxLayout="column" class="goal-list">
          <objective-for-mid-term-review [goal]="goal" [isOperations]="isOperations" [isInnerHtml]="true">
          </objective-for-mid-term-review>
          <ng-container *ngIf="getUserGoalEvaluation(goal)">
            <evaluation [evaluation]="getUserGoalEvaluation(goal)"></evaluation>
          </ng-container>
          <div class="divisor-vertical-line"></div>
        </div>
        <!-- recap dati utente e mid term review -->
        <calibration-recap [user]="personDetails" [midTermData]="userCalibrationRecap.midTerm"
          [finalEvaluationData]="userCalibrationRecap.finalEvaluation"></calibration-recap>
        <!-- Risposta del manager alla review -->
        <ng-container *ngIf="managerAnswer">
          <div class="divisor-vertical-line"></div>
          <calibration-recap [user]="managerData" [midTermData]="managerCalibrationRecap.midTerm"
            [finalEvaluationData]="managerCalibrationRecap.finalEvaluation">
          </calibration-recap>
        </ng-container>
        <div class="performance-level-input">
          <!-- Osservazioni -->
          <input-container [numberCharsTextArea]="charsFinalObservation" [isFromTextArea]="true"
            [isLoading]="isLoadingUserData" [tooltipModalMessage]="observationTooltip"
            [isValued]="calibrationData.observation && calibrationData.observation.trim().length"
            title="{{'calibration.INSERT_OBSERVATIONS' | translate}}">
            <europ-text-area [bindValue]="calibrationData.observation" [maxlength]="600"
              (onModelChanged)="calibrationObservationChanged($event)"
              placeholder="{{'performance.OBSERVATION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>

          <!-- Valutazione -->
          <div class="pl-mark-card-container">
            <div class="title-container">
              <p translate="performance.INSERT_VALUE"></p>
              <span *ngIf="!calibrationData.level">*</span>
              <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer"
                [applyClass]="true" (click)="openMarkCardInfoModal()">
              </svg-icon>
            </div>
            <card-performance class="full-width" (onItemChange)="selectPerformanceLevel($event)"
              [bindValue]="calibrationData.level">
            </card-performance>
          </div>

          <!-- Percentuale payout STI -->
          <ng-container *ngIf="isSti">
            <input-container [disabled]="!calibrationData.level"
              [isLoading]="isLoadingUserData || isLoadingPayoutOptions" [tooltipModalMessage]="payoutTooltip"
              [isValued]="selectedPayout && selectedPayout.id" [isObbligatory]="true"
              title="{{'performance.SET_PAYOUT' | translate}}" [isObbligatory]="true">
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changePayoutPercentage($event)"
                [selectedItem]="calibrationData.payout" [items]="payoutOptions" [searchable]="false" bindLabel="id">
              </europ-select>
            </input-container>
          </ng-container>

          <!-- Conferma calibration -->
          <div class="action-container">
            <europ-button type="primary" [label]="'generic.CONFIRM' | translate" [disabled]="confirmDisabled()"
              (onButtonClicked)="openConfirmModal()">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingUserData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale conferma calibration -->
<modal id="recapConfirm" (onClose)="closeConfirmModal()">
  <ng-container *ngIf="calibrationData.level">
    <p class="modal-title">
      {{ ('calibration.modals.SAVE_OR_CONFIRM' | translate)}}</p>
    <div class="confirmation-container calibration">
      <div class="observation-container">
        <p class="subtitle" translate="calibration.modals.OBSERVATIONS"></p>
        <p class="text">{{ calibrationData.observation }}</p>
      </div>
      <div class="value-container">
        <evaluation customClass="big" [scale]="5" [evaluation]="calibrationData.level"></evaluation>
        <ng-container *ngIf="isSti && calibrationData.payout">
          <div class="payout-label">
            <p>{{calibrationData.payout.title + ' ' + ('performance.modals.OF_PAYOUT' | translate)}}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-action-container">
      <europ-button [label]="'calibration.modals.SAVE_EVAL' | translate" type="primary bordless"
        (onButtonClicked)="closeConfirmModal(true)"></europ-button>
      <europ-button [label]="'calibration.modals.CONFIRM_PL' | translate" type="secondary"
        (onButtonClicked)="closeConfirmModal(true, true)"></europ-button>
    </div>
  </ng-container>
</modal>

<!-- Modale per l'invio della revisione se non ci sono dati obbligatori -->
<modal id="modalCreateRevisionNoDataForCollaborator" (onCancel)="goBackBrowser()"
  (onClose)="closeModalCreateRevisionNoDataForCollaborator()"
  (onConfirm)="closeModalCreateRevisionNoDataForCollaborator()"
  cancelLabel="{{'goalSetting.createGoal.GO_BACK_YES' | translate}}"
  confirmLabel="{{'generic.NO_REMAIN_IN_PAGE' | translate}}">
  <modal-text-content [modalId]="'1052'"
    [title]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA' | translate) : ('evidences.OBBLIGATORY_DATA_NOT_INSERTED' | translate) "
    [text]="backWithData ? ('midTerm.addMidTermReview.BACK_WITH_DATA_TEXT' | translate) : ('midTerm.addMidTermReview.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION' | translate)">
  </modal-text-content>
</modal>

<!-- Modale per l'invio della revisione -->
<modal id="modalCreateRevisionForCollaborator"
  (onCancel)="isFromBack ? goBackBrowser() : closeModalCreateRevisionForCollaborator()"
  (onClose)="closeModalCreateRevisionForCollaborator()" (onConfirm)="sendRevision()"
  [cancelLabel]="'generic.ANNULL' | translate" [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content [modalId]="'d1060'" [title]="'midTerm.addMidTermReview.CONFIRM_REVISION' | translate"
    [subtitle]="'midTerm.addMidTermReview.CONFIRM_REVISION_SUBTITLE' | translate"
    [text]="'midTerm.addMidTermReview.CONFIRM_REVISION_TEXT' | translate">
  </modal-text-content>
  <ng-container *ngIf="loggedUser && cardSelected">
    <div class="modal-recap-container">
      <mid-term-recap [user]="loggedUser" [observation]="osservation" [status]="cardSelected.id">
      </mid-term-recap>
    </div>
  </ng-container>
</modal>

<modal id="modal-info" (onClose)="closeModalInfo()" (onConfirm)="closeModalInfo()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <p class="modal-title center" translate="evaluations.modals.INFO_TITLE"></p>
  <div class="divisor-line"></div>
  <div class="performance-level-mark-info-container">
    <div class="title-row">
      <evaluation evaluation="UNSATISFACTORY" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.UNSATISFACTORY"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="IMPROVABLE" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.IMPROVABLE"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="MEET" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.MEET"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEEDED" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEEDED"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEPTIONAL" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEPTIONAL"></p>
  </div>
</modal>