import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as AdminActions from './admin.actions';
export interface FeatureState extends fromApp.AppState {
  admin: AdminState
}

export interface AdminState {
  matrixData: any;
  filtersText: string;
}

// Store iniziale
const initialState: AdminState = {
  matrixData: {},
  filtersText: ''
};


export const createAdminReducer = createReducer(
  initialState,
  on(AdminActions.SetMatrixDataManager, (state, action) => {
    return {
      ...state,
      matrixData: {
        managerData: action.payload
      }
    };
  }),
  on(AdminActions.SetMatrixDataUserList, (state, action) => {
    return {
      ...state,
      matrixData: {
        userList: action.payload
      }
    };
  }),
  on(AdminActions.SetFiltersForMatrix, (state, action) => {
    return {
      ...state,
      filtersText: action.payload
    };
  }),
)

export function adminReducer(state: AdminState | undefined, action: Action) {
  return createAdminReducer(state, action);
}

export const getMatrixData = (state: AdminState) => state.matrixData;
export const getFiltersText = (state: AdminState) => state.filtersText;