import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { AuthGuard } from './auth/services/auth-guard.service';
import { LoginSamlErrorComponent } from './auth/login/login-saml-error.component';
import { LogoutSsoCompletedComponent } from './auth/login/logout-sso-completed.component';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { LocalLoginComponent } from './auth/login/local-login.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginGuard } from './shared/services/login-guard.service';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { DefaultPageComponent } from './core/defaultPage/default-page.component';
import { ComponentExampleComponent } from './components-example/components-example.component';
import { GenericErrorComponent } from './core/generic-error/generic-error.component';
import { ImpersonateRedirectHomeComponent } from './impersonate-home-redirect/impersonate-home-redirect.component';
import { LoginSsoComponent } from './auth/login/login-sso.component';


const appRoutes: Routes = [
  // Autenticazione
  { path: 'loginLdap', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'localLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'login', component: LoginSsoComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSamlError', component: LoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'logoutSsoCompleted', component: LogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },

  // Pagina di default non è stato specificato nessun URL dall'utente. Viene fatto il redirect in base alle sue auth
  { path: 'defaultPage', component: DefaultPageComponent, canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'impersonateRedirect', component: ImpersonateRedirectHomeComponent, canActivate: [AuthGuard, CompatibilityGuard] },

  // Profili: collaboratore, manager, admin
  { path: 'collaborator', loadChildren: () => import('./collaborator/collaborator.module').then(m => m.CollaboratorModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'collaboratorOperations', loadChildren: () => import('./collaboratorOperations/collaboratorOperations.module').then(m => m.CollaboratorOperationsModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard, CompatibilityGuard] },

  // Altre pagine varie
  { path: 'components', component: ComponentExampleComponent },
  { path: 'errorPage', component: GenericErrorComponent },
  { path: 'errorPage/:errorCode', component: GenericErrorComponent },
  { path: 'compatibilityError', component: CompatibilityErrorComponent },
  { path: '403', component: PageNotAuthorizedComponent },
  { path: '', redirectTo: 'defaultPage', pathMatch: 'full' },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }