import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  showSideNav: any;

  @Input() sidenavTemplateRef: any;
  @Input() hideCloseButton?: boolean;
  @Input() showAppHeader?: boolean;
  @Input() hasImpersonateBanner: boolean = false;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction = 'left';

  constructor(private sidebarService: SidebarService) { }

  ngOnInit() {
    this.showSideNav = this.sidebarService.getShowNav();
  }

  onSidebarClose() {
    this.sidebarService.setShowNav(false);
  }

  getSideNavBarStyle(showNav: boolean | null) {
    let navBarStyle: any = {};

    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = this.navWidth + 'px';
    navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';

    return navBarStyle;
  }
}
