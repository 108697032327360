import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { RedirectService } from '../../shared/services/redirect.service';
import { Store } from '@ngrx/store';
import { LangsService } from '../services/langs.service';
import { ApplicationModalMessage } from '../ngrx/core.reducers';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isManager: boolean = false;
  @Input() isOperations: boolean = false;
  @Input() isCollaborator: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() isSti: boolean = false;
  @Input() avatar?: string;
  @Input() isTeamPage?: boolean = false;
  @Input() isOnboardingDone?: boolean = false;
  @Input() showImpersonateBanner?: boolean = false;
  @Input() pageName?: string = '';
  @Input() tooltipModalMessage: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: '',
    applicationModalButtonCloseText: ''
  };
  initials?: string;
  userName: string = '';
  notificationCount: number = 10;
  isMobile: boolean = false;

  showNotificationPanel: boolean = false;
  notificationValues: DropdownItem[] = [];
  language: string = 'en';
  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTooltipClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNotificationClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUserLogoClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onManagerLogoClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAppLogoClicked: EventEmitter<any> = new EventEmitter<any>();

  noDataObject: DropdownItem = {
    id: 'none',
    name: '',
    icon: '/assets/img/icons/coffee.svg'
  }
  applicationLang$: any;
  loggedUser: any;
  languageToUse: any;
  isUserPageDetail: boolean = false;
  notificationsCounter: number = 0;
  notificationsPollingStarted: boolean = false;
  result$: Subscription = new Subscription();
  notificationInitials: any;

  @ViewChild("headerSearchNotification") notifications?: ElementRef;
  isAdminModule: boolean = false;
  profileChangeMenuOpen: boolean = false;
  profileMenuItems: any[] = [];
  isLoadingProfiles: boolean = true;

  constructor(public translate: TranslateService,
    public redirectService: RedirectService,
    public sidebarService: SidebarService,
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private router: Router,
  ) {
    this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
    this.store.select(fromApp.getLoggedUser)
      .subscribe(
        (loggedUser) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.avatar = this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage;
            this.languageToUse = this.langsService.getUserLang(this.loggedUser.user);
          }
        });

    const notificationsCounter$: Observable<number> = this.store.select(fromApp.getNotificationsCounter);
    const notificationsInitials$: Observable<any> = this.store.select(fromApp.getNotificationsInitials);
    // Sto in ascolto dei vari cambiamenti
    const combinedSelectes$ = combineLatest([notificationsCounter$, notificationsInitials$]);
    this.result$ = combinedSelectes$.subscribe(
      ([notificationsCounter, notificationsInitials]) => {
        this.notificationsCounter = notificationsCounter;
        /* this.notificationInitials = notificationsInitials; */
        if (!this.notificationsPollingStarted) {
          this.store.dispatch(CoreActions.StartCountNotifications());
          /* this.store.dispatch(CoreActions.StartGetNotificationsInitials()); */
          this.notificationsPollingStarted = true;
        }
      })
  }

  ngOnInit() {
    moment.locale(this.language || 'en');
    this.isUserPageDetail = !(window.location.href.indexOf('/collaborator/home') > -1);
    const innerWidth = window.innerWidth;
    if (innerWidth < 600) {
      this.isMobile = true;
    }
    this.initials = this.loggedUser.forename[0] + this.loggedUser.surname[0];
    this.userName = this.loggedUser.surname + ' ' + this.loggedUser.forename;
    this.noDataObject.name = this.translate.instant('notifications.NO_DATA');
    this.translate.get(
      [
        'menu.MANAGER',
        'menu.COLLABORATOR',
        'menu.ADMIN',

      ])
      .subscribe(translations => {
        this.profileMenuItems = [];
        if (this.isCollaborator) {
          this.profileMenuItems.push(
            {
              id: 'collaborator',
              description: translations['menu.COLLABORATOR'],
              icon: '/assets/img/icons/user.svg',
            })
        }
        if (this.isManager) {
          this.profileMenuItems.unshift(
            {
              id: 'manager',
              description: translations['menu.MANAGER'],
              icon: '/assets/img/icons/users.svg',
            })
        }
        if (this.isAdmin) {
          this.profileMenuItems.push(
            {
              id: 'admin',
              description: translations['menu.ADMIN'],
              icon: '/assets/img/icons/settings.svg',
            })
        }
        this.isLoadingProfiles = false;
      })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.showNotificationPanel && this.notifications) {
      if (!this.notifications.nativeElement.contains(event.target)) {
        this.showNotificationPanel = false;
      }
    }
  }

  // Per il cambio vista dei manager
  collaboratorView() {
    return window.location.href.indexOf('/collaborator/') > -1 || window.location.href.indexOf('/collaboratorOperations/') > -1;
  }

  // Gestione sidebar
  isSidebarOpened() {
    return this.sidebarService.isNavOpen();
  }

  // Toggle della sidebar
  toggleSidebar() {
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }

  // Gestione eventi
  emitOnUserLogoClicked() {
    this.onUserLogoClicked.emit();
  }

  isProfileOrTeamPage() {
    if (window.location.href.indexOf('profile') || window.location.href.indexOf('myTeam')) {
      return true
    } else {
      return false;
    }
  }

  // Gestione eventi
  emitOnManagerLogoClicked() {
    this.onManagerLogoClicked.emit();
  }

  // Azione se logo performance care cliccato
  emitOnAppLogoClicked() {
    this.redirectService.goToHome(true);
  }

  // Vai alla pagina delle notifiche
  goToNotificationPage() {
    this.redirectService.goToNotifications();
  }

  emitOnTooltipClicked() {
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage }));
    // this.onTooltipClicked.emit();
  }

  emitOnNotificationClicked($event?: any) {
    /* this.showNotificationPanel = !this.showNotificationPanel;
    this.onNotificationClicked.emit()
    $event.stopPropagation(); */
    this.redirectService.goToNotifications();
  }

  openChangeProfileMenu() {
    this.profileChangeMenuOpen = !this.profileChangeMenuOpen;
  }

  emitOnSwitchProfile(switchTo: string) {
    this.profileChangeMenuOpen = false;
    if (switchTo == 'admin') {
      this.isAdminModule = true;
      this.router.navigate(["/admin/home"]);
    } else if (switchTo == 'manager') {
      this.isAdminModule = false;
      this.router.navigate(["/manager/home"]);
    } else if (switchTo == 'collaborator') {
      this.isAdminModule = false;
      if (this.isOperations && this.isSti) {
        this.router.navigate(["/collaborator/home"]);
      } else if (this.isOperations && !this.isSti) {
        this.router.navigate(["/collaboratorOperations/home"]);
      } else {
        this.router.navigate(["/collaborator/home"]);
      }
    }
  }

  getTimeFromNow(date: Date): string {
    return moment(date).fromNow();
  }
}