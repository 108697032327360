import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareFeedbackDialogue, PerfCareFeedbackDialogueTypes, UpdateUserFeedbackDialogueForUserResponse, CreateUserFeedbackDialogueForUserResponse, GetUserFeedbackDialogueForUserResponse, EnrichedSenecaResponseForBadges } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { TabSection } from 'src/app/utils/classes.utils';
import { CheckExchangeAgendaForFreeSlotsResponse } from 'src/app/shared/services/collaborator.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';

@Component({
  selector: 'app-collaborator-organizeInterview',
  templateUrl: './organizeInterview.component.html',
  styleUrls: ['./organizeInterview.component.scss']
})
export class CollaboratorOrganizeInterviewComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: number = 0;
  userId: string = '';
  personDetails: any;
  tabSectionsList: TabSection[] = [];
  isWhereSection: boolean = false;
  interviewId: string = '';
  goToGoalObjectiveList: boolean = false;
  modalUpdateInterviewTitleConfirmTextButton: string = '';
  modalUpdateInterviewTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  interview: PerfCareFeedbackDialogue = <PerfCareFeedbackDialogue>{
    invitedUserId: '',
    link: ''
  };
  interviewCopy: PerfCareFeedbackDialogue = <PerfCareFeedbackDialogue>{
    invitedUserId: '',
    link: ''
  };
  hourSlots: CheckExchangeAgendaForFreeSlotsResponse[] = [];
  hourSlotsFE: CheckExchangeAgendaForFreeSlotsResponse[] = [];
  slotSelected: CheckExchangeAgendaForFreeSlotsResponse = {
    persons: 0,
    percentage: 0,
    unavailablePersonList: []
  };
  getSlotHours$: Subscription = new Subscription();
  isFetchingSlotHours: boolean = false;
  calledSlotHoursService: boolean = false;
  isFetchingGetInterview: boolean = false;
  getInterview$: Subscription = new Subscription();
  isFetchingCreateInterview: boolean = false;
  createInterview$: Subscription = new Subscription();
  slotInterviewSelected = {
    startLabel: '',
    startTimeLabel: '',
    endTimeLabel: ''
  }
  today = new Date();
  yesterday = new Date();
  isFetchingUpdateInterview: boolean = false;
  updateInterview$: Subscription = new Subscription();
  modalUpdateInterviewTitle: string = '';
  modalUpdateInterviewText: string = '';
  isFetchingManagerData: boolean = false;
  getManagerData$: Subscription = new Subscription();
  myManager: any;

  dateRangeTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  whereTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  linkTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  placeTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  teamsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  interviewDurationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  interviewTypesOptions: any[] = [];
  interviewTypeSelected: any;
  selectedInterviewDuration: any;
  durationOptions: any[] = [];
  argument: string = '';
  argumentTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isImpersonate: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.interviewId = params.interviewId;

              this.yesterday.setDate(this.today.getDate());
              this.yesterday.setHours(0);
              this.yesterday.setMinutes(0);
              this.yesterday.setSeconds(0);
              this.yesterday.setMilliseconds(0);

              this.appStore.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.personDetails = loggedUser && loggedUser.user;

                    this.getMyManagerData();
                    // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                    if (this.personDetails && this.personDetails.userId && this.interviewId) {
                      this.getInterviewById();
                    }
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.WHEN',
        'generic.WHERE',
        'goalSetting.organizeInterview.WHEN_TAB',
        'goalSetting.organizeInterview.WHERE_TAB',
        'generic.IN_PRESENCE',
        'goalSetting.organizeInterview.SELECT_PERIOD',
        'goalSetting.organizeInterview.SELECT_PERIOD_TEXT',
        'goalSetting.organizeInterview.SELECT_PERIOD_TEXT',
        'goalSetting.organizeInterview.MS_TEAMS_DESCRIPTION',
        'goalSetting.organizeInterview.ONLINE_DESCRIPTION',
        'goalSetting.organizeInterview.PRESENCE_DESCRIPTION',
        'goalSetting.organizeInterview.0_5_HOUR_SLOT',
        'goalSetting.organizeInterview.1_HOUR_SLOT',
        'goalSetting.organizeInterview.1_5_HOUR_SLOT',
        'goalSetting.organizeInterview.INTERVIEW_DURATION',
        'goalSetting.organizeInterview.INTERVIEW_DURATION_DESCRIPTION',
        'goalSetting.organizeInterview.ARGUMENT',
        'goalSetting.organizeInterview.ARGUMENT_MODAL_SUBTITLE',
        'goalSetting.organizeInterview.ARGUMENT_MODAL_TEXT'
      ])
      .subscribe(translations => {
        this.durationOptions = [
          {
            code: '30',
            title: translations['goalSetting.organizeInterview.0_5_HOUR_SLOT']
          },
          {
            code: '60',
            title: translations['goalSetting.organizeInterview.1_HOUR_SLOT']
          },
          {
            code: '90',
            title: translations['goalSetting.organizeInterview.1_5_HOUR_SLOT']
          }
        ]
        this.selectedInterviewDuration = this.durationOptions[1];
        this.interview.type = PerfCareFeedbackDialogueTypes.MS_TEAMS;
        this.interviewTypesOptions = [
          {
            id: PerfCareFeedbackDialogueTypes.MS_TEAMS,
            title: translations['goalSetting.organizeInterview.MS_TEAMS_DESCRIPTION'],
            icon: 'assets/img/icons/microsoft-teams.svg'
          },
          {
            id: PerfCareFeedbackDialogueTypes.ONLINE,
            title: translations['goalSetting.organizeInterview.ONLINE_DESCRIPTION'],
            icon: 'assets/img/icons/link.svg'
          },
          {
            id: PerfCareFeedbackDialogueTypes.PRESENCE,
            title: translations['goalSetting.organizeInterview.PRESENCE_DESCRIPTION'],
            icon: 'assets/img/icons/map-pin.svg'
          }
        ]
        this.interviewTypeSelected = this.interviewTypesOptions[0];

        this.tabSectionsList = [
          {
            id: 'whenTab',
            attrAriaLabel: translations['goalSetting.organizeInterview.WHEN_TAB'],
            title: translations['generic.WHEN'],
            isActive: true,
            isCompleted: this.isWhenTabCompleted()
          },
          {
            id: 'whereTab',
            attrAriaLabel: translations['goalSetting.organizeInterview.WHERE_TAB'],
            title: translations['generic.WHERE'],
            isCompleted: this.isWhereTabCompleted(),
            isDisabled: this.isWhereTabDisabled()
          }
        ];

        this.dateRangeTooltipModal = {
          modalId: "019",
          title: translations['goalSetting.organizeInterview.SELECT_PERIOD'],
          text: translations['goalSetting.organizeInterview.SELECT_PERIOD_TEXT']
        };

        this.whereTooltipModal = {
          modalId: "020",
          title: "Informazioni input",
          subtitle: "Tipo colloquio",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };

        this.linkTooltipModal = {
          modalId: "021",
          title: "Informazioni input",
          subtitle: "Link",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };

        this.placeTooltipModal = {
          modalId: "022",
          title: "Informazioni input",
          subtitle: "Dove",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        };

        this.teamsTooltipModal = {
          modalId: "013",
          title: "Informazioni input",
          subtitle: "Teams",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien."
        }
        this.argumentTooltipModal = {
          modalId: "013",
          title: translations['goalSetting.organizeInterview.ARGUMENT'],
          subtitle: translations['goalSetting.organizeInterview.ARGUMENT_MODAL_SUBTITLE'],
          text: translations['goalSetting.organizeInterview.ARGUMENT_MODAL_TEXT']
        }

        this.interviewDurationTooltipModal = {
          modalId: "d177",
          title: translations['goalSetting.organizeInterview.INTERVIEW_DURATION'],
          text: translations['goalSetting.organizeInterview.INTERVIEW_DURATION_DESCRIPTION']
        }

        this.modalUpdateInterviewTitle = this.getModalSaveTitle();
        this.modalUpdateInterviewText = this.getModalSaveDescription();
        this.modalUpdateInterviewTitleConfirmTextButton = this.getConfirmLabelUpdateInterviewModal();
        this.modalUpdateInterviewTextCloseTextButton = this.getCancelLabelUpdateInterviewModal();

        this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
          moment.locale(applicationLang);
        })
      })
  }

  changeInterviewDuration(slot: any) {
    this.selectedInterviewDuration = slot;
  }

  // Controlla le date inserite
  checkDatesValidation() {
    if ((new Date(this.interview.endDate) < new Date(this.interview.startDate)) || (moment(this.interview.endDate).diff(moment(this.interview.startDate), "days") > 30)) {
      return true;
    }
    return false;
  }

  // Seleziona uno slot orario
  selectSlot(slotSelected: any) {
    this.slotSelected = slotSelected;
    this.checkWhenTabCompleted();
    this.checkWhereTabDisabled();
  }

  // Cambia il tipo del colloqio della select
  changeInterviewType(data: any) {
    this.interviewTypeSelected = data;
    this.interview.type = this.interviewTypeSelected.id;
    this.interview.link = '';
    this.checkWhereTabCompleted();
  }

  // Se il pulsante di salva colloquio è disabilitato
  isDisabledSaveInterviewButton() {
    if (this.interview.type == PerfCareFeedbackDialogueTypes.ONLINE || this.interview.type == PerfCareFeedbackDialogueTypes.PRESENCE) {
      if (this.interview.link && this.interview.link.trim().length) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }

  // Quando scrivi sull'input del Link
  interviewLinkChanged(text: string) {
    this.interview.link = text;
    this.checkWhereTabCompleted();
  }

  // Numero da visualizzare nel pulsante mostra altri
  getNumberToChargeSlots() {
    if (this.hourSlots.length - this.hourSlotsFE.length >= 5) {
      return 5;
    } else {
      return this.hourSlots.length - this.hourSlotsFE.length
    }
  }

  // Argomento del colloquio
  argumentChanged(text: string) {
    this.argument = text;
    this.interview.argument = text;
  }

  // Carica altri slots
  chargeMoreSlots() {
    if (this.hourSlots.length - this.hourSlotsFE.length >= 5) {
      let lengthSlotCardsFE = JSON.parse(JSON.stringify(this.hourSlotsFE.length));
      for (let i = this.hourSlotsFE.length; i < (lengthSlotCardsFE + 5); i++) {
        this.hourSlotsFE.push(this.hourSlots[i]);
      }
    } else {
      for (let j = this.hourSlotsFE.length; j < this.hourSlots.length; j++) {
        this.hourSlotsFE.push(this.hourSlots[j]);
      }
    }
  }

  // Recupera gli slot disponibili per il colloquio
  getSlotHours() {
    this.isFetchingSlotHours = true;
    this.calledSlotHoursService = true;
    this.hourSlots.length = 0;
    this.hourSlotsFE.length = 0;
    this.slotInterviewSelected = {
      startLabel: '',
      startTimeLabel: '',
      endTimeLabel: ''
    };
    let start = null;
    let end = null;
    if (this.interview.startDate instanceof moment) {
      start = moment(this.interview.startDate).toDate();
    } else {
      start = this.interview.startDate;
    }

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);

    if (this.interview.endDate instanceof moment) {
      end = moment(this.interview.endDate).toDate();
    } else {
      end = this.interview.endDate;
    }
    let startDateISOString = start.toISOString();
    end.setDate(end.getDate() + 1);
    end.setHours(0);
    end.setMinutes(0);
    end.setSeconds(0);
    let endDateISOString = end.toISOString();
    this.slotSelected = {
      persons: 0,
      percentage: 0,
      unavailablePersonList: []
    };

    this.getSlotHours$ = this.collaboratorService.getSlotCards((this.myManager.email || ''), startDateISOString, endDateISOString, ((this.selectedInterviewDuration && this.selectedInterviewDuration.code) || '60'))
      .subscribe((data: SenecaResponse<CheckExchangeAgendaForFreeSlotsResponse[]>) => {
        this.checkWhenTabCompleted();
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "045",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.hourSlots = data.response;
          for (let i = 0; i < this.hourSlots.length; i++) {
            this.hourSlots[i].startLabel = moment(this.hourSlots[i].start).format('dddd') + ' <b>' + moment(this.hourSlots[i].start).format('DD')
              + '</b> ' + moment(this.hourSlots[i].start).format('MMMM') + ' ' + moment(this.hourSlots[i].start).format('YYYY');
            this.hourSlots[i].startTimeLabel = moment(this.hourSlots[i].start).format('HH') + ':' + moment(this.hourSlots[i].start).format('mm');
            this.hourSlots[i].endTimeLabel = moment(this.hourSlots[i].end).format('HH') + ':' + moment(this.hourSlots[i].end).format('mm');
          }

          if (this.hourSlots.length >= 5) {
            for (let i = 0; i < 5; i++) {
              this.hourSlotsFE.push(this.hourSlots[i]);
            }
          } else {
            for (let i = 0; i < this.hourSlots.length; i++) {
              this.hourSlotsFE.push(this.hourSlots[i]);
            }
          }
        }
        this.isFetchingSlotHours = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "046",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingSlotHours = false;
        })
  }

  // Testo per il modale Salva/Aggiorna Colloquio
  getModalSaveTitle() {
    if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
      if (this.interviewId && this.interviewId.length) {
        return this.translate.instant('goalSetting.organizeInterview.UPDATE_INTERVIEW');
      } else {
        return this.translate.instant('goalSetting.organizeInterview.SAVE_INTERVIEW');
      }
    } else {
      return this.translate.instant('goalSetting.organizeInterview.OBBLIGATORY_DATA_NOT_INSERTED');
    }
  }

  // Descrizione per il modale Aggiorna obiettivo
  getModalSaveDescription() {
    if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
      if (this.interviewId && this.interviewId.length) {
        return this.translate.instant('goalSetting.organizeInterview.DO_YOU_WANT_UPDATE_INTERVIEW');
      } else {
        return this.translate.instant('goalSetting.organizeInterview.DO_YOU_WANT_SAVE_INTERVIEW');
      }
    } else {
      return this.translate.instant('goalSetting.organizeInterview.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
    }
  }

  // Testo per il modale Salva/aggiorna colloquio quando schiacci Torna indietro
  getModalSaveTitleBack() {
    if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
      if (this.interviewId && this.interviewId.length) {
        return this.translate.instant('goalSetting.organizeInterview.UPDATE_INTERVIEW');
      } else {
        return this.translate.instant('goalSetting.organizeInterview.SAVE_INTERVIEW');
      }
    } else {
      return this.translate.instant('goalSetting.organizeInterview.OBBLIGATORY_DATA_NOT_INSERTED');
    }
  }

  // Descrizione per il modale Salva/aggiorna colloquio quando schiacci Torna indietro
  getModalSaveDescriptionBack() {
    if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
      if (this.interviewId && this.interviewId.length) {
        return this.translate.instant('goalSetting.organizeInterview.DO_YOU_WANT_UPDATE_INTERVIEW');
      } else {
        return this.translate.instant('goalSetting.organizeInterview.DO_YOU_WANT_SAVE_INTERVIEW');
      }
    } else {
      return this.translate.instant('goalSetting.organizeInterview.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
    }
  }

  // Testo modale pulsante close
  getCancelLabelUpdateInterviewModal(isBack?: boolean) {
    if (this.interviewId && this.interviewId.length) {
      if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
        if (isBack) {
          return this.translate.instant('goalSetting.createGoal.GO_BACK');
        } else {
          return this.translate.instant('goalSetting.createGoal.GO_BACK');
        }
      } else {
        return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
      }
    } else {
      if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
        if (isBack) {
          return this.translate.instant('goalSetting.createGoal.GO_BACK');
        } else {
          return this.translate.instant('goalSetting.createGoal.GO_BACK');
        }
      } else {
        return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
      }
    }
  }

  // Testo modale pulsante conferma
  getConfirmLabelUpdateInterviewModal(isBack?: boolean) {
    if (this.interviewId && this.interviewId.length) {
      if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
        if (isBack) {
          return this.translate.instant('generic.SAVE_AND_GO_BACK');
        } else {
          return this.translate.instant('goalSetting.organizeInterview.YES_UPDATE_INTERVIEW');
        }
      } else {
        return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
      }
    } else {
      if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
        if (isBack) {
          return this.translate.instant('generic.SAVE_AND_GO_BACK');
        } else {
          return this.translate.instant('goalSetting.organizeInterview.YES_SAVE_INTERVIEW');
        }
      } else {
        return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
      }
    }
  }

  // Gestione tasto conferma modale per update
  updateInterviewHandle() {
    if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
      this.updateInterview();
    } else {
      this.closeModalInterviewUpdate();
    }
  }

  // Gestione tasto conferma modale per creazione
  createInterviewHandle() {
    if (this.isWhenTabCompleted() && this.isWhereTabCompleted()) {
      this.createInterview();
    } else {
      this.closeModalInterviewUpdate();
    }
  }

  // Quando switcho tab Cosa/Come
  onTabClicked(tab: TabSection) {
    switch (tab.id) {
      case 'whenTab':
        this.goToWhenSection()
        break;
      case 'whereTab':
        this.goToWhereSection()
        break;
    }
  }

  // Controlla se mettere completa la tab Quando
  checkWhenTabCompleted() {
    this.tabSectionsList[0].isCompleted = this.isWhenTabCompleted();
  }

  // Controlla se mettere completa la tab Dove
  checkWhereTabCompleted() {
    this.tabSectionsList[1].isCompleted = this.isWhereTabCompleted();
  }

  // Controlla se mettere disabilitata la tab Dove
  checkWhereTabDisabled() {
    this.tabSectionsList[1].isDisabled = this.checkDatesValidation() || (!this.slotSelected || !this.slotSelected.start);
  }

  // Quando si inserisce la data inizio colloquio
  interviewStartDateChanged(date: Date) {
    this.interview.startDate = date;
    this.checkWhenTabCompleted();
    this.checkWhereTabDisabled();
  }

  // Quando si inserisce la data fine colloquio
  interviewEndDateChanged(date: Date) {
    this.interview.endDate = date;
    this.checkWhenTabCompleted();
    this.checkWhereTabDisabled();
  }

  // Vai alla sezione Dove
  goToWhereSection() {
    this.isWhereSection = true;
    this.tabSectionsList[1].isActive = true;
  }

  // Vai alla sezione Quando
  goToWhenSection() {
    this.isWhereSection = false;
    this.tabSectionsList[1].isActive = false;
  }

  // Quando la tab when è completata
  isWhenTabCompleted() {
    if (!this.checkDatesValidation() && this.interview.startDate && this.interview.endDate && ((this.slotSelected && this.slotSelected.start) || (this.slotInterviewSelected && this.slotInterviewSelected.startLabel))) {
      return true;
    } else {
      return false;
    }
  }

  // Quando la tab where è completata
  isWhereTabCompleted() {
    if (!this.isDisabledSaveInterviewButton()) {
      return true;
    }
    return false;
  }

  // Quando la tab where è disabilitata
  isWhereTabDisabled() {
    if (!this.slotSelected || !this.slotSelected.start) {
      return true;
    }
    return false;
  }

  // Formatta le date iniziali
  formatDates() {
    this.interview.startDate = new Date();
    this.interview.endDate = new Date();
    this.interview.endDate.setDate(this.interview.startDate.getDate() + 5);
  }

  // Recupera il colloquio dall'interviewId
  getInterviewById() {
    this.isFetchingGetInterview = true;

    this.getInterview$ = this.collaboratorService.getInterviewByIdForUser(this.runningYear.toString(), this.interviewId)
      .subscribe((data: SenecaResponse<GetUserFeedbackDialogueForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "047",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else if (data && data.response) {
          this.interview = data.response;
          this.slotInterviewSelected.startLabel = moment(this.interview.startDate).format('dddd') + ' <b>' + moment(this.interview.startDate).format('DD')
            + '</b> ' + moment(this.interview.startDate).format('MMMM') + ' ' + moment(this.interview.startDate).format('YYYY');
          this.slotInterviewSelected.startTimeLabel = moment(this.interview.startDate).format('HH') + ':' + moment(this.interview.startDate).format('mm');
          this.slotInterviewSelected.endTimeLabel = moment(this.interview.endDate).format('HH') + ':' + moment(this.interview.endDate).format('mm');
          this.interviewCopy = JSON.parse(JSON.stringify(this.interview));
          this.checkWhenTabCompleted();
          this.checkWhereTabCompleted();
        }
        this.isFetchingGetInterview = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "048",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetInterview = false;
          this.goBackBrowser();
        })
  }

  trackByStart(index: any, item: any) {
    return item.start;
  }

  // Gestisce quando crei un nuovo colloquio
  goToCreateInterview(openModalAlways?: boolean) {
    if (!openModalAlways) {
      this.modalUpdateInterviewTitle = this.getModalSaveTitle();
      this.modalUpdateInterviewText = this.getModalSaveDescription();
      this.modalUpdateInterviewTitleConfirmTextButton = this.getConfirmLabelUpdateInterviewModal();
      this.modalUpdateInterviewTextCloseTextButton = this.getCancelLabelUpdateInterviewModal();
      this.openModalForCreateInterview();
    } else {
      this.modalUpdateInterviewTitle = this.getModalSaveTitleBack();
      this.modalUpdateInterviewText = this.getModalSaveDescriptionBack();
      this.modalUpdateInterviewTitleConfirmTextButton = this.getConfirmLabelUpdateInterviewModal(true);
      this.modalUpdateInterviewTextCloseTextButton = this.getCancelLabelUpdateInterviewModal(true);
      this.openModalForCreateInterview();
    }
  }

  // Gestisce quando aggiorni un colloquio
  goToUpdateInterview(openModalAlways?: boolean) {
    if (!openModalAlways) {
      this.modalUpdateInterviewTitle = this.getModalSaveTitle();
      this.modalUpdateInterviewText = this.getModalSaveDescription();
      this.modalUpdateInterviewTitleConfirmTextButton = this.getConfirmLabelUpdateInterviewModal();
      this.modalUpdateInterviewTextCloseTextButton = this.getCancelLabelUpdateInterviewModal();
      this.openModalForCreateInterview();
    } else {
      if (this.checkIfWhenSectionIsChanged() || this.checkIfWhereSectionIsChanged()) {
        this.modalUpdateInterviewTitle = this.getModalSaveTitleBack();
        this.modalUpdateInterviewText = this.getModalSaveDescriptionBack();
        this.modalUpdateInterviewTitleConfirmTextButton = this.getConfirmLabelUpdateInterviewModal(true);
        this.modalUpdateInterviewTextCloseTextButton = this.getCancelLabelUpdateInterviewModal(true);
        this.openModalForCreateInterview();
      } else {
        this.goBackBrowser();
      }
    }
  }

  // Recupera dati del mio manager
  getMyManagerData() {
    this.isFetchingManagerData = true;

    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "049",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.myManager = data.response;
          this.formatDates();
          if (!this.interviewId || !this.interviewId.length) {
            this.getSlotHours();
          }
        }
        this.isFetchingManagerData = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "050",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingManagerData = false;
        })
  }

  // Aggiorna colloquio
  updateInterview() {
    this.isFetchingUpdateInterview = true;
    this.closeModalInterviewUpdate();
    this.interview.invitedUserId = this.myManager.userId;
    this.interview.startDate = (this.slotSelected && this.slotSelected.start) ? moment(this.slotSelected.start).toDate() : moment(this.interview.startDate).toDate();
    this.interview.endDate = (this.slotSelected && this.slotSelected.start) ? moment(this.slotSelected.end).toDate() : moment(this.interview.endDate).toDate();

    this.updateInterview$ = this.collaboratorService.updateInterviewForUser(this.runningYear.toString(), this.interview)
      .subscribe((data: SenecaResponse<UpdateUserFeedbackDialogueForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "051",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          let description = this.translate.instant('goalSetting.organizeInterview.modal.DESCRIPTION_1') + this.myManager.forename;
          description += this.translate.instant('goalSetting.organizeInterview.modal.DESCRIPTION_2');
          description += this.translate.instant('goalSetting.organizeInterview.modal.WHEN') + moment(this.interview.startDate).format("DD/MM/YYYY") + ' ';;
          description += moment(this.interview.startDate).format('HH:mm') + ' - ' + moment(this.interview.endDate).format('HH:mm');
          if (this.interview.link && this.interview.link.length) {
            description += this.translate.instant('goalSetting.organizeInterview.modal.WHERE') + this.interview.link;
          } else {
            description += this.translate.instant('goalSetting.organizeInterview.modal.WHERE_TEAMS');
          }
          if (this.interview.argument && this.interview.argument.length) {
            description += this.translate.instant('goalSetting.organizeInterview.modal.ARGUMENT') + this.interview.argument;
          }
          const modalContentData: ApplicationModalMessage = {
            modalId: "141",
            title: this.translate.instant('goalSetting.organizeInterview.modal.TITLE'),
            image: "/assets/img/section/feedack.svg",
            subtitle: this.translate.instant('goalSetting.organizeInterview.modal.SUBTITLE') + this.myManager.forename + ' ' + this.myManager.surname,
            text: description,
            noBackground: true
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: modalContentData }));
          this.goBackBrowser();
        }
        this.isFetchingUpdateInterview = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "052",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingUpdateInterview = false;
        })
  }

  // Crea colloquio
  createInterview() {
    this.isFetchingCreateInterview = true;
    this.closeModalInterviewUpdate();
    this.interview.invitedUserId = this.myManager.userId;
    this.interview.startDate = moment(this.slotSelected.start).toDate();
    this.interview.endDate = moment(this.slotSelected.end).toDate();

    this.createInterview$ = this.collaboratorService.createInterviewForUser(this.runningYear.toString(), this.interview)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "053",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // controllo se sono stati rilasciati badge
          let badges = data.obtainedBadges;
          let tmpBadgesList: BadgeObject[] = [];
          if (badges && badges.length) {
            tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
            this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
          }

          if (data && data.response) {
            let description = this.translate.instant('goalSetting.organizeInterview.modal.DESCRIPTION_1') + this.myManager.forename;
            description += this.translate.instant('goalSetting.organizeInterview.modal.DESCRIPTION_2');
            description += this.translate.instant('goalSetting.organizeInterview.modal.WHEN') + moment(this.interview.startDate).format("DD/MM/YYYY") + ' ';
            description += moment(this.interview.startDate).format('HH:mm') + ' - ' + moment(this.interview.endDate).format('HH:mm');
            if (this.interview.link && this.interview.link.length) {
              description += this.translate.instant('goalSetting.organizeInterview.modal.WHERE') + this.interview.link;
            } else {
              description += this.translate.instant('goalSetting.organizeInterview.modal.WHERE_TEAMS');
            }
            if (this.interview.argument && this.interview.argument.length) {
              description += this.translate.instant('goalSetting.organizeInterview.modal.ARGUMENT') + ': ' + this.interview.argument;
            }
            const modalContentData: ApplicationModalMessage = {
              modalId: "140",
              title: this.translate.instant('goalSetting.organizeInterview.modal.TITLE'),
              image: "/assets/img/section/feedback.svg",
              subtitle: this.translate.instant('goalSetting.organizeInterview.modal.SUBTITLE') + this.myManager.forename + ' ' + this.myManager.surname,
              text: description,
              noBackground: true
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: modalContentData }));
          }

          this.goBackBrowser();
        }
        this.isFetchingCreateInterview = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "054",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCreateInterview = false;
        })
  }

  // Controlla se è cambiato qualcosa nella sezione When
  checkIfWhenSectionIsChanged() {
    return this.interview.startDate != this.interviewCopy.startDate || this.interview.endDate != this.interviewCopy.endDate
  }

  // Controlla se è cambiato qualcosa nella sezione Where
  checkIfWhereSectionIsChanged() {
    return this.interview.type != this.interviewCopy.type || this.interview.link != this.interviewCopy.link || this.interview.argument != this.interviewCopy.argument;
  }

  // Apre la modale per l'aggiornamento/salvataggio del colloquio
  openModalForCreateInterview() {

    this.modalService.open('modalUpdateInterviewForUser');
  }

  // Vai indietro history browser
  goBackBrowser() {
    this.goToGoalObjectiveList = true;
    this.redirectService.goBackBrowser();
  }

  // Torna indietro browser
  goBack() {
    this.goToGoalObjectiveList = true;
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per il salvataggio/aggiornamento del colloquio
  closeModalInterviewUpdate() {
    this.modalService.close('modalUpdateInterviewForUser');
  }

  // Apre la modale informativa del seleziona periodo del colloquio
  openModalInfoSelectPeriod() {
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.dateRangeTooltipModal }));
  }

  tabId(index: number, el: any) {
    return el.id;
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.createInterview$) {
      this.createInterview$.unsubscribe();
    }
    if (this.getInterview$) {
      this.getInterview$.unsubscribe();
    }
    if (this.getSlotHours$) {
      this.getSlotHours$.unsubscribe();
    }
    if (this.updateInterview$) {
      this.updateInterview$.unsubscribe();
    }
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
  }
}