<div class="card-container">
  <div class="header-type-container">
    <div class="type-container">
      <div class="type-container-text-icon">
        <svg-icon src="assets/img/icons/clapping-hands.svg" class="small-size" [applyClass]="true"></svg-icon>
        <!-- <svg-icon src="assets/img/icons/clipboard.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
            <svg-icon src="assets/img/icons/calendar.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
            <svg-icon src="assets/img/icons/eye.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
            <svg-icon src="assets/img/icons/hard-hat.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
            <svg-icon src="assets/img/icons/message-circle.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon> -->
        <p *ngIf="!isReceiving" translate="feedbackSection.SENDED_CLAP"></p>
        <p *ngIf="isReceiving" translate="feedbackSection.RECEIVED_CLAP"></p>
      </div>
      <div class="period-time">
        <p>{{object.creationDateAgo}}</p>
        <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
      </div>
    </div>
    <item-list-team-status *ngIf="!isClap" styleItem="border" message="feedback.TO_SEND" type="toStart">
    </item-list-team-status>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <avatar-img *ngIf="isReceiving" fxHide.xs [user]="isClap ? object.senderUser : object.sender" size="medium">
    </avatar-img>
    <avatar-img *ngIf="!isReceiving" fxHide.xs [user]="object.recipientUser" size="medium"></avatar-img>
    <div class="information-text-container">
      <div class="specific-information">
        <!-- Card applauso -->
        <ng-container *ngIf="isClap">
          <p *ngIf="!isReceiving"
            [innerHTML]="('feedbackSection.SENT_TO' | translate) + '<b>' + object.recipientUser.forename + ' ' + object.recipientUser.surname + '</b>'">
          </p>
          <p *ngIf="isReceiving"
            [innerHTML]="('feedbackSection.RECEIVED_FROM' | translate) + '<b>' + object.senderUser.forename + ' ' + object.senderUser.surname + '</b>'">
          </p>
          <svg-icon *ngIf="isReceiving" src="assets/img/icons/arrow-down.svg" class="small-size orange-accent"
            [applyClass]="true"></svg-icon>
          <svg-icon *ngIf="!isReceiving" src="assets/img/icons/arrow-up.svg" class="small-size green-success-svg"
            [applyClass]="true"></svg-icon>
        </ng-container>


        <ng-container *ngIf="!isClap">
          <p *ngIf="!isReceiving"
            [innerHTML]="('feedbackSection.SENT_TO' | translate) + '<b>' + object.senderUser.forename + ' ' + object.senderUser.surname + '</b>'">
          </p>
          <p *ngIf="isReceiving"
            [innerHTML]="('feedbackSection.RECEIVED_FROM' | translate) + '<b>' + object.recipientUser.forename + ' ' + object.recipientUser.surname + '</b>'">
          </p>
          <svg-icon *ngIf="isReceiving" src="assets/img/icons/arrow-down.svg" class="small-size orange-accent"
            [applyClass]="true"></svg-icon>
          <svg-icon *ngIf="!isReceiving" src="assets/img/icons/arrow-up.svg" class="small-size green-success-svg"
            [applyClass]="true"></svg-icon>
        </ng-container>
      </div>
      <p class="information-text">
        <span class="bold-text italic-text" translate="feedbackSection.HOW"></span>
        <span [innerHTML]="' '  + (isClap ? ('careTags.' + object.evidenceCareTagId | translate ) : 'todo' )"></span>
      </p>
      <p class="information-text">
        <span *ngIf="!isClap" class="bold-text italic-text" translate="feedbackSection.OSSERVATION"></span>
        <span [innerHTML]="object.comment"></span>
      </p>
      <div class="action-footer" *ngIf="!isClap">
        <div class="type-container">
          <div class="type-container-text-icon">
            <svg-icon src="assets/img/icons/hard-hat.svg" class="small-size gray-gray2" [applyClass]="true">
            </svg-icon>
            <p class="margin-right6">
              <span translate="feedback.FEEDBACK_REQUEST"></span>
              <span class="bold-text">3 giorni fa</span>
            </p>
          </div>
          <a translate="feedback.SHOW_DONE_REQUEST">
          </a>
        </div>
      </div>
      <div *ngIf="isClap && object.senderUserId == loggedUser.userId && !isHistory" class="action-footer">
        <div class="actions-link-container">
          <a translate="generic.EDIT" (click)="editObject(object.feedbackId)">
          </a>
          <!-- Elimina commentato per gli applausi su richiesta -->
          <!--a translate="generic.DELETE" (click)="deleteObject(object.feedbackId)">
          </a-->
        </div>
      </div>
      <div *ngIf="!isClap" class="action-footer">
        <div class="feedback-actions">
          <europ-button label="{{'feedback.SEND_FEEDBACK' | translate}}" type="primary link" size="medium"
            iconLeft="assets/img/icons/message-circle.svg"></europ-button>
        </div>
      </div>
      <div *ngIf="!isClap" class="files-container">
        <div class="files">
          <div class="file-container">
            <list-doc-item srcIcon="assets/img/icons/file/doc.svg" fileName="Nome del file" chargedTime="13.02.2021">
            </list-doc-item>
            <div class="divisor-line"></div>
          </div>
          <div class="file-container">
            <list-doc-item srcIcon="assets/img/icons/file/xls.svg" fileName="Nome del file" chargedTime="13.02.2021">
            </list-doc-item>
            <div class="divisor-line"></div>
          </div>
          <div class="file-container">
            <list-doc-item srcIcon="assets/img/icons/file/ppt.svg" fileName="Nome del file" chargedTime="13.02.2021">
            </list-doc-item>
            <div class="divisor-line"></div>
          </div>
        </div>
      </div>
      <europ-button *ngIf="!isClap" label="{{'generic.CHARGE_FILE' | translate}}" type="primary link" size="medium"
        iconLeft="assets/img/icons/download-cloud.svg"></europ-button>
    </div>
  </div>
</div>