<div class="team-sidebar-container">
  <div class="searchbar-container">
    <svg-icon src="/assets/img/icons/search.svg" class="regular-size blue-primary" [applyClass]="true"></svg-icon>
    <p class="text-default" *ngIf="!searchedText || !searchedText.length"></p>
    <input class="text-default" [(ngModel)]="searchedText" (keyup)="onUserSearch($event)"
      [placeholder]="'menu.SEARCH_BY' | translate">
  </div>
  <ng-container *ngIf="!searchedText && users && users.length">
    <item-list-team class="full-width" *ngFor="let user of users; trackBy: userId" [user]="user"
      (userClicked)="onUserClicked(user)" [activeUserId]="activeUserId" [currentPhaseId]="currentPhaseId">
    </item-list-team>
  </ng-container>
  <ng-container *ngIf="searchResults && searchResults.length">
    <item-list-team class="full-width" *ngFor="let user of searchResults; trackBy: userId" [user]="user"
      [currentPhaseId]="currentPhaseId" (userClicked)="onUserClicked(user)" [activeUserId]="activeUserId"
      [currentPhaseId]="currentPhaseId">
    </item-list-team>
  </ng-container>
  <ng-container *ngIf="!users || !users.length">
    <div class="no-data-container">
      <p class="no-collab-title" translate="goalSetting.NO_COLLAB"></p>
      <p class="no-collab-description" translate="goalSetting.NO_COLLAB_DESCRIPTION"></p>
    </div>
  </ng-container>
</div>