import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { Subordinateservice } from '../manager/services/subordinates.service';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CollaboratorService } from '../shared/services/collaborator.service';
import { CollaboratorOperationsRoutingModule } from './collaboratorOperations-routing.module';
import { CollaboratorOperationsComponent } from './collaboratorOperations.component';
import { CollaboratorOperationsHomeComponent } from './home/home.component';
import { CollaboratorOperationsPersonDetailsComponent } from './personDetails/personDetails.component';
import { collaboratorReducer } from '../collaborator/ngrx/collaborator.reducers';
import { CollaboratorEffects } from '../collaborator/ngrx/collaborator.effects';
import { QualifyCompetencesComponent } from './qualifyCompetences/qualifyCompetences.component';
import { SetOperationsChallengeComponent } from './setChallenge/setChallenge.component';
import { CollaboratorOperationsGoalDetailsComponent } from './goalDetails/goalDetails.component';
import { CanDeactivateUpdateInterviewCollaboratorGuardService } from '../collaborator/services/can-deactivate-update-interview-collaborator-guard.component';
import { CanDeactivateUpdateClapCollaboratorGuardService } from '../collaborator/services/can-deactivate-update-clap-collaborator-guard.component';
import { SendClapCollaboratorOperationsComponent } from './sendClap/sendClap.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';

@NgModule({
  declarations: [
    CollaboratorOperationsComponent,
    CollaboratorOperationsHomeComponent,
    CollaboratorOperationsPersonDetailsComponent,
    QualifyCompetencesComponent,
    SetOperationsChallengeComponent,
    CollaboratorOperationsGoalDetailsComponent,
    SendClapCollaboratorOperationsComponent,
    CollaboratorOperationsGoalDetailsComponent,
    CreateUpdateEvidenceComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    CollaboratorOperationsRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    StoreModule.forFeature('collaborator', collaboratorReducer),
    EffectsModule.forFeature([CollaboratorEffects])
  ],
  providers: [
    Subordinateservice,
    CollaboratorService,
    PersonDetailsGuard,
    // CanDeactivateUpdateGoalCollaboratorGuardService,
    CanDeactivateUpdateInterviewCollaboratorGuardService,
    CanDeactivateUpdateClapCollaboratorGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CollaboratorOperationsModule { }
