<div class="page-content-wrapper">
  <banner-home [phaseName]="phaseName" [backgroundSrc]="bannerImage"
    [endDate]="runningPhase.phaseEndDateForManager ? runningPhase.phaseEndDateForManager : (runningPhase.deadlineDate ? runningPhase.deadlineDate : runningPhase.phaseEndDate)">
  </banner-home>
  <!--Vista Desktop -->
  <div class="box-collection-container" fxShow.gt-sm fxHide>
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.sm="row wrap" fxLayoutGap.sm="16px grid"
      fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" fxLayoutAlign="start center">
      <!-- Lista feedback per manager senza profilo collaboratore -->
      <ng-container *ngIf="!isCollaborator && peerFeedbackAllowed">
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToFeedbackRequests()"
          imgPath="../../../../assets/img/icons/homeIcons/feedback-img.png"
          title="{{'homeCards.collaborator.feedbackCollection.TITLE' | translate}}"
          description="homeCards.collaborator.feedbackCollection.DESCRIPTION_ALT" [isNew]="false"></box-home>
      </ng-container>
      <!--[disabledModalMessage]="notesDisabledModal"-->
      <!-- Appunti privati -->
      <ng-container *ngIf="isCollaborator">
        <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToTakeNote()"
          imgPath="../../../../assets/img/icons/homeIcons/notes-img.png"
          title="{{'homeCards.manager.notes.TITLE' | translate}}" description="homeCards.manager.notes.DESCRIPTION">
        </box-home>
      </ng-container>
      <!-- Invia un applauso -->
      <!-- [isDisabled]="true" [disabledModalMessage]="applauseDisabledModal" -->
      <box-home fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToSendClapPage()"
        imgPath="../../../../assets/img/icons/homeIcons/applause-img.png"
        title="{{'homeCards.managerOperations.smartFeedback.TITLE' | translate}}"
        description="homeCards.managerOperations.smartFeedback.DESCRIPTION">
      </box-home>
      <!-- Dashboard -->
      <box-home fxFlex.gt-sm="33" fxFlex.sm="50" [isDisabled]="true" [disabledModalMessage]="dashboardDisabledModal"
        imgPath="../../../../assets/img/icons/homeIcons/man-op-dashboard-img.png"
        title="{{'homeCards.managerOperations.dashboard.TITLE' | translate}}"
        description="homeCards.managerOperations.dashboard.DESCRIPTION"></box-home>
    </div>
    <!-- Box mid term review-->
    <div class="row-boxes add-row" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.sm="row wrap"
      fxLayoutGap.sm="16px grid" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" fxLayoutAlign="start center">
      <ng-container *ngIf="isLoadingMidTermData">
        <div class="full-width">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoadingMidTermData && midTermData">
        <mid-term-box-home fxFlex="100" imgPath="../../../../assets/img/icons/homeIcons/mid-term-img.png"
          [isManagerBox]="true" title="{{'midTerm.TITLE' | translate}}" [midTermData]="midTermData"
          (onButtonClicked)="goToMidTermList()">
        </mid-term-box-home>
      </ng-container>
    </div>
  </div>
  <!--Vista tablet / mobile -->
  <div class="box-collection-container" fxShow fxHide.gt-sm>
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.sm="row wrap" fxLayoutGap.sm="16px grid"
      fxLayout.gt-sm="row" fxLayoutGap.gt-sm="16px" fxLayoutAlign="start center">
      <!-- Appunti privati -->
      <!--[disabledModalMessage]="notesDisabledModal"-->
      <box-home fxFlex.sm="50" (onButtonClicked)="goToTakeNote()"
        imgPath="../../../../assets/img/icons/homeIcons/notes-img.png"
        title="{{'homeCards.manager.notes.TITLE' | translate}}" description="homeCards.manager.notes.DESCRIPTION">
      </box-home>
      <!-- Invia un applauso -->
      <box-home fxFlex.sm="50" (onButtonClicked)="goToSendClapPage()"
        imgPath="../../../../assets/img/icons/homeIcons/applause-img.png"
        title="{{'homeCards.manager.smartFeedback.TITLE' | translate}}"
        description="homeCards.manager.smartFeedback.DESCRIPTION">
      </box-home>
      <!-- Dashboard -->
      <box-home fxFlex.sm="50" [isDisabled]="true" [disabledModalMessage]="dashboardDisabledModal"
        imgPath="../../../../assets/img/icons/homeIcons/dashboard-img.png"
        title="{{'homeCards.manager.dashboard.TITLE' | translate}}"
        description="homeCards.manager.dashboard.DESCRIPTION"></box-home>
      <ng-container *ngIf="isLoadingMidTermData">
        <div class="full-width">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoadingMidTermData && midTermData">
        <mid-term-box-home fxFlex.sm="50" imgPath="../../../../assets/img/icons/homeIcons/mid-term-img.png"
          [isManagerBox]="true" title="{{'midTerm.TITLE' | translate}}" [midTermData]="midTermData"
          (onButtonClicked)="goToMidTermList()">
        </mid-term-box-home>
      </ng-container>
    </div>
  </div>
  <!-- TODO solo finchè testiamo -->
  <!--div class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <europ-button label="Reset Ack" (onButtonClicked)="resetOnboardingAck()" type="secondary bordless"></europ-button>
    </div-->
</div>