<div class="page-content-wrapper">
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('midTerm.TITLE' | translate)"
    [adminPageSubtitle]="('midTerm.list.TITLE' | translate)" [adminBack]="true" [hasTabs]="false">
  </header-dossier>

  <div class="main-content-wrapper">
    <div class="admin-table-container">
      <div class="recap-wrapper">
        <p class="section-title" translate="midTerm.list.TITLE"></p>
        <div class="counter-wrapper">
          <!-- Completati -->
          <div class="counter">
            <ng-container *ngIf="!isLoadingMidTermData">
              <p class="value">{{ midTermData.completedMidTermReviewCount }}</p>
            </ng-container>
            <ng-container *ngIf="isLoadingMidTermData">
              <div class="loader spinner-small"></div>
            </ng-container>
            <p class="description" translate="midTerm.home.COMPLETED"></p>
          </div>
          <!-- Totali -->
          <div class="counter">
            <ng-container *ngIf="!isLoadingMidTermData">
              <p class="value">{{midTermData.totalMidTermReviewCount }}</p>
            </ng-container>
            <ng-container *ngIf="isLoadingMidTermData">
              <div class="loader spinner-small"></div>
            </ng-container>
            <p class="description" translate="midTerm.home.TOTAL"></p>
          </div>
          <!-- Da completare -->
          <div class="counter">
            <ng-container *ngIf="!isLoadingMidTermData">
              <p class="value">{{ midTermData.toCompleteMidTermReviewCount }}</p>
            </ng-container>
            <ng-container *ngIf="isLoadingMidTermData">
              <div class="loader spinner-small"></div>
            </ng-container>
            <p class="description" translate="midTerm.home.TO_COMPLETE"></p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isLoadingUsersData">
        <ng-container *ngIf="usersData && usersData.length">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('midTerm.list.COLL_NAME' | translate)}}"></th>
                  <th translate="{{ ('midTerm.list.OBJECTIVE_NUMBER' | translate)}}"></th>
                  <th translate="{{ ('midTerm.list.FEEDBACK' | translate)}}"></th>
                  <th translate="{{ ('midTerm.list.REVIEW_DATE' | translate)}}"></th>
                  <th translate="{{ ('midTerm.list.STATUS' | translate)}}"></th>
                  <th translate="{{ ('midTerm.list.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let userData of usersData">
                  <td>{{ userData?.user.surname + ' ' + userData?.user.forename }}</td>
                  <td> {{ userData.goalCount }} </td>
                  <td class="link" (click)="openFeedbackModal(userData.feedback, userData.user)">
                    {{ userData.feedback.totalFeedbackCount || '-'}}
                  </td>
                  <td>
                    {{ (userData?.userPhase?.midTermReviewManagerObservationDate ?
                    (userData.userPhase.midTermReviewManagerObservationDate | date: 'dd.MM.YYYY') :
                    '- -' )
                    }}
                  </td>
                  <td>
                    <!-- Da completare -->
                    <ng-container
                      *ngIf="!userData.userPhase.midTermRevisionManagerStatus || userData.userPhase.midTermRevisionManagerStatus == 'TO_COMPLETE'">
                      <item-list-team-status message="midTerm.list.TO_START" type="toStart" styleItem="border">
                      </item-list-team-status>
                    </ng-container>
                    <!-- Non revisionabile -->
                    <ng-container
                      *ngIf="userData.userPhase.midTermRevisionManagerStatus && userData.userPhase.midTermRevisionManagerStatus == 'NOT_REVISABLE'">
                      <item-list-team-status message="midTerm.statuses.NOT_REVISABLE" type="disabled" styleItem="vuoto">
                      </item-list-team-status>
                    </ng-container>
                    <!-- Revisione completata -->
                    <ng-container *ngIf="userData.userPhase.midTermRevisionManagerStatus && userData.userPhase.midTermRevisionManagerStatus != 'TO_COMPLETE' 
                                            && userData.userPhase.midTermRevisionManagerStatus != 'NOT_REVISABLE'">
                      <!--div class="status">
                                                <svg-icon class="status-icon"
                                                    [src]="MID_STATUS_SRC[userData.userPhase.midTermReviewUserGoalAdvancement]"
                                                    [applyClass]="true"></svg-icon>
                                                <p
                                                    translate="midTerm.statuses.{{ userData.userPhase.midTermReviewUserGoalAdvancement }}">
                                                </p>
                                            </div-->
                      <item-list-team-status message="midTerm.list.COMPLETED" type="completed" styleItem="border">
                      </item-list-team-status>
                    </ng-container>
                  </td>
                  <ng-container
                    *ngIf="userData.userPhase.midTermRevisionManagerStatus && userData.userPhase.midTermRevisionManagerStatus != 'NOT_REVISABLE'">
                    <ng-container *ngIf="userData.userPhase.midTermRevisionManagerStatus == 'TO_COMPLETE'">
                      <td class="link" (click)="goToMidTermReview(userData)">
                        <!-- completare -->
                        {{ ('midTerm.list.REVIEW' | translate) }}
                      </td>
                    </ng-container>
                    <!-- Azione stato completato -->
                    <ng-container *ngIf="userData.userPhase.midTermRevisionManagerStatus != 'TO_COMPLETE'">
                      <td class="link" (click)="openDetailsModal(userData)">
                        {{ ('midTerm.list.DETAILS' | translate) }}
                      </td>
                    </ng-container>
                  </ng-container>
                  <!-- Se non revisionabile -->
                  <ng-container
                    *ngIf="userData.userPhase.midTermRevisionManagerStatus && userData.userPhase.midTermRevisionManagerStatus == 'NOT_REVISABLE'">
                    <td>
                      <svg-icon (click)="openExcludedModal(userData.user)" src="/assets/img/icons/info.svg"
                        class="info-icon medium-size cursor-pointer primary-icon" [applyClass]="true">
                      </svg-icon>
                    </td>
                  </ng-container>
                </tr>

              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-container *ngIf="!usersData || !usersData.length">
          <div class="no-data-wrapper">
            <p translate="adminTable.NO_DATA_USER"></p>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoadingUsersData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<modal id="feedbackModal" (onClose)="closeFeedbackModal()" (onConfirm)="closeFeedbackModal()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <ng-container *ngIf="userFeedbackModal">
    <modal-text-content [modalId]="userFeedbackModal.modalId" [title]="userFeedbackModal.title"
      [subtitle]="userFeedbackModal.subtitle" [text]="userFeedbackModal.description">
    </modal-text-content>
  </ng-container>
</modal>

<modal id="midTermDetails" (onClose)="closeDetailsModal()" (onConfirm)="closeDetailsModal()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content [modalId]="'d1060'" [title]="'midTerm.addMidTermReview.CONFIRM_REVISION' | translate"
    [subtitle]="'midTerm.addMidTermReview.CONFIRM_REVISION_SUBTITLE' | translate"
    [text]="'midTerm.addMidTermReview.CONFIRM_REVISION_TEXT' | translate">
  </modal-text-content>
  <ng-container *ngIf="dataForModal">
    <div class="modal-recap-container"> 
      <mid-term-recap [user]="dataForModal.user" [date]="dataForModal.userDate"
        [observation]="dataForModal.userObservation" [status]="dataForModal.userStatus">
      </mid-term-recap>
      <mid-term-recap [user]="dataForModal.manager" [observation]="dataForModal.managerObservation">
      </mid-term-recap>
    </div>
  </ng-container>
</modal>

<modal id="excludedModal" (onClose)="closeExcludedModal()" (onConfirm)="closeExcludedModal()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <modal-text-content modalId="mt009" [title]="excludedModalTitle" [text]="excludedModalDescr">
  </modal-text-content>
</modal>