import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as AuthActions from '../ngrx/auth.actions';
import { SenecaResponse } from 'src/commonclasses';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSsoComponent {
  constructor(private store: Store<fromApp.AppState>,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public translate: TranslateService) {
    // Come prima cosa controllo se mi è arrivato il codice "code" che viene appeso durante il ritorno dal sistema remoto di autenticazione OAuth2
    const oauthCode = this.activatedRoute.snapshot.queryParamMap.get('code');
    if (oauthCode) {
      this.store.dispatch(AuthActions.DoLogin({ oauthCode: oauthCode }));
    }
    else {
      // Devo indirizzare l'utente alla pagina di SSO, quindi chiamo il servizio che genera l'url
      this.authService.getSSOUrl()
        .subscribe((data: SenecaResponse<string>) => {
          if (data.error) {
            // this.toastr.error('Si è verificato un errore: ' + data.error);
          } else {
            window.location.href = data.response;
          }
        });
    }
  }
}