<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="personDetails" (onBackFunction)="goToUpdateDevelopAction(true)" [isBackButton]="true"
      [title]="(developmentActionId && developmentActionId.length) ? ('developmentPlan.createUpdateDevelopAction.SIDENAV_UPDATE_TITLE' | translate) : (('developmentPlan.createUpdateDevelopAction.SIDENAV_TITLE' | translate) + personDetails.forename + ' ' + personDetails.surname)"
      description="{{('developmentPlan.createUpdateDevelopAction.SIDENAV_DESCRIPTION' | translate)}}">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingCreateDevelopmentAction && !isFetchingUpdateDevelopmentAction">
        <div class="form-container">
          <input-container
            [isLoading]="isFetchingGetDevelopAction || isFetchingCreateDevelopmentAction || isFetchingUpdateDevelopmentAction"
            [tooltipModalMessage]="titleTooltipModal"
            [isValued]="developAction.title && developAction.title.trim().length"
            title="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_TITLE' | translate}}"
            [isObbligatory]="true">
            <europ-input [bindValue]="developAction.title" (onModelChanged)="developActionTitleChanged($event)"
              placeholder="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_TITLE_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <input-container
            [isLoading]="isFetchingGetDevelopAction || isFetchingCreateDevelopmentAction || isFetchingUpdateDevelopmentAction"
            [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="developAction.description && developAction.description.trim().length" [isObbligatory]="true"
            title="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_DESCRIPTION' | translate}}">
            <europ-text-area [bindValue]="developAction.description"
              (onModelChanged)="developActionDescriptionChanged($event)"
              placeholder="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_DESCRIPTION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <input-container [isObbligatory]="true"
            [isLoading]="isFetchingGetDevelopAction || isFetchingCreateDevelopmentAction || isFetchingUpdateDevelopmentAction"
            [tooltipModalMessage]="deadlineTooltipModal" [isValued]="developAction.startDate"
            title="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_DEADLINE_DATE' | translate}}">
            <europ-datepicker [minDate]="today" [value]="developAction.startDate"
              (dateChanged)="developActionStartDateChanged($event)"
              placeholder="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_DEADLINE_DATE_PLACEHOLDER' | translate}}">
            </europ-datepicker>
          </input-container>
          <input-container
            [isLoading]="isFetchingGetDevelopAction || isFetchingCreateDevelopmentAction || isFetchingUpdateDevelopmentAction"
            [tooltipModalMessage]="categoryTooltipModal"
            [isValued]="developAction.category && developAction.category.trim().length"
            title="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_CATEGORY' | translate}}">
            <europ-input [bindValue]="developAction.category || ''"
              (onModelChanged)="developActionCategoryChanged($event)"
              placeholder="{{'developmentPlan.createUpdateDevelopAction.DEVELOPMENT_ACTION_CATEGORY_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <div class="button-container">
            <europ-button (onButtonClicked)="goToUpdateDevelopAction()"
              [disabled]="isFetchingGetDevelopAction || isFetchingCreateDevelopmentAction || isFetchingUpdateDevelopmentAction"
              type="primary default" size="large"
              [label]="(developmentActionId && developmentActionId.length) ? ('developmentPlan.createUpdateDevelopAction.UPDATE_DEVELOP_ACTION' | translate) : ('developmentPlan.createUpdateDevelopAction.CREATE_DEVELOP_ACTION' | translate)">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="isFetchingGetDevelopAction || isFetchingCreateDevelopmentAction || isFetchingUpdateDevelopmentAction">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un'azione di sviluppo -->
<modal id="modalCreateUpdateDevelopActionForManager" (onCancel)="goBackBrowser()"
  (onClose)="closeModalCreateUpdateDevelopAction()"
  (onConfirm)="(developmentActionId && developmentActionId.length) ? updateDevelopAction() : createDevelopAction()"
  cancelLabel="{{modalCreateUpdateDevelopActionTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdateDevelopActionTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdateDevelopActionId" [title]="modalCreateUpdateDevelopActionTitle"
    [subtitle]="''" [text]="modalCreateUpdateDevelopActionText">
  </modal-text-content>
</modal>