import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ACCEPTED_MIME_TYPES, FileType, GetFileExtensionString } from 'src/app/utils';

@Component({
  selector: 'europ-upload',
  templateUrl: 'europ-upload.component.html',
  styleUrls: ['./europ-upload.component.scss']
})
export class EuropUploadComponent implements OnInit {

  @Input() acceptedMimeTypes = "";
  doc: {
    name?: string;
    file?: any;
    fileType?: FileType;
    type?: string;
    isUpload?: boolean;
  } = {};
  blob?: Blob;
  mbFile: string = '';
  imageErrors: boolean = false;
  @Input() isToReset: boolean = false;
  @Input() isProfileUpload: boolean = false;
  @Output() onUpload: EventEmitter<Blob> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (!this.acceptedMimeTypes) {
      if (this.isProfileUpload) {
        this.acceptedMimeTypes = ".jpg, .jpeg, .png, .tiff";
      } else {
        this.acceptedMimeTypes = ACCEPTED_MIME_TYPES;
      }
    }
  }

  // Funzioni riguardo l'upload del file

  // Gestione del file
  async handleFileInput(file: any) {
    this.imageErrors = false;
    if (file.files && file.files.length) {
      let files = file.files;
      //this.resetFileData();
      this.isToReset = false;
      this.doc.isUpload = true;

      /* if (files[0].type.indexOf("image") > -1) {
        this.doc.fileType = FileType.IMAGE;
        this.imgParsed = await this.toBase64(files[0]);
      } else if (files[0].type.indexOf("pdf") > -1) {
        this.doc.fileType = FileType.PDF;
        try {
          this.pdfFile = await this._loadFile(files[0]);
        } catch (error) {
          console.error(error);
        }
      } else {
        this.doc.fileType = FileType.DOC;
      } */
      this.blob = files[0];
      if (
        this.doc.fileType !== FileType.PDF &&
        this.doc.fileType !== FileType.IMAGE
      ) {
        this.doc.file = files[0];
      }

      this.doc.name = files[0].name;

      this.doc.file = this.blob;
      this.doc.file.sizeRounded = Math.round(this.doc.file.size / 1024);
      if (this.isProfileUpload) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = (rs: any) => {
            const img_height = rs && rs.currentTarget['height'];
            const img_width = rs && rs.currentTarget['width'];

            if (img_height < 140 || img_width < 140) {
              this.imageErrors = true;
            }
          };
        };
        if (this.blob) {
          reader.readAsDataURL(this.blob);
        }
      }

      if (this.isProfileUpload && this.doc.file.sizeRounded > 2048) {
        this.imageErrors = true;
      }
      this.GetFileExt();
    }
  }

  // Permetti il Drag and Drop
  allowDrag(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  // Gestione del file drag and drop
  async handleFileInputOnDrug(file: any) {
    this.isToReset = false;
    (event && event.stopPropagation());
    (event && event.preventDefault());

    if (file.dataTransfer.files && file.dataTransfer.files.length) {
      let dt = file.dataTransfer
      this.handleFileInput(dt);
    }
  }

  // Trasforma codice in Base64
  /* toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }); */

  // Pulisce i dati
  resetFileData() {
    /* this.pdfFile = null;
    this.imgParsed = null; */
    this.doc = {};
    this.blob = undefined;
  }

  // Carica il file
  /* private _loadFile(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  } */

  // Recupera l'estensione del file
  GetFileExt() {
    const type = this.doc.file
      ? this.doc.file.type
      : (this.doc.fileType && this.doc.fileType.toString());
    if (type && type.length) {
      this.doc.file.fileExtension = GetFileExtensionString(type);
    } else {
      let extension = this.doc.file.name.slice(this.doc.file.name.lastIndexOf('.'), this.doc.file.name.length);
      extension = extension.replace('.', '');
      if (extension == 'pptx') {
        this.doc.file.fileExtension = 'ppt';
      } else if (extension == 'jpg') {
        this.doc.file.fileExtension = 'jpeg';
      } else if (extension == 'xlsx') {
        this.doc.file.fileExtension = 'xls';
      } else if (extension == 'docx') {
        this.doc.file.fileExtension = 'doc';
      } else if (extension == 'eml') {
        this.doc.file.fileExtension = 'eml';
      } else if (extension == 'pdf') {
        this.doc.file.fileExtension = 'pdf';
      } else if (extension == 'msg') {
        this.doc.file.fileExtension = 'msg';
      } else if (extension == 'txt') {
        this.doc.file.fileExtension = 'txt';
      } else if (extension == 'rtf') {
        this.doc.file.fileExtension = 'rtf';
      } else if (extension == 'tiff') {
        this.doc.file.fileExtension = 'image';
      }
    }

    this.onUpload.emit(this.blob);
  }

  // Premi sul sfoglia files
  uploadDoc() {
    const element: any = document.querySelector("#uploadFile");
    element.value = "";
    element.click();
  }
}