import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from 'src/app/shared/services/url.service';
import { JwtPayload } from '../../../commonclasses';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-page',
  template: ''
})
export class DefaultPageComponent implements OnDestroy {
  result$: any;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private urlService: UrlService) {
    const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager)
    const isCollaborator$: Observable<boolean> = this.store.select(fromApp.getIsCollaborator);
    const isOperation$: Observable<boolean> = this.store.select(fromApp.getIsOperation);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const isSti$: Observable<boolean> = this.store.select(fromApp.getIsSti);

    const combinedSelectes$ = combineLatest([loggedUser$, isManager$, isCollaborator$, isAdmin$, isOperation$, isSti$]);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, isManager, isCollaborator, isAdmin, isOperation, isSti]) => {
        if (loggedUser && loggedUser.user) {
          // Eseguo il redirect in base alle auth dell'utente
          const redirectPage = this.urlService.getDefaultRedirectPage(isManager, isCollaborator, isAdmin, isOperation, isSti);
          this.router.navigate([redirectPage]);
        }
      });
  }

  ngOnDestroy() {
    this.result$.unsubscribe();
  }
}