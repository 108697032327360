export const CKEditorConfig = {
  allowedContent: true,
  autoParagraph: false,
  extraPlugins: 'richcombo,placeholder_select',
  height: 317,
  enterMode: 2,
  toolbar: [
    {
      name: 'document',
      items: [
        'NumberedList', 'BulletedList', 'Bold', 'Italic', 'Underline',
        'Undo', 'Redo'
      ]
    }
  ]
};