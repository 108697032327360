import { CanDeactivate, ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as ManagerActions from '../../manager/ngrx/manager.actions';

@Injectable()
export class PersonDetailsGuard implements CanDeactivate<any> {
  constructor(private store: Store<fromApp.AppState>) {
  }

  // aggiunto per togliere l'utente selezionato all'uscita dai dettagli dello stesso
  canDeactivate() {
    this.store.dispatch(ManagerActions.RemoveActiveUserId());
    return true;
  }
}