import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'no-data',
  templateUrl: 'no-data-placeholder.component.html',
  styleUrls: ['./no-data-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataPlaceholderComponent implements OnInit {
  @Input() src: string = '/assets/img/section/goal-setting.svg';
  @Input() title: string = "Goal Setting";
  @Input() inNotifyPage: boolean = false;
  @Input() fullSizeImg: boolean = false;
  @Input() description: string = "Aggiungi un obiettivo e avvia la fase di Goal setting di <b>Tramaglino Lorenzo<b>";
  @Input() performanceLevelValue: string = '';
  @Input() performanceLevelObservations: string = '';
  @Input() payout: any;
  @Output() onManagerObservationClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {

  }

  emitOnManagerObservation(data: any) {
    this.onManagerObservationClicked.emit(data);
  }

}
