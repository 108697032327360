/*
 * Componente per l'onboarding video del manager
*/

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'onboarding-video',
  templateUrl: './onboarding-video.component.html',
  styleUrls: ['./onboarding-video.component.scss']
})
export class OnboardingVideoComponent {
  // &flashvars[autoPMute]=false&flashvars[autoPlay]=true
  kalturaVideoOnboardingScript: string = '<script src="https://cdnapisec.kaltura.com/p/1936311/sp/193631100/embedIframeJs/uiconf_id/42700011/partner_id/1936311?autoembed=true&entry_id=1_wmmq4is7&playerId=kalturaVideoOnboardingId&cache_st=1545652499&width=733&height=322"></script>';
  @Output() onItemConsumed: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
  }

  emitOnItemConsumed() {
    this.onItemConsumed.emit();
  }

}
