import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { TabDossier } from 'src/app/utils/classes.utils';

@Component({
    selector: 'admin-process-setup-objectives',
    templateUrl: './processSetupObjectives.component.html',
    styleUrls: ['./processSetupObjectives.component.scss']
})
export class ProcessSetupObjectivesComponent implements OnDestroy {
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;
    currentYear = new Date().getFullYear();

    processYear: string = '';
    tabList: any[] = [];
    currentTab: string = 'participant';
    structureList: any[] = [];
    isLoadingStructureList: boolean = false;

    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.processYear = params.processYear;
                                    this.getStructureList();
                                })
                    }
                });
    }

    ngOnInit() {
        this.translate.get([
            'headerDossier.processSetup.PARTICIPANT',
            'headerDossier.processSetup.OBJECTIVE_DEFINITION',
            'headerDossier.processSetup.PROCESS_PHASES'
        ]).subscribe((translations) => {
            this.tabList = [
                {
                    id: 'participant',
                    name: translations['headerDossier.processSetup.PARTICIPANT']
                },
                {
                    id: 'definition',
                    name: translations['headerDossier.processSetup.OBJECTIVE_DEFINITION']
                },
                {
                    id: 'phases',
                    name: translations['headerDossier.processSetup.PROCESS_PHASES']
                }
            ]
        })
    }

    getStructureList() {
        this.isLoadingStructureList = true;

        let testContent = [
            {
                attrAriaLabel: "Obiettivi company  20%",
                goalTypes: [
                    {
                        accordionTitle: "goal.accordionTitles.COMPANY20% - Massimo 2",
                        maxNumberOfGoals: 2,
                        message: "La percentuale del  20% deve essere raggiunta con al massimo  2 obiettivi di ruolo.",
                        type: "COMPANY",
                        weight: 20
                    }
                ],
                id: "COMPANY_TAB",
                isDisabled: true,
                noSti: false,
                title: "Obiettivi company  20%",
            },
            {
                attrAriaLabel: "Obiettivi di struttura  10%",
                goalTypes: [{
                    accordionTitle: "goal.accordionTitles.STRUCTURE10% - Massimo 1",
                    maxNumberOfGoals: 1,
                    message: "La percentuale del  10% deve essere raggiunta con al massimo  1 obiettivi di ruolo.",
                    type: "STRUCTURE",
                    weight: 10
                }],
                id: "STRUCTURE_TAB",
                isDisabled: true,
                noSti: undefined,
                title: "Obiettivi di struttura  10%"
            }]



        setTimeout(() => {
            this.structureList = [
                {
                    name: "Manager + professional",
                    tootlip: {
                        modalId: 'asd0',
                        title: "Manager + professional".toUpperCase(),
                        text: "Definisci gli obiettivi da assegnare alla sezione manager e progessional"
                    },
                    statusData: {
                        status: "completed",
                        statusMessage: this.translate.instant('process.objectives.STATUS_COMPLETED')
                    },
                    contentTabList: JSON.parse(JSON.stringify(testContent)),
                    isLoadingGoal: false
                },
                {
                    name: "CO techinical MI",
                    tootlip: {
                        modalId: 'asd0',
                        title: "CO techinical MI".toUpperCase(),
                        text: "Definisci gli obiettivi da assegnare alla sezione co technical mi"
                    },
                    statusData: {
                        status: "toStart",
                        statusMessage: this.translate.instant('process.objectives.STATUS_TO_DECLINE')
                    },
                    contentTabList: JSON.parse(JSON.stringify(testContent)),
                    isLoadingGoal: false
                },
                {
                    name: "Co techincal RENDE",
                    tootlip: {
                        modalId: 'asd0',
                        title: "Co techincal RENDE".toUpperCase(),
                        text: "Definisci gli obiettivi da assegnare alla sezione RENDE"
                    },
                    statusData: {
                        status: "completed",
                        statusMessage: this.translate.instant('process.objectives.STATUS_COMPLETED')
                    },
                    contentTabList: JSON.parse(JSON.stringify(testContent)),
                    isLoadingGoal: false
                }
            ]
            this.isLoadingStructureList = false;
        })
    }

    onContentTabChanged(tab: TabDossier, structure: any) {
        structure.isLoadingGoal = true;

        setTimeout(() => { structure.isLoadingGoal = false }, 1500);
    }

    addObjective() {
        this.router.navigate(['/admin/processSetup/defineObjectives']);
    }

    saveObjective() {
        console.log("saveObjective");
    }

    shareObjectives() {
        console.log("shareObjectives");
    }

    editProcess() {
        this.router.navigate(['admin/createUpdateProcess/' + this.processYear]);
    }

    orderBy(fieldName: string) {
        // console.log("fieldName", fieldName);
    }

    ngOnDestroy() {
    }
}