import { RentStatusTypes, GetSubordinatesWithPhaseStatusesForManagerResponse } from 'src/commonclasses';

export class CollaboratorUtils {
  // Ritorna lo stato dell'utente
  static getUserStatus(user: GetSubordinatesWithPhaseStatusesForManagerResponse | null) {
    if (user && user.activeUserPhase) {
      switch (user.activeUserPhase.userStatusType) {
        case RentStatusTypes.TO_START:
          return 'toStart';
        case RentStatusTypes.STARTED:
          return 'inProgress';
        case RentStatusTypes.COMPLETED:
          return 'completed';
        default:
          return 'disabled';
      }
    }
    return '';
  }
}