<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      [title]="userId ? (('feedbackSection.sendClapPage.SIDENAV_TITLE_WITH_USER' | translate) + (personDetails && personDetails.forename) + ' ' + (personDetails && personDetails.surname)) : ('feedbackSection.sendClapPage.SIDENAV_TITLE_WITHOUT_USER' | translate)"
      [description]="userId ? ('feedbackSection.sendClapPage.SIDENAV_DESCRIPTION_WITH_USER' | translate) : ('feedbackSection.sendClapPage.SIDENAV_DESCRIPTION_WITHOUT_USER' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingGetClap">
        <div class="form-container">
          <ng-container *ngIf="!userId">
            <!-- user card -->
            <input-container [tooltipModalMessage]="whoTooltipModal" [isObbligatory]="true"
              [isValued]="selectedUserList && selectedUserList.length"
              [title]="'feedbackSection.sendClapPage.WHO_TO_CLAP' | translate">
              <!-- ricerca -->
              <europ-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                placeholder="{{'feedbackSection.SEARCH_PLACEHOLDER' | translate}}"></europ-input>

              <p>
                <span class="text-label-form primary-color"
                  translate="feedbackSection.sendClapPage.CHOOSE_PERSON"></span>
                <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length == 1"
                  class="text-label-form error-color">{{ ' ' + selectedUserList.length + ' ' +
                  ('generic.SELECTED' |
                  translate)}}</span>
                <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length > 1"
                  class="text-label-form error-color">{{ ' ' + selectedUserList.length + ' ' +
                  ('generic.SELECTEDS' |
                  translate)}}</span>
              </p>
              <div class="user-card-collection-container">
                <div *ngIf="!isFetchingSendClapUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                  fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                  fxLayoutGap.lt-md="16px">
                  <user-card *ngFor="let user of userCardData | slice:0:sendClapUsersNumRecords | paginate: { id: 'sendClapUsers',
                  itemsPerPage: sendClapUsersNumRecords,
                  currentPage: sendClapUsersCurrentPage,
                  totalItems: sendClapUsersCounter }" [user]="user" [selectedUserList]="selectedUserList"
                    [isFeedbackCard]="true" (onCheck)="selectUser($event)"></user-card>
                </div>
                <div *ngIf="isFetchingSendClapUsers" class="full-width min-height500" fxLayout="row wrap"
                  fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                  fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                  <div class="loader spinner-medium"></div>
                </div>
                <div class="full-width" *ngIf="!isFetchingSendClapUsers && userCardData && userCardData.length"
                  fxLayout="row" fxLayoutAlign="center center">
                  <table-pagination id="sendClapUsers" (pageChanged)="clapUsersPageChanged($event)">
                  </table-pagination>
                </div>
              </div>
            </input-container>
          </ng-container>
          <input-container [isLoading]="isFetchingGetClap || isFetchingUpdateClap || isFetchingCreateClap"
            [tooltipModalMessage]="descriptionTooltipModal" [isValued]="clap.comment && clap.comment.trim().length"
            [isObbligatory]="true" title="{{'feedbackSection.sendClapPage.WHY_SEND_CLAP' | translate}}">
            <europ-text-area [bindValue]="clap.comment" (onModelChanged)="clapDescriptionChanged($event)"
              placeholder="{{'feedbackSection.sendClapPage.WHY_SEND_CLAP_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
            <p class="text-label-form primary-color" translate="feedbackSection.sendClapPage.CARE_ASPECT_TEXT">
            </p>
            <p *ngIf="!cardSelected" class="text-label-form primary-color">*</p>
            <svg-icon (click)="openCareCardTooltip()" src="/assets/img/icons/info.svg"
              class="info-icon medium-size blue-primary cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>
          <care-cards [items]="careCards" (onCheck)="onSelectCareCard($event)"></care-cards>
          <div class="button-container">
            <europ-button (onButtonClicked)="clapId ? updateClap() : sendClap()"
              [disabled]="isFetchingGetClap || isFetchingUpdateClap || !checkObbligatoryData()" type="primary default"
              size="large"
              [label]="clapId ? ('feedbackSection.sendClapPage.UPDATE_CLAP' | translate) : ('feedbackSection.sendClapPage.SEND_CLAP' | translate)">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isFetchingUpdateClap">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="modalUpdateForUser" (onCancel)="closeModalUpdate(true)" (onClose)="closeModalUpdate()"
  (onConfirm)="(clapId && clapId.length) ? updateClap(true) : sendClap(true)"
  cancelLabel="{{modalUpdateTextCloseTextButton}}" confirmLabel="{{modalUpdateTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalUpdateId" [title]="modalUpdateTitle" [subtitle]="''" [text]="modalUpdateText">
  </modal-text-content>
</modal>

<!-- Modale per card C.A.R.E -->
<modal id="careTooltip" (onClose)="closeCareCardTooltip()" (onConfirm)="closeCareCardTooltip()">
  <modal-text-content [modalId]="careTooltipModal.modalId" [title]="careTooltipModal.title"
    [subtitle]="careTooltipModal.subtitle" [text]="careTooltipModal.text">
  </modal-text-content>
</modal>