/*
 * Componente per l'onboarding del manager
*/

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";
import * as AuthActions from "../../../auth/ngrx/auth.actions";
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from '../modal/modal.service';
import { RedirectService } from '../../services/redirect.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Subordinateservice } from 'src/app/manager/services/subordinates.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { EnrichedSenecaResponseForBadges, SenecaResponse } from 'src/commonclasses';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  userAcknowledges: any;
  updateUserAck$: Subscription = new Subscription();
  isUpdatingAcks: boolean = false;
  showBadge: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private authService: AuthService,
    public translate: TranslateService,
    public modalService: ModalService,
    public redirectService: RedirectService,
    private subordinateService: Subordinateservice
  ) {
    const isCollaborator$: Observable<boolean> = this.store.select(fromApp.getIsCollaborator);
    const userAck$: Observable<any> = this.store.select(fromApp.getUserAcknowledges);
    const combinedSelectes$ = combineLatest([isCollaborator$, userAck$])
      .subscribe(
        ([isCollaborator, userAck]) => {
          this.showBadge = isCollaborator;
          this.userAcknowledges = userAck;
        })
    // this.store.select(fromApp.getUserAcknowledges)
    //   .subscribe((userAck: any) => {
    //     this.userAcknowledges = userAck;
    //   })
  }

  setUserAck() {
    this.isUpdatingAcks = true;
    if (this.updateUserAck$) {
      this.updateUserAck$.unsubscribe();
    }
    let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
    acks.data['PERFORMANCE_CARE_ONBOARDING'] = moment().toISOString();

    if (this.showBadge) {
      this.updateUserAck$ = this.authService.updateUserAcknowledges(acks)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "133",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.subordinateService.processFirstAccessBadge()
              .subscribe((badgeData: any) => {
                if (badgeData && badgeData.error) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "140",
                    text: this.translate.instant("errors." + badgeData.error),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else {
                  // controllo se sono stati rilasciati badge
                  let badges = badgeData.obtainedBadges;
                  let tmpBadgesList: BadgeObject[] = [];
                  if (badges && badges.length) {
                    tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
                    this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
                  }
                  this.store.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
                  this.isUpdatingAcks = false;
                  this.redirectService.goToHome();
                }
              })
          }
        })
    } else {
      this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
        subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "133",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.store.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
            this.isUpdatingAcks = false;
            this.redirectService.goToHome();
          }
        })
    }
  }

  // Chiude la modale contenente un errore
  closeModalMessage() {
    this.store.dispatch(CoreActions.DeleteApplicationModalMessage())
    this.modalService.close('modalMessage');
  }
}
