import { createReducer, on, Action } from '@ngrx/store';
import { GetSubordinatesWithPhaseStatusesForManagerResponse } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import * as MangerActions from './manager.actions';

export interface FeatureState extends fromApp.AppState {
  manager: ManagerState
}

export interface ManagerState {
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[],
  runningPhaseId: string,
  activeUserId: string,
  buttonTeamClicked: boolean
}

// Store iniziale
const initialState: ManagerState = {
  subordinatesWithPhaseStatuses: [],
  runningPhaseId: '',
  activeUserId: '',
  buttonTeamClicked: false
};

export const createManagerReducer = createReducer(
  initialState,
  on(MangerActions.SetSuboardinatesWithPhaseStatuses, (state, action) => {
    return {
      ...state,
      subordinatesWithPhaseStatuses: action.payload
    };
  }),
  on(MangerActions.SetHeaderTeamClickedButton, (state, action) => {
    return {
      ...state,
      buttonTeamClicked: action.payload
    };
  }),
  on(MangerActions.UpdateCollaboratorUserStatusType, (state, action) => {
    let userToUpdate: any = null;
    let index = 0;
    if (state.subordinatesWithPhaseStatuses && state.subordinatesWithPhaseStatuses.length) {
      for (let i = 0; i < state.subordinatesWithPhaseStatuses.length; i++) {
        if (state.subordinatesWithPhaseStatuses[i].userId === action.payload.userId) {
          userToUpdate = state.subordinatesWithPhaseStatuses[i];
          index = i;
          break;
        }
      }
    }
    const updatedUserActiveUserPhase = {
      ...userToUpdate.activeUserPhase,
      userStatusType: action.payload.userStatusType
    }

    let updatedSubordinatesWithPhaseStatuses = JSON.parse(JSON.stringify(state.subordinatesWithPhaseStatuses));
    updatedSubordinatesWithPhaseStatuses[index].activeUserPhase = updatedUserActiveUserPhase;

    return {
      ...state,
      subordinatesWithPhaseStatuses: updatedSubordinatesWithPhaseStatuses
    };
  }),
  on(MangerActions.SetUpdatedCollaboratorData, (state, action) => {
    let index = 0;
    if (state.subordinatesWithPhaseStatuses && state.subordinatesWithPhaseStatuses.length) {
      for (let i = 0; i < state.subordinatesWithPhaseStatuses.length; i++) {
        if (state.subordinatesWithPhaseStatuses[i].userId === action.payload[0].userId) {
          index = i;
          break;
        }
      }
    }
    let updatedSubordinatesWithPhaseStatuses = JSON.parse(JSON.stringify(state.subordinatesWithPhaseStatuses));
    updatedSubordinatesWithPhaseStatuses[index] = action.payload[0];

    return {
      ...state,
      subordinatesWithPhaseStatuses: updatedSubordinatesWithPhaseStatuses
    };
  }),
  on(MangerActions.SetActiveUserId, (state, action) => {
    return {
      ...state,
      activeUserId: action.payload
    };
  }),
  on(MangerActions.RemoveActiveUserId, (state, action) => {
    return {
      ...state,
      activeUserId: ''
    };
  })
)

export function managerReducer(state: ManagerState | undefined, action: Action) {
  return createManagerReducer(state, action);
}

export const getActiveUserId = (state: ManagerState) => state.activeUserId;
export const getIsButtonTeamClicked = (state: ManagerState) => state.buttonTeamClicked;