import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'europ-switch',
  templateUrl: 'europ-switch.component.html',
  styleUrls: ['./europ-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropSwitchComponent implements OnInit {
  @Input() leftLabelObject: any;
  @Input() rightLabelObject: any;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Output() onSwitch: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  emitOnSwitch(data?: string) {
    this.onSwitch.emit(data || null);
  }
}