import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');

@Component({
    selector: 'admin-process-setup-participant',
    templateUrl: './processSetupParticipant.component.html',
    styleUrls: ['./processSetupParticipant.component.scss']
})
export class ProcessSetupParticipantComponent implements OnDestroy {
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;
    currentYear = new Date().getFullYear();

    isLoadingProcessData: boolean = false;
    getProcessesData$: Subscription = new Subscription;
    processData: {
        fromRecord: number,
        numRecords: number,
        page: number,
        counter: number,
        list: any[]

    } = {
            fromRecord: 0,
            numRecords: 5,
            page: 1,
            counter: 0,
            list: []
        }

    rowPerPageOptions = [
        {
            id: 5,
            title: 5
        },
        {
            id: 10,
            title: 10
        },
        {
            id: 15,
            title: 15
        },
        {
            id: 20,
            title: 20
        }
    ]
    selectedRows: { id: number, title: number };

    menuOptions: any[] = [];
    processToDelete: any;

    processYear: string = '';
    tabList: any[] = [];
    searchedText: string = '';
    stiModalUser: any;

    stiEnableTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };

    stiValueTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };

    stiDateTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };
    today = moment().format();

    confirmSTIModal: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };
    // modali inclusione / esclusione 
    resetIncludeUpload: boolean = false;
    resetExcludeUpload: boolean = false;
    includeFileObject: any;
    excludeFileObject: any;
    isDownloadingExcludeTrack: boolean = false;
    isDownloadingIncludeTrack: boolean = false;
    uploadIncludeTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };
    uploadExcludeTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };

    stiFilters: any[] = [];
    statusFilters: any[] = [];
    definitionFilters: any[] = [];

    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        this.selectedRows = this.rowPerPageOptions[0];
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.processYear = params.processYear;
                                    this.getProcessData();
                                })
                    }
                });
    }

    ngOnInit() {
        this.translate.get([
            'process.details.PERSON_DETAILS',
            'process.details.MANAGE_STI',
            'process.details.EXCLUDE_FROM_PROCESS',
            'process.sti.ENABLE_TITLE',
            'process.sti.ENABLE_INFO',
            'process.sti.GIVE_VALUE',
            'process.sti.GIVE_VALUE_INFO',
            'process.sti.SIGNED_DATE_TITLE',
            'process.sti.SIGNED_DATE_INFO',
            'process.sti.CONFIRM_ENABLE',
            'process.sti.CONFIRM_ENABLE_SUB',
            'process.sti.CONFIRM_ENABLE_TEXT',
            'process.manage.INCLUDE_TITLE',
            'process.manage.INCLUDE_INFO',
            'process.manage.EXCLUDE_TITLE',
            'process.manage.EXCLUDE_INFO',
            'headerDossier.processSetup.PARTICIPANT',
            'headerDossier.processSetup.OBJECTIVE_DEFINITION',
            'headerDossier.processSetup.PROCESS_PHASES',
            'process.filters.STI_ON',
            'process.filters.STI_NO',
            'process.filters.STI_OFF',
            'process.filters.EXCLUDED',
            'process.filters.ACTIVE',
        ]).subscribe((translations) => {
            this.stiEnableTooltip = {
                modalId: 'ps010',
                title: translations['process.sti.ENABLE_TITLE'],
                text: translations['process.sti.ENABLE_INFO']
            }
            this.stiValueTooltip = {
                modalId: 'ps011',
                title: translations['process.sti.GIVE_VALUE'],
                text: translations['process.sti.GIVE_VALUE_INFO']
            }
            this.stiDateTooltip = {
                modalId: 'ps012',
                title: translations['process.sti.SIGNED_DATE_TITLE'],
                text: translations['process.sti.SIGNED_DATE_INFO']
            }

            this.confirmSTIModal = {
                modalId: 'ps013',
                title: translations['process.sti.CONFIRM_ENABLE'],
                subtitle: translations['process.sti.CONFIRM_ENABLE_SUB'],
                text: translations['process.sti.CONFIRM_ENABLE_TEXT']
            }

            this.uploadIncludeTooltip = {
                modalId: 'ps014',
                title: translations['process.manage.INCLUDE_TITLE'],
                text: translations['process.manage.INCLUDE_INFO'],
            };
            this.uploadExcludeTooltip = {
                modalId: 'ps015',
                title: translations['process.manage.EXCLUDE_TITLE'],
                text: translations['process.manage.EXCLUDE_INFO'],
            };

            this.menuOptions = [
                {
                    id: 'details',
                    title: translations['process.details.PERSON_DETAILS'],
                    icon: '/assets/img/icons/clipboard.svg',
                },
                {
                    id: 'manageSTI',
                    title: translations['process.details.MANAGE_STI'],
                    icon: '/assets/img/icons/pie-chart.svg',
                },
                {
                    id: 'exclude',
                    title: translations['process.details.EXCLUDE_FROM_PROCESS'],
                    icon: '/assets/img/icons/trash-2.svg',
                }
            ]

            this.tabList = [
                {
                    id: 'participant',
                    name: translations['headerDossier.processSetup.PARTICIPANT']
                },
                {
                    id: 'definition',
                    name: translations['headerDossier.processSetup.OBJECTIVE_DEFINITION']
                },
                {
                    id: 'phases',
                    name: translations['headerDossier.processSetup.PROCESS_PHASES']
                }
            ]

            this.stiFilters = [
                {
                    id: 'on',
                    title: translations['process.filters.STI_ON'],
                    isChecked: false
                },
                {
                    id: 'off',
                    title: translations['process.filters.STI_OFF'],
                    isChecked: false
                },
                {
                    id: 'no',
                    title: translations['process.filters.STI_NO'],
                    isChecked: false
                },
            ]

            this.statusFilters = [
                {
                    id: 'active',
                    title: translations['process.filters.ACTIVE'],
                    isChecked: false
                },
                {
                    id: 'excluded',
                    title: translations['process.filters.EXCLUDED'],
                    isChecked: false
                }
            ]
            // PLACEHOLDER 
            this.definitionFilters = [
                {
                    id: 'SERVIZIO CLIENTI',
                    title: 'SERVIZIO CLIENTI',
                    isChecked: false
                },
                {
                    id: 'SERVIZIO CONT.CLIENTI',
                    title: 'SERVIZIO CONT.CLIENTI',
                    isChecked: false
                },
                {
                    id: 'Risorse umane',
                    title: 'Risorse umane',
                    isChecked: false
                },
                {
                    id: 'Ammin.prodotti',
                    title: 'Ammin.prodotti',
                    isChecked: false
                },
                {
                    id: 'Gestione credito',
                    title: 'Gestione credito',
                    isChecked: false
                },
                {
                    id: 'SERVIZIO CLIENTI',
                    title: 'SERVIZIO CLIENTI',
                    isChecked: false
                },
                {
                    id: 'SERVIZIO CONT.CLIENTI',
                    title: 'SERVIZIO CONT.CLIENTI',
                    isChecked: false
                },
                {
                    id: 'Risorse umane',
                    title: 'Risorse umane',
                    isChecked: false
                },
                {
                    id: 'Ammin.prodotti',
                    title: 'Ammin.prodotti',
                    isChecked: false
                },
                {
                    id: 'Gestione credito',
                    title: 'Gestione credito',
                    isChecked: false
                }
            ]
        })
    }

    // ricerca
    searchedTextChange(text: string) {
        this.searchedText = text;
    }

    onSearch() {
        console.log("SEARCH!");
    }

    // Recupera la lista utenti e altre info sul processo (?)
    getProcessData() {
        this.isLoadingProcessData = true;
        setTimeout(() => {
            this.processData.list.push({
                forename: "Pinco",
                surname: "Panco",
                cid: 12345,
                hasSTI: false,
                stiValue: null,
                stiDueDate: null,
                denominazioneSO3: "SERVIZIO CONT.CLIENTi",
                status: "EXCLUDED"
            },
                {
                    forename: "Panco",
                    surname: "Pinco",
                    cid: 54321,
                    hasSTI: true,
                    stiValue: 7800,
                    stiDueDate: new Date().toISOString(),
                    denominazioneSO3: "SERVIZIO CONT.CLIENTi",
                    status: "INCLUDED"
                }
            )
            this.isLoadingProcessData = false;
        }, 1000)
    }

    processPageChanged(page: number) {
        this.processData.page = page;
        this.processData.list = [];
        this.getProcessData();
    }

    changeNumRecords(item: any) {
        this.selectedRows = item;
        this.processData.numRecords = item.id;
        this.processData.list = [];
        this.processData.fromRecord = 0;
        this.getProcessData();
    }

    editProcess() {
        this.router.navigate(['admin/createUpdateProcess/' + this.processYear]);
    }

    // menu utente
    // dalla tabella apre il menu con le azioni sull'utente
    openCloseMenu(user: any) {
        user.isMenuOpen = !user.isMenuOpen;
    }

    // gestisce le opzioni del menu utente
    onMenuOptionsClicked(options: any, user: any) {
        user.isMenuOpen = false;
        if (options.id == 'details') {

        } else if (options.id == 'manageSTI') {
            this.stiModalUser = user;
            this.modalService.open('manageSTI');
        } else {
            // this.modalService.open('deleteProcess');
        }
    }

    switchSTI() {
        this.stiModalUser.hasSTI = !this.stiModalUser.hasSTI;
    }

    stiValueChanged(text: string) {
        this.stiModalUser.stiValue = text;
    }

    stiDateChanged(newDate: Date) {
        this.stiModalUser.stiDueDate = newDate;
    }

    closeManageSTI(save?: boolean) {
        this.modalService.close('manageSTI')
        if (save) {
            this.confirmSTIModal.subtitle = this.translate.instant('process.sti.CONFIRM_ENABLE_SUB') + ' ' + this.stiModalUser.surname + ' ' + this.stiModalUser.forename
            this.modalService.open('confirmSTI');
        }
    }

    closeConfirmSTI() {
    }

    // Includi ed escludi liste utenti
    assignFile(file: any, isInclude?: boolean) {
        if (isInclude) {
            this.includeFileObject = file;
        } else {
            this.excludeFileObject = file;
        }
    }

    openIncludePeopleModal() {
        this.resetIncludeUpload = true;
        this.modalService.open('includePeople')
    }

    closeIncludePeopleModal(confirm?: boolean) {
        this.modalService.close('includePeople');
        if (confirm) {
            console.log("Carica")
        }
    }

    downloadIncludeTrack() {
        this.isDownloadingIncludeTrack = true;
        console.log("download track");
        this.isDownloadingIncludeTrack = false;
    }

    openExcludePeopleModal() {
        this.resetExcludeUpload = true;
        this.modalService.open('excludePeople')
    }

    closeExcludePeopleModal(confirm?: boolean) {
        this.modalService.close('excludePeople');
        if (confirm) {
            console.log("Carica")
        }
    }

    downloadExcludeTrack() {
        this.isDownloadingExcludeTrack = true;
        console.log("download track e");
        this.isDownloadingExcludeTrack = false;
    }

    // Funzioni modale filtri
    openFiltersModal() {
        this.modalService.open('filtersModal')
    }

    applyFilters() {
        this.closeFiltersModal();
        this.getProcessData();
    }

    clearFilters() {
        let maxLength = Math.max(this.stiFilters.length, this.statusFilters.length, this.definitionFilters.length);
        for (let i = 0; i < maxLength; i++) {
            if (this.stiFilters[i]) {
                this.stiFilters[i].isChecked = false;
            }
            if (this.statusFilters[i]) {
                this.statusFilters[i].isChecked = false;
            }
            if (this.definitionFilters[i]) {
                this.definitionFilters[i].isChecked = false;
            }
        }
        this.closeFiltersModal();
        this.getProcessData();
    }

    closeFiltersModal() {
        this.modalService.close("filtersModal")
    }

    toggleFilter(filterClicked: any) {
        filterClicked.isChecked = !filterClicked.isChecked;
    }

    orderBy(fieldName: string) {
        // console.log("fieldName", fieldName);
    }

    ngOnDestroy() {
        if (this.getProcessesData$) {
            this.getProcessesData$.unsubscribe();
        }
    }
}


