import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentBannerPhaseResponse, JwtPayload, PerfCareAssessmentPhaseKeys, RequiredAuth } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";

@Component({
  selector: 'app-admin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class AdminHomeComponent {
  runningYear$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "GOAL_SETTING";
  bannerImage: string = '/assets/img/banner/bg-banner-gs.png';
  updateUserAck$: any;
  userAcknowledges: any;
  runningPhase: any;
  isLoadingCurrentPhase: boolean = true;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  canAccessCalibration: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    public redirectService: RedirectService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });

    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.appStore.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, userAck$, getRunningPhase$])
      .subscribe(
        ([loggedUser, userAck, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            if (loggedUser.auths && loggedUser.auths.find((x: any) => x == RequiredAuth.PERFORMANCECARE_CALIBRATION_ACCESS)) {
              this.canAccessCalibration = true;
            }
            this.runningPhase = runningPhase;
            this.userAcknowledges = userAck;
            this.phaseName = this.runningPhase.phaseKey; // 'CONTINUOS_FEEDBACK'  
            this.bannerImage = this.getBannerImage(this.phaseName);
          }
        });
  }

  ngOnInit() {
    this.boxDisabledModal = {
      modalId: "a001",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
  }

  goToMonitoring() {
    this.router.navigate(['/admin/monitoring']);
  }

  goToProcessSetup() {
    this.router.navigate(['/admin/processSetup']);
  }

  goToPeopleAnag() {
    this.router.navigate(['/admin/peopleAnagraphics']);
  }

  goToStructureAnag() {
    this.router.navigate(['/admin/structureAnag']);
  }

  goToImpersonate() {
    this.router.navigate(['/admin/impersonate']);
  }

  goToCalibration() {
    this.router.navigate(['/admin/calibration']);
  }

  getBannerImage(phaseName: string) {
    if (phaseName == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW) {
      return 'assets/img/banner/bg-banner-mid-term.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
      return 'assets/img/banner/bg-banner-final-evaluation.png';
    } else if (phaseName == 'CONTINUOS_FEEDBACK') {
      return 'assets/img/banner/bg-banner-feedback.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.POST_CALIBRATION) {
      return 'assets/img/banner/bg-banner-final-evaluation.png';
    } else {
      return 'assets/img/banner/bg-banner-gs.png';
    }
  }
}
