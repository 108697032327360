import { createAction, props } from '@ngrx/store';
import { GetSubordinatesWithPhaseStatusesForManagerResponse } from 'src/commonclasses';

export const RetrieveSuboardinatesWithPhaseStatuses = createAction(
  '[manager] retrieve subordinates with phase statuses from service'
);

export const SetSuboardinatesWithPhaseStatuses = createAction(
  '[manager] set subordinates with phase statuses',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
);

export const UpdateCollaboratorUserStatusType = createAction(
  '[manager] update collaborator user status type',
  props<{
    payload: {
      userId: string,
      userStatusType: string
    }
  }>()
);

export const UpdateCollaboratorData = createAction(
  '[manager] update collaborator status'
);

export const SetUpdatedCollaboratorData = createAction(
  '[manager] set collaborator updated data',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
)

export const SetHeaderTeamClickedButton = createAction(
  '[manager] set header team clicked button',
  props<{ payload: boolean }>()
);

export const SetActiveUserId = createAction(
  '[manager] set active user id',
  props<{ payload: string }>()
)

export const RemoveActiveUserId = createAction(
  '[manager] remove active user id',
)