import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
import * as fromApp from "../../../ngrx/app.reducers";
import { Store } from '@ngrx/store';
import { LangsService } from 'src/app/core/services/langs.service';
import { JwtPayload } from '../../../../commonclasses';
import { combineLatest, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'banner-home',
  templateUrl: 'banner-home.component.html',
  styleUrls: ['./banner-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerHomeComponent {
  @Input() meetingNumber: number = 0;
  @Input() phaseName: string = 'GOAL_SETTING'; // GOAL_SETTING, CONTINUOS_FEEDBACK, TUTORIAL, 'FINAL_EVALUATION'
  @Input() endDate: Date = moment().toDate();
  @Input() backgroundSrc: string = '/assets/img/banner/bg-banner-gs.png' // vedi /banner/
  @Input() customClass: string = 'blue-bg' // blue-bg, red-bg, green-bg
  @Input() isNarrow: boolean = false;
  @Input() hasButton: boolean = false;
  @Input() isCollaborator: boolean = false;
  @Input() buttonLabel: string = '';
  today: string = '';
  isManager: boolean = false;
  isStaff: boolean = false;
  userName: string = '';
  combinedSelectes$: Subscription;

  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  mobileToday: string = '';

  constructor(
    private store: Store<fromApp.AppState>,
    private langsService: LangsService
  ) {
    const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager)
    const isStaff$: Observable<boolean> = this.store.select(fromApp.getIsStaff)
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

    this.combinedSelectes$ = combineLatest([loggedUser$, isManager$, isStaff$])
      .subscribe(
        ([loggedUser, isManager, isStaff]) => {
          if (loggedUser && loggedUser.user) {
            this.userName = loggedUser.user && loggedUser.user.forename;
            let language = this.langsService.getUserLang(loggedUser.user);
            moment.locale(language);
            this.today = moment().format('dddd D MMMM YYYY');
            this.mobileToday = moment().format('D MMMM YYYY');
            this.isManager = isManager;
            this.isStaff = isStaff;
          }
        });
  }

  emitOnButtonClicked(data?: any) {
    this.onButtonClicked.emit(data);
  }

  ngOnDestroy() {
    if (this.combinedSelectes$) {
      this.combinedSelectes$.unsubscribe();
    }
  }
}