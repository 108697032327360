import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
    selector: 'app-admin-create-update-process',
    templateUrl: './createUpdateProcess.component.html',
    styleUrls: ['./createUpdateProcess.component.scss']
})
export class CreateUpdateProcessComponent implements OnInit {
    runningYear$: Subscription = new Subscription();
    runningYear: number = 0;
    processId: string = '';
    isLoadingProcess: boolean = true;

    processName: string = '';
    processNameCopy: string = '';

    nameModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };
    yearModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };
    statusModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };
    noteModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };


    yearOptions: any;
    selectedYear: any;
    selectedYearCopy: any;

    statusOptions: any;
    selectedStatus: any;
    selectedStatusCopy: any;

    notes: string = '';
    notesCopy: string = '';

    modalUpdateId: string = '';
    modalUpdateTitle: string = '';
    modalUpdateSubtitle: string = '';
    modalUpdateText: string = '';
    modalUpdateTitleConfirmTextButton: string = '';
    modalUpdateTextCloseTextButton: string = '';

    constructor(
        public translate: TranslateService,
        public adminService: AdminService,
        public redirectService: RedirectService,
        private store: Store<fromApp.AppState>,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
            if (this.runningYear) {
                this.route.params
                    .subscribe(
                        (params: Params) => {
                            this.processId = params.processId;
                            if (!this.processId) {
                                this.isLoadingProcess = false;
                            } else {
                                this.getProcessData();
                            }
                        }
                    );
            }
        });
    }

    ngOnInit() {
        this.translate.get(
            [
                'process.create.NAME',
                'process.create.NAME_INFO',
                'process.create.YEAR',
                'process.create.YEAR_INFO',
                'process.create.STATUS',
                'process.create.STATUS_INFO',
                'process.create.NOTE',
                'process.create.NOTE_INFO',
                'process.create.ACTIVE',
                'process.create.STANDBY',
                'process.create.CLOSED'
            ])
            .subscribe(translations => {
                this.nameModalInfo = {
                    modalId: "ps001",
                    title: translations['process.create.NAME'],
                    subtitle: '',
                    text: translations['process.create.NAME_INFO'],
                };
                this.yearModalInfo = {
                    modalId: "ps002",
                    title: translations['process.create.YEAR'],
                    subtitle: '',
                    text: translations['process.create.YEAR_INFO'],
                };
                this.statusModalInfo = {
                    modalId: "ps003",
                    title: translations['process.create.STATUS'],
                    subtitle: '',
                    text: translations['process.create.STATUS_INFO'],
                };
                this.noteModalInfo = {
                    modalId: "ps004",
                    title: translations['process.create.NOTE'],
                    subtitle: '',
                    text: translations['process.create.NOTE_INFO'],
                };

                this.yearOptions = [];

                for (let i = 1; i < 4; i++) {
                    this.yearOptions.push({
                        id: this.runningYear + i,
                        title: (this.runningYear + i).toString()
                    })
                }

                this.statusOptions = [
                    {
                        id: 'active',
                        title: translations['process.create.ACTIVE']
                    },
                    {
                        id: 'standby',
                        title: translations['process.create.STANDBY']
                    },
                    {
                        id: 'closed',
                        title: translations['process.create.CLOSED']
                    }
                ];
                if (!this.processId) {
                    this.selectedStatus = this.statusOptions[1];
                }
                // this.modalUpdateId = this.getModalSaveId();
                // this.modalUpdateTitle = this.getModalSaveTitle();
                // this.modalUpdateText = this.getModalSaveDescription();
                // this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelUpdateModal();
                // this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
            })
    }

    nameChanged(text: string) {
        this.processName = text;
    }
    
    
    changeYear(yearItem: any) {
        this.selectedYear = yearItem;
    }
    
    changeStatus(statusItem: any) {
        this.selectedYear = statusItem;
    }
    
    noteChanged(text: string) {
        this.notes = text;
    }
    
    getProcessData() {
        setTimeout(() => {
            this.processId = "123123";
            this.processName = "Prova dato falso";
            this.processNameCopy = "Prova dato falso";
            this.selectedYear = this.yearOptions[1];
            this.selectedYearCopy = this.yearOptions[1];
            this.selectedStatus = this.statusOptions[1];
            this.selectedStatusCopy = this.statusOptions[1];
            this.isLoadingProcess = false;
        }, 1500)
    }


    // Gestisce quando crei/aggiorni un'evidenza
    goToUpdateProcess(isBack?: boolean) {
        if (!this.checkIfDataIsChanged() && isBack) {
            this.goBackBrowser();
        } else {
            this.formatUpdateModal(isBack);
            this.openUpdateModal();
        }
    }

    openUpdateModal() {
        this.modalService.open('processUpdateModal');
    }

    closeUpdateModal() {
        this.modalService.close('processUpdateModal');
    }

    formatUpdateModal(isBack?: boolean) {
        this.modalUpdateId = this.getModalId(isBack);
        this.modalUpdateTitle = this.getModalSaveTitle(isBack);
        this.modalUpdateSubtitle = this.getModalSaveSubtitle();
        this.modalUpdateText = this.getModalSaveDescription(isBack);
        this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
        this.modalUpdateTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
    }

    // Recupera il modalId per la modale di crea/aggiorna evidenza
    getModalId(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return 'ps005';
        } else {
            if (isBack) {
                if (this.processId && this.processId.length) {
                    return 'ps006';
                } else {
                    return 'ps007';
                }
            } else {
                if (this.processId) {
                    return 'ps008';
                } else {
                    return 'ps009';
                }
            }
        }
    }

    getModalSaveTitle(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
        } else {
            if (isBack) {
                return this.translate.instant('generic.GO_BACK');
            } else {
                if (this.processId) {
                    return this.translate.instant('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE');
                } else {
                    return this.translate.instant('privateNotes.createUpdate.INSERT_PRIVATE_NOTE');
                }
            }
        }
    }

    // Sottotitolo per il modale crea/aggiorna evidenza
    getModalSaveSubtitle() {
        if (!this.processId && this.isObbligatoryDataInserted()) {
            return this.translate.instant('privateNotes.modals.YOU_ARE_INSERTING_A_NOTE');
        } else if (this.processId && this.isObbligatoryDataInserted()) {
            return this.translate.instant('privateNotes.modals.YOU_ARE_UPDATING_A_NOTE');
        } else {
            return '';
        }
    }

    // Descrizione per il modale crea/aggiorna evidenza
    getModalSaveDescription(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            if (this.processId && this.processId.length) {
                return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
            } else {
                return this.translate.instant('privateNotes.modals.OBBLIGATORY_DATA_NOT_INSERTED');
            }
        } else {
            if (isBack) {
                if (this.processId && this.processId.length) {
                    return this.translate.instant('privateNotes.modals.DO_YOU_WANT_UPDATE_NOTE_BACK');
                } else {
                    return this.translate.instant('privateNotes.modals.DO_YOU_WANT_CREATE_NOTE_BACK');
                }
            } else {
                if (this.processId && this.processId.length) {
                    return this.translate.instant('privateNotes.modals.DO_YOU_WANT_UPDATE_NOTE');
                } else {
                    return this.translate.instant('privateNotes.modals.DO_YOU_WANT_CREATE_NOTE');
                }
            }
        }
    }

    // Testo modale pulsante close
    getCancelLabelCreateUpdateNoteModal() {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
        } else {
            return this.translate.instant('generic.ANNULL');
        }
    }

    // Testo modale pulsante crea/aggiorna
    getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
        } else {
            if (isBack) {
                if (this.processId && this.processId.length) {
                    return this.translate.instant('privateNotes.modals.UPDATE_NOTE_BACK');
                } else {
                    return this.translate.instant('privateNotes.modals.INSERT_NOTE_BACK');
                }
            } else {
                if (this.processId && this.processId.length) {
                    return this.translate.instant('privateNotes.createUpdate.UPDATE_PRIVATE_NOTE');
                } else {
                    return this.translate.instant('privateNotes.createUpdate.INSERT_PRIVATE_NOTE');
                }
            }
        }
    }

    // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
    isObbligatoryDataInserted() {
        return this.processName && this.processName.trim().length && this.selectedStatus && this.selectedStatus.id && this.selectedYear && this.selectedYear.id;
    }

    checkIfDataIsChanged() {
        return this.processName && this.processNameCopy && this.processName != this.processNameCopy && this.selectedStatus && this.selectedStatusCopy && this.selectedStatus.id != this.selectedStatusCopy.id && this.selectedYear && this.selectedYearCopy && this.selectedYear.id != this.selectedYearCopy.id;
    }


    createProcess() {
        console.log("crea processo");
    }

    updateProcess() {
        console.log("aggiorna processo");
    }

    // Vai indietro back browser
    goBackBrowser() {
        this.redirectService.goBackBrowser();
    }

    ngOnDestroy() {
        if (this.runningYear$) {
            this.runningYear$.unsubscribe();
        }
    }
}